import { styled } from '@mui/material/styles';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

export const Container = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '28px',
    top: 0,
    height: '100%',
    width: '80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    zIndex: 100,

    [theme.breakpoints.down(600)]: {
        height: '60px',
        width: '100%',
        flexDirection: 'row',
        gap: '6px',
        right: 'auto',
    }
}));

export const EmojiStep = styled('div')(({ theme, selected }) => ({
    width: selected ? '50px' : '40px',
    height: selected ? '50px' : '40px',
    borderRadius: '50px',
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: selected ? 'white' : 'rgba(255, 255, 255, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: selected ? '32px' : '22px',
    cursor: 'pointer',

    [theme.breakpoints.down(600)]: {
        width: selected ? '40px' : '30px',
        height: selected ? '40px' : '30px',
        borderRadius: '40px',
        fontSize: selected ? '22px' : '12px',
    }
}));

export const EmojiStepContainer = styled('div')(({ theme, selected }) => ({
    position: 'relative',
}));

export const EmojiStepValidIcon = styled(CheckCircleOutlinedIcon)(({ theme, selected }) => ({
    position: 'absolute',
    left: selected ? '38px' : '30px',
    top: '-10px',
    backgroundColor: theme.palette.background.default,
    borderRadius: '30px',
    padding: '4px',
    fontSize: '28px',
    color: selected ? theme.palette.text.primary : theme.palette.text.secondary,

    [theme.breakpoints.down(600)]: {
        fontSize: '22px',
        left: selected ? '24px' : '18px',
        top: '-8px',
    },
}));

export const Connector = styled('div')(({ theme }) => ({
    height: '32px',
    width: '1px',
    backgroundColor: theme.palette.secondary.main,
}));