import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import TwoPanelsLayout from "../../Components/library/Layout/TwoPanels";
import ClemLeftPanel from "./Clem";

import Sessions from "./Sessions/Sessions";
import Reports from "./Reports";

const ClemPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [view, setView] = useState(params.view ? params.view : null);

    useEffect(() => {

    }, []);

    useEffect(() => {
        setView(params.view)
    }, [params.view]);

    const renderRightPanel = () => {
        if (view === 'sessions') {
            return (
                <Sessions
                    onBack={() => {
                        setView(null);
                        navigate(`/app/clem/`)
                    }}
                />
            );
        } else if (view === 'reports') {
            return (
                <Reports
                    onBack={() => {
                        setView(null);
                        navigate(`/app/clem/`)
                    }}
                />
            );
        }

        return null;
    }

    return (
        <TwoPanelsLayout
            leftPanel={
                <ClemLeftPanel
                    onSelectView={(_view) => {
                        setView(_view);
                        navigate(`/app/clem/${_view}`)
                    }}
                />
            }
            rightPanel={renderRightPanel()}
            showRightPanel={!!view}
        />
    );
};

export default ClemPage;