import { getData } from "../utils";
import DataElem from "../DataElem";
import FileComp from "../../Reporter/Form/Inputs/File";
import { SplitContentContainer, SplitContent, SplitContentSingle, Separator } from "../SessionReport.styled";

export default { // eslint-disable-line
    name: 'previewing',
    emoji: '🤳',
    time: (reports) => {
        return getData(reports.sessionReport, 'times', 'previewing') || 'Not yet';
    },
    title: () => {
        return 'Pre-viewing checklist';
    },
    subtitle: (reports) => {
        return (
            <>
                <DataElem
                    report={reports.sessionReport}
                    attr="files"
                    objkey="images-previewing"
                    sx={{ marginLeft: 0 }}
                    format={(files) => files.length}
                />
                photos
            </>
        );
    },
    content: (reports) => {
        const s3URLS = getData(reports.sessionReport, 'files', 'images-previewing');

        return (
            <>
                <SplitContentContainer>
                    <SplitContentSingle sx={{ width: '100%' }}>
                        <div>
                            🧹
                            <DataElem
                                report={reports.sessionReport}
                                attr="info"
                                objkey="clem-name"
                            />
                            prepared the property for viewings
                        </div>
                    </SplitContentSingle>
                </SplitContentContainer>
                <Separator />
                <SplitContentContainer sx={{ justifyContent: 'space-around' }}>
                    <SplitContent>
                        {
                            s3URLS.filter((_, idx) => idx % 2 === 0).map((url, idx) => (
                                <FileComp file={{ s3: url }} type="image" display key={`previewing${idx}`} />
                            ))
                        }
                    </SplitContent>
                    <SplitContent>
                        {
                            s3URLS.filter((_, idx) => idx % 2 !== 0).map((url, idx) => (
                                <FileComp file={{ s3: url }} type="image" display key={`previewing${idx}`} />
                            ))
                        }
                    </SplitContent>
                </SplitContentContainer>
            </>
        );
    },
};