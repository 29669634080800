import React, { useEffect, useState } from 'react';
import { startOfWeek, endOfWeek, nextMonday, eachDayOfInterval, format, isToday } from 'date-fns';
import { API } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import CircularProgress from "@mui/material/CircularProgress";
import { Container, DayContainer, Day, ClemContainer } from './Availability.styled';
import ClemAvailabilityForm from './ClemAvailability';

const Availability = () => {
    const [clems, setClems] = useState(null);
    const [availabilities, setAvailabilities] = useState(null);
    const [loading, setLoading] = useState(false);
    const [availabilityLoading, setAvailabilityLoading] = useState({});

    const startOfWeekDate = startOfWeek(new Date(), { weekStartsOn: 1 });
    const endOf2WeeksDate = endOfWeek(nextMonday(new Date()), { weekStartsOn: 1 })
    const days = eachDayOfInterval({ start: startOfWeekDate, end: endOf2WeeksDate });

    useEffect(() => {
        const loadClemAndAvailability = async () => {
            setLoading(true);

            const resAvailability = await API.graphql({
                query: queries.clemAvailabilityManager,
                variables: {
                    op: 'get',
                    dateStart: startOfWeekDate.toISOString(),
                    dateEnd: endOf2WeeksDate.toISOString(),
                },
            });

            const _availabilities = JSON.parse(resAvailability.data.clemAvailabilityManager);
            console.log(_availabilities);
            setAvailabilities(_availabilities);

            const res = await API.graphql({
                query: queries.getClems,
                variables: {
                    date: (new Date()).toISOString(),
                },
            });

            const _clems = JSON.parse(res.data.getClems);
            setClems(_clems);

            setLoading(false);
        };

        loadClemAndAvailability();
    }, []); // eslint-disable-line

    const createAvailability = async (date, clem, weight = 0) => {
        const resAvailability = await API.graphql({
            query: queries.clemAvailabilityManager,
            variables: {
                op: 'create',
                date: format(date, 'yyyy-MM-dd'),
                clem: clem.id,
                weight: weight,
            },
        });

        setAvailabilities([
            ...availabilities,
            JSON.parse(resAvailability.data.clemAvailabilityManager),
        ]);
    };

    const deleteAvailability = async (availability) => {
        await API.graphql({
            query: queries.clemAvailabilityManager,
            variables: {
                op: 'delete',
                id: availability.id,
            },
        });

        setAvailabilities([
            ...availabilities.filter(a => a.id !== availability.id)
        ]);
    };

    console.log(availabilities);

    if (loading || !availabilities || !clems) {
        return (
            <Container>
                <CircularProgress sx={{ color: 'center' }} color="primary" />
            </Container>
        );
    }

    return (
        <Container>
            {
                days.map((day, index) => (
                    day < new Date() && !isToday(day)
                        ? null : (
                            <DayContainer key={`weekday-${index}`}>
                                <Day>{format(day, 'EEEE do')}</Day>
                                <ClemContainer>
                                    {
                                        clems.map((clem) => (
                                            <ClemAvailabilityForm
                                                key={`weekday-${index}-clem-${clem.id}`}
                                                clem={clem}
                                                loading={!!availabilityLoading[`${index}${clem.id}`]}
                                                availability={availabilities
                                                    .filter(a => a.date === format(day, 'yyyy-MM-dd'))
                                                    .find(a => a.clem === clem.id)}
                                                onSave={async (clem, weight) => {
                                                    setAvailabilityLoading({ ...availabilityLoading, [`${index}${clem.id}`]: true });
                                                    await createAvailability(day, clem, weight);
                                                    setAvailabilityLoading({ ...availabilityLoading, [`${index}${clem.id}`]: false });

                                                }}
                                                onDelete={async (av) => {
                                                    setAvailabilityLoading({ ...availabilityLoading, [`${index}${clem.id}`]: true });
                                                    await deleteAvailability(av);
                                                    setAvailabilityLoading({ ...availabilityLoading, [`${index}${clem.id}`]: false });
                                                }}
                                            />
                                        ))
                                    }
                                </ClemContainer>
                                <Day>{`N=${availabilities.filter(a => a.date === format(day, 'yyyy-MM-dd')).length}`}</Day>
                            </DayContainer>
                        )
                ))
            }
        </Container>
    );
};

export default Availability;