import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import Text from '../../Typography/Text';

export const ContainerSelectDisplay = styled(Stack)(({ theme }) => ({
    padding: '20px',
    width: '100%',
    gap: '20px',

    [theme.breakpoints.down(1000)]: {
        padding: '12px',
        paddingTop: '0px',
        gap: '8px',
    },
}));

export const CardSelectDisplayTitle = styled(Text)(({ theme }) => ({
    fontSize: '14px',
    width: '100%',

    [theme.breakpoints.down(600)]: {
        fontSize: '12px',
    },
}));

export const CardSelectDisplayBody = styled(Stack)(({ theme }) => ({
    padding: '20px',

    [theme.breakpoints.down(600)]: {
        padding: '12px',
    },
}));

export const CardSelectDisplayValue = styled(Text)(({ theme }) => ({
    fontSize: '30px',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down(600)]: {
        fontSize: '20px',
    },
}));
