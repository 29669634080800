import { styled } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) => ({
    position: 'relative',
    width: 'calc(100% - 40px)',
    height: '100%',
    marginLeft: '40px',
    maxWidth: '800px',
    boxShadow: theme.shadows[18],
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,

    [theme.breakpoints.down(600)]: {
        width: 'calc(100% - 40px)',
        margin: '0px 20px 0px',
        height: '100%',
    },
}));

export const Content = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    overflow: 'auto',
    padding: '20px',
    paddingBottom: '94px',

    [theme.breakpoints.down(600)]: {
        paddingBottom: '52px',
    }
}));

export const FormContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
}));

export const Footer = styled('div')(({ theme }) => ({
    width: '210px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'flex-end',
    alignSelf: 'center',
    gap: '40px',
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    bottom: '20px',
    borderRadius: '20px',
    boxShadow: theme.shadows[8],
    padding: '10px',
    zIndex: 3,

    [theme.breakpoints.down(600)]: {
        bottom: '12px',
    },
}));

export const Viewing = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    margin: '12px',
    marginBottom: '0px',
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    boxShadow: theme.shadows[4],
    borderRadius: '12px',
}));

export const CustomerNameContainer = styled('div')(({ theme }) => ({
    display: 'flex',
}));

export const Emoji = styled('div')(({ theme }) => ({
    fontSize: '22px',
    display: 'inline-block',
    lineHeight: '22px',
}));

export const CustomerName = styled('div')(({ theme }) => ({
    fontSize: '16px',
    display: 'inline-block',
    fontWeight: 'bold',
    marginLeft: '12px',
    lineHeight: '22px',
    verticalAlign: 'middle',
}));

export const ViewingStatusContainer = styled('div')(({ theme }) => ({
    width: '100%',
    position: 'relative',
    marginTop: '12px',
}));