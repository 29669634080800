import React, { useState } from 'react';
import { getData } from './utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
    Container,
    Content,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionHeader,
    AccordionTime,
    AccordionEmoji,
    AccordionTitleContainer,
    AccordionTitle,
    AccordionSubtitle,
} from './SessionReport.styled';
import SessionReportHeader from './SessionReportHeader';
import Steps from './Steps/Steps';

const sortReports = (a, b) => (
    new Date(getData(a, 'times', 'viewing-scheduled-start')) - new Date(getData(b, 'times', 'viewing-scheduled-start'))
);

const SessionReport = ({ reports }) => {
    const [expanded, setExpanded] = useState({});

    const handleChange = (panel) => (_, isExpanded) => {
        setExpanded({
            ...expanded,
            [panel]: isExpanded,
        });
    };

    const _reports = {
        ...reports,
        viewingReports: reports.viewingReports.sort(sortReports),
        isBlockViewings: !!reports.viewingReports.find((vr) => {
            const cn = vr.info.find((i) => i.key === 'customer-name').value;
            return cn === 'BLOCK VIEWING';
        }),
        success: reports.viewingReports.filter((v) => (
            v.viewingStatus !== 'CANCEL' && v.viewingStatus !== 'NOSHOW'
        )),
        fail: reports.viewingReports.filter((v) => (
            v.viewingStatus === 'CANCEL' || v.viewingStatus === 'NOSHOW'
        )),
    };

    return (
        <Container>
            <Content>
                <SessionReportHeader reports={_reports} />
                {
                    (_reports.isBlockViewings ? Steps.filter(s => s.name !== 'viewings' && s.name !== 'notified') : Steps).map((step) => (
                        <Accordion
                            expanded={step.expanded ? true : !!expanded[step.name]}
                            onChange={step.expanded ? () => {} : handleChange(step.name)}
                            key={`report-accordion-${step.name}`}
                        >
                            <AccordionSummary
                                expandIcon={step.expanded ? <VisibilityOutlinedIcon /> : <ExpandMoreIcon />}
                                aria-controls="report-content"
                            >
                                <AccordionHeader>
                                    <AccordionTime>
                                        {step.time(_reports)}
                                    </AccordionTime>
                                    <AccordionEmoji>
                                        {step.emoji}
                                    </AccordionEmoji>
                                    <AccordionTitleContainer>
                                        <AccordionTitle>
                                            {step.title(_reports)}
                                        </AccordionTitle>
                                        <AccordionSubtitle>
                                            {step.subtitle(_reports)}
                                        </AccordionSubtitle>
                                    </AccordionTitleContainer>
                                </AccordionHeader>
                            </AccordionSummary>
                            <AccordionDetails>
                                {step.content(_reports)}
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
            </Content>
        </Container >
    )
};

export default SessionReport;