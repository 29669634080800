import { styled } from '@mui/material/styles';
import _Accordion from '@mui/material/Accordion';
import _AccordionSummary from '@mui/material/AccordionSummary';
import _AccordionDetails from '@mui/material/AccordionDetails';

export const Container = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    padding: '20px',
    paddingBottom: '120px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',

    [theme.breakpoints.down(600)]: {
        padding: '20px',
        paddingBottom: '66px',
    }
}));

export const Content = styled('div')(({ theme }) => ({
    maxWidth: '1100px',

    '& > .MuiAccordion-root:last-of-type': {
        borderRadius: '12px',
    },
}));

export const PropertyContainer = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
    width: '100%',
    padding: '12px',
    borderRadius: '12px',
    marginBottom: '20px',
    boxShadow: theme.shadows[24],
    position: 'sticky',
    zIndex: 10,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `2px solid ${theme.palette.background.default}`,

    [theme.breakpoints.down(800)]: {
        position: 'relative',
    },

    [theme.breakpoints.down(600)]: {
        flexDirection: 'column',
        gap: '20px',
    },
}));

export const PropertyEmoji = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '22px',
    fontSize: '28px',
    display: 'inline-block',
    height: '44px',
    width: '44px',
    lineHeight: '44px',
    textAlign: 'center',
    verticalAlign: 'middle',
}));

export const PropertyAddressContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: '12px',
}));

export const PropertyAddress = styled('div')(({ theme }) => ({
    display: 'inline-block',
    margin: '0px 12px',
    textAlign: 'center',
    verticalAlign: 'middle',
}));

export const HeaderSummaryContainer = styled('div')(({ theme }) => ({
    width: '400px',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    padding: '12px 20px 8px',
    borderRadius: '8px',
    display: 'flex',
    gap: '20px',
    flexShrink: 0,
    flexWrap: 'wrap',

    [theme.breakpoints.down(800)]: {
        width: '260px',
        gap: '12px',

        '& > div': {
            width: '100%',
        }
    },
}));

export const HeaderElem = styled('div')(({ theme }) => ({

}));

export const HeaderStatic = styled('p')(({ theme }) => ({
    display: 'inline',
    color: theme.palette.primary.light,
    margin: '0px',
}));

export const HeaderData = styled('p')(({ theme }) => ({
    borderBottom: '1px dashed',
    display: 'inline',
    paddingBottom: '2px',
    margin: '0px 8px',
}));

export const Accordion = styled(_Accordion)(({ theme }) => ({
    borderRadius: '12px',
    marginTop: '8px',
    marginLeft: '30px',
    marginRight: '30px',

    '&.Mui-expanded': {
        marginLeft: '20px',
        marginRight: '20px',
    },

    '&:before': {
        width: 'calc(100% - 24px)',
        left: '12px',
    },

    [theme.breakpoints.down(600)]: {
        marginLeft: '12px',
        marginRight: '12px',

        '&.Mui-expanded': {
            marginLeft: '0px',
            marginRight: '0px',
        },
    }
}));

export const AccordionSummary = styled(_AccordionSummary)(({ theme }) => ({
    '&.Mui-expanded': {
        backgroundColor: 'rgba(0,0,0, 0.03)',
        borderRadius: '12px 12px 0px 0px',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },

    ['& .MuiAccordionSummary-content']: { // eslint-disable-line
        flexWrap: 'wrap',
        maxWidth: 'calc(100% - 24px)',
    }
}));

export const AccordionDetails = styled(_AccordionDetails)(({ theme }) => ({
    padding: '20px 80px',

    [theme.breakpoints.down(800)]: {
        padding: '20px 40px',
    },

    [theme.breakpoints.down(600)]: {
        padding: '20px 20px',
    },
}));

export const AccordionHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const AccordionTime = styled('div')(({ theme }) => ({
    fontSize: '12px',
    backgroundColor: theme.palette.background.default,
    padding: '8px',
    borderRadius: '12px',
    height: '28px',
    lineHeight: '12px',
    fontWeight: 'bold',
    width: '70px',
    textAlign: 'center',
}));

export const AccordionEmoji = styled('div')(({ theme }) => ({
    flexShrink: 0,
    backgroundColor: theme.palette.background.default,
    padding: '2px 8px',
    borderRadius: '12px',
    fontSize: '18px',
    lineHeight: '28px',
    marginRight: '12px'
}));

export const AccordionTitleContainer = styled('div')(({ theme }) => ({
    minWidth: '320px',
    width: 'calc(100% - 140px)',

    [theme.breakpoints.down(650)]: {
        width: '100%',
        textAlign: 'center',
    }
}));

export const AccordionTitle = styled('div')(({ theme }) => ({
    flexShrink: 0,
    fontWeight: 'bold',
}));

export const AccordionSubtitle = styled('div')(({ theme }) => ({
    flexShrink: 0,
    color: theme.palette.primary.light,
    marginTop: '4px',
    width: '100%',
}));

export const ViewingList = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
}));

export const SplitContentContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    alignItems: 'center',
    lineHeight: '2em',

    [theme.breakpoints.down(800)]: {
        flexDirection: 'column-reverse',
    }
}));

export const SplitContent = styled('div')(({ theme }) => ({
    width: 'calc(50% - 10px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignSelf: 'flex-start',
    lineHeight: '2em',

    [theme.breakpoints.down(800)]: {
        width: 'calc(100%)',
    }
}));

export const SplitContentSingle = styled('div')(({ theme }) => ({
    width: 'calc(50% - 10px)',
    lineHeight: '2em',

    [theme.breakpoints.down(800)]: {
        width: 'calc(100%)',
    }
}));

export const List = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
}));

export const Separator = styled('div')(({ theme }) => ({
    width: '60%',
    height: '1px',
    backgroundColor: theme.palette.primary.main,
    opacity: 0.3,
    margin: '40px auto',
}));

export const ViewingSubtitleContainer = styled('div')(({ theme }) => ({
    marginTop: '8px',
}));