import React from 'react';
import { Container, EmojiStepContainer, EmojiStep, EmojiStepValidIcon, Connector } from './WizardNav.styled';

const WizardNav = ({ currentStep, totalSteps, goToStep, stepStatus, emoji }) => {
    const handleStepChange = (step) => {
        goToStep(step);
    };

    return (
        <Container>
            {
                currentStep !== totalSteps &&
                Object.keys(stepStatus).map((key, index) => ([
                    <EmojiStepContainer key={`wizard-nav-${key}`}>
                        <EmojiStep
                            selected={currentStep === (index + 1)}
                            onClick={() => handleStepChange((index + 1))}
                        >{emoji[key]}</EmojiStep>
                        {stepStatus[key] ? <EmojiStepValidIcon selected={currentStep === (index + 1)} /> : null}
                    </EmojiStepContainer>,
                    index < Object.keys(stepStatus).length - 1 ? <Connector key={`wizard-nav-${key}-connector`}/> : null,
                ]))
            }
        </Container>
    );
};

export default WizardNav;