import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { Auth, DataStore } from 'aws-amplify';
import { User } from '../../models';
import { LargeContainer, Logo, FieldContainer, HalfInputContainer, Input, HalfInput, ActionButton } from './Login.styled';

const fetchUserData = async (id) => {
    try {
        const userData = await DataStore.query(User, id);
        return userData;
    } catch (e) {
        console.log(e);
    }
};

const createUser = async ({ id, firstName, lastName, email, phone, keySecureLocation }) => {
    await API.graphql({
        query: queries.createUserData,
        variables: {
            firstName,
            lastName,
            email,
            phone,
            keySecureLocation,
        },
    });

    const userData = await fetchUserData(id);
    
    return userData;
};

const UserData = ({ onUser }) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        // email: {
        //     value: '',
        //     valid: false,
        // },
        // password: {
        //     value: '',
        //     valid: false,
        // },
    });

    // const navigate = useNavigate();

    const handleFormData = (key, value, valid) => {
        const newFormData = { ...formData, [key]: { value, valid } };
        setFormData(newFormData);
    };

    const handleUserData = async () => {
        setLoading(true);

        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log(user);
            const self = await createUser({
                id: user.username,
                firstName: formData.firstName.value,
                lastName: formData.lastName.value,
                email: user.attributes.email,
                phone: formData.phone.value,
                keySecureLocation: formData.office.value
            });

            onUser(user, self);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }

    return (
        <LargeContainer>
            <Logo src='logo-white.svg' />
            <FieldContainer>
                <HalfInputContainer>
                    <HalfInput
                        required
                        name="UserData-first-name"
                        label="First Name"
                        helperText=""
                        placeholder=""
                        onChange={(value, valid) => handleFormData('firstName', value, valid)}
                    />
                    <HalfInput
                        required
                        name="UserData-last-name"
                        label="Last Name"
                        helperText=""
                        placeholder=""
                        onChange={(value, valid) => handleFormData('lastName', value, valid)}
                    />
                </HalfInputContainer>
                <Input
                    required
                    name="UserData-phone"
                    label="Mobile Phone"
                    helperText="Work mobile"
                    placeholder="+44 07xxx xxx xxx"
                    errorHelperText="Invalid phone number - format: +44 07xxx xxx xxx"
                    mask={'+44 00000000000'}
                    validator={/^\+44 (07[\d]{8,12}|447[\d]{7,11})$/}
                    onChange={(value, valid) => handleFormData('phone', value, valid)}
                />
                <Input
                    required
                    name="UserData-office-address"
                    label="Office Address"
                    helperText="This will be used for keys pickups/dropoffs"
                    placeholder=""
                    onChange={(value, valid) => handleFormData('office', value, valid)}
                />
                {error}
                <ActionButton
                    loading={loading}
                    disabled={!Object.keys(formData).reduce((final, curr) => (final && formData[curr].valid), true)}
                    variant="outlined"
                    size="large"
                    onClick={handleUserData}
                >
                    Confirm
                </ActionButton>
            </FieldContainer >
        </LargeContainer>
    );
};

export default UserData;