import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import Time from '../Inputs/Time';
import { FormContainer, Viewing, CustomerNameContainer, Emoji, CustomerName, ViewingStatusContainer } from '../form.styled';
import { StatusContainer } from '../Inputs/inputs.styled';
import { format } from 'date-fns';

const sortReports = (a, b) => (
    new Date(a.times.find((i) => i.key === 'viewing-scheduled-start').timeStamp) - new Date(b.times.find((i) => i.key === 'viewing-scheduled-start').timeStamp)
)

const ViewingsForm = ({ onUpdate, reports }) => {
    const [loading, setLoading] = useState(false);

    const handleFormData = async ({ type, id, data, key, viewingReportId }) => {
        await onUpdate({ type, id, data, key, viewingReportId });
    };

    return (
        <FormContainer>
            {
                reports.viewingReports.sort(sortReports).map((report) => (
                    <Viewing key={`viewing-report-display-${report.id}`}>
                        <CustomerNameContainer>
                            <Emoji>🧑</Emoji>
                            <CustomerName>{`${report.info.find((i) => i.key === 'customer-name').value}`}</CustomerName>
                        </CustomerNameContainer>
                        <CustomerNameContainer>
                            <Emoji>🕑</Emoji>
                            <CustomerName>{`${report.times.find((i) => i.key === 'viewing-scheduled-start').timeStamp.split('T')[1].substr(0, 5)}`}</CustomerName>
                        </CustomerNameContainer>
                        <ViewingStatusContainer>
                            <FormControl fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={report.viewingStatus}
                                    onChange={async (e) => {
                                        setLoading(true);
                                        await handleFormData({ type: 'update', data: { viewingStatus: e.target.value }, viewingReportId: report.id })
                                        setLoading(false);
                                    }}
                                    disabled={loading}
                                    label="Status"
                                    sx={{ width: '100%' }}
                                >
                                    <MenuItem value={'PENDING'}>⌛ Pending</MenuItem>
                                    <MenuItem value={'NORMAL'}>🤟 Success</MenuItem>
                                    <MenuItem value={'LATE'}>🏃 Late</MenuItem>
                                    <MenuItem value={'CANCEL'}>🙅 Cancelled</MenuItem>
                                    <MenuItem value={'NOSHOW'}>🤷 No Show</MenuItem>
                                </Select>
                            </FormControl>
                            <StatusContainer>
                                {
                                    loading ? (
                                        <BackupOutlinedIcon fontSize='inherit' />
                                    ) : (
                                        <CloudDoneOutlinedIcon fontSize='inherit' />
                                    )
                                }
                            </StatusContainer>
                        </ViewingStatusContainer>
                        {
                            report.viewingStatus !== 'NOSHOW' && report.viewingStatus !== 'CANCEL' ? (
                                <>
                                    <Time
                                        name={'viewing-start'}
                                        title={'Viewing start'}
                                        subtitle={'At what time did you customer arrive?'}
                                        currentTime={report ? report.times.filter((t) => t.key === 'viewing-start')[0] : null}
                                        onChange={async (newTime, id) => {
                                            await handleFormData({ type: 'times', id, data: { time: format(newTime, 'HH:mm') }, key: 'viewing-start', viewingReportId: report.id })
                                        }}
                                    />
                                    <Time
                                        name={'viewing-end'}
                                        title={'Viewing end'}
                                        subtitle={'At what time did the customer leave?'}
                                        currentTime={report ? report.times.filter((t) => t.key === 'viewing-end')[0] : null}
                                        onChange={async (newTime, id) => {
                                            await handleFormData({ type: 'times', id, data: { time: format(newTime, 'HH:mm') }, key: 'viewing-end', viewingReportId: report.id })
                                        }}
                                    />
                                </>
                            ) : (
                                report.viewingStatus === 'CANCEL' ? (
                                    <Time
                                        name={'viewing-cancel'}
                                        title={'Viewing cancel'}
                                        subtitle={'At what time did the customer cancel?'}
                                        currentTime={report ? report.times.filter((t) => t.key === 'viewing-cancel')[0] : null}
                                        onChange={async (newTime, id) => {
                                            await handleFormData({ type: 'times', id, data: { time: format(newTime, 'HH:mm') }, key: 'viewing-cancel', viewingReportId: report.id })
                                        }}
                                    />
                                ) : null
                            )
                        }
                    </Viewing>
                ))
            }
        </FormContainer>
    );
};

export default ViewingsForm;