import React, { useState } from 'react';
import { Container, Title, SubTitle, FilesContainer, NowButton, TimePickerContainer, TimeSpacer, InputStatusContainer } from './inputs.styled';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';


const getDateFromTime = (time) => {
    const d = new Date();
    d.setHours(time.split(':')[0]);
    d.setMinutes(time.split(':')[1]);

    return d;
};

const Time = ({
    currentTime = null,
    sessionId,
    name,
    title,
    subtitle,
    onChange = () => { },
}) => {
    const [timeValue, setTimeValue] = useState(currentTime ? getDateFromTime(currentTime.time) : null);
    const [loading, setLoading] = useState(false);

    const handleTimeChange = async (newTime) => {
        setLoading(true);
        setTimeValue(newTime);
        await onChange(newTime, currentTime ? currentTime.id : null);
        setLoading(false);
    };

    return (
        <Container data-disabled={loading}>
            {!loading && !currentTime ? null : (
                <InputStatusContainer>
                    {
                        loading ? (
                            <BackupOutlinedIcon fontSize='inherit' />
                        ) : (
                            currentTime && currentTime.id ? (
                                <CloudDoneOutlinedIcon fontSize='inherit' />
                            ) : null
                        )
                    }
                </InputStatusContainer>
            )}
            <div>
                <Title>{title}</Title>
                <SubTitle>{subtitle}</SubTitle>
            </div>
            <FilesContainer>
                <TimePickerContainer styles={"width: 100px;"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileTimePicker
                            value={timeValue}
                            onChange={(newValue) => { setTimeValue(newValue) }}
                            onAccept={(newValue) => {
                                handleTimeChange(newValue);
                            }}
                            size="small"
                            renderInput={(params) => <TextField size="small" placeholder="18:15" {...params} />}
                            ampm={false}
                        />
                    </LocalizationProvider>
                </TimePickerContainer>
                <TimeSpacer>OR</TimeSpacer>
                <NowButton
                    size="small"
                    variant="outlined"
                    onClick={() => { handleTimeChange(new Date()) }}
                >
                    Now
                </NowButton>
            </FilesContainer>
        </Container>
    );
};

export default Time;