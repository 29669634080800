import Text from '../../Components/library/Typography/Text';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Card from '../../Components/library/Cards/Generic';
import Header from '../../Components/library/Cards/Headers/Standard';
import APPS_CONFIG from '../config';
import MarketPlaceTag from '../../Components/library/Tag/MarketPlaceTag';

const PropertyCard = ({
    sx = {},
}) => {
    return (
        <Card
            sx={{
                backgroundColor: 'background.default',
                ...sx,
            }}
            header={
                <Header
                    emoji='🏡'
                    title={
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                        >
                            <Text weight="bold" size="medium" span>{'E5 9QG'.split(' ')[0]}</Text>
                            <Text span>{' - 178 Iron Court, 3 Essex Wharf, London'.replace(/[0-9]/gi, '')}</Text>
                        </Stack>
                    }
                />
            }
            body={
                <Stack
                    gap={1.5}
                    direction="row"
                >
                    <Text>Property details <Text sx={{ display: 'inline-block' }} href="https://www.zoopla.co.uk/to-rent/details/64380690" target="_blank"><MarketPlaceTag marketPlace='zoopla' /></Text></Text>
                    <Divider  orientation="vertical" flexItem />
                    <Text>Managed by <Text span size="medium" weight="bold">Hood Estates</Text></Text>
                </Stack>
            }
        />
    );
};

export default PropertyCard;