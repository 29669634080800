import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Button, Stack } from "@mui/material";

import Card from "../../Components/library/Cards/Generic";
import Header from "../../Components/library/Cards/Headers/Standard";
import Footer from "../../Components/library/Cards/Footers/Action";
import Text from "../../Components/library/Typography/Text";

import KeysData from "../../data/keys";
import NewKeys from "./New";
import KeysDisplay from "./KeysDisplay";

const Keys = ({
    propertyId,
    withDeactivatedKeys = true,
    headerProps = {},
    selectedId = '',
    selectable = false,
    selectedOnly = false,
    deactivatable = true,
    onSelect = () => { },
    color = 'secondary.light',
}) => {
    const [keys, setKeys] = useState(null);
    const [tempKeys, setTempKeys] = useState([]);

    useEffect(() => {
        const fetchKeys = async () => {
            const _keys = await KeysData.getByProperty(propertyId);
            setKeys(_keys.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
        };

        fetchKeys();
    }, [propertyId]);

    const saveKeys = async (keysData) => {
        const k = await KeysData.create({
            propertyId: propertyId,
            type: keysData.type,
            info: keysData.notes,
            data: keysData.data,
            status: 'ACTIVE',
        });

        setKeys(
            [
                ...(keys ? keys : []),
                {
                    ...k,
                    createdAt: (new Date()).toISOString(),
                }
            ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
    };

    const updateKeys = async (keysData) => {
        const k = await KeysData.update(keysData.id, keysData);
        const newKeys = keys.map(ke => ke.id === k.id ? k : ke).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setKeys(newKeys);
    };

    return (
        <Card
            header={
                <Header
                    emoji="🔑"
                    title="Keys"
                    sx={{ backgroundColor: color }}
                    {...headerProps}
                />
            }
            body={
                keys && keys.length && keys.find(k => k.id === selectedId) && selectedOnly ? (
                    <KeysDisplay
                        selected={false}
                        selectable={false}
                        deactivatable={false}
                        keys={keys.find(k => k.id === selectedId)}
                    />
                ) : (
                    <Stack
                        gap={2}
                    >
                        {

                            keys && keys.filter(k => k.status === 'ACTIVE').length ? (
                                <Stack
                                    gap={2}
                                >
                                    {
                                        withDeactivatedKeys ? (
                                            <Text weight="bold" size="medium">🆗 Active Keys</Text>
                                        ) : null
                                    }
                                    <Stack
                                        gap={2}
                                        sx={{ pl: withDeactivatedKeys ? '20px' : '0px' }}
                                    >
                                        {
                                            keys.filter(k => k.status === 'ACTIVE').map(k => (
                                                <KeysDisplay
                                                    key={k.id}
                                                    onSelect={() => onSelect(k)}
                                                    selected={selectedId === k.id}
                                                    selectable={selectable}
                                                    deactivatable={deactivatable}
                                                    keys={k}
                                                    onDeactivate={() => {
                                                        updateKeys({ ...k, status: 'DEACTIVATED' });
                                                    }}
                                                />
                                            ))
                                        }
                                    </Stack>
                                </Stack>
                            ) : null
                        }
                        {

                            withDeactivatedKeys && keys && keys.filter(k => k.status !== 'ACTIVE').length ? (
                                <Stack
                                    gap={2}
                                >
                                    <Text weight="bold" size="medium">♻️ Deactivated Keys</Text>
                                    <Stack
                                        gap={2}
                                        sx={{
                                            opacity: 0.5,
                                            pl: '20px',
                                            pointerEvents: 'none',
                                        }}
                                    >
                                        {
                                            keys.filter(k => k.status !== 'ACTIVE').map(k => (
                                                <KeysDisplay
                                                    key={k.id}
                                                    onSelect={() => onSelect(k)}
                                                    selectable={selectable}
                                                    deactivatable={deactivatable}
                                                    selected={selectedId === k.id}
                                                    keys={k}
                                                />
                                            ))
                                        }
                                    </Stack>
                                </Stack>
                            ) : null
                        }
                        {
                            tempKeys.length ? (
                                tempKeys.map((tempKey) => (
                                    <NewKeys
                                        key={tempKey.id}
                                        id={tempKey.id}
                                        onSave={async (tmpk) => {
                                            await saveKeys(tmpk);
                                            setTempKeys(tempKeys.filter((tk) => tk.id !== tmpk.id))
                                        }}
                                        onCancel={(tmpk) => {
                                            setTempKeys(tempKeys.filter((tk) => tk.id !== tmpk.id))
                                        }}
                                    />
                                ))
                            ) : null
                        }
                    </Stack>
                )
            }
            footer={
                !selectedOnly ? (
                    <Footer
                        align='flex-end'
                        actions={
                            [
                                {
                                    button: true,
                                    buttonVariant: 'outlined',
                                    value: 'ADD NEW KEYS',
                                    sx: (!Object.keys(tempKeys).length && propertyId ? {
                                        opacity: 1,
                                        pointerEvents: 'all',
                                    } : {
                                        opacity: 0.3,
                                        pointerEvents: 'none',
                                        cursor: 'not-allowed',
                                    }),
                                    onClick: () => { setTempKeys([...tempKeys, { id: uuidv4() }]) },
                                },
                            ]}
                    />
                ) : null
            }
        />
    );
};

export default Keys;