import { useParams } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import Card from "../../Components/library/Cards/Generic";
import Header from "../../Components/library/Cards/Headers/Standard";
import Footer from "../../Components/library/Cards/Footers/Action";
import FeedbackButton from "../../Components/library/Button/FeedbackButton";
import Text from "../../Components/library/Typography/Text";
import Tag from "../../Components/library/Tag/Tag";

import { useOutletContext } from '../../Auth';
import APPS_CONFIG from "../config";

import {
    ContainerCard,
    CardHeaderContainer,
    CardTitleText
} from '../Leads/leads.styled';
import MarketPlaceTag from "../../Components/library/Tag/MarketPlaceTag";
import { useEffect, useState } from "react";

const User = ({
    integrations,
    onSave = () => { },
    onSelectView = () => { },
}) => {
    const context = useOutletContext();
    const params = useParams();
    const [initialDisplay, setInitialDisplay] = useState(true);

    useEffect(() => {
        if (integrations && initialDisplay) {
            setInitialDisplay(false);
        }

    }, [integrations]);

    return (
        <ContainerCard
            header={
                <CardHeaderContainer>
                    <Header
                        title={
                            <CardTitleText size="large" weight="bold" sx={{
                                flexShrink: 0,
                                display: 'flex',
                                alignItems: 'center',
                                alignSelf: 'flex-start',
                                textAlign: 'left',
                                justifyContent: 'flex-start',
                                pointerEvents: 'none',
                                gap: 1,
                                position: 'relative',
                            }}><img src={APPS_CONFIG.user.logo} width="20px" /> {context.userData.name}</CardTitleText>
                        }
                    />
                    <Stack sx={{ width: '100%', height: 'fit-content', pt: 1.5 }}>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{ width: 'fit-content', m: 2.5, ml: 1.5, mt: 1.5 }}
                            onClick={() => context.signOut()}
                        >
                            <LogoutOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                            Log out
                        </Button>
                    </Stack>
                </CardHeaderContainer>
            }
            withBodyContainer={false}
            body={
                <Stack
                    gap={2.5}
                    sx={{
                        p: 2.5,
                        maxHeight: '100%',
                        height: '100%',
                        overflow: 'auto',
                        opacity: initialDisplay ? 0 : 1,
                        transition: 'opacity 0.5s ease-out',
                    }}
                >
                    {
                        context.userData.isAdmin ? (
                            <Card
                                header={
                                    <Header
                                        emoji='👮'
                                        title="Admin"
                                        sx={{ backgroundColor: APPS_CONFIG.user.color }}
                                    />
                                }
                                body={
                                    <Text
                                        color="primary.light"
                                        span
                                        sx={{ width: '100%', textAlign: 'center' }}
                                    >
                                        Go to the <Text weight="bold" span>Admin</Text> view
                                    </Text>
                                }
                                footer={
                                    <Footer
                                        align='flex-end'
                                        actions={[
                                            {
                                                button: true,
                                                buttonVariant: 'contained',
                                                value: 'See Admin',
                                                sx: {
                                                    opacity: params.view === 'admin' ? 0.5 : 1,
                                                    pointerEvents: params.view === 'admin' ? 'none' : 'all',
                                                },
                                                onClick: () => {
                                                    onSelectView('admin')
                                                }
                                            },
                                        ]}
                                    />
                                }
                            />
                        ) : null
                    }
                    <Card
                        header={
                            <Header
                                emoji='ℹ️'
                                title="User Information"
                                sx={{ backgroundColor: APPS_CONFIG.user.color }}
                            />
                        }
                        withBodyContainer={false}
                        body={
                            <Stack
                            // gap={2.5}
                            >
                                <Stack
                                    direction="row"
                                    flexWrap="wrap"
                                    gap={1}
                                    sx={{ p: 1.5 }}
                                >
                                    <FeedbackButton
                                        revertAfter={2000}
                                        variant="outlined"
                                        size="small"
                                        sx={{ textTransform: 'none' }}
                                        feedback={<div>Copied to clipboard</div>}
                                    >{context.userData.email}</FeedbackButton>
                                    <FeedbackButton
                                        revertAfter={2000}
                                        variant="outlined"
                                        size="small"
                                        sx={{ textTransform: 'none' }}
                                        feedback={<div>Copied to clipboard</div>}
                                    >{context.userData.phone}</FeedbackButton>
                                </Stack>
                                <Divider />
                                <Stack
                                    gap={1.5}
                                    direction="row"
                                    alignItems="center"
                                    sx={{ p: 2.5 }}
                                >
                                    <Tag>🏢 Office Location</Tag>
                                    <Text weight="bold">{context.userData.keySecureLocation}</Text>
                                </Stack>
                            </Stack>
                        }
                    />
                    <Card
                        selected={params.view === 'integrations'}
                        header={
                            <Header
                                emoji='🔗'
                                title="Integrations"
                                tags={[
                                    <Tag key='beta-tag'>BETA</Tag>
                                ]}
                                sx={{ backgroundColor: APPS_CONFIG.user.color }}
                            />
                        }
                        body={
                            // integrations && integrations.length ? (
                            //     <Stack
                            //         gap={1}
                            //     >
                            //         {
                            //             integrations.map((i) => (
                            //                 <Stack
                            //                     key={`stack-${i.id}`}
                            //                     direction="row"
                            //                     alignItems="center"
                            //                     gap={2}
                            //                 >
                            //                     <MarketPlaceTag key={`tag-${i.id}`} marketPlace={i.platform.toLowerCase()} />
                            //                     <Text size="medium" weight="bold">🔗</Text>
                            //                     <Tag>
                            //                         <img src="/app/logo-purple.svg" width="20px" />
                            //                     </Tag>
                            //                 </Stack>
                            //             ))
                            //         }
                            //     </Stack>
                            // ) : (
                            <Stack
                                gap={1.5}
                                alignItems="center"
                            >
                                <Text
                                    color="primary.light"
                                    span
                                    sx={{ width: '100%', textAlign: 'center' }}
                                >
                                    Contact us to integrate with
                                </Text>
                                <Stack
                                    direction="row"
                                    gap={1.5}
                                >
                                    <MarketPlaceTag marketPlace="rightmove" />
                                    <MarketPlaceTag marketPlace="zoopla" />
                                    <MarketPlaceTag marketPlace="onthemarket" />
                                </Stack>
                            </Stack>
                            // )
                        }
                    // footer={
                    //     <Footer
                    //         align='flex-end'
                    //         actions={[
                    //             {
                    //                 button: true,
                    //                 buttonVariant: 'contained',
                    //                 value: 'Configure',
                    //                 sx: {
                    //                     opacity: params.view === 'integrations' ? 0.5 : 1,
                    //                     pointerEvents: params.view === 'integrations' ? 'none' : 'all',
                    //                 },
                    //                 onClick: () => {
                    //                     onSelectView('integrations')
                    //                 }
                    //             },
                    //         ]}
                    //     />
                    // }
                    />
                    <Card
                        selected={params.view === 'bills'}
                        header={
                            <Header
                                emoji='🧾'
                                title="Bills"
                                sx={{ backgroundColor: APPS_CONFIG.user.color }}
                                tags={[
                                    <Tag key="beta-tag">BETA</Tag>
                                ]}
                            />
                        }
                        body={
                            <Text
                                color="primary.light"
                                span
                                sx={{ width: '100%', textAlign: 'center' }}
                            >
                                See your <Text weight="bold" span>MONTHLY</Text> spending
                            </Text>
                        }
                        footer={
                            <Footer
                                align='flex-end'
                                actions={[
                                    {
                                        button: true,
                                        buttonVariant: 'contained',
                                        value: 'See Bills',
                                        sx: {
                                            opacity: params.view === 'bills' ? 0.5 : 1,
                                            pointerEvents: params.view === 'bills' ? 'none' : 'all',
                                        },
                                        onClick: () => {
                                            onSelectView('bills')
                                        }
                                    },
                                ]}
                            />
                        }
                    />
                </Stack>
            }
        />
    );
};

export default User;