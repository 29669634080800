import { styled } from '@mui/material/styles';
import { Link as _Link } from 'react-router-dom';

export const Container = styled('div')(({ theme }) => ({
    height: '100%',
    width: '74px',
    flexShrink: 0,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.down(800)]: {
        height: '70px',
        width: '100%',
        flexDirection: 'row',
        boxShadow: theme.shadows[14],
        borderTop: `1px solid ${theme.palette.primary.ultraLight}`,
    }
}));

export const Link = styled(_Link)(({ theme }) => ({
    width: '100%',
    textAlign: 'center',
    textDecoration: 'none',

    [theme.breakpoints.down(800)]: {
        width: 'auto',
        display: 'flex',
    }
}));

export const NavBarButtonsContainter = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '8px',
    width: '100%',
    paddingTop: '30px',
    paddingBottom: '80px',

    [theme.breakpoints.down(800)]: {
        flexDirection: 'row',
        padding: '0px 20px',
    },
}));

export const NavBarButtonContainter = styled('div')(({ theme }) => ({
    position: 'relative',

    '&:hover': {
        '& > a > div[data-selected=false] > svg': {
            backgroundColor: theme.palette.secondary.main,
        },

        '& > div': {
            display: 'flex',
        },
    },

    [theme.breakpoints.down(800)]: {
        position: 'unset',
    }
}));

export const NavBarButtonContainterProfile = styled(NavBarButtonContainter)(({ theme }) => ({
    [theme.breakpoints.down(800)]: {
        paddingRight: '12px',
    },
}));

export const NavBarButton = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    padding: '8px',
    textAlign: 'center',
    fontSize: '10px',
    width: '100%',

    '& > svg': {
        fontSize: '30px',
        borderRadius: '8px',
        padding: '4px',
        color: theme.palette.primary.light,
    },

    '&:hover': {
        '& > svg': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },

        '& > img': {
            filter: 'grayscale(30%)',
            opacity: 0.7,
        },
    },

    '&[data-selected=true]': {
        '& > svg': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
        },

        '& > div': {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
        },

        '&:hover': {
            '& > svg': {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
            },

            '& > img': {
                filter: 'none',
                opacity: 1,
            },
        },
    },

    '&[data-submenu=true]': {
        textAlign: 'left',
        padding: 0,
        paddingLeft: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
    },

    [theme.breakpoints.down(800)]: {
        width: 'auto',
        minWidth: '55px',

        '&:active': {
            '& > svg': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.main,
            }
        },

        '&[data-submenu=true]': {
            display: 'block',
            padding: '8px',
            textAlign: 'center',
            marginTop: '6px',
        },
    }
}));

export const SubMenuContainer = styled('div')(({ theme }) => ({
    display: 'none',
    width: '150px',
    paddingBottom: '12px',
    boxShadow: theme.shadows[14],
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    top: '12px',
    left: '69px',
    borderRadius: '12px',
    borderTopLeftRadius: '0px',
    border: `1px solid ${theme.palette.primary.ultraLight}`,
    overflow: 'hidden',
    zIndex: 999,
    flexDirection: 'column',
    gap: '4px',

    [theme.breakpoints.down(800)]: {
        display: 'none',

        '&[data-selected=true]': {
            display: 'flex',
        },

        flexDirection: 'row',
        top: 'auto',
        bottom: 0,
        left: 0,
        height: '70px',
        width: '100%',
        border: 'none',
        borderRadius: '0px',
        paddingBottom: 0,
        gap: '20px',
    },
}));

export const SubMenuTitle = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    fontSize: '12px',
    marginBottom: '8px',
    padding: '8px 12px',

    '& > div': {
        display: 'none',
    },

    [theme.breakpoints.down(800)]: {
        padding: '10px 20px',
        marginBottom: '0px',
        textAlign: 'center',

        '& > div': {
            cursor: 'pointer',
            display: 'block',
            backgroundColor: theme.palette.background.default,
            marginTop: '8px',
            padding: '4px 8px',
            borderRadius: '8px',
        },
    },
}));

export const ProfileButton = styled(NavBarButton)(({ theme }) => ({
    position: 'absolute',
    bottom: '40px',
    left: '0px',
    width: '70px',

    [theme.breakpoints.down(800)]: {
        position: 'relative',
        bottom: 'auto',
        left: 'auto',
        right: '12px',
        // paddingRight: '20px',
    },
}));

export const Logo = styled('img')(({ theme }) => ({
    width: '50px',
    height: '50px',
    marginTop: '30px',

    [theme.breakpoints.down(800)]: {
        width: '42px',
        height: '42px',
        marginTop: '8px',
        marginLeft: '20px',
        display: 'none',
    }
}));

export const ServiceLogo = styled('img')(({ theme }) => ({
    width: '30px',
    height: '30px',
    filter: 'grayscale(100%)',
    opacity: 0.5,

    '&[data-selected=true]': {
        filter: 'none',
        opacity: 1,
    }
}));