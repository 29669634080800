import { useState } from 'react';
import ControlledInput from '../../../Components/Input/ControlledInputWithStatus';
import Stack from "@mui/material/Stack";
import ActionFooter from '../../../Components/library/Cards/Footers/Action';

const SessionInfo = ({ admin, keys = '', info = '', onSave }) => {
    const [data, setData] = useState({
        // keys: keys,
        info: info,
    });

    const [focus, setFocus] = useState({
        // keys: false,
        info: false,
    });

    return (
        <Stack>
            <Stack
                gap={1}
            >
                {/* <ControlledInput
                    name="session-keys-info"
                    label="Keys Information"
                    placeholder=""
                    defaultValue={keys}
                    multiline
                    rows={focus.keys ? 4 : (data.keys !== '' ? 4 : 1)}
                    onFocus={() => setFocus({
                        ...focus,
                        keys: true,
                    })}
                    onBlur={() => setFocus({
                        ...focus,
                        keys: false,
                    })}
                    onChange={(value) => setData({
                        ...data,
                        keys: value,
                    })}
                    currentStatus={data.keys === keys ? 'SAVED' : 'TOSYNC'}
                /> */}
                <ControlledInput
                    name="session-additional-info"
                    label="Additional Information"
                    placeholder=""
                    defaultValue={info}
                    multiline
                    rows={focus.info ? 4 : (data.info !== '' ? 4 : 1)}
                    onFocus={() => setFocus({
                        ...focus,
                        info: true,
                    })}
                    onBlur={() => setFocus({
                        ...focus,
                        info: false,
                    })}
                    onChange={(value) => setData({
                        ...data,
                        info: value,
                    })}
                    currentStatus={data.info === info ? 'SAVED' : 'TOSYNC'}
                />
            </Stack>
            <ActionFooter
                align='flex-end'
                sx={{
                    p: 0,
                    pt: 1.5,
                }}
                actions={[
                    {
                        button: true,
                        buttonVariant: 'contained',
                        value: 'Save',
                        onClick: () => {
                            onSave(data)
                        },
                        sx: {
                            opacity: info === data.info ? 0.5 : 1,
                            pointerEvents: info === data.info ? 'none' : 'all',
                        },
                    },

                ]}
            />
        </Stack>
    );
};

export default SessionInfo;