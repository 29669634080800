import { styled } from '@mui/material/styles';

export const DashboardContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignContent: 'flex-start',
    padding: '40px',
    width: '100%',
    gap: '20px',
    overflow: 'auto',
    flexWrap: 'wrap',

    [theme.breakpoints.down(800)]: {
        padding: '20px',
    }
}));