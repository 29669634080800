import { format } from 'date-fns';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Card from '../../../Components/library/Cards/Generic';
import Header from '../../../Components/library/Cards/Headers/Standard';
import Footer from '../../../Components/library/Cards/Footers/Action';
import Tag from '../../../Components/library/Tag/Tag';

import ViewingReportCard from '../../../Components/Viewing/Card/ViewingReportCard';
import { Link } from '../../../Components/Dashboard/Cards/cards.styled';

const PastViewing = ({ sessionReport }) => {
    if (!sessionReport) {
        return null;
    }

    return (
        <Card
            header={
                <Header
                    title={format(new Date(sessionReport.info.find(i => i.key === 'date').value), 'do MMMM')}
                    titleSize="medium"
                    sx={{ backgroundColor: 'secondary.light' }}
                    tags={[
                        <Tag backgroundColor="background.default">{`${sessionReport.viewingReports.length} viewing${sessionReport.viewingReports.length > 1 ? 's' : ''}`}</Tag>,
                    ]}
                />
            }
            body={
                <Stack>
                    {
                        sessionReport.viewingReports.map((viewingReport, vIndex) => (
                            <ViewingReportCard
                                key={`past-viewing-${vIndex}`}
                                viewingReport={viewingReport}
                            />
                        ))
                    }
                </Stack>
            }
            footer={
                <Footer
                    align='flex-end'
                    actions={[{
                        value: (
                            <Link to={`/app/reports/${sessionReport.id}`}>
                                <Button variant="contained" sx={{ marginTop: '12px', textDecoration: 'none' }}>See Detailed Report</Button>
                            </Link>
                        ),
                    }]}
                />
            }
        />
    );
};

export default PastViewing;