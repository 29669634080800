import { useState } from 'react';

import Card from '../../../Components/library/Cards/Generic';
import Header from '../../../Components/library/Cards/Headers/Standard';
import FeedbackButton from '../../../Components/library/Button/FeedbackButton';
import Text from '../../../Components/library/Typography/Text';
import AutoSaveInput from '../../../Components/library/Inputs/AutoSaveInput';
import ControlledInput from '../../../Components/Input/ControlledInput';
import Tag from '../../../Components/library/Tag/Tag';
import MarketPlaceTag from '../../../Components/library/Tag/MarketPlaceTag';
import { emojiFromString } from '../../../utils/emoji';
import LeadFooter from './LeadFooter';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { formatDistance } from 'date-fns';

const Lead = ({
    lead,
    color = 'secondary.light',
    onNoteChange = () => { },
    onAction = () => { },
    displayOnly = false,
    noEmailPhone = false,
    sx = {},
}) => {
    const [notes, setNotes] = useState(lead.notes);
    const [editingNotes, setEditingNotes] = useState(false);
    const [editData, setEditData] = useState({
        name: { value: lead.name, valid: true },
        email: { value: lead.email, valid: true },
        phone: { value: lead.phone, valid: true },
    });

    return (
        <Card
            sx={sx}
            header={
                <Header
                    emoji={emojiFromString(lead.id)}
                    title={
                        lead.mode !== 'EDIT' ? (
                            <Text
                                size="medium"
                                weight="bold"
                                color={`${color.split('.')[0]}.contrastText`}
                                sx={{ flexShrink: 0 }}
                            >
                                {lead.name}
                            </Text>
                        ) : (
                            <ControlledInput
                                name="name"
                                label="Name"
                                size="small"
                                autoFocus={true}
                                autoComplete="off"
                                defaultValue={editData.name.value}
                                onChange={(value, valid) => setEditData((ed) => ({
                                    ...ed,
                                    name: { value, valid }
                                }))}
                            />
                        )
                    }
                    titleSize="medium"
                    actions={lead.mode !== 'EDIT' && !displayOnly ? [
                        {
                            value: 'Edit',
                            onClick: () => { onAction('EDIT') }
                        },
                        // {
                        //     value: 'copy lead to property',
                        // },
                        // {
                        //     value: 'duplicate lead to property',
                        // }
                    ] : []}
                    tags={lead.mode !== 'EDIT' ? [
                        (
                            !displayOnly ? (
                                <Tag
                                    backgroundColor="background.default"
                                    key="lead-tag-date"
                                >
                                    {formatDistance(lead.createdAt ? new Date(lead.createdAt) : new Date(), new Date(), { addSuffix: true })}
                                </Tag>
                            ) : null
                        ), (
                            lead.marketPlace ? (
                                <MarketPlaceTag
                                    marketPlace={lead.marketPlace.platform}
                                    sx={{ backgroundColor: 'background.default' }}
                                    key="lead-tag-mp"
                                />
                            ) : null
                        ),
                    ] : []}
                    sx={{ backgroundColor: color }}
                />
            }
            withBodyContainer={false}
            body={
                <>
                    {
                        !noEmailPhone ? (
                            <Stack
                                gap={1}
                                direction="row"
                                flexWrap="wrap"
                                sx={{ p: 1.5 }}
                            >
                                {
                                    lead.mode === 'EDIT' ? (
                                        <>
                                            <ControlledInput
                                                name="email"
                                                label="Email"
                                                size="small"
                                                autoComplete="off"
                                                defaultValue={editData.email.value}
                                                mask={/^\S*@?\S*$/}
                                                validator={/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/} // eslint-disable-line no-control-regex
                                                onChange={(value, valid) => setEditData((ed) => ({
                                                    ...ed,
                                                    email: { value, valid },
                                                }))}
                                            />
                                            <ControlledInput
                                                name="phone"
                                                label="Phone"
                                                size="small"
                                                autoComplete="off"
                                                defaultValue={editData.phone.value}
                                                mask={'+44 00000000000'}
                                                validator={/^\+44 (07\d{9})$/}
                                                onChange={(value, valid) => setEditData((ed) => ({
                                                    ...ed,
                                                    phone: { value, valid },
                                                }))}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <FeedbackButton
                                                revertAfter={2000}
                                                variant="outlined"
                                                size="small"
                                                sx={{ textTransform: 'none' }}
                                                feedback={<div>Copied to clipboard</div>}
                                            >{lead.email}</FeedbackButton>
                                            <FeedbackButton
                                                revertAfter={2000}
                                                variant="outlined"
                                                size="small"
                                                sx={{ textTransform: 'none' }}
                                                feedback={<div>Copied to clipboard</div>}
                                            >{lead.phone}</FeedbackButton>
                                        </>
                                    )
                                }
                            </Stack>
                        ) : null
                    }
                    {!noEmailPhone && ((notes && notes !== '') || !displayOnly) ? <Divider /> : null}
                    {
                        !displayOnly ? (
                            <Stack
                                spacing={1}
                                direction="column"
                                sx={{
                                    p: !!notes || notes === '' ? 2.5 : 0,
                                    pb: !!notes || notes === '' ? 1 : 0,
                                    transition: 'all 0.2s',
                                    opacity: !!notes || notes === '' ? 1 : 0,
                                    height: !!notes || notes === '' ? (!displayOnly ? '138px' : '92px') : '0px',
                                    overflow: 'hidden',
                                }}
                            >
                                {
                                    !displayOnly && (!!notes || notes === '') ? (
                                        <AutoSaveInput
                                            name="lead-notes"
                                            label="Lead Notes (Private to you)"
                                            defaultValue={notes}
                                            autoFocus={notes === ''}
                                            multiline
                                            rows={!displayOnly ? 4 : 2}
                                            onChange={(value) => {
                                                setEditingNotes(true);
                                                setNotes(value);
                                            }}
                                            placeholder={`Write down some notes on ${lead.name}`}
                                            saveDebounceTime={2000}
                                            onSave={async (value) => {
                                                await onNoteChange(value);
                                                setEditingNotes(false);
                                            }}
                                            statuses={{
                                                saving: (<Tag color="primary.main">💾 Saving...</Tag>),
                                                saved: (<Tag color="primary.main">👍 Saved!</Tag>),
                                                changed: (<Tag color="primary.main">💾 Saving...</Tag>),
                                                default: null,
                                            }}
                                            sx={{ pointerEvents: displayOnly ? 'none' : 'all' }}
                                        />
                                    ) : null
                                }
                            </Stack>
                        ) : (
                            !!notes || notes === '' ? (
                                <Stack
                                    sx={{ p: 1.5 }}
                                >
                                    <Text weight='lighter' size="10px" sx={{ pb: 1, }}>Lead Notes (Private to you)</Text>
                                    {
                                        notes.split('\n').map((n, i) => <Text key={`line-${i}`}>{n}</Text>)
                                    }
                                </Stack>
                            ) : null
                        )
                    }
                    {
                        lead.aiPrequalAvailable ? (
                            !lead.aiPrequal ? (
                                <Button
                                    size="small"
                                    onClick={() => onAction('CREATE_AIPREQUAL')}
                                    variant="outlined"
                                    sx={{
                                        width: 'fit-content',
                                    }}
                                >
                                    Set AI Prequalification
                                </Button>
                            ) : (
                                <Text>{lead.aiPrequal.id}</Text>
                            )
                        ) : null
                    }
                    {/* {
                        !lead.aiPrequal ? (
                            <Button
                                size="small"
                                onClick={() => onAction('CREATE_AIPREQUAL')}
                                variant="outlined"
                                sx={{
                                    width: 'fit-content',
                                }}
                            >
                                Set AI Prequalification
                            </Button>
                        ) : (
                            <Text>{lead.aiPrequal.id}</Text>
                        )
                    } */}
                </>
            }
            footer={
                !displayOnly ? (
                    <LeadFooter
                        lead={lead}
                        notes={notes}
                        setNotes={setNotes}
                        disabled={editingNotes || (lead.mode === 'EDIT' && (!editData.name.valid || editData.name.value.length < 2 || !editData.phone.valid || !editData.email.valid))}
                        onSave={() => {
                            onAction('SAVE', {
                                ...lead,
                                name: editData.name.value,
                                email: editData.email.value,
                                phone: editData.phone.value,
                            })
                        }}
                        onApprove={() => { onAction('APPROVED') }}
                        onReject={() => { onAction('REJECTED') }}
                        onCancel={() => { onAction('CANCEL') }}
                        onBooking={() => { onAction('BOOKING') }}
                        onCancelTimeout={() => { onAction('CANCELTIMEOUT') }}
                    />
                ) : null
            }
        />
    );
};

export default Lead;