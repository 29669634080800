import Stack from '@mui/material/Stack';
import Text from '../../../Components/library/Typography/Text';
import PastViewing from '../Viewing/Past';

const PastViewings = ({
    reports,
}) => {
    return (
        <Stack
            gap={2.5}
            direction="column"
            sx={{ p: 2.5, overflow: 'auto', height: '100%' }}
        >
            {
                reports.length ? reports.map((sessionReport, rIndex) => (
                    <PastViewing
                        key={`past-report-${rIndex}`}
                        sessionReport={sessionReport}
                    />
                )) : (
                    <Text
                        color="primary.light"
                        span
                        sx={{ width: '100%', textAlign: 'center', pt: 4, pb: 4 }}
                    >No <Text weight="bold" span>PAST</Text> viewings yet</Text>
                )
            }
        </Stack>
    );
};

export default PastViewings;