import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

/*
const ACTIONS = [
    {
        value: '', // string or component,
        button: true,
        buttonVariant: 'contained', //mui button variant
        onClick: () => { },
        sx: {},
    },
];
*/

const ActionFooter = ({ actions = [], align = 'center', sx = {}, ...otherProps }) => {
    return (
        <Stack
            direction="row"
            justifyContent={align}
            alignItems="center"
            spacing={1.5}
            sx={{ p: 1.5, pt: 0, ...sx }}
            {...otherProps}
        >
            {
                actions.map((action, index) => {
                    if (action.button) {
                        return (
                            <Button
                                onClick={action.onClick || (() => { })}
                                variant={action.buttonVariant || 'contained'}
                                size="small"
                                sx={action.sx || {}}
                                key={`footer-action-${index}`}
                                {...(action.href ? { href: action.href, target: '_blank' } : {})}
                                {...(action.buttonProps ? action.buttonProps : {})}
                            >
                                {action.value}
                            </Button>
                        );
                    }


                    return (
                        <div
                            onClick={action.onClick || (() => { })}
                            key={`footer-action-${index}`}
                        >
                            {action.value}
                        </div>
                    );
                })
            }
        </Stack>
    );
};

export default ActionFooter;