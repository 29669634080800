import { useState } from "react";
import { Stack } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Text from "../../Components/library/Typography/Text";

const KeysTypeSelector = ({
    keysOptions,
    selected = false,
    onSelect = () => { },
}) => {
    const [hoverInfo, setHoverInfo] = useState(false);

    return (
        <Stack
            sx={(theme) => ({
                border: `1px solid ${selected ? theme.palette.primary.main : theme.palette.primary.light}`,
                borderRadius: '12px',
                padding: hoverInfo ? '0px' : '0px 12px',
                width: '110px',
                height: '90px',
                color: theme.palette.primary.light,
                backgroundColor: selected ? theme.palette.primary.main : theme.palette.secondary.light,
                cursor: 'pointer',
                position: 'relative',
            })}
            alignItems="center"
            onClick={() => onSelect()}
        >
            <Stack
                sx={(theme) => ({
                    position: 'absolute',
                    right: '4px',
                    top: '4px',
                    color: selected ? theme.palette.secondary.main : theme.palette.primary.ultraLight,
                    fontSize: '14px',
                })}
                onMouseEnter={() => setHoverInfo(true)}
                onMouseLeave={() => setHoverInfo(false)}
            >
                <InfoOutlinedIcon fontSize="14px" />
            </Stack>
            {
                !hoverInfo ? (
                    <>
                        <Stack
                            sx={{ mt: '8px', backgroundColor: 'secondary.light', p: '4px', borderRadius: '8px' }}
                        >
                            {keysOptions.icon}
                        </Stack>
                        <Stack
                            sx={{
                                height: '100%',
                            }}
                            justifyContent="center"
                        >
                            <Text
                                size="normal"
                                weight="bold"
                                align="center"
                                color={selected ? 'primary.contrastText' : 'primary.main'}
                            >
                                {keysOptions.display}
                            </Text>
                        </Stack>
                    </>
                ) : (
                    <Stack
                        sx={{
                            padding: '0px 8px',
                            height: '100%',
                        }}
                        justifyContent="center"
                    >
                        <Text
                            size="10px"
                            color={selected ? 'primary.contrastText' : 'primary.main'}
                        >{keysOptions.info}</Text>
                    </Stack>
                )
            }
        </Stack>
    );
};

export default KeysTypeSelector;