import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import useStyle from './wizard.css.js';
import WizardNav from './WizardNav';
import { searchParamsToObject } from '../../../utils/urlSearchParams';
import { styled } from '@mui/material/styles';

const StepWizardStyled = styled(StepWizard)(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    '& > div:nth-child(n+2)': {
        height: '100%',
    },
    '& > div:nth-child(n+2) > div': {
        height: '100%',
    },
}));

const Wizard = ({ children, name, stepStatus = {}, emoji, onStepChange = () => {}, sx = {} }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    let initialStep = searchParams.get(`${name}-step`) || 1;
    const classes = useStyle();

    useEffect(() => {
        if (!searchParams.get(`${name}-step`)) {
            setSearchParams({
                ...searchParamsToObject(searchParams),
                [`${name}-step`]: 1,
            });
        }

        onStepChange(parseInt(initialStep, 10));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const customTransitions = {
        enterRight: classes.fadeIn,
        enterLeft: classes.fadeIn,
        exitRight: classes.fadeOut,
        exitLeft: classes.fadeOut,
    };

    return (
        <StepWizardStyled
            nav={<WizardNav stepStatus={stepStatus} emoji={emoji} />}
            transitions={customTransitions}
            initialStep={initialStep}
            onStepChange={({ activeStep }) => {
                onStepChange(activeStep);
                if (activeStep === React.Children.count(children)) {
                    // Last step we cleanup
                    const { [`${name}-step`]: _, ...newParams } = searchParamsToObject(searchParams);
                    setSearchParams(newParams);
                } else {
                    setSearchParams({
                        ...searchParamsToObject(searchParams),
                        [`${name}-step`]: activeStep,
                    });
                }
            }}
            sx={sx}
        >
            {children}
        </StepWizardStyled>
    );
};

export default Wizard;