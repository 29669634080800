import React, { useEffect, useState } from "react";
import { API } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import Card from "../../../Components/library/Cards/Generic";
import Header from "../../../Components/library/Cards/Headers/Standard";

import {
    BackButton,
    CardTitleText,
} from '../../Leads/leads.styled';

import Session from "./Session";
import Text from "../../../Components/library/Typography/Text";
import { differenceInHours, setHours, addDays, subDays } from "date-fns";

const Sessions = ({
    onBack = () => { },
}) => {
    const [todaySessions, setTodaySessions] = useState(null);
    const [tomorrowSessions, setTomorrowSessions] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchSessions = async () => {
        const _date = new Date().toISOString();
        setLoading(true);
        const resSessionsToday = await API.graphql({
            query: queries.sessionManager,
            variables: {
                op: 'get',
                payload: JSON.stringify({
                    date: _date,
                    ghost: false,
                }),
            },
        });

        let resSessionsTomorrow = [];
        if (differenceInHours(new Date(), setHours(new Date(), 19)) > 0) { // after 8pm
            resSessionsTomorrow = await API.graphql({
                query: queries.sessionManager,
                variables: {
                    op: 'get',
                    payload: JSON.stringify({
                        date: addDays(new Date(), 1).toISOString(),
                        ghost: false,
                    }),
                },
            });

            resSessionsTomorrow = JSON.parse(resSessionsTomorrow.data.sessionManager);
        }

        setTodaySessions(
            JSON.parse(resSessionsToday.data.sessionManager).sort((a, b) => new Date(a.startsAt) - new Date(b.startsAt))
        );
        setTomorrowSessions(
            resSessionsTomorrow.sort((a, b) => new Date(a.startsAt) - new Date(b.startsAt))
        );

        setLoading(false);
    };

    useEffect(() => {
        fetchSessions();
    }, []);

    console.log(tomorrowSessions);

    return (
        <Card
            sx={{
                backgroundColor: 'background.default',
                height: '100%',
            }}
            header={
                <Header
                    title={
                        <Stack
                            direction="row"
                            alignItems="center"
                        >
                            <BackButton
                                sx={{ padding: 0 }}
                                onClick={onBack}
                            >
                                <ArrowBackOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                                Back
                            </BackButton>
                            <CardTitleText size="large" weight="bold" sx={{
                                flexShrink: 0,
                                display: 'flex',
                                alignItems: 'center',
                                alignSelf: 'center',
                                textAlign: 'center',
                                pointerEvents: 'none',
                                gap: 1,
                            }}>🗻 Your Sessions</CardTitleText>
                        </Stack>
                    }
                />
            }
            withBodyContainer={false}
            body={
                <Stack
                    gap={2.5}
                    sx={{
                        maxHeight: '100%',
                        height: '100%',
                        overflow: 'auto',
                        p: 3.5,
                        marginLeft: 2.5,
                    }}
                >
                    {
                        loading ? (
                            [
                                <Skeleton key="loading-1"/>,
                                <Skeleton key="loading-2" />,
                                <Skeleton key="loading-3" />,
                            ]
                        ) : null
                    }
                    {
                        !loading && todaySessions ? (
                            [
                                <Text weight="bold" size="title" key="today-title" sx={{ marginLeft: -3.5 }}>🏎️ Today</Text>,
                                todaySessions.length ? todaySessions.map((session) => (
                                    <Session
                                        key={session.id}
                                        session={session}
                                    />
                                )) : <Text size="medium" key="no-today-title">No sessions Today</Text>,
                            ]
                        ) : null
                    }
                    {
                        !loading && tomorrowSessions && tomorrowSessions.length ? (
                            [
                                <Text weight="bold" size="title" key="tomorrow-title" sx={{ marginLeft: -3.5 }}>🔥 Tomorrow</Text>,
                                tomorrowSessions.map((session) => (
                                    <Session
                                        key={session.id}
                                        session={session}
                                    />
                                ))
                            ]
                        ) : null
                    }
                </Stack >
            }
        />
    );
};

export default Sessions;