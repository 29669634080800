import React, { useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { format, isSameDay, differenceInMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DateSlotContainer, DateSlotWrapper, DateSlotButton } from './CalendarSlots.styled';

import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { API } from 'aws-amplify';
import * as queries from '../../../../graphql/queries';
import Text from '../../../../Components/library/Typography/Text';
import DayPicker, { Day } from '../../../../Components/library/DatePicker/DayPicker';
import Lead from '../../../Leads/Lead/LeadDisplay';


export const TextFieldCustom = styled(TextField)(() => ({
    marginBottom: '300px',
}));

const getSlotsBefore = (calendar, firstViewingIndex, targetMinSlots, viewingType) => {
    let res = [];

    if (viewingType === 'BLOCK') {
        if (firstViewingIndex - 1 >= 0 &&
            Math.abs(differenceInMinutes(
                new Date(`2023-06-01T${calendar[firstViewingIndex].time}`),
                new Date(`2023-06-01T${calendar[firstViewingIndex - 1].time}`)
            )) === 60) {
            return [{ ...calendar[firstViewingIndex - 1], sandwich: true }]
        } else {
            return [];
        }
    }

    for (let i = 1; firstViewingIndex - i >= 0; i++) {
        if (
            firstViewingIndex - i >= 0 &&
            Math.abs(differenceInMinutes(
                new Date(`2023-06-01T${calendar[firstViewingIndex - i].time}`),
                new Date(`2023-06-01T${calendar[firstViewingIndex - (i - 1)].time}`)
            )) === 15 &&
            res.length < targetMinSlots
        ) {
            res = [...res, { ...calendar[firstViewingIndex - i], sandwich: ((firstViewingIndex - (i - 1)) === firstViewingIndex) }];
        } else {
            break;
        }
    }

    return res;
};

const getSlotsAfter = (calendar, lastViewingIndex, targetMinSlots) => {
    let res = [];

    for (let i = 1; i <= targetMinSlots; i++) {
        if (lastViewingIndex + i < calendar.length &&
            differenceInMinutes(
                new Date(`2023-06-01T${calendar[lastViewingIndex + i].time}`),
                new Date(`2023-06-01T${calendar[lastViewingIndex + (i - 1)].time}`)
            ) === (calendar[lastViewingIndex].viewing.type === 'BLOCK' ? 60 : 15)) {
            res = [...res, { ...calendar[lastViewingIndex + i], sandwich: ((lastViewingIndex + (i - 1)) === lastViewingIndex) }];
        } else {
            break;
        }
    }

    return res;
}

const contigousBeforeAfter = (calendar, firstViewingIndex, lastViewingIndex, targetMinSlots = 6, viewingType = 'SINGLE') => {
    let res = [
        ...new Set([calendar[firstViewingIndex], calendar[lastViewingIndex]]),
        ...calendar.filter((_, i) => i > firstViewingIndex && i < lastViewingIndex).map((c) => ({ ...c, sandwich: true })),
    ];

    let before = getSlotsBefore(calendar, firstViewingIndex, targetMinSlots / 2, viewingType);
    let target = targetMinSlots - [...res, ...before].filter(c => c.type !== 'viewing').length;
    target = Math.ceil(target > targetMinSlots / 2 ? target : targetMinSlots / 2);
    let after = getSlotsAfter(calendar, lastViewingIndex, target);
    if (targetMinSlots - [...res, ...before, ...after].filter(c => c.type !== 'viewing').length > 0) {
        before = getSlotsBefore(calendar, firstViewingIndex, targetMinSlots - [...res, ...after].filter(c => c.type !== 'viewing').length);
    }

    res = [...res, ...before, ...after];

    return res.sort((a, b) => a.time < b.time ? -1 : 1);
}

const getContigous = (calendar, maxBeforeAfter = 3, viewingType = 'SINGLE') => {
    let firstViewingIndex = null;
    let lastViewingIndex = null;
    for (let index = 0; index < calendar.length; index++) {
        const element = calendar[index];
        if (element.type === 'viewing' && firstViewingIndex === null) {
            firstViewingIndex = index;
        }
        if (element.type === 'viewing') {
            lastViewingIndex = index;
        }
    }

    const res = contigousBeforeAfter(
        calendar,
        firstViewingIndex,
        lastViewingIndex,
        (firstViewingIndex !== null && calendar.find(c => c.type === 'viewing' && c.viewing.type === 'BLOCK') ? 1 : maxBeforeAfter * 2),
        viewingType
    );
    return res;
};

const Slots = ({ calendar, viewings, handleTimeChange, timeValue, viewingType }) => {
    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({ block: "center", inline: "nearest" });
        }
    }, [timeValue]);

    let session = null;
    if (viewings && viewings.length && calendar && calendar.length) {
        session = getContigous(calendar, viewingType === 'BLOCK' ? 1 : 3, viewingType);
    }

    return (
        <>
            {
                viewings && viewings.length && calendar && calendar.length && session && !session.find(s => s.type === 'slot') ? (
                    <Text color="primary.light">No additional slots available for this property</Text>
                ) : null
            }
            {
                viewings && viewings.length && calendar && calendar.length ? (
                    session.map((cal) => {
                        if (cal.type === 'slot' && !cal.outside) {
                            return (
                                <Stack sx={{ width: '100%' }} alignItems="center">
                                    <DateSlotButton
                                        {...cal.time === timeValue ? { ref: ref } : {}}
                                        key={`${cal.type}-${cal.time}`}
                                        variant={cal.time === timeValue ? 'contained' : 'outlined'}
                                        size="large"
                                        onClick={() => handleTimeChange(cal.time)}
                                    >
                                        {`${cal.time}${cal.sandwich ? ' 🔥' : ''}`}
                                    </DateSlotButton>
                                    {
                                        viewingType === 'BLOCK' && cal.time === timeValue ? (
                                            <Text sx={{ m: '10px' }} weight="bold" size="medium">
                                                1h slot until {parseInt(cal.time.split(':')[0], 10) + 1}:{cal.time.split(':')[1]}
                                            </Text>
                                        ) : null
                                    }
                                </Stack>
                            );
                        } else if (cal.type === 'viewing' && !cal.outside) {
                            return (
                                <Stack
                                    key={cal.viewing.id}
                                    gap={2}
                                    sx={{
                                        borderColor: 'primary.light',
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        width: 'calc(100% - 40px)',
                                        margin: '0px 20px',
                                        borderRadius: '8px',
                                        p: 2.5,
                                        pointerEvents: 'none',
                                        opacity: 0.7,
                                    }}
                                >
                                    <Text weight='bold' size="large" sx={{ width: '100%', textAlign: 'center' }}>{cal.time}{cal.viewing.type === 'BLOCK' ? ` - ${parseInt(cal.time.split(':')[0], 10) + 1}:${cal.time.split(':')[1]}` : ''} ✅</Text>
                                    {
                                        cal.viewing.type === 'BLOCK' ? (
                                            <Text size="large" weight='bold' sx={{ width: '100%', textAlign: 'center' }}>Block Viewing (1h)</Text>
                                        ) : (
                                            <Lead
                                                lead={cal.viewing.Lead}
                                                sx={{ width: '100%' }}
                                                noEmailPhone
                                            />
                                        )
                                    }
                                </Stack>
                            );
                        }

                        return null;
                    })
                ) : null
            }
            {
                viewings && !viewings.length && calendar && calendar.length ? (
                    calendar.map((cal) => (
                        <Stack sx={{ width: '100%' }} alignItems="center">
                            <DateSlotButton
                                key={`${cal.type}-${cal.time}`}
                                variant={cal.time === timeValue ? 'contained' : 'outlined'}
                                size="large"
                                onClick={() => handleTimeChange(cal.time)}
                            >
                                {cal.time}
                            </DateSlotButton>
                            {
                                viewingType === 'BLOCK' && cal.time === timeValue ? (
                                    <Text sx={{ m: '10px' }} weight="bold" size="medium">
                                        1h slot until {parseInt(cal.time.split(':')[0], 10) + 1}:{cal.time.split(':')[1]}
                                    </Text>
                                ) : null
                            }
                        </Stack>
                    ))
                ) : null
            }
            {
                calendar.length === 0 ? (
                    <Text>No slots available</Text>
                ) : null
            }
        </>
    );
};

const CalendarSlots = ({
    onUpdate = () => { },
    viewings,
    initialFormState,
    postcode,
    targetDay,
    targetTime,
    error,
    viewingType = 'SINGLE',
}) => {
    const [formData, setFormData] = useState(initialFormState);
    const [startTime, setStartTime] = useState(targetDay && targetTime ? targetDay : null);
    const [currentPostcode, setCurrentPostcode] = useState(postcode);
    const [timeValue, setTimeValue] = useState(null);
    const [calendar, setCalendar] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(false);
    const [foundSlot, setFoundSlot] = useState(false);

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + (tomorrow.getHours() >= 20 ? 2 : 1)); // +1 day or +2 days if after 8pm
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);

    if (postcode !== currentPostcode && startTime) {
        setStartTime(null);
        setCalendar(null);
    }

    useEffect(() => {
        if (error) {
            handleDayChange(startTime);
        }
    }, [error]);

    useEffect(() => {
        const preselectTime = async () => {
            if (targetDay && targetTime) {
                setInitialLoading(true);
                const calendar = await handleDayChange(targetDay);
                if (calendar && calendar.length) {
                    const availableSlot = calendar.find(c => c.time === targetTime && c.type === 'slot');
                    if (availableSlot) {
                        await handleTimeChange(availableSlot.time);
                        setFoundSlot(true);
                    }
                }
                setInitialLoading(false);
            }
        };
        preselectTime();
    }, []);

    const fetchAvailableCalendar = async (startTime) => {
        setCurrentPostcode(postcode);

        const res = await API.graphql({
            query: queries.bookingAvailableTime,
            variables: {
                date: startTime.toISOString(),
                postcode: postcode.toUpperCase().replace(/\s/g, ''),
                type: viewingType,
                viewingLength: viewingType === 'BLOCK' ? 60 : 15,
            },
        });

        return JSON.parse(res.data.bookingAvailableTime);
    };

    const handleDayChange = async (newValue) => {
        const date = new Date(newValue);
        date.setSeconds(0);
        date.setHours(1);
        date.setMilliseconds(0);
        setStartTime(date);
        setTimeValue(null);
        const newFormData = {
            ...formData,
            dateTime: { value: null, valid: false },
        };
        setFormData(newFormData);
        onUpdate(newFormData);
        setLoading(true);

        const availableCalendar = await fetchAvailableCalendar(date);

        const _calendar = [
            ...Object.keys(availableCalendar.availableCalendar).map(k => ({
                type: 'slot',
                time: k,
                availability: availableCalendar.availableCalendar[k],
            })),
            ...viewings.filter(v => isSameDay(new Date(v.startsAt), date)).map(v => ({
                type: 'viewing',
                time: formatInTimeZone(new Date(v.startsAt), 'Etc/UTC', 'kk:mm'),
                viewing: v,
            })),
        ].sort((a, b) => a.time < b.time ? -1 : 1);

        setCalendar(_calendar);
        setLoading(false);

        return _calendar;
    };

    const handleTimeChange = async (newValue) => {
        let newFormData;
        setTimeValue(newValue);
        const [hours, minutes] = newValue.split(':');
        const date = new Date(startTime);
        date.setHours(hours);
        date.setMinutes(minutes);

        newFormData = {
            ...formData,
            dateTime: { value: date, valid: true },
        };

        setFormData(newFormData);
        onUpdate(newFormData);
    };

    if (foundSlot) {
        return (
            <Stack
                direction="row"
                gap={1.5}
                sx={{ p: 2.5, color: 'primary.main' }}
                alignItems="center"
            >
                <DateSlotButton
                    variant={'contained'}
                    size="large"
                    sx={{ height: 'fit-content' }}
                >
                    <Text color="primary.contrastText" sx={{ paddingRight: 1.5, textTransform: 'none' }}>{format(new Date(startTime), 'eeee do MMMM')}</Text>
                    {timeValue}
                </DateSlotButton>
            </Stack>
        );
    }

    return (
        <Stack
            direction="column"
            gap={1.5}
            sx={{ p: 1.5, color: 'primary.main' }}
        >
            {
                initialLoading ? <CircularProgress /> : null
            }
            {
                postcode && !initialLoading ? (
                    <>
                        <DayPicker
                            startDate={tomorrow}
                            selectedDate={startTime}
                            onSelectDate={handleDayChange}
                            maxDays={10}
                        />
                        <DateSlotContainer>
                            <Text weight='bold' color="primary.light" size="medium" sx={{ textAlign: 'center' }}>
                                Availability for
                                <br />
                                <Text span>{startTime ? `${format(new Date(startTime), 'eeee do MMMM')} @` : null} {postcode}</Text>
                            </Text>
                            {
                                error && !timeValue ? (
                                    <Text weight='bold' sx={{ textAlign: 'center', color: '#ff6969' }}>
                                        {error}
                                    </Text>
                                ) : null
                            }
                            <DateSlotWrapper
                                sx={{
                                    justifyContent: loading || !startTime ? 'center' : 'flex-start',
                                }}
                            >
                                {
                                    loading ? <CircularProgress /> : null
                                }
                                {
                                    !loading && startTime && calendar ? (
                                        <Slots
                                            calendar={calendar}
                                            timeValue={timeValue}
                                            handleTimeChange={handleTimeChange}
                                            viewings={startTime ? viewings.filter(v => isSameDay(new Date(v.startsAt), startTime)) : []}
                                            viewingType={viewingType}
                                        />
                                    ) : null
                                }
                                {
                                    !loading && !startTime ? (
                                        <Text color="primary.light">Select a day above ⬆️</Text>
                                    ) : null
                                }
                            </DateSlotWrapper>
                        </DateSlotContainer>
                    </>
                ) : null
            }
            {
                !postcode && !initialLoading ? (
                    <Text color="primary.light" sx={{ p: 1 }}>Select a property first</Text>
                ) : null
            }
        </Stack >
    );
};

export default CalendarSlots;