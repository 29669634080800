import React from 'react'
import Stack from '@mui/material/Stack';
import Text from '../../Typography/Text';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
    TitleContainer,
} from './Standard.styled';

const Header = ({
    title,
    emoji,
    titleSize = 'large',
    tags = [],
    actions = [],
    action = null,
    sx = {},
    sxTitle = {},
    ...otherProps
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Stack
            direction="row"
            gap={1.5}
            alignItems="center"
            justifyContent="space-between"
            sx={{ padding: 1.5, ...sx }}
            {...otherProps}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                flexWrap="wrap"
                gap={1.5}
                sx={{ width: '100%', ...sxTitle }}
            >
                <TitleContainer
                    direction="row"
                    alignItems="center"
                    spacing={1.5}
                    sx={{ flexShrink: 0, maxWidth: '100%' }}
                >
                    {
                        emoji ? <Text size="large" weight="bold">{emoji}</Text> : null
                    }
                    {typeof title === 'string' ? <Text size={titleSize} weight="bold" sx={{ flexShrink: 0 }}>{title}</Text> : title}
                </TitleContainer>
                {
                    (tags && tags.length > 0) ? (
                        <Stack
                            direction="row"
                            spacing={1.5}
                            sx={{ maxWidth: '100%' }}
                        >
                            {
                                tags && tags.length > 0 ? (
                                    <Stack
                                        direction="row"
                                        gap={1}
                                        alignItems="center"
                                        flexWrap="wrap"
                                        justifyContent="flex-end"
                                        sx={{ maxWidth: '100%' }}
                                    >
                                        {
                                            tags.map((tag) => tag)
                                        }
                                    </Stack>
                                ) : null
                            }
                        </Stack>
                    ) : null
                }
            </Stack>
            {
                actions && actions.length > 0 ? (
                    <>
                        <Text size="title" color="primary.light"
                            sx={{ alignSelf: 'flex-start', height: '22px', display: 'flex' }}
                        >
                            <IconButton
                                onClick={handleClick}
                                sx={{ fontSize: 'inherit', color: 'inherit', p: 0 }}
                            >

                                <MoreVertIcon sx={{ fontSize: 'inherit', color: 'inherit' }} />
                            </IconButton>
                        </Text>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                },
                            }}
                        >
                            {actions.map((action) => (
                                <MenuItem
                                    key={action.value}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleClose();
                                        action.onClick();
                                    }}
                                >
                                    <Text>{action.value}</Text>
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                ) : null
            }
            {
                action ? (
                    <Stack
                        sx={{ alignSelf: 'flex-start' }}
                    >
                        {action}
                    </Stack>
                ) : null
            }
        </Stack >
    );
};

export default Header;