import Stack from '@mui/material/Stack';
import ControlledInput from '../../../../Components/Input/ControlledInput';
import LeadCard from '../../../Leads/Lead/LeadDisplay';


const Customer = ({
    lead,
    handleFormData = () => {},
}) => {
    return (
        <Stack
            gap={1.5}
            direction="column"
            sx={{ p: 2.5, overflow: 'auto', height: '100%' }}
        >
            {
                lead ? (
                    <LeadCard
                        lead={lead}
                    />
                ) : (
                    <>
                        <ControlledInput
                            required
                            size="small"
                            name="book-viewing-customer-name"
                            label="Name"
                            helperText="Customer full name"
                            placeholder="John Doe"
                            errorHelperText="Invalid name"
                            onChange={(value, valid) => handleFormData('customer', 'name', value, valid)}
                        />
                        <ControlledInput
                            required
                            size="small"
                            name="book-viewing-customer-email"
                            label="Email"
                            helperText="Customer email address"
                            placeholder="john@example.com"
                            errorHelperText="Invalid email address"
                            mask={/^\S*@?\S*$/}
                            validator={/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/} // eslint-disable-line no-control-regex
                            onChange={(value, valid) => handleFormData('customer', 'email', value, valid)}
                        />
                        <ControlledInput
                            required
                            size="small"
                            name="book-viewing-customer-phone"
                            label="Phone"
                            helperText="Mobile phone number"
                            placeholder="+44 07xxx xxx xxx"
                            errorHelperText="Invalid phone number - format: +44 07xxx xxx xxx"
                            mask={'+44 00000000000'}
                            validator={/^\+44 (07\d{9})$/}
                            onChange={(value, valid) => handleFormData('customer', 'phone', value, valid)}
                        />
                    </>
                )
            }
        </Stack>
    );
};

export default Customer;