import { getData, distanceFromViewingEnd } from "../utils";
import DataElem from "../DataElem";
import FileComp from "../../Reporter/Form/Inputs/File";
import { SplitContentContainer, SplitContent, SplitContentSingle } from '../SessionReport.styled';

export default { // eslint-disable-line
    name: 'exit',
    emoji: '🔒',
    time: (reports) => {
        return getData(reports.sessionReport, 'times', 'exit') || 'Not yet';
    },
    title: () => {
        return 'Exited the property';
    },
    subtitle: () => {
        return <DataElem text="on time" sx={{ margin: 0 }} />;
    },
    content: (reports) => {
        const exitVideosS3URLS = getData(reports.sessionReport, 'files', 'videos-exit');
        const lastViewing = reports.success.length ? reports.success[reports.success.length - 1] : null;

        return (
            <SplitContentContainer>
                <SplitContent>
                    {
                        exitVideosS3URLS.map((url, idx) => (
                            <FileComp file={{ s3: url }} type="video" display key={`videos-exit${idx}`} />
                        ))
                    }
                </SplitContent>
                <SplitContentSingle>
                    🔓
                    <DataElem
                        report={reports.sessionReport}
                        attr="info"
                        objkey="clem-name"
                    />
                    closed the door{exitVideosS3URLS.length > 1 ? 's' : null}
                    {
                        lastViewing ? (
                            <>
                                <DataElem
                                    text={distanceFromViewingEnd({ time: getData(reports.sessionReport, 'times', 'exit'), viewingReport: lastViewing })}
                                />
                                the end of the last viewing
                            </>
                        ) : null
                    }
                </SplitContentSingle>
            </SplitContentContainer>
        );
    },
};