import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

const fontSizes = {
    normal: '12px',
    medium: '14px',
    large: '16px',
    title: '20px',
};

const Div = styled('div', {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'color' && prop !== 'weight' && prop !== 'sx',
})(({ size, color, weight, theme }) => ({
    fontSize: fontSizes[size] || size,
    color: theme.palette[color.split('.')[0]][color.split('.')[1]],
    fontWeight: weight,
}));

const Span = styled('span', {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'color' && prop !== 'weight' && prop !== 'sx',
})(({ size, color, weight, theme }) => ({
    fontSize: fontSizes[size] || size,
    color: theme.palette[color.split('.')[0]][color.split('.')[1]],
    fontWeight: weight,
}));

const A = styled('a', {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'color' && prop !== 'weight' && prop !== 'sx',
})(({ size, color, weight, theme }) => ({
    fontSize: fontSizes[size] || size,
    color: theme.palette[color.split('.')[0]][color.split('.')[1]],
    fontWeight: weight,
    textDecoration: 'none',
}));

const Text = ({
    children,
    size = 'normal',
    color = 'primary.main',
    weight = 'normal',
    span = false,
    sx = {},
    multiline = false,
    sxContainer = {},
    ...otherProps
}) => {
    let Elem = span ? Span : Div;

    if (otherProps.href) {
        Elem = A;
    }

    if (multiline) {
        return (
            <Stack
                gap={1}
                sx={{ ...sxContainer }}
            >
                {
                    children.split('\n').map((line, idx) => (
                        <Elem
                            key={`line-${idx}`}
                            size={size}
                            color={color}
                            weight={weight}
                            sx={{ ...sx }}
                            {...otherProps}
                        >{line}</Elem>
                    ))
                }
            </Stack>
        )
    }

    return (
        <Elem size={size} color={color} weight={weight} sx={{ ...sx }} {...otherProps}>{children}</Elem>
    );
};

export default Text;