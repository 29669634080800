import React, { useState } from 'react';
import { Container, ValidateButton, IconContainer, ClemName } from './ClemAvailability.styled';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ControlledInput from '../../Input/ControlledInput';
import CircularProgress from "@mui/material/CircularProgress";

const ClemAvailability = ({ onSave, onDelete, availability, clem, loading }) => {
    const [weight, setWeight] = useState(availability && availability.weight ? availability.weight : 0);

    return (
        <Container data-available={!!availability}>
            <IconContainer>
                {
                    loading ? (
                        <CircularProgress sx={{ color: 'center' }} size="18px" color="primary" />
                    ) : (
                        availability
                            ? <CheckCircleOutlineOutlinedIcon fontSize='inherit' />
                            : <CancelOutlinedIcon fontSize='inherit' />
                    )
                }
            </IconContainer>
            <ClemName>{clem.name}</ClemName>
            <ControlledInput
                sx={{ minWidth: '45px', maxWidth: '45px', marginLeft: '8px', flexShink: 0 }}
                disabled={!!availability}
                name="clem-availability-weight"
                label="Weight"
                placeholder="10"
                type="number"
                variant="standard"
                size="small"
                color={availability ? 'secondary' : 'primary'}
                defaultValue={weight}
                value={weight}
                onChange={(value) => setWeight(value)}
            />
            <ValidateButton
                size="small"
                disabled={loading}
                color={availability ? 'secondary' : 'primary'}
                variant={availability ? 'outlined' : 'contained'}
                onClick={() => availability ? onDelete(availability) : onSave(clem, weight)}
            >
                {availability ? 'Delete' : 'Save'}
            </ValidateButton>
        </Container>

    );
};

export default ClemAvailability;