import React from 'react';
import { Link } from "react-router-dom";
import { Wrapper, Container, Logo, TagLineContainer, TagLine, Login, Screen, SubTagLine } from './Landing.styled';

const Landing = () => {
    return (
        <Wrapper>
            <Container>
                <Logo src="logo-purple.svg" />
                <TagLineContainer>
                    <TagLine>Property viewings reinvented</TagLine>
                    <SubTagLine>Coming Soon - Private Beta</SubTagLine>
                </TagLineContainer>
                <Link to="/app"><Login>Login</Login></Link>
                <Screen />
            </Container>
        </Wrapper>
    );
};

export default Landing;