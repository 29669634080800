import { formatInTimeZone } from 'date-fns-tz';

const getViewingMessage = (viewing, property) => (
`Hello ${viewing.viewer.name} 👋

This is an automatic reminder of your property viewing today. See information below:

🏠  Location: ${property.address} - ${property.postcode}

⏰  Date: ${formatInTimeZone(new Date(viewing.startsAt), 'Etc/UTC', 'kk:mm | dd/MM/yyyy')}

💻  Property: ${property.listingUrl}

🚨 Please confirm by responding 'YES' to this message.

🚨 🚨 There are back to back appointments so please arrive on time to avoid disappointment.

Clem Viewings`
);

export default getViewingMessage;