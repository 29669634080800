import { DataStore } from '@aws-amplify/datastore';
import { MarketPlaceConfig, PropertyMarketPlace } from '../models';

// MarketPlaceConfig 

export const createMarketPlaceConfig = async (data) => {
    const marketPlaceConfig = await DataStore.save(
        new MarketPlaceConfig({
            type: data.type ? data.type : 'PUSH',
            platform: data.platform,
            authConfig: JSON.stringify(data.authConfig),
        })
    );

    return marketPlaceConfig;
};

export const updateMarketPlaceConfig = async (mpId, data) => {
    const original = await DataStore.query(MarketPlaceConfig, mpId);
    const marketPlaceConfig = await DataStore.save(
        MarketPlaceConfig.copyOf(original, updated => {
            updated.type = data.type ? data.type : original.type;
            updated.platform = data.platform ? data.platform : original.platform;
            updated.authConfig = data.authConfig ? JSON.stringify(data.authConfig) : original.authConfig;
        })
    );

    return marketPlaceConfig;
};

export const getMarketPlaceConfig = async () => {
    return await DataStore.query(MarketPlaceConfig);
};

// PropertyMarketPlace 

export const createPropertyMarketPlace = async (data) => {
    const propertyMarketPlace = await DataStore.save(
        new PropertyMarketPlace({
            propertyId: data.propertyId,
            externalId: data.externalId,
            platform: data.platform,
            url: data.url,
        })
    );

    return propertyMarketPlace;
};

export const updatePropertyMarketPlace = async (mpId, data) => {
    const original = await DataStore.query(PropertyMarketPlace, mpId);
    const propertyMarketPlace = await DataStore.save(
        PropertyMarketPlace.copyOf(original, updated => {
            updated.propertyId = data.propertyId ? data.propertyId : original.propertyId;
            updated.externalId = data.externalId ? data.externalId : original.externalId;
            updated.platform = data.platform ? data.platform : original.platform;
            updated.url = data.url ? data.url : original.url;
        })
    );

    return propertyMarketPlace;
};

export const deletePropertyMarketPlace = async (mpId) => {
    const original = await DataStore.query(PropertyMarketPlace, mpId);
    const propertyMarketPlace = await DataStore.save(
        PropertyMarketPlace.copyOf(original, updated => {
            updated.deletedAt = new Date().toISOString();
        })
    );

    return propertyMarketPlace;
};

export const getPropertyMarketPlace = async () => {
    return (await DataStore.query(PropertyMarketPlace)).filter(mp => !mp.deletedAt);
};

export const getPropertyMarketPlaceByProperty = async (propertyId) => {
    return (await DataStore.query(PropertyMarketPlace, pm => pm.propertyId('eq', propertyId))).filter(mp => !mp.deletedAt);
};


export default { // eslint-disable-line
    config: {
        create: createMarketPlaceConfig,
        update: updateMarketPlaceConfig,
        getAll: getMarketPlaceConfig,
    },
    property: {
        create: createPropertyMarketPlace,
        update: updatePropertyMarketPlace,
        delete: deletePropertyMarketPlace,
        getAll: getPropertyMarketPlace,
        getByProperty: getPropertyMarketPlaceByProperty,
    },
};