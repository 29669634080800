import React from 'react';
import Files from '../Inputs/Files';
import Time from '../Inputs/Time';
import { FormContainer } from '../form.styled';
import { format } from 'date-fns';

const PreViewingForm = ({ sessionId, onUpdate, reports }) => {
    const handleFormData = async ({ type, id, data, key }) => {
        await onUpdate({ type, id, data, key });
    };

    return (
        <FormContainer>
            <Time
                name={'previewing'}
                title={'Previewing checklist time'}
                subtitle={'At what time did you finish the previewing checklist?'}
                currentTime={reports.sessionReport ? reports.sessionReport.times.filter((t) => t.key === 'previewing')[0] : null}
                onChange={async (newTime, id) => {
                    await handleFormData({ type: 'times', id, data: { time: format(newTime, 'HH:mm') }, key: 'previewing' })
                }}
            />
            <Files
                title={'Previewing checklist photos'}
                subtitle={'Take a picture of each room after the previewing checklist'}
                files={reports.sessionReport ? reports.sessionReport.files.filter((f) => f.key === 'images-previewing') : []}
                type="image"
                name={'images-previewing'}
                sessionId={sessionId}
                onChange={async (newFiles) => await handleFormData({ type: 'files', data: newFiles, key: 'images-previewing' })}
                onDelete={async (file) => await handleFormData({
                    type: 'update', data: {
                        files: [...reports.sessionReport.files.filter((f) => f.id !== file.id)]
                    }
                })}
            />
        </FormContainer>
    );
};

export default PreViewingForm;