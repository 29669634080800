import React, { useState } from 'react';
import { FormContainer, ViewingStatusContainer } from '../form.styled';
import AutoSaveInput from '../../../library/Inputs/AutoSaveInput';
import Tag from '../../../library/Tag/Tag';
import { Container, Title, SubTitle } from '../Inputs/inputs.styled';

const MoreInfoForm = ({ sessionId, onUpdate, reports }) => {
    const [loading, setLoading] = useState(false);

    const handleFormData = async ({ type, id, data, key }) => {
        await onUpdate({ type, id, data, key });
    };

    return (
        <FormContainer>
            <ViewingStatusContainer sx={{ width: 'calc(100% - 10px)' }}>
                <Container data-disabled={loading}>
                    <div>
                        <Title>Session notes</Title>
                        <SubTitle>Write who came to the viewing & any additional info</SubTitle>
                    </div>
                    <AutoSaveInput
                        defaultValue={(reports.sessionReport.info.find((t) => t.key === 'more-info') || { value: '' }).value}
                        multiline
                        rows={8}
                        onChange={(value) => {
                            console.log('change val', value);
                        }}
                        saveDebounceTime={2000}
                        onSave={async (value) => {
                            const id = (reports.sessionReport.info.find((t) => t.key === 'more-info') || { id: null }).id;
                            await handleFormData({ type: 'info', id, data: { value }, key: 'more-info' });
                        }}
                        statuses={{
                            saving: (<Tag color="primary.main">💾 Saving...</Tag>),
                            saved: (<Tag color="primary.main">👍 Saved!</Tag>),
                            changed: (<Tag color="primary.main">💾 Saving...</Tag>),
                            default: null,
                        }}
                    />
                </Container>
            </ViewingStatusContainer>
        </FormContainer>
    );
};

export default MoreInfoForm 