import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Fuse from 'fuse.js';
import { formatDistance } from 'date-fns';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Card from '../../../Components/library/Cards/Generic';
import Header from '../../../Components/library/Cards/Headers/Standard';
import HeaderWithSearch from '../../../Components/library/Cards/Headers/WithSearch';
import Text from '../../../Components/library/Typography/Text';
import Tag from '../../../Components/library/Tag/Tag';
import MarketPlaceTag from '../../../Components/library/Tag/MarketPlaceTag';

import {
    ContainerCard,
    CardHeaderContainer
} from '../../Leads/leads.styled';

const fuseOptions = {
    includeScore: true,
    minMatchCharLength: 2,
    keys: ['postcode']
};

const PropertyList = ({
    properties,
    title = 'Properties',
    selectedProperty = null,
    color = 'secondary.light',
    addPropertyDisabled = false,
    compactDisplay = true,
    onAddProperty = () => { },
    onSelectProperty = () => { },
    noAdd = false,
}) => {
    const navigate = useNavigate();
    const [initialDisplay, setInitialDisplay] = useState(true);
    const [fuse, setFuse] = useState(null);
    const [filteredProperties, setFilteredProperties] = useState(properties);

    useEffect(() => {
        if (properties && properties.length && initialDisplay) {
            setInitialDisplay(false);
        }

        setFuse(new Fuse(properties, fuseOptions));
        setFilteredProperties(properties);
    }, [properties]);

    return (
        <ContainerCard
            sx={{ minWidth: '400px', maxWidth: '600px', width: '100%' }}
            header={
                <CardHeaderContainer>
                    <HeaderWithSearch
                        title={title}
                        tags={[
                            <Tag key="plist-tag-1">{properties.length} Properties</Tag>,
                        ]}
                        onSearchChange={(value) => {
                            if (value.length === 0) {
                                setFilteredProperties(properties);
                                return;
                            }

                            if (value.length < 2) {
                                return;
                            }

                            const res = fuse.search(value).map((r) => r.item);

                            setFilteredProperties(res);
                        }}
                        onSearchClose={() => {
                            setFilteredProperties(properties);
                        }}
                    />
                    {
                        !noAdd ? (
                            <Stack sx={{ width: '100%', height: 'fit-content' }}>
                                <Button
                                    disabled={addPropertyDisabled}
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: 'fit-content', m: 2.5, ml: 1.5, mt: 1.5 }}
                                    onClick={() => onAddProperty()}
                                >
                                    <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                                    Add Property
                                </Button>
                            </Stack>
                        ) : null
                    }
                </CardHeaderContainer >
            }
            withBodyContainer={false}
            body={
                <Stack
                    spacing={2.5}
                    direction="column"
                    sx={{
                        p: 2.5,
                        overflow: 'auto',
                        height: '100%',
                        opacity: initialDisplay ? 0 : 1,
                        transition: 'opacity 0.5s ease-out',
                    }}
                >
                    {
                        filteredProperties.length ? filteredProperties.map((property) => (
                            <Card
                                sx={{ cursor: 'pointer' }}
                                selected={selectedProperty && selectedProperty.id === property.id}
                                key={property.id}
                                header={
                                    <Header
                                        emoji='🏡'
                                        title={
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                gap={1}
                                            >

                                                <Text
                                                    weight="bold"
                                                    size="medium"
                                                    span
                                                    color={`${color.split('.')[0]}.contrastText`}
                                                >{property.postcode}</Text>
                                                <Text
                                                    span
                                                    color={`${color.split('.')[0]}.contrastText`}
                                                > - {property.address}</Text>
                                            </Stack>
                                        }
                                        sx={{ backgroundColor: color }}
                                        actions={[
                                            {
                                                value: 'View Property',
                                                onClick: () => navigate(`/app/properties/${property.id}`),
                                            },
                                        ]}
                                    />
                                }
                                body={
                                    !compactDisplay ? (
                                        <Stack
                                            spacing={1.5}
                                        >
                                            {
                                                property.marketPlaces.map((mp) => (
                                                    <Stack
                                                        direction="row"
                                                        spacing={1.5}
                                                        alignItems="center"
                                                        key={`${property.id}-${mp.id}`}
                                                    >
                                                        <Stack
                                                            spacing={1.5}
                                                            direction="row"
                                                            alignItems="center"
                                                            sx={{ width: '100%' }}
                                                        >
                                                            {mp.platform === 'unknown' ? <Tag>Custom</Tag> : <MarketPlaceTag marketPlace={mp.platform} />}
                                                            <Text> | </Text>
                                                            <Tag sx={{ maxWidth: '250px' }}>{mp.url.replace('https://', '').replace('www.', '')}</Tag>
                                                        </Stack>
                                                        {mp.createdAt ? <Tag>{formatDistance(new Date(mp.createdAt), new Date(), { addSuffix: true })}</Tag> : null}
                                                    </Stack>
                                                ))
                                            }
                                        </Stack>
                                    ) : null
                                }
                                footer={
                                    !compactDisplay && !property.price ? (
                                        <Stack
                                            sx={{
                                                p: 2,
                                                pt: 0,
                                            }}
                                        >
                                            <Text weight="bold"><Text size="large" span>⚠️</Text> Missing price information</Text>
                                        </Stack>
                                    ) : null
                                }
                                onClick={() => {
                                    onSelectProperty(property);
                                }}
                            />
                        )) : (
                            <Text
                                color="primary.light"
                                span
                                sx={{ width: '100%', textAlign: 'center', pt: 4, pb: 4 }}
                            >No properties add one with the <Text weight="bold" span>ADD PROPERTY</Text> button</Text>
                        )
                    }
                </Stack >
            }
        />
    );
};

export default PropertyList;