import React, { useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ViewingCard, Info, Status, SettingsMenu, Place, Time, ReportLink, ReportButton, PastViewingCard, ConfirmCancelContainer } from './ViewingCard.styled';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import { formatInTimeZone } from 'date-fns-tz';
import MenuItem from '@mui/material/MenuItem';

const ViewingCards = ({ viewing, simple, title, subheader, avatar, cancelAction = false }) => {
    const [toBeCancelled, setToBeCancelled] = useState(false);

    let past = false;
    if (new Date() - new Date(viewing.startsAt) > 0) {
        past = true;
    }

    const Card = past ? PastViewingCard : ViewingCard;

    if (!viewing.Viewer) {
        return (
            <Card variant="outlined" data-simple={simple}>
                <Info
                    avatar={
                        <Time label={formatInTimeZone(new Date(viewing.startsAt), 'Etc/UTC', 'kk:mm')} />
                    }
                    title={'⚠️ Ooops something is wrong!'}
                    subheader={
                        <div>
                            Try refresh the page.
                            <br />
                            {cancelAction ? 'If the problem persist cancel the viewing and book again.' : ''}
                        </div>
                    }
                    action={
                        !past && cancelAction ? (
                            <SettingsMenu
                                Button={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                onClose={() => setToBeCancelled(false)}
                                stayOpen={toBeCancelled}
                            >
                                {
                                    toBeCancelled ? (
                                        <ConfirmCancelContainer>
                                            Are you sure ?
                                            <MenuItem
                                                onClick={() => {
                                                    setToBeCancelled(false);
                                                    console.log('delete viewing', viewing);
                                                    DataStore.delete(viewing);
                                                }}
                                            >
                                                Yes
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    setToBeCancelled(false);
                                                }}
                                            >
                                                No
                                            </MenuItem>
                                        </ConfirmCancelContainer>
                                    ) : (
                                        <MenuItem
                                            onClick={(e) => {
                                                setToBeCancelled(true);
                                            }}
                                        >
                                            Cancel Viewing
                                        </MenuItem>
                                    )
                                }
                            </SettingsMenu>
                        ) : null
                    }
                />
            </Card>
        );
    }

    return (
        <Card variant="outlined" data-simple={simple}>
            <Info
                avatar={
                    <Time label={formatInTimeZone(new Date(viewing.startsAt), 'Etc/UTC', 'kk:mm')} />
                }
                action={
                    !past && cancelAction ? (
                        <SettingsMenu
                            Button={
                                <IconButton aria-label="settings">
                                    <MoreVertIcon />
                                </IconButton>
                            }
                            onClose={() => setToBeCancelled(false)}
                            stayOpen={toBeCancelled}
                        >
                            {
                                toBeCancelled ? (
                                    <ConfirmCancelContainer>
                                        Are you sure ?
                                        <MenuItem
                                            onClick={() => {
                                                setToBeCancelled(false);
                                                console.log('delete viewing', viewing);
                                                DataStore.delete(viewing);
                                            }}
                                        >
                                            Yes
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                setToBeCancelled(false);
                                            }}
                                        >
                                            No
                                        </MenuItem>
                                    </ConfirmCancelContainer>
                                ) : (
                                    <MenuItem
                                        onClick={(e) => {
                                            setToBeCancelled(true);
                                        }}
                                    >
                                        Cancel Viewing
                                    </MenuItem>
                                )
                            }
                        </SettingsMenu>
                    ) : null
                }
                title={viewing.Viewer.name}
            />
            {
                simple ? (
                    avatar && (title || subheader) ?
                        <Info
                            avatar={avatar}
                            title={title}
                            subheader={subheader}
                        />
                        : null
                ) : (
                    <Info
                        avatar={
                            <Place aria-label="place">
                                <PlaceOutlinedIcon />
                            </Place>
                        }
                        title={viewing.Property ? viewing.Property.postcode : 'Error getting the Property'}
                        subheader={viewing.Property ? viewing.Property.address : ''}
                    />
                )
            }
            {
                simple ? (
                    null
                ) : (
                    past ? (
                        <ReportLink to={`/report/${viewing.id}`}>
                            <ReportButton aria-label="settings" variant="outlined">
                                View Report
                            </ReportButton>
                        </ReportLink>
                    ) : (
                        <Status>{!viewing.createdAt ? 'pending' : <ThumbUpOffAltOutlinedIcon />}</Status>
                    )
                )
            }
        </Card >
    );
};

export default ViewingCards;