import { useEffect, useState } from "react";
import { format, getDaysInMonth } from "date-fns";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';

import Stack from "@mui/material/Stack";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import Card from "../../Components/library/Cards/Generic";
import Header from '../../Components/library/Cards/Headers/Standard';
import Text from "../../Components/library/Typography/Text";
import Tag from "../../Components/library/Tag/Tag";

import Bills from "../../data/bills";

import APPS_CONFIG from "../config";

import {
    BackButton,
    CardTitleText,
} from '../Leads/leads.styled';

import { Divider } from "@mui/material";

const createChartData = (dayBills) => {
    const daysOfMonth = getDaysInMonth(dayBills.length ? new Date(dayBills[0].date) : new Date());
    const billsArray = dayBills.reduce((tot, dBill) => {
        const dBillIndex = parseInt(dBill.date.split('-')[2], 10);

        tot[dBillIndex - 1] = {
            name: `${format(new Date(dBill.date), 'do')}`,

            properties: dBill.items.filter(i => i.type === 'property').reduce((t, i) => t + parseInt(i.charge, 10), 0) / 100,
            leads: dBill.items.filter(i => i.type === 'lead').reduce((t, i) => t + parseInt(i.charge, 10), 0) / 100,
            manualLeads: dBill.items.filter(i => i.type === 'lead' && i.status === 'MANUAL').reduce((t, i) => t + parseInt(i.charge, 10), 0) / 100,
            autoLeads: dBill.items.filter(i => i.type === 'lead' && i.status === 'AUTO').reduce((t, i) => t + parseInt(i.charge, 10), 0) / 100,
            viewings: dBill.items.filter(i => i.type === 'viewing').reduce((t, i) => t + parseInt(i.charge, 10), 0) / 100,

            manualLeadsCount: dBill.items.filter(i => i.type === 'lead' && i.status === 'MANUAL').length,
            autoLeadsCount: dBill.items.filter(i => i.type === 'lead' && i.status === 'AUTO').length,
            propertiesCount: dBill.items.filter(i => i.type === 'property').length,
            successViewingsCount: dBill.items.filter(i => i.type === 'viewing' && i.status === 'SUCCESS').length,
            failedViewingsCount: dBill.items.filter(i => i.type === 'viewing' && i.status === 'FAILED').length,

            _total: new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(dBill.value / 100),
        };

        return tot;
    }, Array.apply(null, Array(daysOfMonth)).map((elem, index) => ({
        name: `${format(new Date(`2023-01-${index + 1}`), 'do')}`,
        properties: 0, 
        leads: 0, 
        manualLeads: 0, 
        autoLeads: 0, 
        viewings: 0, 
        manualLeadsCount: 0,
        autoLeadsCount: 0,
        propertiesCount: 0,
        successViewingsCount: 0,
        failedViewingsCount: 0,
        _total: new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(0),
    })));

    console.log(billsArray);

    return billsArray;
};

const chartToolTipFormatter = (value, name, props) => {
    const names = {
        properties: `${props.payload.propertiesCount} Active Properties`,
        autoLeads: `${props.payload.autoLeadsCount} Leads from Marketplaces`,
        manualLeads: `${props.payload.manualLeadsCount} Leads added manually`,
        viewings: `${props.payload.successViewingsCount} SUCCESSFUL Viewings`,
    };

    return [new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value), names[name]];
}

const UserBills = ({
    onBack = () => { },
}) => {
    const [dayBills, setDayBills] = useState(null);
    const [monthBills, setMonthBills] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const dBills = await Bills.day.getMonthToDate();
            const mBills = await Bills.month.getLastSixMonth();
            setDayBills(dBills);
            setMonthBills(mBills);
        };

        fetchData();
    }, []);

    return (
        <Card
            sx={{
                backgroundColor: 'background.default',
                height: '100%',
            }}
            header={
                <Header
                    title={
                        <Stack
                            direction="row"
                            alignItems="center"
                        >
                            <BackButton
                                sx={{ padding: 0 }}
                                onClick={onBack}
                            >
                                <ArrowBackOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                                Back
                            </BackButton>
                            <CardTitleText size="large" weight="bold" sx={{
                                flexShrink: 0,
                                display: 'flex',
                                alignItems: 'center',
                                alignSelf: 'center',
                                textAlign: 'center',
                                pointerEvents: 'none',
                                gap: 1,
                            }}>🧾 Bills</CardTitleText>
                        </Stack>
                    }
                    tags={[
                        <Tag key="beta-tag">BETA</Tag>
                    ]}
                />
            }
            withBodyContainer={false}
            body={
                <Stack
                    gap={2.5}
                    sx={{
                        p: 2.5,
                        maxHeight: '100%',
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    <Text sx={{ pb: 1.5 }}>
                        <Text weight="bold" size="medium" sx={{ pb: 1 }}>🟠 Disclamer</Text>
                        <Text sx={{ pl: 1.5 }}>The billing service is in <Text weight="bold" size="medium" span>BETA PHASE</Text></Text>
                        <Text sx={{ pl: 1.5 }}>All numbers below are indicative only and can change in your final bill that you will receive by email.</Text>
                    </Text>
                    <Card
                        header={
                            <Header
                                emoji="👇"
                                title="Current Month"
                                sx={{ backgroundColor: APPS_CONFIG.user.color }}
                                tags={[
                                    <Tag
                                        color="primary.main"
                                        key={`tag-month`}
                                    >
                                        {format(dayBills && dayBills[0] ? new Date(dayBills[0].date) : new Date(), 'MMMM')}
                                    </Tag>
                                ]}
                            />
                        }
                        body={
                            <Stack
                                gap={1.5}
                                sx={{
                                    position: 'relative',
                                }}
                            >
                                <Stack
                                    sx={{ marginLeft: '-30px', color: 'primary.main' }}
                                >
                                    {
                                        dayBills ? (
                                            <ResponsiveContainer
                                                width="100%"
                                                height={600}
                                                minHeight={600}
                                                debounce={1000}
                                            >
                                                <BarChart
                                                    layout="vertical"
                                                    data={createChartData(dayBills ? dayBills : [])}
                                                >
                                                    <XAxis
                                                        unit="£"
                                                        interval={0}
                                                        scale="linear"
                                                        type="number"
                                                        domain={[0, 'dataMax + 10']}
                                                    />
                                                    <YAxis
                                                        dataKey="name"
                                                        interval={0}
                                                        type="category"
                                                    />
                                                    <Tooltip formatter={chartToolTipFormatter} />
                                                    <Bar dataKey="properties" stackId="a" fill="#f8b15d" layout="horizontal" />
                                                    <Bar dataKey="autoLeads" stackId="a" fill="#73d4bc" layout="horizontal" />
                                                    <Bar dataKey="manualLeads" stackId="a" fill="#4bd1b0" layout="horizontal" />
                                                    <Bar dataKey="viewings" stackId="a" fill="#4c3492" layout="horizontal">
                                                        <LabelList dataKey="_total" position="right" />
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        ) : null
                                    }
                                </Stack>
                                <Text
                                    color="primary.light"
                                    weight="bold"
                                    sx={{ width: '100%', textAlign: 'center' }}
                                    size="large"
                                >
                                    Total: <Text weight="bold" size="large" span>{
                                        new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(
                                            (dayBills || []).reduce((tot, dB) => tot + dB.value, 0) / 100
                                        )
                                    }</Text>
                                    <Text color="primary.light" size="normal">(up to yesterday)</Text>
                                </Text>
                            </Stack>
                        }
                    />
                    <Card
                        header={
                            <Header
                                emoji="🗄️"
                                title="Past Months"
                                sx={{ backgroundColor: APPS_CONFIG.user.color }}
                            />
                        }
                        body={
                            <Stack
                                gap={1.5}
                                sx={{
                                    position: 'relative',
                                }}
                            >
                                {
                                    monthBills ? monthBills.map((mBill) => (
                                        <Card
                                            key={`mbill-${mBill.id}`}
                                            header={
                                                <Header
                                                    title={format(new Date(mBill.date), 'yyyy - MMMM')}
                                                    sx={{ backgroundColor: APPS_CONFIG.user.color }}
                                                    tags={[
                                                        <Tag
                                                            color="primary.main"
                                                            key={`tag-mbill-price`}
                                                        >
                                                            {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(mBill.value / 100)}
                                                        </Tag>,
                                                        <Tag key={`tag-mbill-status`}>
                                                            {`${mBill.status} ${mBill.status === 'UNPAID' ? '🔴' : '🟢'}`}
                                                        </Tag>,
                                                    ]}
                                                />
                                            }
                                            body={
                                                <Stack
                                                    gap={1.5}
                                                >
                                                    {
                                                        mBill.items.map((item) => (
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="space-between"
                                                            >
                                                                <Stack
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    gap={1.5}
                                                                >
                                                                    <img width="20px" src={APPS_CONFIG[item.type === 'property' ? 'properties' : `${item.type}s`].logo} />
                                                                    <Tag>{item.count}</Tag>
                                                                    <Text>
                                                                        {(item.type === 'property' ? 'properties' : `${item.type}s`).toUpperCase()}
                                                                    </Text>
                                                                </Stack>
                                                                <Tag>{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.charge / 100)}</Tag>
                                                            </Stack>
                                                        ))
                                                    }
                                                    <Divider />
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        gap={1.5}
                                                        justifyContent="space-between"
                                                    >
                                                        <Text
                                                            size="large"
                                                            weight="bold"
                                                        >TOTAL</Text>
                                                        <Tag>
                                                            <Text size="large" weight="bold">
                                                                {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(mBill.value / 100)}
                                                            </Text>
                                                        </Tag>
                                                    </Stack>
                                                </Stack>
                                            }
                                        />
                                    )) : null
                                }
                            </Stack>
                        }
                    />
                </Stack>
            }
        />
    );
};

export default UserBills;