import { getData, distance } from "../utils";
import DataElem from "../DataElem";
import FileComp from "../../Reporter/Form/Inputs/File";
import { SplitContentContainer, SplitContent, SplitContentSingle } from '../SessionReport.styled';

export default { // eslint-disable-line
    name: 'keysdropff',
    emoji: '🔑',
    time: (reports) => {
        return getData(reports.sessionReport, 'times', 'keydropoff') || 'Not yet';
    },
    title: (reports) => {
        return (
            <>
                Clem dropped off the keys
            </>
        );
    },
    subtitle: (reports) => {
        return (
            <>
                to
                <DataElem
                    report={reports.sessionReport}
                    attr="info"
                    objkey="keydropoff-location"
                />
            </>
        );
    },
    content: (reports) => {
        const keydropoffTime = getData(reports.sessionReport, 'times', 'keydropoff');
        const exitTime = getData(reports.sessionReport, 'times', 'exit');
        if (!keydropoffTime) {
            return 'Keys have not being dropped yet';
        }

        const s3URLS = getData(reports.sessionReport, 'files', 'images-keydropoff');

        return (
            <SplitContentContainer>
                <SplitContent>
                    {
                        s3URLS.map((url, idx) => (
                            <FileComp file={{ s3: url }} type="image" display key={`images-keydropoff${idx}`} />
                        ))
                    }
                </SplitContent>
                <SplitContentSingle>
                    <DataElem
                        report={reports.sessionReport}
                        attr="info"
                        objkey="clem-name"
                        sx={{ marginLeft: 0 }}
                    />
                    dropped off the keys
                    {
                        exitTime ? (
                            <>
                                <DataElem
                                    text={
                                        distance({
                                            timeStart: exitTime,
                                            timeEnd: keydropoffTime,
                                        })
                                    }
                                />
                                exiting the property
                            </>
                        ) : null
                    }
                </SplitContentSingle>
            </SplitContentContainer>
        );
    },
};