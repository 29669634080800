import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '../Cards/Generic';
import Text from '../Typography/Text';

export const ContainerCard = styled(Card)(({ theme }) => ({
    // minWidth: '600px',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    maxHeight: '100%',
    overflow: 'hidden',
    position: 'relative',
    flexShrink: 1,

    [theme.breakpoints.down(800)]: {
        minWidth: '100%',
        maxWidth: '100%',
        border: 'none',
    },
}));

export const BackButton = styled(Button)(({ theme }) => ({
    display: 'none',

    [theme.breakpoints.down(1000)]: {
        display: 'flex',
    },
}));

export const CardTitleText = styled(Text)(({ theme }) => ({
    position: 'relative',
    justifyContent: 'center',

    [theme.breakpoints.down(1000)]: {
        position: 'absolute',
        width: 'calc(100% - 24px)',
    },
}));