import Text from '../../Components/library/Typography/Text';
import Stack from '@mui/material/Stack';
import Card from '../../Components/library/Cards/Generic';
import ActionFooter from '../../Components/library/Cards/Footers/Action';
import PropertyCard from './PropertyCard';

const ChatInto = ({
    start = () => { },
}) => {
    return (
        <Card
            sx={{
                margin: 4,
                backgroundColor: 'secondary.light'
            }}
            body={
                <Stack
                    sx={{
                        margin: 2.5,
                        marginTop: 0,
                    }}
                    gap={1.5}
                    alignItems="center"
                >

                    <Text weight="bold" size="title">Thanks for your interest in this property ⬇️</Text>
                    <PropertyCard
                        sx={{ margin: 4 }}
                    />
                    <Text size="large" weight="bold" sx={{ textAlign: 'center' }}>Clem works with Hood Estates <Text size="large" weight="bold">To give you the best viewing experience possible</Text></Text>
                    <Stack
                        gap={1.5}
                        sx={{
                            margin: '20px 0px',
                            padding: 2.5,
                            backgroundColor: 'background.default',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: 'primary.light',
                            borderRadius: '12px',
                        }}
                        alignItems="center"
                    >
                        <Text size="large" weight="bold" sx={{ textAlign: 'center' }}>Here are the steps to view the property</Text>
                        <Stack
                            sx={{
                                marginLeft: '20px',
                            }}
                        >
                            <Text size="medium">1️⃣ We ask you some basic questions now</Text>
                            <Text size="medium">2️⃣ Hood Estates will arange the viewing</Text>
                            <Text size="medium">3️⃣ You view the property</Text>
                        </Stack>
                    </Stack>
                    <Text size="medium" weight="bold">Let's start by some questions, it should not take more than 3 minutes</Text>
                </Stack >
            }
            footer={
                < ActionFooter
                    align='center'
                    actions={
                        [
                            {
                                button: true,
                                buttonVariant: 'contained',
                                value: 'Start',
                                onClick: () => {
                                    start();
                                }
                            }
                        ]}
                />
            }
        />
    );
};

export default ChatInto;