import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { useOutletContext } from '../../Auth';
import Text from '../library/Typography/Text';

import getItems from './NavBar.items';

const Img = styled('img')(() => ({
    // width: '30px',
    // transition: 'all 0.2s ease-in'
}));

const MStack = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down(800)]: {
        flexDirection: 'row',
    },
}));

const NavBarItem = ({
    logo,
    name,
    isSelected,
    onClick = () => { },
}) => (
    <Stack
        alignItems="center"
        gap={0.5}
        sx={{
            cursor: 'pointer',
            '& > img': !isSelected ? {
                filter: 'grayscale(100%)',
                opacity: 0.5,

                '&:hover': {
                    filter: 'grayscale(30%)',
                    opacity: 0.7,
                },
            } : {
                filter: 'grayscale(0%)',
                opacity: 1,
            },
        }}
        onClick={onClick}
    >
        <Img
            src={logo}
            sx={{
                width: '30px',
                transition: 'all 0.2s ease-in'
            }}
        />
        <Text
            size="10px"
            weight={isSelected ? 'bold' : 'normal'}
            sx={{ textTransform: 'capitalize' }}
        >
            {name}
        </Text>
    </Stack>
);

const NavBar = () => {
    const context = useOutletContext();
    const location = useLocation();
    const navigate = useNavigate();

    const items = getItems(context.userData.isClem, context.userData.isAdmin);

    return (
        <MStack
            alignItems="center"
            gap={6}
            sx={(theme) => ({
                pt: 3.5,
                width: '74px',
                backgroundColor: 'background.default',
                zIndex: 1,

                [theme.breakpoints.down(800)]: {
                    pt: 0,
                    width: '100%',
                    height: '70px',
                    borderTop: `1px solid ${theme.palette.primary.ultraLight}`,
                    boxShadow: theme.shadows[14],
                },
            })}
        >
            <Img
                width="50px"
                src='/app/logo-purple.svg'
                sx={(theme) => ({
                    [theme.breakpoints.down(800)]: {
                        display: 'none',
                    },
                })}
            />
            <MStack
                gap={2.5}
                sx={(theme) => ({
                    [theme.breakpoints.down(800)]: {
                        pl: 3,
                    },
                })}
            >
                {
                    items.filter((i) => i.enabled).map((item) => (
                        <NavBarItem
                            key={`navbar-item-${item.link}`}
                            name={item.name}
                            logo={item.logo}
                            isSelected={location.pathname.startsWith(`/app${item.link}`)}
                            onClick={() => navigate(`/app${item.link}`)}
                        />
                    ))
                }
            </MStack>
            <MStack
                justifyContent="flex-end"
                sx={(theme) => ({
                    height: '100%',
                    width: '100%',
                    p: 2.5,
                    [theme.breakpoints.down(800)]: {
                        p: 1.5,
                    },
                })}
            >
                <NavBarItem
                    name="Profile"
                    logo="/app/services-logos/user.svg"
                    isSelected={location.pathname.startsWith(`/app/user`)}
                    onClick={() => navigate('/app/user')}
                />
            </MStack>
        </MStack>
    );
};

export default NavBar;