import { useEffect, useState } from "react";
import { Button, Stack, IconButton } from "@mui/material";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Card from "../../../Components/library/Cards/Generic";
import Header from '../../../Components/library/Cards/Headers/Standard';
import Footer from '../../../Components/library/Cards/Footers/Action';

import Tag from "../../../Components/library/Tag/Tag";
import Text from "../../../Components/library/Typography/Text";
import ControlledInput from "../../../Components/Input/ControlledInput";
import MarketPlaceTag from "../../../Components/library/Tag/MarketPlaceTag";

const getMarketPlaceFromUrl = (url = '') => {
    if (url.includes('zoopla.co.uk')) {
        return 'zoopla';
    } else if (url.includes('rightmove.co.uk') || url.includes('rightmove.com')) {
        return 'rightmove';
    } else if (url.includes('onthemarket.com')) {
        return 'onthemarket';
    }

    return 'unknown';
};

const PropertyMarketPlaces = ({
    property,
    color = 'secondary.light',
    onSave = () => { },
    onEdit = () => { },
    onCancel = () => { },
    onChange = () => { },
    create = false,
}) => {
    const [editing, setEditing] = useState(create);
    const [saving, setSaving] = useState(false);
    const [newMarketPlaces, setNewMarketPlaces] = useState(
        !create ? [] : [{
            url: '',
            platform: 'unknown',
            createdAt: new Date(),
        }]
    );
    const [modifiedMarketPlaces, setModifiedMarketPlaces] = useState(property.marketPlaces || []);
    const [toDelete, setToDelete] = useState([]);
    const [idsModified, setIdsModified] = useState({});

    useEffect(() => {
        setEditing(create);
        setToDelete([]);
        setIdsModified({});
        setModifiedMarketPlaces(property.marketPlaces || []);
    }, [property]);

    const renderMarketPlaceTag = (mp, idx) => (
        mp.platform === 'unknown' ? (
            <Tag key={`${property.id}-mkplace-tag-${idx}`} sx={{ width: '100px', flexShrink: 0 }}>Custom</Tag>
        ) : (
            <MarketPlaceTag key={`${property.id}-mkplace-tag-${idx}`} sx={{ width: '100px', flexShrink: 0 }} marketPlace={mp.platform} />
        )
    );

    const handleCancel = () => {
        setEditing(false);
        setNewMarketPlaces([]);
        setModifiedMarketPlaces(property.marketPlaces || []);
        onCancel();
    };

    return (
        <Card
            header={
                <Header
                    emoji="🏭"
                    title={
                        <Text
                            size="large"
                            weight="bold"
                            color={`${color.split('.')[0]}.contrastText`}
                            sx={{ flexShrink: 0 }}
                        >
                            Market Places
                        </Text>
                    }
                    sx={{ backgroundColor: color }}
                />
            }
            body={
                editing ? (
                    <Stack
                        gap={2.5}
                    >
                        <Stack>
                            {modifiedMarketPlaces.map((mp, index) => (
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    key={`${property.id}-mkplace-edit-${index}`}
                                >
                                    <Stack
                                        direction="row"
                                        gap={1}
                                        alignItems="center"
                                    >
                                        {renderMarketPlaceTag(mp, index)}
                                        <Text>{mp.url}</Text>
                                    </Stack>
                                    {/* <ControlledInput
                                    fullWidth
                                    key={`marketplace-input-${index}`}
                                    label="Market Place (URL)"
                                    size="small"
                                    errorHelperText="Invalid url - don't forget 'https://' at the start of the url"
                                    validator={/^(http(s)?):\/\/[(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/} // eslint-disable-line
                                    defaultValue={mp.url}
                                    value={mp.url}
                                    onChange={(val, valid) => {
                                        setModifiedMarketPlaces((currentMP) => {
                                            const modifMP = currentMP.map((m, idx) => {
                                                if (idx === index) {
                                                    return {
                                                        ...m,
                                                        url: val,
                                                        valid,
                                                        platform: getMarketPlaceFromUrl(val),
                                                    };
                                                }

                                                return m;
                                            });

                                            setIdsModified((c) => ({
                                                ...c,
                                                [mp.id]: true,
                                            }))

                                            onChange({ modifiedMarketPlaces: modifMP });

                                            return modifMP;
                                        });
                                    }}
                                /> */}
                                    <IconButton
                                        onClick={() => {
                                            const modifMP = modifiedMarketPlaces.filter((_, idx) => idx !== index);
                                            setToDelete([...toDelete, mp]);
                                            onChange({ modifiedMarketPlaces: modifMP });
                                            setModifiedMarketPlaces(modifMP);
                                        }}
                                        sx={{ display: 'flex' }}
                                    >
                                        <DeleteForeverOutlinedIcon />
                                    </IconButton>
                                </Stack>
                            ))}
                        </Stack>
                        <Stack
                            gap={2.5}
                        >
                            {
                                newMarketPlaces && newMarketPlaces.length ? newMarketPlaces.map((nm, index) => (
                                    <Stack
                                        direction="row"
                                        gap={1}
                                        alignItems="center"
                                        key={`${property.id}-mkplace-${index}`}
                                    >
                                        {renderMarketPlaceTag(nm)}
                                        <ControlledInput
                                            fullWidth
                                            autoFocus={!create}
                                            key={`newmarketplace-input-${index}`}
                                            label="Market Place (URL)"
                                            size="small"
                                            value={nm.url}
                                            errorHelperText="Invalid url - don't forget 'https://' at the start of the url"
                                            validator={/^(http(s)?):\/\/[(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/} // eslint-disable-line
                                            onChange={(val, valid) => {
                                                setNewMarketPlaces((currentMP) => {
                                                    const modifNewMP = currentMP.map((m, idx) => {
                                                        if (idx === index) {
                                                            return {
                                                                ...m,
                                                                url: val,
                                                                valid,
                                                                platform: getMarketPlaceFromUrl(val),
                                                            };
                                                        }

                                                        return m;
                                                    });

                                                    onChange({ newMarketPlaces: modifNewMP });

                                                    return modifNewMP;
                                                });
                                            }}
                                        />
                                        <IconButton
                                            onClick={() => {
                                                const newList = newMarketPlaces.filter((_, idx) => idx !== index);
                                                onChange({ newMarketPlaces: newList });
                                                setNewMarketPlaces(newList);

                                                if (newList.length === 0 && modifiedMarketPlaces.length === 0) {
                                                    handleCancel();
                                                }
                                            }}
                                        >
                                            <DeleteForeverOutlinedIcon />
                                        </IconButton>
                                    </Stack>
                                )) : null
                            }
                        </Stack>
                    </Stack>
                ) : (
                    property.marketPlaces && property.marketPlaces.length ? (
                        <Stack
                            gap={1.5}
                        >
                            {property.marketPlaces.map((mp, index) => (
                                <Stack
                                    direction="row"
                                    gap={1}
                                    alignItems="center"
                                    key={`${property.id}-mkplace-${index}`}
                                >
                                    {renderMarketPlaceTag(mp)}
                                    <Text>{mp.url}</Text>
                                </Stack>
                            ))}
                        </Stack>
                    ) : (
                        <Text color="primary.light">This property is not connected to any Market Place yet</Text>
                    )
                )
            }
            footer={
                <Footer
                    align='flex-end'
                    actions={[
                        {
                            button: true,
                            buttonVariant: 'text',
                            value: (
                                <Stack
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    sx={{ color: 'primary.light' }}
                                >
                                    <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px' }} />
                                    <Text weight='bold' color="primary.light">Add Market Place</Text>
                                </Stack>
                            ),
                            onClick: () => {
                                setEditing(true);
                                onEdit();
                                setNewMarketPlaces([
                                    ...(newMarketPlaces || []),
                                    {
                                        url: '',
                                        platform: 'unknown',
                                        createdAt: new Date(),
                                    },
                                ])
                            },
                            sx: {
                                marginRight: 'auto',
                                pointerEvents: !saving ? 'all' : 'none',
                            },
                        },
                        ...(!create && editing ? [
                            {
                                value: 'Cancel',
                                button: true,
                                buttonVariant: 'outlined',
                                onClick: handleCancel,
                            },
                            {
                                value: 'Save',
                                button: true,
                                buttonVariant: 'contained',
                                onClick: async () => {
                                    setEditing(false);
                                    setSaving(true);
                                    await onSave({ modifiedMarketPlaces, newMarketPlaces, toDelete, idsModified: Object.keys(idsModified) });
                                    setSaving(false);
                                    setNewMarketPlaces([]);
                                },
                            },
                        ] : [
                            ...(!create && property.marketPlaces && property.marketPlaces.length ? [
                                {
                                    value: 'Edit',
                                    button: true,
                                    buttonVariant: 'outlined',
                                    onClick: () => {
                                        setEditing(true);
                                        onEdit();
                                    },
                                    sx: {
                                        pointerEvents: !saving ? 'all' : 'none',
                                    },
                                },
                            ] : [])
                        ])
                    ]}
                />
            }
        />
    );
};

export default PropertyMarketPlaces;