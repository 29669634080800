import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const Container = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: '20px',
    display: 'flex',
    gap: '12px',
    flexDirection: 'column',
    borderRadius: '20px',
    border: `1px solid ${theme.palette.primary.main}`,
    position: 'relative',

    '&[data-disabled="true"]': {
        pointerEvents: 'none',
        opacity: 0.8,
    },
}));

export const FilesInput = styled('input')(({ theme }) => ({
    display: 'none',
}))

export const FilesContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',

    [theme.breakpoints.down(600)]: {
        justifyContent: 'center',
    },
}));

export const Title = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '14px',
    marginBottom: '4px',
}));

export const SubTitle = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 'lighter',
    fontSize: '12px',
}));

export const AddFiles = styled('div')(({ theme }) => ({
    width: '150px',
    height: '150px',
    backgroundColor: theme.palette.action.disabledBackground,
    borderRadius: '12px',
    border: `1px dashed ${theme.palette.primary.light}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '48px',
    color: theme.palette.primary.light,
    cursor: 'pointer',
    transition: 'all 0.3s',

    '&:hover': {
        boxShadow: theme.shadows[8],
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
    },

    [theme.breakpoints.down(600)]: {
        height: '50px',
        width: '100%',
        fontSize: '28px',
    },
}));

export const FileContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.action.disabledBackground,
    padding: '4px',
    textAlign: 'center',
    borderRadius: '12px',
    boxShadow: theme.shadows[4],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '&[data-display="true"]': {
        padding: '12px',
    },
}));

export const FileContainerImg = styled(FileContainer)(({ theme }) => ({
    width: '150px',
    height: '150px',

    '&[data-display="true"]': {
        height: 'auto',
        width: '400px',
        maxWidth: '100%',

        '& img': {
            height: 'auto',
            width: '376px',
            maxWidth: '100%',
            maxHeight: 'fit-content',
        }
    },

    [theme.breakpoints.down(600)]: {
        maxWidth: '45%',
    }
}));

export const FileContainerVideo = styled(FileContainer)(({ theme }) => ({
    maxWidth: '344px',
    height: '252px',

    '&[data-display="true"]': {
        height: 'auto',
        width: '400px',
        maxWidth: '100%',

        '& video': {
            height: 'auto',
            width: '376px',
            maxWidth: '100%',
            maxHeight: 'fit-content',
        }
    },

    [theme.breakpoints.down(600)]: {
        maxWidth: '100%',
    },
}));

export const Image = styled('img')(({ theme }) => ({
    maxWidth: '142px',
    maxHeight: '142px',
    borderRadius: '12px',

    [theme.breakpoints.down(600)]: {
        maxWidth: '100%',
    }
}));

export const Video = styled('video')(({ theme }) => ({
    maxWidth: '320px',
    height: '240px',
    borderRadius: '12px',

    [theme.breakpoints.down(600)]: {
        maxWidth: '100%',
    },
}));

export const FileUploadProgress = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1,
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    fontWeight: 'bold',
    fontSize: '18px',
}));

export const StatusContainer = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    width: '28px',
    height: '28px',
    borderRadius: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '22px',
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    zIndex: 2,
    cursor: 'pointer',
}));

export const InputStatusContainer = styled(StatusContainer)(({ theme }) => ({
    top: '8px',
    right: '8px',
}));


export const DeleteActionContainer = styled('div')(({ theme }) => ({
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
}));

export const NowButton = styled(Button)(({ theme }) => ({
    width: '80px',
}));

export const TimeSpacer = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    height: '100%',
    verticalAlign: 'middle',
    fontSize: '12px',
    lineHeight: '40px',
}));

export const TimePickerContainer = styled('div')(({ theme }) => ({
    width: '80px',
}));