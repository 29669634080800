import { v4 as uuidv4 } from 'uuid';

export const createMessage = (message) => {
    return {
        ...message,
        _id: uuidv4(),
    };
};

export const parseResponse = (txt) => {
    return JSON.parse(txt);
};

export const cleanResponse = (txt) => {
    let text = txt;
    if (!text.startsWith('{')) {
        text = `{\n${text}`;
    }
    if ((/\,(?=\s*?[\}\]])/g).test(text)) {
        console.log('contains trailing comma');
        text = text.replace(/\,(?=\s*?[\}\]])/g, '');
    }
    return text;
};

export const processResponse = (txt) => {
    const text = cleanResponse(txt);
    const parsedResponse = parseResponse(text);

    return {
        text,
        parsedResponse,
    };
};