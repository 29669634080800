import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Stack } from "@mui/material";

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import RoomServiceRoundedIcon from '@mui/icons-material/RoomServiceRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Card from "../../Components/library/Cards/Generic";
import Header from "../../Components/library/Cards/Headers/Standard";
import Footer from "../../Components/library/Cards/Footers/Action";
import Text from "../../Components/library/Typography/Text";
import ControlledInput from "../../Components/Input/ControlledInput";

import KeysTypeSelector from "./KeyTypeSelector";
import KEYS_OPTIONS from "./KeysOptions";

const isContentValid = (tempKeys) => {
    return (
        tempKeys.type &&
        (!tempKeys.data || Object.keys(tempKeys.data).reduce((final, curr) => (final && tempKeys.data[curr].valid), true))
    );
};

const NewKeys = ({
    id = '',
    onSave = () => { },
    onCancel = () => { },
}) => {
    const [tempKeys, setTempKeys] = useState({
        id,
    });

    return (
        <Card
            sx={{
                backgroundColor: 'secondary.light',
            }}
            header={
                <Header
                    title="New Keys"
                    emoji="🆕"
                />
            }
            withBodyContainer={false}
            body={
                <Stack
                    key={tempKeys.id}
                    sx={{
                        p: '12px 12px',
                    }}
                >
                    <Text
                        size="medium"
                        weight="bold"
                        sx={{ mb: '12px' }}
                    >📍 Keys Location</Text>
                    <Stack
                        direction="row"
                        flexWrap="wrap"
                        gap={1}
                        justifyContent="center"
                    >
                        {
                            KEYS_OPTIONS.map((keysOptions) => (
                                <KeysTypeSelector
                                    key={keysOptions.type}
                                    keysOptions={keysOptions}
                                    selected={keysOptions.type === tempKeys.type}
                                    onSelect={() => {
                                        setTempKeys({
                                            ...tempKeys,
                                            type: keysOptions.type,
                                            data: null,
                                        })
                                    }}
                                />
                            ))
                        }
                    </Stack>
                    {
                        tempKeys.type && KEYS_OPTIONS.find(ko => ko.type === tempKeys.type).options ? (() => {
                            const OptionsComp = KEYS_OPTIONS.find(ko => ko.type === tempKeys.type).options;
                            return (
                                <OptionsComp
                                    onDataChange={(data) => (
                                        setTempKeys({
                                            ...tempKeys,
                                            data,
                                        })
                                    )}
                                />
                            );
                        })() : null
                    }
                    <Text
                        size="medium"
                        weight="bold"
                        sx={{ m: '20px 0px' }}
                    >✍️ Additional Information</Text>
                    <ControlledInput
                        label="Notes (Public to Clem)"
                        multiline
                        rows={2}
                        placeholder="Additional keys information (contact phone number, keynest links)"
                        defaultValue={tempKeys.notes || ''}
                        value={tempKeys.notes || ''}
                        onChange={(value, valid) => {
                            setTempKeys({
                                ...tempKeys,
                                notes: value,
                            })
                        }}
                        size="small"
                    />
                </Stack>
            }
            footer={
                <Footer
                    align='flex-end'
                    actions={[
                        {
                            button: true,
                            buttonVariant: 'outlined',
                            value: 'Cancel',
                            onClick: () => onCancel(tempKeys),
                        },
                        {
                            button: true,
                            buttonVariant: 'contained',
                            value: 'Save',
                            onClick: () => onSave({
                                ...tempKeys,
                                data: (
                                    tempKeys.data ?
                                        Object.keys(tempKeys.data).reduce((tot, k) => ({
                                            ...tot,
                                            [k]: tempKeys.data[k].value,
                                        }), {})
                                        : null
                                ),
                            }),
                            sx: {
                                ...(isContentValid(tempKeys) ? {
                                    opacity: 1,
                                    pointerEvents: 'all',
                                } : {
                                    opacity: 0.3,
                                    pointerEvents: 'none',
                                    cursor: 'not-allowed',
                                }),
                            },
                        },
                    ]}
                />
            }
        />
    );
};

export default NewKeys;