import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import HeaderStatusSelector from '../../../Components/library/Cards/Headers/StatusSelector';
import Tag from '../../../Components/library/Tag/Tag';
import RightPanel from '../../../Components/library/Layout/RightPanel';

import APPS_CONFIG from '../../config';

const ViewingsListSkeleton = ({
    propertyAddress = '',
}) => {
    const display = 'UPCOMING';
    const isMobile = useMediaQuery('(max-width: 1000px)');

    return (
        <RightPanel
            title={<><img src={APPS_CONFIG.viewings.logo} width="20px" /> Viewings</>}
            header={
                <>
                    <HeaderStatusSelector
                        currentStatus={display}
                        statuses={
                            [{
                                status: 'PAST',
                                title: '✅ Past',
                            },
                            {
                                status: 'UPCOMING',
                                title: '👀 Upcoming',
                            }]
                        }
                        statusCount={{
                            PAST: <Skeleton variant="rounded" width={isMobile ? '20px' : '30px'} height={isMobile ? '22px' : '39px'} sx={{ borderRadius: '4px' }} />,
                            UPCOMING: <Skeleton variant="rounded" width={isMobile ? '20px' : '30px'} height={isMobile ? '22px' : '39px'} sx={{ borderRadius: '4px' }} />,
                        }}
                    />
                    <Stack sx={{ width: '100%', height: 'fit-content', p: 2.5, pl: 2.5, pt: 0 }}>
                        <Skeleton variant="rounded" width="150px" height="30px" sx={{ borderRadius: '4px' }} />
                    </Stack>
                </>
            }
            tags={[
                <Tag key="viewing-tag-addr">
                    {propertyAddress}
                </Tag>,
            ]}
            body={
                <Stack
                    sx={{ width: '100%', padding: 4 }}
                    alignItems="center"
                    gap={1}
                >
                    <Skeleton variant="rounded" width="100px" height="20px" sx={{ borderRadius: '4px' }} />
                    <Skeleton variant="rounded" width="200px" height="20px" sx={{ borderRadius: '4px' }} />
                    <Skeleton variant="rounded" width="100px" height="20px" sx={{ borderRadius: '4px' }} />
                </Stack>
            }
        />
    );
};

export default ViewingsListSkeleton;