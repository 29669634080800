import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';

export const FAB = styled(Fab)(({ theme, selected }) => ({
    position: 'absolute',
    bottom: '60px',
    right: '60px',
    width: '70px',
    height: '70px',
    backgroundColor: selected ? theme.palette.primary.main : theme.palette.secondary.main,
    color: selected ? theme.palette.primary.contrastText : theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
    fontSize: '32px',
    boxShadow: selected ? theme.shadows[24] : theme.shadows[0],
    transform: selected ? 'rotate(45deg)' : 'rotate(0deg)',
    transition: 'transform 0.4s, box-shadow 0.4s, background-color 0.4s',
    zIndex: selected ? 2000 : 0,
    
    '&:hover': {
        backgroundColor: selected ? theme.palette.primary.main : theme.palette.primary.light,
        color: selected ? theme.palette.primary.contrastText : theme.palette.secondary.main,
        boxShadow: selected ? theme.shadows[24] : theme.shadows[10],
    },

    [theme.breakpoints.down(800)]: {
        bottom: '98px',
    },

    [theme.breakpoints.down(600)]: {
        display: 'none',
    },
}));

export const FABActionContainer = styled('div')(({ theme, selected }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    position: 'absolute',
    paddingTop: '0px',
    bottom: '130px',
    right: '95px',
    width: '0px',
    height: '0px',
    overflow: 'hidden',
    transition: 'all 0.4s',

    '&[data-visible=true]': {
        // backgroundColor: 'purple',
        paddingTop: '20px',
        height: '155px',
        width: '140px',
        right: '26px',
    },
}));

export const FABAction = styled(Fab)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    transition: 'all 0.2s',

    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },

    '&[data-visible=true]': {
        // backgroundColor: 'red',
    },
}));

export const ModalContent = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: '120px',
    // bottom: '60px',
    right: '150px',
    width: 1000,
    height: 'calc(100% - 150px)',
    maxHeight: 860,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[24],
    color: theme.palette.primary.contrastText,
    padding: 40,
    paddingBottom: 0,
    borderRadius: 20,

    [theme.breakpoints.between(1100, 1405)]: {
        bottom: '60px',
        width: 900,
        height: 'calc(100% - 120px)',
    },

    [theme.breakpoints.down(1100)]: {
        bottom: '30px',
        right: '28px',
        width: 'calc(100% - 60px)',
        height: 'calc(100% - 60px)',
    },
}));