import Text from "../Typography/Text";

import Chip from '@mui/material/Chip';

const Tag = ({ children, color = 'primary.main', backgroundColor = 'secondary.light', sx = {}, ...otherProps }) => {
    return (
        <Chip
            label={<Text color={color} sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }}>{children}</Text>}
            sx={{
                width: 'fit-content',
                backgroundColor: backgroundColor,
                height: '24px',
                borderRadius: 3,
                maxWidth: '100%',
                ...sx,
            }}
            {...otherProps}
        />
    )
};

export default Tag;