import { lightFormat } from 'date-fns';
import { DataStore } from '@aws-amplify/datastore';
import { Lead } from '../models';

export const createLead = async (data) => {
    const property = await DataStore.save(
        new Lead({
            email: data.email,
            phone: data.phone,
            name: data.name,
            propertyId: data.propertyId,
            status: data.status,
            notes: data.notes ? data.notes : null,
            enquiredFrom: null,
            createdDate: lightFormat(new Date(), 'yyyy-MM-dd'),
            owner: data.owner,
        })
    );

    return property;
};

export const updateLead = async (leadId, data) => {
    const original = await DataStore.query(Lead, leadId);
    const lead = await DataStore.save(
        Lead.copyOf(original, updated => {
            updated.email = data.email ? data.email : original.email;
            updated.phone = data.phone ? data.phone : original.phone;
            updated.name = data.name ? data.name : original.name;
            updated.status = data.status ? data.status : original.status;
            updated.notes = data.notes ? data.notes : original.notes;
        })
    );

    return lead;
};

export const getLead = async (leadId) => {
    return await DataStore.query(Lead, leadId);
};

export const getLeadsByProperty = async (propertyId) => {
    return await DataStore.query(Lead, l => l.propertyId('eq', propertyId));
};

export const getAllLeads = async () => {
    return await DataStore.query(Lead, p => p.createdAt('gt', '2022-07-20T14:00:00.000Z'));
};

export const leadsSubscription = (cb, predicate) => {
    const q = DataStore.observeQuery(Lead, predicate);
    const subscription = q.subscribe(async ({ items, isSynced }) => {
        cb(items, isSynced);
    });

    return subscription;
};

export default { // eslint-disable-line
    create: createLead,
    get: getLead,
    update: updateLead,
    getByProperty: getLeadsByProperty,
    getAll: getAllLeads,
    leadsSubscription,
    subscribeToProperty: (cb, propId) => leadsSubscription(cb, (v) => v.propertyId('eq', propId)),
};