import { TypeAnimation } from 'react-type-animation';
import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";

import Text from "../../Components/library/Typography/Text";
import { Button, Divider } from "@mui/material";
import Card from '../../Components/library/Cards/Generic';
import Header from '../../Components/library/Cards/Headers/Standard';
import Tag from '../../Components/library/Tag/Tag';

const isUser = (role) => (
    role === 'user'
);

const DebugTag = ({ children, sx = {}, ...otherProps }) => (
    <Tag
        {...otherProps}
        sx={{
            backgroundColor: 'primary.light',
            ...sx,
        }}
        color="primary.contrastText"
    >{children}</Tag>
);

const withDebug = (Comp) => (props) => {
    if (props.message.role === 'system') {
        return <Comp {...props} />;
    }
    return (
        <Comp
            {...props}
            debug={
                !isUser(props.message.role) ? (
                    <Card
                        sx={{
                            marginTop: '20px',
                            backgroundColor: 'secondary.light',
                        }}
                        header={
                            <Header
                                title={
                                    <Text size="large" weight="bold">✨ AI Generated</Text>
                                    // <DebugTag>{isUser(props.message.role) ? 'Human Input' : '🦾 AI Generated'}</DebugTag>
                                }
                                tags={[
                                    <Tag sx={{ backgroundColor: 'background.default' }}>👀 Only visible to you</Tag>
                                ]}
                            />
                        }
                        body={
                            <Stack>
                                {
                                    !isUser(props.message.role) ? (
                                        <Stack
                                            gap={1.5}
                                        >
                                            {/* <Text size="medium" weight="bold">Reasoning <Tag sx={{ backgroundColor: 'background.default'}}>🧠 Internal AI Reasoning</Tag></Text> */}
                                            <Text size="medium" weight="bold">🧠 AI Reasoning</Text>
                                            <Text sx={{
                                                paddingLeft: '20px',
                                            }}>
                                                {props.message.parsed.thoughts.reasoning}
                                            </Text>
                                            <Text size="medium" weight="bold">🛠 Command Type: <DebugTag>{props.message.parsed.command.name}</DebugTag></Text>
                                            <Text size="medium" weight="bold">🧪 Command Parameters:</Text>
                                            <Stack>
                                                {
                                                    Object.keys(props.message.parsed.command.args).map((key) => (
                                                        <Stack>
                                                            <Divider flexItem />
                                                            <Stack
                                                                gap={1}
                                                                direction="row"
                                                            >
                                                                <Divider orientation="vertical" flexItem />
                                                                <Text
                                                                    sx={{
                                                                        width: '150px',
                                                                        minWidth: '150px',
                                                                        margin: '8px',
                                                                    }}
                                                                    weight="bold"
                                                                >
                                                                    {key}
                                                                </Text>
                                                                {/* <DebugTag
                                                                    sx={{
                                                                        width: '150px',
                                                                        minWidth: '150px',
                                                                        margin: '8px',
                                                                    }}
                                                                >{key}</DebugTag> */}
                                                                <Divider orientation="vertical" flexItem />
                                                                <Text
                                                                    sx={{
                                                                        whiteSpace: 'pre-wrap',
                                                                        margin: '12px 8px',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    {JSON.stringify(props.message.parsed.command.args[key], null, 4)}
                                                                </Text>
                                                                <Divider orientation="vertical" flexItem />
                                                            </Stack>
                                                            <Divider flexItem />
                                                        </Stack>

                                                    ))
                                                }
                                            </Stack>
                                        </Stack>
                                    ) : (
                                        <Stack>
                                            {
                                                JSON.parse(props.message.content).answerTo ? (
                                                    <Text>{`Direct answer to: ${JSON.parse(props.message.content).answerTo}`}</Text>
                                                ) : null
                                            }
                                        </Stack>
                                    )
                                }
                            </Stack>
                        }
                    />
                ) : null
            }
        />
    );
};

export const Message = ({
    message,
    onSendMessage = () => { },
    isActive = false,
    debug,
    scrollIntoView = () => { },
}) => {
    const [displayChoices, setDisplayChoices] = useState(false);

    useEffect(() => {
        scrollIntoView();
    }, [displayChoices]);

    return (
        <Stack
            gap={0.5}
            sx={{
                // p: 1.5,
                // backgroundColor: !isUser(message.role) ? 'white' : 'secondary.light',
                // justifySelf: !isUser(message.role) ? 'flex-start' : 'flex-end',
                flexShrink: 0,
                borderRadius: 4,
                width: 'fit-content',
                maxWidth: '70%',
                // marginLeft: !isUser(message.role) ? '0px' : '25%',
                // marginRight: !isUser(message.role) ? '25%' : '0px',
                // borderWidth: '1px',
                // borderStyle: 'solid',
                // borderColor: 'secondary.main',
                position: 'relative',
                // minHeight: '60px',
                height: 'fit-content',
            }}
            flexDirection="row"
        >
            {
                !isUser(message.role) ? (
                    <Stack
                        sx={{
                            marginTop: '12px',
                            marginRight: '12px',
                        }}
                    >
                        <img src="/app/logo-purple.svg" width="30px" />
                    </Stack>
                ) : null
                // (
                //     <Text color="primary.light">{debug !== undefined ? 'User' : 'You'}</Text>
                // )
            }
            <Stack
                sx={{
                    p: 2,
                    backgroundColor: !isUser(message.role) ? 'white' : 'secondary.light',
                    // justifySelf: !isUser(message.role) ? 'flex-start' : 'flex-end',
                    // flexShrink: 0,
                    borderRadius: 4,
                    width: 'fit-content',
                    maxWidth: '100%',
                    // marginLeft: !isUser(message.role) ? '0px' : '25%',
                    marginRight: isUser(message.role) ? '8px' : '0px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'secondary.main',
                    position: 'relative',
                    // minHeight: '60px',
                    height: 'fit-content',
                }}
            >
                <Stack
                    // sx={{
                    //     paddingLeft: !isUser(message.role) ? '40px' : 0,
                    //     paddingTop: !isUser(message.role) ? '8px' : 0,
                    // }}
                >
                    {
                        !isUser(message.role) ? (
                            <TypeAnimation
                                sequence={[
                                    message.content,
                                    () => {
                                        setDisplayChoices(true);
                                    },
                                ]}
                                speed={80}
                                wrapper="div"
                                cursor={false}
                                repeat={0}
                                style={{ fontSize: '12px', display: 'inline-block', whiteSpace: 'pre-wrap' }}
                            />
                        ) : (
                            <Text size="medium" multiline>{message.raw || JSON.parse(message.content).message}</Text>
                        )
                    }
                    {
                        displayChoices && message.choices ? (
                            <Stack
                                direction="column"
                                gap={1}
                                sx={{
                                    pt: 2,
                                }}
                            >
                                {
                                    message.choices.map((choice, idx) => (
                                        <Button
                                            key={`m-choice-${idx}`}
                                            variant="contained"
                                            sx={{
                                                width: 'fit-content',
                                            }}
                                            onClick={() => onSendMessage(choice, message.id)}
                                            size="small"
                                            disabled={!isActive}
                                        >
                                            {choice}
                                        </Button>
                                    ))
                                }
                            </Stack>
                        ) : null
                    }
                </Stack>
                {
                    debug ? debug : null
                }
            </Stack>
        </Stack>
    );
};

export const MessageWithDebug = withDebug(Message);