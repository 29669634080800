import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';

import Card from "../../../Components/library/Cards/Generic";
import Header from '../../../Components/library/Cards/Headers/Standard';
import Footer from '../../../Components/library/Cards/Footers/Action';

import Text from "../../../Components/library/Typography/Text";
import ControlledInput from "../../../Components/Input/ControlledInput";

import POSTCODES from "./postcodes_clean.json";

const COMPACT_POSTCODES = Object.keys(POSTCODES).reduce((tot, key) => ([
    ...tot,
    ...POSTCODES[key],
]), []);

const POSTCODE_REGEXP = new RegExp(`^(?:${COMPACT_POSTCODES.map(p => p.toLowerCase()).join('|')})(?:[a-z]|[0-9]| )+$`);

const PropertyInfo = ({
    property,
    color = 'secondary.light',
    onSave = () => { },
    onEdit = () => { },
    onCancel = () => { },
    onChange = () => { },
    create = false,
}) => {
    const [editing, setEditing] = useState(create);
    const [propertyData, setPropertyData] = useState({
        postcode: create ? { value: '', valid: false } : { value: property.postcode, valid: true },
        address: create ? { value: '', valid: false } : { value: property.address, valid: true },
        occupied: create ? { value: false, valid: true } : { value: property.occupied, valid: true },
        price: create ? { value: 0, valid: false } : { value: property.price, valid: true },
        info: create ? { value: '', valid: true } : { value: property.info, valid: true },
    });

    useEffect(() => {
        onChange(propertyData);
    }, []);

    useEffect(() => {
        setEditing(create);
        const propData = {
            postcode: create ? { value: '', valid: false } : { value: property.postcode, valid: true },
            address: create ? { value: '', valid: false } : { value: property.address, valid: true },
            occupied: create ? { value: false, valid: true } : { value: property.occupied, valid: true },
            price: create ? { value: 0, valid: false } : { value: property.price, valid: true },
            info: create ? { value: '', valid: true } : { value: property.info, valid: true },
        };
        onChange(propData);
        setPropertyData(propData);
    }, [property]);

    return (
        <Card
            header={
                <Header
                    emoji="ℹ️"
                    // title="Info"
                    title={
                        <Text
                            size="large"
                            weight="bold"
                            color={`${color.split('.')[0]}.contrastText`}
                            sx={{ flexShrink: 0 }}
                        >
                            Info
                        </Text>
                    }
                    sx={{ backgroundColor: color }}
                />
            }
            body={
                editing ? (
                    <Stack
                        gap={2}
                    >
                        <ControlledInput
                            label="Postcode"
                            required
                            autoComplete="off"
                            defaultValue={propertyData.postcode.value || ''}
                            value={propertyData.postcode.value || ''}
                            // validator={/^(?!\s*$).+/}
                            validator={{
                                test: (postcode) => {
                                    return POSTCODE_REGEXP.test(postcode.toLowerCase());
                                }
                            }}
                            onChange={(value, valid) => {
                                const newData = { ...propertyData, postcode: { value, valid } };
                                onChange(newData);
                                setPropertyData(newData);
                            }}
                            errorHelperText="Postcode outside of CLEM working area"
                            size="small"
                        />
                        <ControlledInput
                            label="Address"
                            required
                            autoComplete="off"
                            defaultValue={propertyData.address.value || ''}
                            value={propertyData.address.value || ''}
                            validator={/^(?!\s*$).+/}
                            onChange={(value, valid) => {
                                const newData = { ...propertyData, address: { value, valid } };
                                onChange(newData);
                                setPropertyData(newData);
                            }}
                            size="small"
                        />
                        <ControlledInput
                            disabled
                            required
                            name="book-viewing-property-city"
                            label="City"
                            // helperText="Property city"
                            placeholder="London"
                            errorHelperText="Invalid city"
                            defaultValue={'London'}
                            size="small"
                        // onChange={(value, valid) => handleFormData('city', value, valid)}
                        />
                        <ControlledInput
                            label="Price (£)"
                            placeholder="per month - pcm"
                            required
                            autoComplete="off"
                            defaultValue={`${propertyData.price.value}` || ''}
                            value={`${propertyData.price.value}` || ''}
                            validator={/^[0-9]{3,}$/}
                            mask={'00000'}
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                            }}
                            onChange={(value, valid) => {
                                console.log(value, valid)
                                const newData = { ...propertyData, price: { value: parseInt(value), valid } };
                                onChange(newData);
                                setPropertyData(newData);
                            }}
                            size="small"
                        />
                        <FormControl>
                            <FormLabel id="tenanted-btn" required>Tenanted</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="tenanted-btn"
                                name="Tenanted"
                                value={!!propertyData.occupied.value ? 'YES' : 'NO'}
                                onChange={(e) => {
                                    const newData = { ...propertyData, occupied: { value: !!(e.target.value === 'YES'), valid: true } };
                                    onChange(newData);
                                    setPropertyData(newData);
                                }}
                            >
                                <FormControlLabel value="YES" control={<Radio />} label={<Text size="medium">YES</Text>} />
                                <FormControlLabel value="NO" control={<Radio />} label={<Text size="medium">NO</Text>} />
                            </RadioGroup>
                        </FormControl>
                        <ControlledInput
                            label="Notes (Public to Clem)"
                            multiline
                            rows={4}
                            placeholder="Additional property information (keys, access, special features, etc...)"
                            defaultValue={propertyData.info.value || ''}
                            value={propertyData.info.value || ''}
                            onChange={(value, valid) => {
                                const newData = { ...propertyData, info: { value, valid } };
                                onChange(newData);
                                setPropertyData(newData);
                            }}
                            size="small"
                        />
                    </Stack>
                ) : (
                    <Stack
                        gap={1}
                    >
                        <Text size="medium" color="primary.light" weight="bold">Postcode: <Text size="normal" weight="bold" span>{property.postcode}</Text></Text>
                        <Text size="medium" color="primary.light" weight="bold">Address: <Text size="normal" weight="bold" span>{property.address}</Text></Text>
                        <Text size="medium" color="primary.light" weight="bold">Tenanted: <Text size="normal" weight="bold" span>{!!property.occupied ? 'YES' : 'NO'}</Text></Text>
                        <Text size="medium" color="primary.light" weight="bold">Price: <Text size="normal" weight="bold" span>{property.price ? property.price : <Text weight="bold" span><Text size="large" span>⚠️</Text> Missing price information</Text>}</Text></Text>
                        {
                            property.info && property.info.length > 0 ? (
                                <Text size="medium" color="primary.light" weight="bold">Notes (Public to Clem): <Text size="normal" weight="bold" multiline span>{property.info}</Text></Text>
                            ) : null
                        }
                    </Stack>
                )
            }
            footer={
                !create ? (
                    <Footer
                        align='flex-end'
                        actions={
                            editing ? [
                                {
                                    value: 'Cancel',
                                    button: true,
                                    buttonVariant: 'outlined',
                                    onClick: () => {
                                        setEditing(false);
                                        onCancel();
                                    },
                                },
                                {
                                    value: 'Save',
                                    button: true,
                                    buttonVariant: 'contained',
                                    onClick: async () => {
                                        setEditing(false);
                                        await onSave(propertyData);
                                    },
                                },
                            ] : [
                                {
                                    value: 'Edit',
                                    button: true,
                                    buttonVariant: 'outlined',
                                    onClick: () => {
                                        setEditing(true);
                                        onEdit();
                                    },
                                },
                            ]
                        }
                    />
                ) : null
            }
        />
    );
};

export default PropertyInfo;