import React, { useState, useEffect } from 'react';
import { Auth, DataStore, Hub } from 'aws-amplify';
import { User } from '../../models';
import Login from './Login';
import UserData from './UserData';
import ResetPassword from './ResetPassword';
import { Container, Logo } from './Login.styled';

const fetchUserData = async (id) => {
    try {
        const userData = await DataStore.query(User, id);
        return userData;
    } catch (e) {
        console.log(e);
    }
};

const Authenticator = ({ children }) => {
    const [initialFetch, setInitialFetch] = useState(true);
    const [tempUser, setTempUser] = useState();
    const [user, setUser] = useState();
    const [userData, setUserData] = useState();
    const [requestUserData, setRequestUserData] = useState(false);


    useEffect(() => {
        const initial = async () => {
            Hub.listen('datastore', async (capsule) => {
                const {
                    payload: { event },
                } = capsule;

                if (event === 'ready') {
                    const _user = await Auth.currentAuthenticatedUser();
                    let userData = await fetchUserData(_user.username);
                    if (!userData) {
                        setRequestUserData(true);
                    } else {
                        handleUserData(_user, userData);
                    }
                }
            });

            try {
                const _user = await Auth.currentAuthenticatedUser();
                const userData = await fetchUserData(_user.username);
                handleUserData(_user, userData);
                setInitialFetch(false);
            } catch (e) {
                setInitialFetch(false);
            }
        };

        initial();
    }, []);

    const handleReset = (_user) => {
        if (typeof _user === 'string') {
            setTempUser(null);
        } else {
            setTempUser(null);
            setUser(_user);
        }
    };

    const handleLogin = async (_user) => {
        if (typeof _user === 'string') {
            setTempUser(_user);
        } else {
            if (_user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setTempUser(_user);
            } else {
                const userData = await fetchUserData(_user.username);
                handleUserData(_user, userData);
            }
        }
    };

    const handleUserData = async (_user, userData) => {
        setRequestUserData(false);
        setUserData(userData);
        setUser(_user);
    };

    const signOut = async () => {
        try {
            await Auth.signOut();
            setUser(null);
            setUserData(null);
            setRequestUserData(false);
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    if (initialFetch) {
        return null;
    }

    if (user && userData) {
        return children({ signOut, user, userData })
    }

    const renderLogin = () => {
        if (!user && !tempUser) {
            return <Login onUser={handleLogin} />;
        } else if (tempUser) {
            return <ResetPassword user={tempUser} onUser={handleReset} />;
        }
    };

    return (
        <Container>
            <Logo src='/app/logo-white.svg' />
            {requestUserData ? (
                <UserData onUser={async (_user) => {
                    await DataStore.clear();
                    await DataStore.stop();
                    await DataStore.start();
                    const userData = await fetchUserData(_user.username);
                    handleUserData(_user, userData)
                }} />
            ) : (
                renderLogin()
                // !user && !tempUser ? (
                //     <Login onUser={handleLogin} />
                // ) : (
                //     <ResetPassword user={tempUser} onUser={handleReset} />
                // )
            )}
        </Container>
    );
};

export default Authenticator;