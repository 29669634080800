import ControlledInput from "./ControlledInput";
import { Container, StatusContainer } from './ControlledInputWithStatus.styled';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';

const STATUSES = {
    'LOADING': BackupOutlinedIcon,
    'SAVED': CloudDoneOutlinedIcon,
    'TOSYNC': SyncOutlinedIcon,
};

const ControlledInputWithStatus = ({ currentStatus, ...otherProps }) => {
    const StatusIcon = STATUSES[currentStatus];

    return (
        <Container>
            <ControlledInput fullWidth {...otherProps} />
            {
                STATUSES[currentStatus] ? (
                    <StatusContainer>
                        <StatusIcon fontSize='inherit' />
                    </StatusContainer>
                ) : null
            }
        </Container>
    );
};

export default ControlledInputWithStatus;