import { useState, useEffect } from "react";
import { API } from 'aws-amplify';
import { addHours, format, subDays, subMinutes } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

import * as queries from '../../../graphql/queries';

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import Card from "../../../Components/library/Cards/Generic";
import Header from "../../../Components/library/Cards/Headers/Standard";
import DayPicker from "../../../Components/library/DatePicker/DayPicker";
import CalendarDay from "../../../Components/library/Calendar/Day";
import SessionAdmin from "./Session";

import {
    BackButton,
    CardTitleText,
} from '../../Leads/leads.styled';
import Text from "../../../Components/library/Typography/Text";
import FutureViewing from "../../Viewings/Viewing/Future_v2";
import Tag from "../../../Components/library/Tag/Tag";

const Sessions = ({
    onBack = () => { },
}) => {
    const [clems, setClems] = useState(null);
    const [sessions, setSessions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [bySlot, setBySlot] = useState({});
    const [date, setDate] = useState(new Date());

    console.log(date);

    const fetchSessions = async (__date) => {
        const _date = addHours(__date ? __date : date, 2).toISOString();
        console.log(__date, _date);
        setLoading(true);
        const resSessions = await API.graphql({
            query: queries.sessionManager,
            variables: {
                op: 'get',
                payload: JSON.stringify({
                    date: _date,
                    ghost: true,
                }),
            },
        });

        let _sessions = JSON.parse(resSessions.data.sessionManager);

        const resClems = await API.graphql({
            query: queries.getClems,
            variables: {
                date: date,
            },
        });

        const _clems = JSON.parse(resClems.data.getClems);
        _sessions = _sessions.map(s => ({ ...s, clem: _clems.find(c => c.id === s.clem) }));
        setClems(_clems);
        _sessions = _sessions.map(s => ({ ...s, viewings: s.viewings.sort((a, b) => new Date(a.startsAt) - new Date(b.startsAt)) }));
        setSessions(_sessions.sort((a, b) => new Date(a.startsAt) - new Date(b.startsAt)));
        const _bySlot = [
            ..._sessions.reduce((tot, s) => ([...tot, ...s.viewings.map((v) => ({
                viewing: v,
                session: s,
                type: 'viewing',
                time: formatInTimeZone(new Date(v.startsAt), 'Etc/UTC', 'kk:mm'),
            }))]), []),
            ..._sessions.map((s) => ({
                session: s,
                type: 'session',
                time: formatInTimeZone(subMinutes(new Date(s.viewings[0].startsAt), 15), 'Etc/UTC', 'kk:mm'),
            })),
        ].reduce((tot, data) => ({
            ...tot,
            [data.time]: [
                ...(tot[data.time] || []),
                data,
            ],
        }), {});
        console.log(_bySlot)
        setBySlot(_bySlot);
        setLoading(false);
    };

    const createSessions = async (_date) => {
        setLoading(true);
        const res = await API.graphql({
            query: queries.sessionManager,
            variables: {
                op: 'create',
                payload: JSON.stringify({
                    date: (new Date(_date)).toISOString(),
                }),
            },
        });
        console.log(res);
        await fetchSessions(_date);

        setLoading(false);
    };

    const deleteSessions = async (_date) => {
        setLoading(true);
        await API.graphql({
            query: queries.sessionManager,
            variables: {
                op: 'delete',
                payload: JSON.stringify({
                    date: (new Date(_date)).toISOString(),
                }),
            },
        });

        await fetchSessions(_date);

        setLoading(false);
    };

    const reassignSessionToClem = async (clemId, sessionId) => {
        setLoading(true);
        await API.graphql({
            query: queries.sessionManager,
            variables: {
                op: 'update',
                payload: JSON.stringify({
                    clemId: clemId,
                    sessionId: sessionId,
                }),
            },
        });

        await fetchSessions(date);

        setLoading(false);
    };

    const updateSessionInfo = async (data, sessionId, session) => {
        setLoading(true);

        const _data = {};
        if (data.keys !== session.keys) {
            _data.keys = data.keys
        }
        if (data.info !== session.info) {
            _data.info = data.info
        }

        await API.graphql({
            query: queries.sessionManager,
            variables: {
                op: 'update',
                payload: JSON.stringify({
                    data: _data,
                    sessionId: sessionId,
                }),
            },
        });

        await fetchSessions(date);

        setLoading(false);
    };

    const notifyClems = async (sessionIds) => {
        setLoading(true);
        await API.graphql({
            query: queries.sessionManager,
            variables: {
                op: 'notify',
                payload: JSON.stringify({
                    sessionIds: sessionIds,
                }),
            },
        });

        await fetchSessions(date);

        setLoading(false);
    };

    useEffect(() => {
        fetchSessions();
    }, []); // eslint-disable-line

    const handleChangeDate = async (_date) => {
        const newDate = addHours(_date, 2)
        setDate(newDate);
        await fetchSessions(newDate);
    }

    return (
        <Card
            sx={{
                backgroundColor: 'background.default',
                height: '100%',
            }}
            header={
                <Stack>
                    <Header
                        title={
                            <Stack
                                direction="row"
                                alignItems="center"
                            >
                                <BackButton
                                    sx={{ padding: 0 }}
                                    onClick={onBack}
                                >
                                    <ArrowBackOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                                    Back
                                </BackButton>
                                <CardTitleText size="large" weight="bold" sx={{
                                    flexShrink: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    textAlign: 'center',
                                    pointerEvents: 'none',
                                    gap: 1,
                                }}>👀 Sessions</CardTitleText>
                            </Stack>
                        }
                    />
                    <Stack
                        sx={{
                            p: 2.5,
                            pt: 1,
                        }}
                        gap={2.5}
                    >
                        <DayPicker
                            startDate={subDays(new Date(), 5)}
                            selectedDate={date}
                            onSelectDate={handleChangeDate}
                            maxDays={10}
                        />
                        {
                            loading ? (
                                <CircularProgress />
                            ) : null
                        }
                        {
                            !loading && sessions && sessions.length && !sessions[0].id ? (
                                <Stack
                                    direction="row"
                                    gap={1.5}
                                >
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => createSessions(date)}
                                    >
                                        {`🚀 Create Sessions for ${format(date, 'dd/MM')}`}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={() => deleteSessions(date)}
                                        sx={{ display: 'none' }}
                                    >
                                        {`Delete Sessions for ${format(date, 'dd/MM')}`}
                                    </Button>
                                </Stack>
                            ) : null
                        }
                    </Stack>
                </Stack>
            }
            withBodyContainer={false}
            body={
                <Stack
                    sx={{
                        maxHeight: '100%',
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    {
                        !loading && (!sessions || !sessions.length) ? (
                            <Stack
                                alignItems="center"
                                sx={{ pt: 4 }}
                            >
                                <Text weight="bold" size="medium">No sessions today - {format(date, 'dd/MM')}</Text>
                            </Stack>
                        ) : null
                    }
                    {
                        !loading && sessions && sessions.length ? (
                            <Stack
                                sx={{ m: 2.5 }}
                                gap={2}
                            >
                                {
                                    sessions.map((session, index) => (
                                        <SessionAdmin
                                            key={`i-${index}`}
                                            session={session}
                                            clems={clems}
                                            reassignSessionToClem={reassignSessionToClem}
                                            updateSessionInfo={updateSessionInfo}
                                        />
                                    ))
                                }
                            </Stack>
                        ) : null
                    }
                    {/*
                        !loading && sessions && sessions.length ? (
                            <CalendarDay
                                sx={{ opacity: !sessions[0].id ? 0.7 : 1 }}
                                day={date}
                                startsAt={formatInTimeZone(new Date(sessions[0].viewings[0].startsAt), 'Etc/UTC', 'kk:mm')}
                                endsAt={formatInTimeZone(new Date(sessions[sessions.length - 1].viewings[sessions[sessions.length - 1].viewings.length - 1].startsAt), 'Etc/UTC', 'kk:mm')}
                                data={[]}
                                bindData={(slot) => {
                                    if (bySlot[slot.dateTime] && bySlot[slot.dateTime]) {
                                        return {
                                            component: (
                                                <Stack
                                                    gap={1}
                                                >
                                                    {
                                                        bySlot[slot.dateTime].map((data, idx) => {
                                                            if (data.type === 'session') {
                                                                return (
                                                                    <SessionAdmin
                                                                        key={`i-${idx}`}
                                                                        session={data.session}
                                                                        clems={clems}
                                                                        reassignSessionToClem={reassignSessionToClem}
                                                                        updateSessionInfo={updateSessionInfo}
                                                                    />
                                                                )
                                                            } else if (data.type === 'viewing') {
                                                                return (
                                                                    <Stack
                                                                        key={`i-${idx}`}
                                                                        sx={{ position: 'relative' }}
                                                                    >
                                                                        <FutureViewing
                                                                            key={`i-${idx}`}
                                                                            noActions
                                                                            withProperty
                                                                            viewing={{
                                                                                ...data.viewing,
                                                                                Lead: {
                                                                                    ...data.viewing.viewer,
                                                                                    id: data.session.id ? data.session.id : '---',
                                                                                },
                                                                                Property: {
                                                                                    ...data.session.property,
                                                                                    id: data.session.id ? data.session.id : '---',
                                                                                },
                                                                            }}
                                                                        />
                                                                        {
                                                                            data.viewing.cancelled ? (
                                                                                <Stack
                                                                                    justifyContent="center"
                                                                                    alignItems="center"
                                                                                    sx={{
                                                                                        width: '100%',
                                                                                        height: '100%',
                                                                                        backgroundColor: 'rgba(255,255,255, 0.5)',
                                                                                        position: 'absolute'
                                                                                    }}
                                                                                >
                                                                                    <Tag sx={{ border: '1px solid gray', borderColor: 'primary.light', }}>🙅 CANCELLED</Tag>
                                                                                </Stack>
                                                                            ) : null
                                                                        }
                                                                    </Stack>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </Stack>
                                            ),
                                        };
                                    }

                                    return null;
                                }}
                            />
                        ) : null
                            */}
                </Stack>
            }
        />
    );
};

export default Sessions;