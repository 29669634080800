import { styled } from '@mui/material/styles';
import { Container as Wrapper } from './Form/form.styled';

export const Container = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    position: 'relative',
}));

export const BottomContainer = styled('div')(({ theme }) => ({
    position: 'absolute',
    padding: '12px',
    borderRadius: '16px',
    bottom: '20px',
    margin: '0px 40px',
    width: 'calc(100% - 180px)',
    maxWidth: '780px',
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[16],
    color: theme.palette.primary.main,
    zIndex: 101,
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',

    '& > label': {
        margin: 0,
    },

    [theme.breakpoints.down(600)]: {
        padding: '8px',
        width: 'calc(100% - 40px)',
        margin: '0px 20px',

        '& > label > .MuiFormControlLabel-label': {
            fontSize: '14px',
        }
    },

    '&[data-complete="true"]': {
        width: 'calc(100% - 80px)',
        maxWidth: '1100px',

        [theme.breakpoints.down(600)]: {
            width: 'calc(100% - 40px)',
        },
    }
}));

export const WizzardContainer = styled('div')(({ theme }) => ({
    display: 'none',
    height: '100%',
    maxWidth: '960px',

    '&[data-visible="true"]': {
        display: 'block',
    },

    '& > div': {
        position: 'relative',
        paddingRight: '40px',
        paddingBottom: '90px',
        flexDirection: 'row-reverse',
    },

    '& > div:first-child': {
        paddingTop: '40px',

        '> :first-child': {
            position: 'relative',
            right: 'auto',
            paddingTop: '20px',
            paddingBottom: '12px',
            height: 'fit-content',
            maxHeight: 'calc(100% - 20px)',
            backgroundColor: theme.palette.background.default,
            margin: '0px 0px 0px 20px',
            paddingLeft: '20px',
            paddingRight: '30px',
            borderRadius: '20px',
            boxShadow: theme.shadows[18],
            // border: `1px solid ${theme.palette.primary.main}`,
            zIndex: 10,
            gap: '8px',

            '> :nth-child(even)': {
                height: '12px',
            },
        },

        '& > div:nth-child(n+2)': {
            width: '100%',
        },
    },

    [theme.breakpoints.down(600)]: {
        '& > div': {
            position: 'relative',
            paddingRight: '0px',
            paddingBottom: '80px',
            flexDirection: 'column',
        },

        '& > div:first-child': {
            paddingTop: '0px',

            '> :first-child': {
                paddingTop: '8px',
                paddingBottom: '8px',
                width: 'fit-content',
                maxWidth: 'calc(100% - 20px)',
                margin: '12px 0px',
                paddingLeft: '20px',
                paddingRight: '20px',
                gap: '6px',
                alignSelf: 'center',
            },

            '& > div:nth-child(n+2)': {
                position: 'relative',
                zIndex: 100,
                height: 'calc(100% - 76px)',
            },
        },
    },
}));

export const SuccessWrapper = styled(Wrapper)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    margin: '40px',
    height: 'calc(100% - 80px)',
    width: 'calc(100% - 80px)',
    gap: '20px',
    padding: '20px',

    [theme.breakpoints.down(600)]: {
        margin: '20px',
        height: 'calc(100% - 40px)',
        width: 'calc(100% - 40px)',
    },
}));

export const SuccessPill = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '0px 12px',
    textAlign: 'center',
    fontSize: '12px',
    borderRadius: '20px',
    lineHeight: '30px',

    [theme.breakpoints.down(600)]: {
        left: '10px',
    },

    [theme.breakpoints.down(1000)]: {
        left: '30px',
    }
}));

export const SuccessActionWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
}));