import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';

const CustomPickersDay = styled(PickersDay)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,

    '&.MuiPickersDay-today': {
        color: theme.palette.primary.main,
        border: `1px dashed ${theme.palette.primary.main}`,

        '&.Mui-selected': {
            color: theme.palette.primary.contrastText,
        },

        '&:hover': {
            backgroundColor: 'inherit',
        },
    },

    '&[data-hasdata=true]': {
        backgroundColor: theme.palette.secondary.main,

        '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
}));

const StaticDatePickerWrapper = styled('div')(({ theme }) => ({
    height: '320px',
    overflow: 'hidden',
    borderRadius: '12px',

    '& > div': {
        backgroundColor: theme.palette.background.default,
    },
}));

const DatePicker = ({ dayHasData = () => false, ...props }) => {
    const renderPickerDay = (date, _, pickersDayProps) => (
        <CustomPickersDay
            {...pickersDayProps}
            disableMargin
            data-hasdata={dayHasData(date)}
        />
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePickerWrapper>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    renderDay={renderPickerDay}
                    renderInput={(params) => <TextField {...params} />}
                    {...props}
                />
            </StaticDatePickerWrapper>
        </LocalizationProvider>
    );
};

export default DatePicker;