import { useEffect, useState } from 'react';
import { formatISO, format, addMinutes, isBefore, startOfToday, isSameDay } from 'date-fns';
import { useNavigate, useParams } from "react-router-dom";
import Stack from '@mui/material/Stack';

import { useOutletContext } from '../../Auth';

import Property from '../../data/property';
import Viewing from '../../data/viewing';
import Viewer from '../../data/viewer';
import Lead from '../../data/lead';
import Reports from '../../data/reports';

import TwoPanelsLayout from '../../Components/library/Layout/TwoPanels';
import RightPanel from '../../Components/library/Layout/RightPanel';
import Text from '../../Components/library/Typography/Text';

import PropertyList from '../Properties/PropertyList/PropertyList';
import ViewingsList from './ViewingsList/ViewingsList';
import ViewingsListSkeleton from './ViewingsList/ViewingsListSkeleton';
import NewViewing from './Viewing/New/New';
import Card from '../../Components/library/Cards/Generic';
import DayPicker from '../../Components/library/DatePicker/DayPicker';
import UpcomingViewings from './ViewingsList/Upcoming';
import Header from '../../Components/library/Cards/Headers/Standard';

import APPS_CONFIG from '../config';
import Tag from '../../Components/library/Tag/Tag';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';


const Viewings = () => {
    const navigate = useNavigate();
    const params = useParams();
    const context = useOutletContext();
    const [view, setView] = useState(params.view ? params.view.toUpperCase() : 'UPCOMING');
    const [property, setProperty] = useState(null);
    const [viewings, setViewings] = useState(null);
    const [reports, setReports] = useState(null);
    const [viewingsSubscription, setViewingsSubscription] = useState(null);
    const [properties, setProperties] = useState(null);
    const [fetchingViewings, setFetchingViewings] = useState(false);
    const [newViewing, setNewViewing] = useState(null);
    const [calendarDay, setCalendarDay] = useState(null);

    useEffect(() => {
        const initialFetch = async () => {
            setFetchingViewings(true);
            await handleFetchProperty();
            await handleFetchViewings();
        };
        initialFetch();

        return () => {
            if (viewingsSubscription) {
                viewingsSubscription.unsubscribe();
            }
        };
    }, []);

    useEffect(() => {
        handleNavigation();
    }, [params]);

    const handleNavigation = async () => {
        if (params['*'].includes('/calendar')) {
            if (params.date && !fetchingViewings) {
                if (isBefore(startOfToday(), new Date(params.date))) {
                    await handleChangeDay(new Date(params.date));
                } else { // date in the past -> navigate to today
                    navigate(`/app/viewings/calendar/${format(new Date(), 'yyyy-MM-dd')}`);
                }
            }
        } else if (params.propertyId) {
            await handleNavigateToProperty();
        } else if (params['*'].includes('/new')) {
            if (!newViewing) {
                setNewViewing({});
            }
            console.log('new route');
        }
    };

    const handleNavigateToProperty = async () => {
        const prop = await handleFetchProperty(params.propertyId);
        if (prop) {
            await handleChangeProperty(prop);
            if (params.view === 'new') {
                setNewViewing({});
            }
        }
    };

    const handleFetchProperty = async (id) => {
        if (properties && properties.length) {
            return properties.find(p => p.id === id);
        } else {
            const props = await Property.getAll();
            const prepedProps = props.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(p => ({
                ...p,
                marketPlaces: [{ url: p.listingUrl, type: 'unknown', createdAt: p.createdAt }]
            }));
            setProperties(prepedProps);

            return prepedProps.find(p => p.id === params.propertyId);
        }
    };

    const handleFetchViewings = async () => {
        if (viewingsSubscription) {
            viewingsSubscription.unsubscribe();
            setViewingsSubscription(null);
        }
        setFetchingViewings(true);
        const subscription = Viewing.viewingsSubscription(async (items, isSynced) => {
            if (isSynced) {
                setFetchingViewings(false);
                setViewings(items);
            }
        }, (v) => v.date('ge', format(startOfToday(), 'yyyy-MM-dd')));
        setViewingsSubscription(subscription);
    };

    const handleChangeProperty = async (prop) => {
        setProperty(prop);

        setCalendarDay(null);
        if (view === 'CALENDAR') {
            setView('UPCOMING');
        }

        if (!params.propertyId || params.propertyId !== prop.id) {
            navigate(`/app/viewings/${prop.id}${params.view ? `/${params.view}` : ''}`);
        }

        const reports = await Reports.fetchByProperty(prop.id);
        setReports(reports.filter(r => r.reportStatus === 'SENT'));
    };

    const handleChangeDay = async (day) => {
        console.log('here');

        setView('CALENDAR');
        setCalendarDay(day);
        setProperty(null);

        if (!params.date || params.date !== format(day, 'yyyy-MM-dd')) {
            navigate(`/app/viewings/calendar/${format(day, 'yyyy-MM-dd')}`);
        }
    };

    const handleBack = () => {
        if (!property && calendarDay && !params['*'].includes('/calendar')) {
            handleChangeDay(calendarDay);
        } else if (property && !params.propertyId) {
            handleChangeProperty({ id: property.id })
        } else {
            setProperty(null);
            setCalendarDay(null);
            navigate(`/app/viewings/`);
            setView('UPCOMING');
        }
    };

    const handleBookViewing = async (data) => {
        const viewingData = {
            startsAt: formatISO(new Date(data.calendar.dateTime.value)).replace('+01:00', 'Z').replace('Z', '.000Z'),
            endsAt: formatISO(addMinutes(new Date(data.calendar.dateTime.value), data.viewingType === 'BLOCK' ? 59 : 14)).replace('Z', '.000Z'),
            date: format(new Date(data.calendar.dateTime.value), 'yyyy-MM-dd'),
            notes: data.info.notes.value,
            postcode: data.property.postcode.value || property.postcode,
            keysId: data.keys.id.value,
            type: data.viewingType,
        };

        let viewerData = {};
        if (data.viewingType === 'SINGLE') {
            viewerData = {
                name: data.customer.name.value,
                phone: data.customer.phone.value,
                email: data.customer.email.value,
            };
        } else if (data.viewingType === 'BLOCK') {
            viewerData = {
                name: 'BLOCK VIEWING',
                phone: '+44 07123456789',
                email: 'block@blockviewing.com',
            };
        }

        // console.log(viewingData, viewerData);

        // return;

        const viewer = await Viewer.create(viewerData);

        let leadId = data.leadId;
        if (!data.leadId && data.viewingType === 'SINGLE') {
            const leadData = {
                name: data.customer.name.value,
                phone: data.customer.phone.value,
                email: data.customer.email.value,
                propertyId: data.property.id.value,
                status: 'APPROVED',
                owner: context.userData.owner,
            };

            const lead = await Lead.create(leadData);
            leadId = lead.id;
        }

        viewingData.leadId = leadId;

        if (!viewer) {
            console.log('Could not create booking missing data viewer');
            return null;
        }

        const currentProperty = await Property.get(data.property.id.value);

        if (!currentProperty) {
            console.log('Could not create booking missing data property');
            return null;
        }

        const viewing = await Viewing.create(viewingData, viewer, currentProperty);

        console.log('booked viewing');

        setNewViewing(null);
        setView('UPCOMING');
        navigate(`/app/viewings/${data.property.id.value}/upcoming`);

        return viewing;
    };

    const handleUpdateViewing = async (viewing, data) => {
        await Viewing.update(viewing.id, data);
    };

    const handleCancelViewing = async (viewing) => {
        console.log(viewing);
        await Viewing.delete(viewing.id);
    };

    const rightPanel = () => {
        if (property && !newViewing) {
            if (fetchingViewings) {
                return (
                    <ViewingsListSkeleton
                        propertyAddress={property ? `${property.address} - ${property.postcode}` : ''}
                    />
                );
            }
            return (
                <ViewingsList
                    viewings={{
                        PAST: reports ? reports : [],
                        UPCOMING: viewings ? viewings.filter(v => v.propertyId === property.id) : [],
                    }}
                    propertyAddress={property ? `${property.address} - ${property.postcode}` : ''}
                    onAddViewing={(data = {}) => {
                        navigate(`/app/viewings/new`);
                        setNewViewing(data);
                    }}
                    onNoteChange={async (viewing, notes) => await handleUpdateViewing(viewing, { notes })}
                    onCancel={async (viewing) => await handleCancelViewing(viewing)}
                    onBack={handleBack}
                    view={view}
                    onSelectView={(_view) => {
                        setView(_view);
                        navigate(`/app/viewings/${property.id}/${_view.toLowerCase()}`);
                    }}
                    loading={fetchingViewings}
                />
            );
        } else if (view === 'CALENDAR' && !newViewing && !property) {
            return (
                <RightPanel
                    title={<><img src={APPS_CONFIG.viewings.logo} width="20px" /> Viewings</>}
                    header={
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{ width: 'fit-content', m: 2.5, ml: 2.5, mt: 0 }}
                            onClick={() => {
                                navigate(`/app/viewings/new`);
                                setNewViewing({});
                            }}
                        >
                            <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                            Book a Viewing
                        </Button>
                    }
                    tags={[
                        <Tag key="selected-calday">{format(calendarDay, 'MMMM do')}</Tag>
                    ]}
                    onBack={handleBack}
                    body={
                        fetchingViewings ? (
                            <Stack
                                alignItems="center"
                                sx={{ p: 4 }}
                            >
                                <CircularProgress />
                            </Stack>
                        ) : (
                            <UpcomingViewings
                                onlyViewings
                                withProperties
                                noKeys
                                viewings={
                                    viewings ? viewings.filter(v => isSameDay(new Date(v.startsAt), calendarDay)) : []
                                }
                            />
                        )
                    }
                />
            );
        } else if (newViewing) {
            return (
                <NewViewing
                    onBack={() => {
                        setNewViewing(null);
                        handleBack();
                    }}
                    onBook={handleBookViewing}
                    property={property}
                    properties={properties}
                    viewings={viewings ? viewings : []}
                    time={newViewing.time}
                    day={newViewing.day}
                />
            );
        }

        return null;
    };

    return (
        <TwoPanelsLayout
            leftPanel={
                <Stack
                    gap={1.5}
                    sx={{
                        width: '100%',
                        maxWidth: '600px',
                        height: '100%',
                        overflow: 'auto',
                        opacity: newViewing ? 0.5 : 1,
                        pointerEvents: newViewing ? 'none' : 'all',
                    }}
                >
                    {/* <Card
                        sx={{ backgroundColor: 'background.default' }}
                        header={
                            <Header
                                title="Viewings by Calendar"
                            />
                        }
                        body={
                            <DayPicker
                                startDate={new Date()}
                                selectedDate={!newViewing ? calendarDay : null}
                                onSelectDate={handleChangeDay}
                                maxDays={10}
                            />
                        }
                    /> */}
                    <PropertyList
                        title="Viewings by Properties"
                        properties={properties || []}
                        selectedProperty={!newViewing ? property : null}
                        onSelectProperty={handleChangeProperty}
                        onAddProperty={() => navigate('/app/properties/new')}
                        noAdd
                    />
                </Stack>
            }
            rightPanel={
                rightPanel()
            }
            rightPanelPlaceholder={
                <Stack
                    sx={{
                        height: '100%',
                        width: '100%',
                        maxWidth: '600px',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        borderRadius: '12px',
                    }}
                >
                    <Stack>
                        <Text color="primary.light" size="medium" span sx={{ width: 'fit-content', ml: '20px', mt: '110px' }}>👈 See viewings by selecting a calendar day</Text>
                        <Text color="primary.light" size="medium" span sx={{ width: 'fit-content', ml: '20px', mt: '150px' }}>👈 See viewings by selecting a property</Text>
                    </Stack>
                    <Stack
                        sx={{ height: '100%' }}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            variant="contained"
                            // size="small"
                            sx={{ width: 'fit-content', mt: '-150px' }}
                            onClick={() => {
                                navigate(`/app/viewings/new`);
                                setNewViewing({});
                            }}
                        >
                            <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                            Book a Viewing
                        </Button>
                    </Stack>
                </Stack>
            }
            showRightPanel={(property || newViewing) && !fetchingViewings}
        />
    );
};

export default Viewings;