import { useEffect, useState } from 'react';
import { eachDayOfInterval, addDays, format, isSameDay } from 'date-fns';
import Stack from '@mui/material/Stack';
import Text from '../Typography/Text';

export const Day = ({ day, selected, onSelect = () => { } }) => {
    return (
        <Stack
            gap={1}
            alignItems="center"
            sx={{
                borderColor: 'primary.light',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: '8px',
                flexShrink: 0,
                cursor: 'pointer',
                p: 1.5,
                backgroundColor: selected ? 'primary.main' : 'none',
                color: selected ? 'primary.contrastText' : 'primary.main',
                width: '80px',
                // pointerEvents: selected ? 'none' : 'all',
            }}
            onClick={() => {
                if (!selected) {
                    onSelect(day);
                }
            }}
        >
            <Text sx={{ color: 'inherit' }}>{format(day, 'MMM')}</Text>
            <Text sx={{ color: 'inherit' }} weight='bold' size="medium">{`${format(day, 'EEE')} ${format(day, 'dd')}`}</Text>
        </Stack>
    );
};

const DayPicker = ({
    startDate,
    maxDays,
    selectedDate,
    onSelectDate = () => { },
}) => {
    const [days, setDays] = useState([]);

    useEffect(() => {
        const _days = eachDayOfInterval({
            start: new Date(startDate),
            end: addDays(new Date(startDate), maxDays),
        });
        setDays(_days);
    }, [startDate, maxDays]);

    return (
        <Stack
            direction="row"
            gap={1}
            sx={{
                width: '100%',
                overflow: 'auto',
                backgroundColor: 'secondary.light',
                p: 1.5,
                borderRadius: '12px',
                height: '92px',
                flexShrink: 0,
            }}
        >
            {
                days.map((day) => (
                    <Day
                        key={`${day}`}
                        day={day}
                        selected={isSameDay(selectedDate, day)}
                        onSelect={onSelectDate}
                    />
                ))
            }
        </Stack>
    );
};

export default DayPicker;