import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
    TokenContainer,
    Token,
    TokenValue,
    TokenString,
    CalcContainer,
} from './AgentReportRenderer.styled';
import { styled } from '@mui/material/styles';

const VAR_REGEXP = /\$([a-zA-Z]*)/g;

const Tt = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => {
    return ({
        '& .MuiTooltip-tooltip': {
            backgroundColor: theme.palette.background.default,
            padding: '20px',
            width: 'fit-content',
            maxWidth: 'none',
            borderRadius: '12px',
            border: `2px solid ${theme.palette.primary.light}`,
            boxShadow: theme.shadows[18],
        }
    });
});

const tokeniseString = (str, variables) => {
    const vars = str.match(VAR_REGEXP) ? str.match(VAR_REGEXP).map(v => v.replace('$', '')) : [];

    const tokenized = vars.reduce((tot, v) => {
        const s = tot[tot.length - 1].split(v);

        return [
            ...(tot.length > 1 ? tot.slice(0, -1) : []),
            s[0],
            {
                name: v,
                ...variables[v],
            },
            s[1],
        ];
    }, [str.replace(/\$/g, '')]).filter(t => !!t && t !== '');

    return tokenized;
}

const TooltipCalc = ({ calc = '', children, variables }) => {
    let calcs = [];

    if (typeof calc === 'string') {
        calcs = [tokeniseString(calc, variables)];
    } else {
        calcs = calc.map(c => tokeniseString(c, variables));
    }

    return (
        <Tt
            // open
            title={
                <div>
                    {
                        calcs.length ?
                            calcs.map(tokenized => (
                                <CalcContainer>
                                    {
                                        tokenized.map((t) => typeof t === 'string' ? (
                                            <TokenContainer>
                                                <TokenString>{t}</TokenString>
                                            </TokenContainer>
                                        ) : (
                                            <TokenContainer>
                                                <Token>{t.text}</Token>
                                                <TokenValue>{t.value}</TokenValue>
                                            </TokenContainer>
                                        ))
                                    }
                                </CalcContainer>
                            )) : null
                    }
                </div>
            }
            placement="top"
        >
            <div>
                {children}
            </div>
        </Tt>
    );
};

export default TooltipCalc;