import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Stack from '@mui/material/Stack';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';

const CustomTimeline = styled(Timeline)(({ theme }) => ({
    padding: 0,
}));

const CustomTimelineItem = styled(TimelineItem, { shouldForwardProp: (prop) => prop !== 'summary' })(({ theme, summary }) => ({
    minHeight: summary ? '50px' : '70px',
}));

const CustomTimelineDot = styled(TimelineDot, { shouldForwardProp: (prop) => prop !== 'summary' })(({ theme, summary }) => ({
    padding: summary ? '4px' : '6px',
    margin: summary ? 0 : '12px 0',
    color: theme.palette.primary.dark,
}));

const Connector = styled(TimelineConnector, { shouldForwardProp: (prop) => prop !== 'summary' })(({ theme, dataend, summary }) => ({
    backgroundColor: dataend === 'true' ? 'transparent' : theme.palette.secondary.main,

}));

const DateContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'summary' })(({ theme, summary }) => ({
    width: summary ? '90px' : '120px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.action.disabledBackground,
    border: `1px solid ${theme.palette.action.disabled}`,
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontSize: summary ? '8px' : '10px',
    fontWeight: 'bold',
    padding: '4px 10px',
    borderRadius: '40px',
    marginRight: '12px',
    alignSelf: 'flex-start',
    ...(summary ? {} : {
        [theme.breakpoints.down(1100)]: {
            position: 'absolute',
            left: '60px',
        }
    }),
}));

const Content = styled('div', { shouldForwardProp: (prop) => prop !== 'summary' })(({ theme, summary }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: summary ? '0px' : '40px',
    marginTop: '16px',
}));

const ContentImage = styled('img')(({ theme }) => ({
    width: '100%',
    borderRadius: '16px',
    display: 'block',
}));

const ContentGalleryImageContainer = styled('div')(({ theme }) => ({
    width: '100%',
    borderRadius: '20px',
    transition: 'transform 0.2s',
    // '&:hover': {
    //     zIndex: 100,
    //     transform: 'scale(2)',
    //     transformOrigin: 'bottom right',
    // },
    padding: 10,
    backgroundColor: '#f6f5f9',
    border: `1px solid ${theme.palette.background.paper}`,
    boxShadow: theme.shadows[8],
    zIndex: 1,
}));

const ContentImageContainer = styled(ContentGalleryImageContainer)(({ theme }) => ({
    width: '100%',
    maxWidth: '400px',
}));

const ContentTitle = styled('div', { shouldForwardProp: (prop) => prop !== 'summary' })(({ theme, summary }) => ({
    width: '100%',
    color: theme.palette.primary.main,
    fontSize: summary ? '16px' : '22px',
    fontWeight: 'bold',

    ...(summary ? {} : {
        [theme.breakpoints.down(1100)]: {
            marginTop: '50px',
        },
    }),
}));

const ContentText = styled('div')(({ theme }) => ({
    width: '100%',
    color: theme.palette.primary.main,
    fontWeight: 'lighter',
}));

const ContentVideoContainer = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: '600px',
    borderRadius: '20px',
    backgroundColor: '#f6f5f9',
    border: `1px solid ${theme.palette.background.paper}`,
    padding: 10,
    boxShadow: theme.shadows[8],
}));

const ContentVideo = styled('video')(() => ({
    borderRadius: '16px',
    display: 'block',
    width: '100%',
}));

const Emoji = styled('div', { shouldForwardProp: (prop) => prop !== 'summary' })(({ summary }) => ({
    width: summary ? '16px' : '24px',
    height: summary ? '16px' : '24px',
    fontSize: summary ? '16px' : '24px',
    lineHeight: summary ? '16px' : '24px',
}));

const ReportTimeline = ({ content, summary }) => {
    return (
        <CustomTimeline>
            {
                content.map((item, index) => (
                    <CustomTimelineItem summary={summary} key={`timeline-item-${index}-${summary}`}>
                        <TimelineOppositeContent
                            sx={{
                                margin: summary ? 0 : '12px 0',
                                maxWidth: '132px',
                                padding: 0,
                                flex: 'initial',
                            }}

                            variant="body2"
                            color="text.secondary"
                        >
                            <DateContainer summary={summary}>
                                <div>
                                    {item.date}
                                </div>
                                <div>
                                    {item.time}
                                </div>
                            </DateContainer>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            {/* <Connector dataend={`${index === 0}`} /> */}
                            <CustomTimelineDot color="secondary" variant="outlined" summary={summary}>
                                <Emoji summary={summary}>{item.emoji}</Emoji>
                            </CustomTimelineDot>
                            <Connector dataend={`${index === content.length - 1}`} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ margin: summary ? '0px 0px 0px 12px' : '12px 12px', padding: 0 }}>
                            <ContentTitle summary={summary}>
                                {item.contentTitle}
                            </ContentTitle>
                            {summary ? null : (
                                <Content summary={summary}>
                                    {item.contentBody.map((itemContent, i) => {
                                        if (itemContent.type === 'text') {
                                            return (
                                                <ContentText key={`item-content-${index}-${i}-${summary}`}>
                                                    {itemContent.value}
                                                </ContentText>
                                            );
                                        } else if (itemContent.type === 'image') {
                                            return (
                                                <ContentImageContainer key={`item-content-${index}-${i}-${summary}`}>
                                                    <ContentImage src={itemContent.value} />
                                                </ContentImageContainer>
                                            );
                                        } else if (itemContent.type === 'gallery' && itemContent.value) {
                                            return (
                                                <Masonry columns={window.innerWidth > 1100 ? 2 : 1} spacing={2} key={`item-content-${index}-${i}-${summary}`}>
                                                    {itemContent.value.map((src, index) => (
                                                        <Stack key={index}>
                                                            <ContentGalleryImageContainer>
                                                                <ContentImage
                                                                    src={src}
                                                                    loading="lazy"
                                                                />
                                                            </ContentGalleryImageContainer>
                                                        </Stack>
                                                    ))}
                                                </Masonry>
                                            );
                                        } else if (itemContent.type === 'video') {
                                            return (
                                                <ContentVideoContainer key={`timeline-item-content-${index}-${i}-${summary}`}>
                                                    <ContentVideo controls>
                                                        <source src={itemContent.value} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </ContentVideo>
                                                </ContentVideoContainer>
                                            );
                                        }

                                        return '';
                                    })}
                                </Content>
                            )}
                        </TimelineContent>
                    </CustomTimelineItem>
                ))
            }
        </CustomTimeline>
    );
};

export default ReportTimeline;
