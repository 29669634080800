import { useState } from 'react';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ControlledInput from '../../../Input/ControlledInput';

import Header from "./Standard";

const HeaderWithSearch = ({ onSearchChange = () => { }, onSearchClose = () => { }, ...otherProps }) => {
    const [search, setSearch] = useState(false);
    const [searchTransition, setSearchTransition] = useState(false);

    return (
        <Stack
            sx={{ position: 'relative' }}
        >
            <Header
                {...otherProps}
                action={
                    <Button
                        size="small"
                        sx={{
                            color: 'primary.light',
                            alignSelf: 'flex-start',
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                        onClick={() => {
                            setSearchTransition(true);
                            setSearch(!search);
                            setTimeout(() => setSearchTransition(false), 500);
                        }}
                    >
                        <SearchOutlinedIcon
                            sx={{ fontSize: '16px', mr: 0.5 }}
                        />
                        Search
                    </Button>
                }
                sx={{
                    width: '100%',
                    position: 'relative',
                    marginLeft: search ? '-100%' : '0',
                    transition: 'all 0.4s cubic-bezier(.67,-0.01,.96,.57)',
                }}
                sxTitle={{ width: 'calc(100% - 90px)'}}
            />
            <Stack
                sx={{
                    position: 'absolute',
                    marginLeft: search ? '0' : '100%',
                    transition: 'all 0.4s cubic-bezier(.67,-0.01,.96,.57)',
                    padding: 1.5,
                    width: '100%',
                }}
                spacing={1.5}
                direction="row"
            >
                {
                    search && !searchTransition ? (
                        <Fade key="fade-search" in={true} appear>
                            <Stack sx={{ width: '100%', height: '40px' }}>
                                <ControlledInput
                                    name="search"
                                    label="Search"
                                    size="small"
                                    fullWidth
                                    autoFocus
                                    onChange={onSearchChange}
                                />
                            </Stack>
                        </Fade>
                    ) : (
                        <Stack sx={{ width: '100%', height: '40px' }}></Stack>
                    )
                }
                <Button
                    size="small"
                    sx={{ color: 'primary.light', padding: '0 12px' }}
                    onClick={() => {
                        setSearch(!search);
                        onSearchClose();
                    }}
                >
                    <CloseOutlinedIcon
                        sx={{ fontSize: '16px', mr: 0.5 }}
                    />
                    Close
                </Button>
            </Stack>
        </Stack>
    )
};

export default HeaderWithSearch;