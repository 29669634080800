import React from 'react';
import ReactDOM from 'react-dom';
import jss from 'jss';
import preset from 'jss-preset-default';
import { Amplify } from 'aws-amplify';
// import { ThemeProvider } from '@mui/material/styles';
import awsExports from './aws-exports';
import './index.css';
import { Provider as ThemeProvider } from './theme';
import Router from './Router';
import reportWebVitals from './reportWebVitals';

Amplify.configure(awsExports);
jss.setup(preset())

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
