import { styled } from '@mui/material/styles';

export const DayContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    backgroundColor: theme.palette.background.default,
    padding: '12px',
    borderRadius: '12px',
}));

export const DayTitle = styled('div')(({ theme }) => ({
    fontSize: '14px',
    backgroundColor: theme.palette.primary.light,
    // border: `1px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.contrastText,
    width: 'fit-content',
    fontWeight: 'bold',
    padding: '6px 12px',
    borderRadius: '20px',
    textTransform: 'capitalize',

    [theme.breakpoints.down(800)]: {
        fontSize: '12px',
    },
}));

export const DayContent = styled('div')(({ theme }) => ({
    paddingLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',

    [theme.breakpoints.down(800)]: {
        paddingLeft: 0,
    },
}));