import { useNavigate } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import Card from '../../../Components/library/Cards/Generic';
import Header from '../../../Components/library/Cards/Headers/Standard';
import FeedbackButton from '../../../Components/library/Button/FeedbackButton';
import Text from '../../../Components/library/Typography/Text';
import Tag from '../../../Components/library/Tag/Tag';
import MarketPlaceTag from '../../../Components/library/Tag/MarketPlaceTag';
import { emojiFromString } from '../../../utils/emoji';

import Stack from '@mui/material/Stack';
import { Divider } from '@mui/material';

const LeadDisplay = ({
    lead,
    color = 'secondary.light',
    noEmailPhone = false,
    sx = {},
}) => {
    const navigate = useNavigate();

    return (
        <Card
            sx={sx}
            header={
                <Header
                    emoji={emojiFromString(lead.id)}
                    title={lead.name}
                    titleSize="medium"
                    actions={[
                        // {
                        //     value: 'See Lead',
                        //     onClick: () => { navigate(`/app/leads/${lead.propertyId}`) }
                        // },
                        {
                            value: <Text>Click to Copy to clipboard</Text>,
                            onClick: () => { },
                        },
                        (
                            lead.email ? (
                                {
                                    value: <Tag>{`📤: ${lead.email}`}</Tag>,
                                    onClick: () => copy(lead.email),
                                }
                            ) : null
                        ),
                        (
                            lead.phone ? (
                                {
                                    value: <Tag>{`☎️: ${lead.phone}`}</Tag>,
                                    onClick: () => copy(lead.phone),
                                }
                            ) : null
                        ),
                    ].filter(e => !!e)}
                    tags={[
                        ...(lead.marketPlace ? [
                            <MarketPlaceTag
                                marketPlace={lead.marketPlace.platform}
                                sx={{ backgroundColor: 'background.default' }}
                                key="lead-tag-mp"
                            />,
                        ] : []),
                    ]}
                    sx={{ backgroundColor: color }}
                />
            }
            withBodyContainer={false}
            body={
                (!!lead.notes || lead.notes === '') || !noEmailPhone ? (
                    <>
                        {
                            !noEmailPhone ? (
                                <Stack
                                    gap={1}
                                    direction="row"
                                    flexWrap="wrap"
                                    sx={{ p: 1.5 }}
                                >

                                    <FeedbackButton
                                        revertAfter={2000}
                                        variant="outlined"
                                        size="small"
                                        sx={{ textTransform: 'none' }}
                                        feedback={<div>Copied to clipboard</div>}
                                    >{lead.email}</FeedbackButton>
                                    <FeedbackButton
                                        revertAfter={2000}
                                        variant="outlined"
                                        size="small"
                                        sx={{ textTransform: 'none' }}
                                        feedback={<div>Copied to clipboard</div>}
                                    >{lead.phone}</FeedbackButton>
                                </Stack>
                            ) : null
                        }
                        {!noEmailPhone && (!!lead.notes || lead.notes === '') ? <Divider /> : null}
                        {
                            !!lead.notes || lead.notes === '' ? (
                                <Stack
                                    sx={{ p: 1.5 }}
                                >
                                    <Text weight='lighter' size="10px" sx={{ pb: 1, }}>Lead Notes (Private to you)</Text>
                                    {
                                        lead.notes.split('\n').map((n, i) => <Text key={`line-${i}`}>{n}</Text>)
                                    }
                                </Stack>
                            ) : null
                        }
                    </>
                ) : null
            }
        />
    );
};

export default LeadDisplay;