import { styled } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) => ({
    padding: '40px',
    width: 'fit-content',
    height: 'fit-content',
    paddingBottom: '40px',

    [theme.breakpoints.down(800)]: {
        padding: '20px',
    },
}));

export const DayContainer = styled('div')(({ theme }) => ({
    marginBottom: '30px',
    padding: '12px 20px 20px',
    borderRadius: '20px',
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.down(800)]: {
        padding: '12px',
    },
}));

export const Day = styled('div')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: 'bold',

    [theme.breakpoints.down(800)]: {
        textAlign: 'center',
    },
}));

export const ClemContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    margin: '20px 0px',
    maxWidth: '400px',

    [theme.breakpoints.down(800)]: {
        gap: '12px',
        margin: '12px 0px',
    }
}));