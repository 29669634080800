import { useEffect, useState } from "react";
import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import DashboardCard from "../Dashboard/Cards/DashboardCard/DashboardCard";
import Day from "../Dashboard/Cards/DashboardCard/Day";
import Property from "../Dashboard/Cards/DashboardCard/Property";
import { DescriptionText, Link } from "../Dashboard/Cards/cards.styled";
import Button from "@mui/material/Button";
import { DashboardContainer } from "../Dashboard/Dashboard.styled";
import { addDays, subDays, isFuture, isToday, format } from "date-fns";
import DatePicker from '../Input/DatePicker/DatePicker';

const queryReportManager = async (op, payload) => (
    JSON.parse((await API.graphql({
        query: queries.reportManager,
        variables: {
            op,
            payload: JSON.stringify(payload),
        },
    })).data.reportManager)
);

const getData = async (dates) => {
    return await dates.reduce(async (p, date) => {
        const tot = await p;
        const res = await queryReportManager('get', { date: date });
        return [
            ...tot,
            res,
        ]
    }, Promise.resolve([]));
};

const Reports = ({ admin = false }) => {
    const [reports, setReports] = useState(null);
    const [currentDay, setCurrentDay] = useState(new Date());
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const initialFetch = async () => {
            await fetchReports(currentDay);
        };

        initialFetch();
    }, []); // eslint-disable-line

    const fetchReports = async (date) => {
        setLoading(true);

        let dates = [
            date.toISOString().split('T')[0],
            subDays(date, 1).toISOString().split('T')[0],
        ];

        if (admin) {
            dates = [
                addDays(date, 1).toISOString().split('T')[0],
                ...dates,
            ];
        }

        dates = dates.filter((d) => !(reports || []).find(r => r.date === d));
        let _reports = await getData(dates);

        if (!admin) {
            _reports = _reports.map((rep) => ({
                ...rep,
                sessionReports: rep.sessionReports.filter(r => r.reportStatus !== 'SENT'),
            }));
            console.log(_reports);
        }

        setReports([...(reports || []), ..._reports].sort((a, b) => new Date(b.date) - new Date(a.date)));
        setLoading(false);
    };

    const handleSendReport = async (report) => {
        setLoading(true);

        const reportDate = report.info.find(i => i.key === 'date').value;
        const data = await queryReportManager('send', { sessionId: report.sessionId });
        const _reports = reports.map((r) => {
            if (r.date === reportDate) {
                return {
                    ...r,
                    sessionReports: r.sessionReports.map(sr => sr.id === report.id ? data.sessionReport : sr),
                };
            }

            return r;
        });

        setReports(_reports);
        setLoading(false);
    };

    return (
        <DashboardContainer>
            {
                admin ? (
                    <DashboardCard title={`🔧 Controls`} subtitle={loading ? 'Loading...' : 'Fetched'} expanded={true} >
                        <DatePicker
                            label="Report date picker"
                            disabled={loading}
                            value={currentDay}
                            onChange={(newValue) => {
                                setCurrentDay(newValue);
                                if (!reports.find(r => r.date === format(newValue, 'yyyy-MM-dd'))) {
                                    fetchReports(newValue);
                                }
                            }}
                            dayHasData={(day) => !!(reports || []).find(r => r.date === format(day, 'yyyy-MM-dd'))}
                        />
                    </DashboardCard>
                ) : null
            }
            <DashboardCard title={`📝 Reports`} subtitle={`Fetched ${reports ? reports.length : 0} days of reports`} expanded={true} >
                {
                    reports && reports.length ?
                        reports.map((report) => (
                            report.sessionReports && report.sessionReports.length ? (
                                <Day key={`past-day-${report.sessionReports[0].info.find(i => i.key === 'date').value}`} day={report.sessionReports[0].info.find(i => i.key === 'date').value} >
                                    {
                                        report.sessionReports.map((sessionReport, rIndex) => (
                                            <Property
                                                key={`past-property-${sessionReport.id}-${rIndex}`}
                                                property={{
                                                    address: sessionReport.info.find(i => i.key === 'property-address').value.split(' - ')[0],
                                                    postcode: sessionReport.info.find(i => i.key === 'property-address').value.split(' - ')[1]
                                                }}
                                                expanded={true}
                                                subtitle={{ COMPLETE: '✅ Complete', PENDING: '⌛ In Progress', SENT: '📤 Sent' }[sessionReport.reportStatus]}
                                            >
                                                <Link to={`/app/clem/report/${sessionReport.sessionId}`}>
                                                    <Button variant="outlined">
                                                        {sessionReport.reportStatus !== 'SENT' ? '🖊️ Edit Report' : '👀 View Report'}
                                                    </Button>
                                                </Link>
                                                {
                                                    admin && (
                                                        isToday(new Date(sessionReport.info.find(i => i.key === 'date').value)) ||
                                                        !isFuture(new Date(sessionReport.info.find(i => i.key === 'date').value))
                                                    ) && (
                                                            sessionReport.reportStatus === 'COMPLETE' ||
                                                            sessionReport.reportStatus === 'SENT'
                                                        ) ? (
                                                        <Button
                                                            disabled={loading}
                                                            variant={sessionReport.reportStatus !== 'SENT' ? 'contained' : 'outlined'}
                                                            sx={{ width: '150px', alignSelf: 'center' }}
                                                            onClick={() => handleSendReport(sessionReport)}
                                                        >
                                                            {sessionReport.reportStatus !== 'SENT' ? 'Send report' : 'Re-Send report'}
                                                        </Button>
                                                    ) : null
                                                }
                                            </Property>
                                        ))
                                    }
                                </Day>
                            ) : null
                        )) : (
                            loading ? (
                                <DescriptionText>{`Hang tight! Loading...`}</DescriptionText>
                            ) : (
                                <DescriptionText>
                                    {admin ? `No reports found` : `You are up to date! No session to report on`}
                                </DescriptionText>
                            )
                        )
                }
            </DashboardCard>
        </DashboardContainer >
    );
};

export default Reports;