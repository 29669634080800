import { useEffect, useState } from 'react';
import { formatInTimeZone } from 'date-fns-tz';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import AutoSaveInput from '../../../Components/library/Inputs/AutoSaveInput';
import Card from '../../../Components/library/Cards/Generic';
import Header from '../../../Components/library/Cards/Headers/Standard';
import Footer from '../../../Components/library/Cards/Footers/Action';
import Tag from '../../../Components/library/Tag/Tag';
import Text from '../../../Components/library/Typography/Text';

import Lead from '../../Leads/Lead/LeadDisplay';

import APPS_CONFIG from '../../config';
import { useNavigate } from 'react-router-dom';

export const colorFromString = (str) => {
    const getNumFromString = (s) => (
        ([...new Array(s.length)]).reduce((tot, _, index) => (
            tot + s.charCodeAt(index)
        ), 0)
    );

    const segmentLength = Math.floor(str.length / 3);
    const r = (Math.floor(getNumFromString(str.slice(0, segmentLength)) * 1.5) % 255).toString(16);
    const g = (Math.floor(getNumFromString(str.slice(segmentLength, segmentLength * 2)) * 1.8) % 255).toString(16);
    const b = (Math.floor(getNumFromString(str.slice(segmentLength * 2, segmentLength * 3)) * 1.3) % 255).toString(16);

    return `#${r.length === 2 ? r : `0${r}`}${g.length === 2 ? g : `0${g}`}${b.length === 2 ? b : `0${b}`}`;
};

const FutureViewing = ({
    viewing,
    withProperty = false,
    noActions = false,
    clickable = false,
    onNoteChange = () => { },
    onCancel = () => { },
    displayOnly = false,
}) => {
    const [notes, setNotes] = useState(viewing.notes);
    const [cancelling, setCancelling] = useState(false);
    const [editingNotes, setEditingNotes] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setNotes(viewing.notes);
    }, [viewing.notes]);

    return (
        <Card
            onClick={() => {
                if (clickable) {
                    console.log('navigate to viewings', `/app/viewings/${viewing.Property.id}/upcoming`);
                    navigate(`/app/viewings/${viewing.Property.id}/upcoming`);
                }
            }}
            sx={{ cursor: clickable ? 'pointer' : 'default' }}
            header={
                <Stack>
                    {
                        withProperty ? (
                            <>
                                <Header
                                    // emoji='🏡'
                                    title={
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={1}
                                            sx={{ textAlign: 'center' }}
                                        >
                                            <Text weight="bold" size="medium" span color={'primary.light'}>{viewing.Property.postcode}</Text>
                                            <Text color={'primary.light'} span> - {viewing.Property.address}</Text>
                                        </Stack>
                                    }
                                    action={viewing.Lead.presence || (viewing.Viewer && viewing.Viewer.presence) ? (
                                        <Text>{viewing.Lead.presence || viewing.Viewer.presence}</Text>
                                    ) : null}
                                    sx={{ backgroundColor: APPS_CONFIG.viewings.color }}
                                />
                                <Divider sx={{ borderColor: colorFromString(viewing.Property.id) }} />
                            </>
                        ) : null
                    }
                    {
                        viewing.Lead ? (
                            <Lead
                                lead={viewing.Lead}
                                noEmailPhone={true}
                                sx={{ width: '100%', backgroundColor: APPS_CONFIG.viewings.color, border: 'none', borderRadius: 0 }}
                                color={APPS_CONFIG.viewings.color}
                            />
                        ) : null
                    }
                    {
                        viewing.type === 'BLOCK' ? (
                            <Header
                                title="Block Viewing (1h)"
                                sx={{ backgroundColor: APPS_CONFIG.viewings.color }}
                            />
                        ) : null
                    }
                </Stack>
            }
            withBodyContainer={false}
            body={
                !!notes || notes === '' ? (
                    <Stack
                        gap={3}
                        direction="column"
                        sx={{ p: 2, width: '100%', /* backgroundColor: APPS_CONFIG.viewings.color*/ }}
                    >
                        {
                            noActions ? (
                                <Text multiline>{notes}</Text>
                            ) : (
                                <AutoSaveInput
                                    name="viewing-notes"
                                    label="Viewing Notes (Public to Clem)"
                                    defaultValue={notes}
                                    autoFocus={notes === ''}
                                    multiline
                                    rows={4}
                                    onChange={(value) => {
                                        setEditingNotes(true);
                                        setNotes(value);
                                    }}
                                    placeholder={`Write down some notes for the viewing`}
                                    saveDebounceTime={2000}
                                    onSave={async (value) => {
                                        await onNoteChange(value);
                                        setEditingNotes(false);
                                    }}
                                    statuses={{
                                        saving: (<Tag color="primary.main">💾 Saving...</Tag>),
                                        saved: (<Tag color="primary.main">👍 Saved!</Tag>),
                                        changed: (<Tag color="primary.main">💾 Saving...</Tag>),
                                        default: null,
                                    }}
                                    sx={{ pointerEvents: displayOnly ? 'none' : 'all' }}
                                />
                            )
                        }
                    </Stack>
                ) : null
            }
            footer={
                !noActions ? (
                    <Footer
                        align='flex-end'
                        sx={{
                            pt: 1.5,
                        }}
                        actions={
                            cancelling ? [
                                {
                                    value: (<><Text span>Are you sure you want to <Text weight="bold" span>CANCEL</Text>?</Text></>),
                                },
                                {
                                    button: true,
                                    buttonVariant: 'contained',
                                    value: 'Yes',
                                    onClick: () => {
                                        onCancel();
                                    },
                                    sx: {
                                        opacity: editingNotes ? 0.5 : 1,
                                        pointerEvents: editingNotes ? 'none' : 'all',
                                    },
                                },
                                {
                                    button: true,
                                    buttonVariant: 'outlined',
                                    value: 'No',
                                    onClick: () => {
                                        console.log('cancel');
                                        setCancelling(false);
                                    },
                                    sx: {
                                        opacity: editingNotes ? 0.5 : 1,
                                        pointerEvents: editingNotes ? 'none' : 'all',
                                    },
                                },
                            ] :
                                [
                                    {
                                        button: true,
                                        buttonVariant: 'text',
                                        value: (
                                            <Stack
                                                spacing={1}
                                                direction="row"
                                                alignItems="center"
                                                sx={{ color: 'primary.light' }}
                                            >
                                                <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px' }} />
                                                <Text weight='bold' color="primary.light">Viewing Notes</Text>
                                            </Stack>
                                        ),
                                        onClick: () => {
                                            setNotes('');
                                        },
                                        sx: {
                                            marginRight: 'auto',
                                            transition: 'all 0.2s',
                                            opacity: !!notes || notes === '' ? 0 : 1,
                                            pointerEvents: !!notes || notes === '' ? 'none' : 'all',
                                        },
                                    },
                                    {
                                        button: true,
                                        buttonVariant: 'outlined',
                                        value: 'Cancel',
                                        onClick: () => {
                                            console.log('cancel');
                                            setCancelling(true);
                                        },
                                        sx: {
                                            opacity: editingNotes ? 0.5 : 1,
                                            pointerEvents: editingNotes ? 'none' : 'all',
                                        },
                                    },
                                    // {
                                    //     button: true,
                                    //     buttonVariant: 'outlined',
                                    //     value: 'Re-schedule',
                                    //     onClick: () => {
                                    //         console.log('reschedule');
                                    //     }
                                    // }
                                ]
                        }
                    />
                ) : null
            }
        />
    );
};

export default FutureViewing;