import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';

export const Container = styled('div')(({ theme }) => ({
    height: 'calc(100% - 40px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

export const FieldContainer = styled('div')(({ theme }) => ({
    width: 'calc(100% - 40px)',
    maxWidth: '640px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '22px',
    justifyContent: 'flex-start',
    padding: '20px 20px',
    
    [theme.breakpoints.down(800)]: {
        padding: '20px 60px 20px 0px',
    },

    [theme.breakpoints.down(600)]: {
        padding: '20px 0px',
    }
    // paddingRight: '60px',
}));

export const Header = styled('div')(({ theme }) => ({
    width: '100%',
}));

export const Footer = styled('div')(({ theme }) => ({
    width: '100%',
    height: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gap: '80px',
}));

export const Title = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    fontSize: '26px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '8px',
}));

export const Subtitle = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 'lighter',
    marginBottom: '20px',
}));

export const HalfInputContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
}));

export const AddMoreButton = styled(Button)(({ theme }) => ({
    // alignSelf: 'flex-start',
}));

export const KeysLabelTitle = styled(FormLabel)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '18px',
    marginBottom: '10px',
    fontWeight: 'lighter',
}));

export const RadioButtonsContainer = styled(FormControl)(({ theme }) => ({
    margin: '12px 0px',
}));

export const DateSlotContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: 'calc(50% - 40px)',
    height: '100%',
    overflow: 'auto',
    alignItems: 'center',
    maxHeight: '360px',
}));

export const DateSlotWrapper = styled('div')(({ theme }) => ({
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    alignItems: 'center',
}));

export const DateSlotButton = styled(Button)(({ theme, variant }) => ({
    width: '100%',
    pointerEvents: variant === 'contained' ? 'none' : 'all',

    '& > div > span': {
        fontWeight: 'lighter',
        textTransform: 'none',
        fontSize: '12px',
    },
}));

export const DateSlotInfoContainer = styled('div')(({ theme }) => ({
    padding: '20px',
    width: '100%',
    borderRadius: '20px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid white',
}));

export const PropertyResultContainer = styled('div')(({ theme }) => ({
    height: '100%',
    overflow: 'auto',
}));

export const PropertySelect = styled('div')(({ theme }) => ({
    textAlign: 'center',
}));
