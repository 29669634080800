import { formatDistance, isBefore } from 'date-fns'

export const getData = (report, attr, key) => {
    const obj = report[attr].filter(t => t.key === key);

    if (obj && obj[0] && attr !== 'files') {
        if (attr === 'info') {
            return obj[0].value;
        } else if (attr === 'times') {
            return obj[0].time || obj[0].timeStamp;
        }

        return obj[0];
    } else if (obj && attr === 'files') {
        return obj.map((f) => f.location);
    }

    return null;
};

export const distance = ({ dateStart, timeStart, dateEnd, timeEnd, suffix = true }) => {
    if ((!dateStart && !timeStart) || (!dateEnd && !timeEnd)) {
        return '';
    }

    let start = dateStart;
    if (timeStart && dateEnd) {
        start = `${dateEnd.split('T')[0]}T${timeStart}:00.000Z`;
    } else if (timeStart) {
        start = `2022-01-01T${timeStart}:00.000Z`;
    }

    let end = dateEnd;
    if (timeEnd && dateStart) {
        end = `${dateStart.split('T')[0]}T${timeEnd}:00.000Z`;
    } else if (timeEnd) {
        end = `2022-01-01T${timeEnd}:00.000Z`;
    }

    start = new Date(start);
    end = new Date(end);


    let suffixText = '';

    if (suffix) {
        suffixText = ' before';
        if (isBefore(start, end)) {
            suffixText = ' after';
        }
    }

    return `${formatDistance(start, end)}${suffixText}`;
};

export const distanceFromViewing = ({ date, time, viewingReport, suffix = true, end = false }) => {
    if (!date && !time) {
        return '';
    }

    let viewingTime;
    if (!end) {
        viewingTime = getData(viewingReport, 'times', 'viewing-scheduled-start');
    } else {
        viewingTime = getData(viewingReport, 'times', 'viewing-end');
    }

    return distance({
        ...(end ? { timeStart: viewingTime } : { dateStart: viewingTime}),
        ...(time ? { timeEnd: time } : { dateEnd: date }),
        suffix
    });
};

export const distanceFromViewingStart = ({ date, time, viewingReport, suffix = true }) => {
    return distanceFromViewing({ date, time, viewingReport, suffix });
};

export const distanceFromViewingEnd = ({ date, time, viewingReport, suffix = true }) => {
    return distanceFromViewing({ date, time, viewingReport, suffix, end: true });
};

export const getEmojiForViewingStatus = (report) => (
    {
        'NORMAL': '🤟',
        'PENDING': '⌛',
        'LATE': '🏃',
        'CANCEL': '🙅',
        'NOSHOW': '🤷',
    }[report.viewingStatus] || '-'
);