import DataElem from "../DataElem";
import { getData, distanceFromViewingStart } from "../utils";
import { ViewingList, ViewingSubtitleContainer } from "../SessionReport.styled";
import ViewingCard from "../../Viewing/Card/ViewingCard";
import { formatInTimeZone } from 'date-fns-tz';
import { subMinutes } from 'date-fns';

export default { // eslint-disable-line
    name: 'notified',
    emoji: '📲',
    time: () => {
        return 'Multi';
    },
    title: (reports) => {
        return (
            <>
                <DataElem
                    text={`${reports.viewingReports.length}`}
                    sx={{ marginLeft: 0 }}
                />
                customers notified
            </>
        );
    },
    subtitle: () => {
        return (
            <>
                via
                <DataElem
                    text="Whatsapp"
                />
                or
                <DataElem
                    text="SMS"
                />
            </>
        );
    },
    content: (reports) => {
        return (
            <ViewingList>
                {
                    reports.viewingReports.map((viewingReport) => (
                        <ViewingCard
                            key={`viewing-report-notif-${viewingReport.id}`}
                            simple
                            avatar={'📲'}
                            title={
                                ''
                            }
                            subheader={
                                <ViewingSubtitleContainer>
                                    Notified
                                    <DataElem
                                        text={
                                            distanceFromViewingStart({ time: formatInTimeZone(subMinutes(new Date(getData(viewingReport, 'times', 'viewing-scheduled-start')), 187), 'Etc/UTC', 'kk:mm'), viewingReport })
                                        }
                                    />
                                </ViewingSubtitleContainer>
                            }
                            viewing={{
                                startsAt: getData(viewingReport, 'times', 'viewing-scheduled-start'),
                                Viewer: { name: getData(viewingReport, 'info', 'customer-name') },
                            }}
                        />
                    ))
                }
            </ViewingList>
        );
    },
};