import { styled } from '@mui/material/styles';
import { invert } from '../../theme';

const _Container = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    alignItems: 'center',
    left: 0,
    top: 0,
    overflow: 'auto',
}));

export const Container = _Container;

export const _Header = styled('div')(({ theme }) => ({
    height: '45%',
    minHeight: '340px',
    maxHeight: '420px',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
}));

export const Header = invert(_Header);

export const Title = styled('div')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '40px',
    fontWeight: 'bold',
    marginLeft: '60px',
    marginTop: '180px',

    [theme.breakpoints.down(1100)]: {
        marginLeft: 0,
        width: '100%',
        textAlign: 'center',
    },
}));

export const SubtitleRow = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px',
    alignItems: 'center',
    gap: '20px',
}));

export const SubtitleIcon = styled('div')(({ theme }) => ({
    fontSize: '26px',
    lineHeight: '24px',
    alignSelf: 'flex-start',
    color: theme.palette.secondary.main,
}));

export const SubtitleText = styled('div')(({ theme }) => ({
    fontWeight: 'bold',
    lineHeight: '24px',
}));

export const SubtitleContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    fontSize: '16px',
    padding: '20px',
    paddingBottom: '0px',
    borderRadius: '10px',
    top: '40px',
    maxWidth: '600px',
    position: 'absolute',
    right: '40px',
    boxShadow: theme.shadows[24].replace(/76,52,146/gi, '0,0,0'),

    [theme.breakpoints.down(1100)]: {
        position: 'relative',
        width: 'calc(100% - 40px)',
        right: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '500px',
        paddingBottom: '1px',
    },
}));

export const Logo = styled('img')(({ theme }) => ({
    height: '80px',
    width: '80px',
    position: 'absolute',
    top: '40px',
    left: '50px',

    [theme.breakpoints.down(1100)]: {
        left: 'calc(50% - 40px)',
    }
}));

export const SummaryTitle = styled('div')(({ theme }) => ({
    margin: 0,
    marginBottom: '10px',
    color: theme.palette.text.primary,
    fontSize: '26px',
    fontWeight: 'lighter',
}));

export const Summary = styled('div')(({ theme }) => ({
    width: '450px',
    maxWidth: '450px',
    border: `1px solid ${theme.palette.background.paper}`,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    borderRadius: '10px',
    padding: '20px',
    paddingBottom: 0,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[24],
    marginLeft: '40px',
    position: 'sticky',
    top: '40px',
    height: 'fit-content',
    maxHeight: 'calc(100vh - 80px)',
    overflow: 'auto',

    [theme.breakpoints.down(1100)]: {
        position: 'relative',
        width: 'calc(100% - 40px)',
        maxWidth: 'none',
        marginLeft: '20px',
        top: 'auto',
        marginTop: '40px',
        overflow: 'auto',
        maxHeight: 'none',
    },
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'absolute',
    top: 'max(30%, 280px)',
    width: '100%',
    gap: '40px',

    [theme.breakpoints.down(1100)]: {
        top: '500px',
        flexDirection: 'column',
    },
}));

export const Content = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[24],
    width: 'calc(100% - 570px)',
    borderRadius: '10px',
    padding: '0 20px',

    [theme.breakpoints.down(1100)]: {
        width: 'calc(100% - 40px)',
        marginLeft: '20px',
    },
}));

export const Footer = styled('div')(({ theme }) => ({
    marginBottom: '40px',
    color: theme.palette.text.primary,
    width: '100%',
    textAlign: 'center',
}));