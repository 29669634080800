import { useState, useEffect } from 'react';
import Fuse from 'fuse.js';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HeaderStatusSelector from '../../../Components/library/Cards/Headers/StatusSelector';
import Tag from '../../../Components/library/Tag/Tag';
import RightPanel from '../../../Components/library/Layout/RightPanel';

import APPS_CONFIG from '../../config';

import PastViewings from './Past';
import UpcomingViewings from './Upcoming';

const fuseOptions = {
    includeScore: true,
    minMatchCharLength: 2,
    keys: ['name']
};

const ViewingsList = ({
    viewings = { PAST: [], UPCOMING: [] },
    propertyAddress = '',
    view = 'PAST',
    onAddViewing = () => { },
    onNoteChange = () => { },
    onCancel = () => { },
    onBack = () => { },
    onSelectView = () => { },
}) => {
    const [fuse, setFuse] = useState(null);
    const [filteredViewings, setFilteredViewings] = useState(viewings);

    useEffect(() => {
        setFuse({
            PAST: new Fuse(viewings.PAST, fuseOptions),
            UPCOMING: new Fuse(viewings.UPCOMING, fuseOptions),
        });
        setFilteredViewings(viewings);
    }, [viewings]);

    return (
        <RightPanel
            title={<><img src={APPS_CONFIG.viewings.logo} width="20px" /> Viewings</>}
            header={
                <>
                    <HeaderStatusSelector
                        currentStatus={view}
                        onSelect={(status) => {
                            onSelectView(status);
                        }}
                        statuses={
                            [{
                                status: 'PAST',
                                title: '✅ Past',
                            },
                            {
                                status: 'UPCOMING',
                                title: '👀 Upcoming',
                            }]
                        }
                        statusCount={{
                            PAST: filteredViewings.PAST.length,
                            UPCOMING: filteredViewings.UPCOMING.length,
                        }}
                    />
                    <Stack sx={{ width: '100%', height: 'fit-content' }}>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{ width: 'fit-content', m: 2.5, ml: 2.5, mt: 0 }}
                            onClick={() => onAddViewing()}
                        >
                            <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                            Book a Viewing
                        </Button>
                    </Stack>
                </>
            }
            tags={[
                <Tag key="lead-tag-addr">
                    {propertyAddress}
                </Tag>,
            ]}
            onBack={onBack}
            body={
                view === 'PAST' ? (
                    <PastViewings reports={viewings.PAST} />
                ) : (
                    <UpcomingViewings
                        viewings={viewings.UPCOMING}
                        onNoteChange={onNoteChange}
                        onCancel={onCancel}
                        onAddViewing={onAddViewing}
                    />
                )
            }
        />
    );
};

export default ViewingsList;