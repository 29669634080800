import { useEffect, useState } from 'react';
import { format, isSameDay } from 'date-fns';
import { useSearchParams } from "react-router-dom";
import { API } from 'aws-amplify';
import * as queries from '../../../../graphql/queries';

import Lead from '../../../../data/lead';

import Stack from '@mui/material/Stack';

import Card from '../../../../Components/library/Cards/Generic';
import Header from '../../../../Components/library/Cards/Headers/Standard';
import Footer from '../../../../Components/library/Cards/Footers/Action';
import Tag from '../../../../Components/library/Tag/Tag';
import Text from '../../../../Components/library/Typography/Text';
import ControlledInput from '../../../../Components/Input/ControlledInput';
import RightPanel from '../../../../Components/library/Layout/RightPanel';

import CalendarSlots from './CalendarSlots';
import Customer from './Customer';
import PropertySelector from './Property';
import { CircularProgress } from '@mui/material';
import Keys from '../../../Keys/Keys';
import ViewingTypeSelector from './ViewingTypeSelector';

const NewViewing = ({
    property,
    properties,
    viewings,
    day,
    time,
    onBack = () => { },
    onBook = () => { },
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [lead, setLead] = useState(null);
    const [isComplete, setIsComplete] = useState(false);
    const [loadingBook, setLoadingBook] = useState(false);
    const [timeError, setTimeError] = useState(null);
    const [currentKeys, setCurrentKeys] = useState(null);

    const [viewingType, setViewingType] = useState(null);

    const [formData, setFormData] = useState({
        calendar: {
            dateTime: {
                value: '',
                valid: false,
            },
        },
        customer: {
            name: {
                value: '',
                valid: false,
            },
            email: {
                value: '',
                valid: false,
            },
            phone: {
                value: '',
                valid: false,
            },
        },
        keys: {
            id: {
                value: '',
                valid: false,
            },
        },
        info: {
            notes: {
                value: '',
                valid: true,
            },
        },
        property: {
            id: {
                value: property ? property.id : '',
                valid: !!property,
            },
            postcode: {
                value: property ? property.postcode : '',
                valid: !!property,
            },
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            if (searchParams.get('leadId')) {
                const _lead = await Lead.get(searchParams.get('leadId'));
                setLead(_lead);
                setFormData({
                    ...formData,
                    customer: {
                        name: { value: _lead.name, valid: true, },
                        email: { value: _lead.email, valid: true, },
                        phone: { value: _lead.phone, valid: true, },
                    },
                    leadId: searchParams.get('leadId'),
                });
            }
        };

        fetchData();
    }, []);

    const handleFormData = (data = []) => {
        const newFormData = data.reduce((tot, { section, key, value, valid }) => ({
            ...tot,
            [section]: {
                ...tot[section],
                [key]: {
                    value,
                    valid,
                },
            },
        }), { ...formData });

        setFormData(newFormData);

        setIsComplete(
            (viewingType === 'BLOCK' || Object.keys(newFormData.customer).reduce((tot, key) => (tot && newFormData.customer[key].valid), true)) &&
            Object.keys(newFormData.calendar).reduce((tot, key) => (tot && newFormData.calendar[key].valid), true) &&
            Object.keys(newFormData.keys).reduce((tot, key) => (tot && newFormData.keys[key].valid), true) &&
            Object.keys(newFormData.info).reduce((tot, key) => (tot && newFormData.info[key].valid), true) &&
            Object.keys(newFormData.property).reduce((tot, key) => (tot && newFormData.property[key].valid), true)
        );
    };

    const handleChangeDateTime = (data) => {
        if (data.dateTime.valid) {
            setTimeError(null);
        }

        let newFormData = [{
            section: 'calendar',
            key: 'dateTime',
            value: data.dateTime.value,
            valid: data.dateTime.valid,
        }];

        const res = viewings.find((v) => (
            isSameDay(new Date(v.startsAt), new Date(data.dateTime.value)) &&
            v.propertyId === formData.property.id.value
        ));
        if (res && res.keysId) {
            setCurrentKeys(res.keysId);
            newFormData = [
                ...newFormData,
                {
                    section: 'keys',
                    key: 'id',
                    value: res.keysId,
                    valid: true,
                }
            ];
        } else {
            setCurrentKeys(null);
            newFormData = [
                ...newFormData,
                {
                    section: 'keys',
                    key: 'id',
                    value: '',
                    valid: false,
                }
            ];
        }

        handleFormData(newFormData);
    };

    return (
        <RightPanel
            title={`🆕 Book a Viewing${viewingType ? ` - ${viewingType}` : ''}`}
            tags={[
                formData.calendar.dateTime.value ?
                    <Tag key="lead-tag-date">
                        {format(new Date(formData.calendar.dateTime.value), 'MMMM do HH:mm')}
                    </Tag> : null,
            ]}
            onBack={onBack}
            body={
                viewingType ? (
                    <Stack
                        gap={2.5}
                        sx={{
                            p: 2.5,
                            maxHeight: '100%',
                            height: '100%',
                            overflow: 'auto',
                        }}
                    >
                        <Card
                            sx={{ maxHeight: '400px' }}
                            header={
                                <Header
                                    emoji="🏡"
                                    title="Property"
                                    titleSize="medium"
                                    sx={{ backgroundColor: 'secondary.light' }}
                                    tags={[
                                        <Tag
                                            backgroundColor="background.default"
                                            key={`tag-valid`}
                                        >
                                            {
                                                formData.property.id.valid ? '✅' : '🟠 Incomplete'
                                            }
                                        </Tag>,
                                    ]}
                                />
                            }
                            withBodyContainer={false}
                            body={
                                <PropertySelector
                                    displayOnly={!!property}
                                    propertyId={formData.property.id.valid ? formData.property.id.value : null}
                                    properties={properties}
                                    onSelectProperty={(property) => {
                                        handleFormData([
                                            {
                                                section: 'property',
                                                key: 'id',
                                                value: property.id,
                                                valid: true,
                                            },
                                            {
                                                section: 'property',
                                                key: 'postcode',
                                                value: property.postcode,
                                                valid: true,
                                            },
                                            {
                                                section: 'keys',
                                                key: 'id',
                                                value: '',
                                                valid: false,
                                            },
                                        ])
                                    }}
                                />
                            }
                        />
                        <Card
                            header={
                                <Header
                                    emoji="⏰"
                                    title="Time Slot"
                                    titleSize="medium"
                                    sx={{ backgroundColor: 'secondary.light' }}
                                    tags={[
                                        <Tag
                                            backgroundColor="background.default"
                                            key={`tag-valid`}
                                        >
                                            {
                                                formData.calendar.dateTime.valid ? '✅' : '🟠 Incomplete'
                                            }
                                        </Tag>,
                                    ]}
                                />
                            }
                            withBodyContainer={false}
                            body={
                                <CalendarSlots
                                    postcode={formData.property.id.valid ? (
                                        (properties.find(p => p.id === formData.property.id.value) || {}).postcode
                                    ) : null}
                                    viewings={(viewings || []).filter((v) => v.propertyId === formData.property.id.value)}
                                    targetDay={day}
                                    targetTime={time}
                                    onUpdate={handleChangeDateTime}
                                    error={timeError}
                                    viewingType={viewingType}
                                />
                            }
                        />
                        {
                            viewingType === 'SINGLE' ? (
                                <Card
                                    header={
                                        <Header
                                            emoji="🧑"
                                            title="Customer Information"
                                            sx={{ backgroundColor: 'secondary.light' }}
                                            titleSize="medium"
                                            tags={[
                                                <Tag
                                                    backgroundColor="background.default"
                                                    key={`tag-valid`}
                                                >
                                                    {
                                                        Object.keys(formData.customer).reduce((tot, key) => (tot && formData.customer[key].valid), true)
                                                            ? '✅' : '🟠 Incomplete'
                                                    }
                                                </Tag>,
                                            ]}
                                        />
                                    }
                                    withBodyContainer={false}
                                    body={
                                        <Customer
                                            lead={lead}
                                            handleFormData={(section, key, value, valid) => handleFormData([{ section, key, value, valid }])}
                                        />
                                    }
                                />
                            ) : null
                        }
                        <Stack
                            sx={{
                                opacity: !formData.property.id.valid || !formData.calendar.dateTime.valid || currentKeys ? 0.7 : 1,
                                pointerEvents: !formData.property.id.valid || !formData.calendar.dateTime.valid || currentKeys ? 'none' : 'all',
                            }}
                        >
                            <Keys
                                propertyId={formData.property.id.value}
                                withDeactivatedKeys={false}
                                selectable={!currentKeys}
                                deactivatable={false}
                                selectedOnly={!!currentKeys}
                                headerProps={{
                                    tags: [
                                        ...(
                                            currentKeys ? [
                                                <Tag
                                                    backgroundColor="background.default"
                                                    key={`tag-existing-keys`}
                                                >
                                                    From a viewing that day
                                                </Tag>
                                            ] : []
                                        ),
                                        <Tag
                                            backgroundColor="background.default"
                                            key={`tag-valid`}
                                        >
                                            {
                                                formData.keys.id.valid ? '✅' : '🟠 Incomplete'
                                            }
                                        </Tag>,
                                    ],
                                    titleSize: 'medium',
                                }}
                                selectedId={formData.keys.id.value}
                                onSelect={(keys) => {
                                    handleFormData(
                                        [{
                                            section: 'keys',
                                            key: 'id',
                                            value: keys.id,
                                            valid: true,
                                        }]
                                    );
                                }}
                            />
                        </Stack>
                        <Card
                            header={
                                <Header
                                    emoji="ℹ️"
                                    title="Info"
                                    titleSize="medium"
                                    sx={{ backgroundColor: 'secondary.light' }}
                                    tags={[
                                        <Tag
                                            backgroundColor="background.default"
                                            key={`tag-valid`}
                                        >
                                            ✅
                                        </Tag>,
                                    ]}
                                />
                            }
                            body={
                                <ControlledInput
                                    size="small"
                                    name="book-viewing-info"
                                    label="Additional Information"
                                    placeholder="Information specific to this viewing"
                                    multiline
                                    rows={3}
                                    onChange={(value, valid) => handleFormData(
                                        [{
                                            section: 'info',
                                            key: 'notes',
                                            value,
                                            valid,
                                        }]
                                    )}
                                />
                            }
                        />
                    </Stack >
                ) : (
                    <ViewingTypeSelector
                        onSelect={(type) => setViewingType(type)}
                    />
                )
            }
            footer={
                viewingType ? (
                    <Footer
                        align='flex-end'
                        sx={{
                            pt: 1.5,
                        }}
                        actions={
                            [
                                {
                                    button: true,
                                    buttonVariant: 'outlined',
                                    value: 'Cancel',
                                    onClick: () => {
                                        onBack();
                                    },
                                    sx: {
                                        pointerEvents: !loadingBook ? 'all' : 'none',
                                        opacity: !loadingBook ? 1 : 0.5,
                                    },
                                },
                                {
                                    button: true,
                                    buttonVariant: 'contained',
                                    value: !loadingBook ? 'Book' : <CircularProgress color="secondary" size="22px" />,
                                    sx: {
                                        pointerEvents: isComplete ? 'all' : 'none',
                                        opacity: isComplete ? 1 : 0.5,
                                    },
                                    onClick: async () => {
                                        setLoadingBook(true);
                                        const postcode = formData.property.id.valid ? (
                                            (properties.find(p => p.id === formData.property.id.value) || {}).postcode
                                        ) : null;

                                        if (postcode && formData.calendar.dateTime.valid) {
                                            const res = await API.graphql({
                                                query: queries.bookingAvailableTime,
                                                variables: {
                                                    date: formData.calendar.dateTime.value.toISOString(),
                                                    postcode: postcode.toUpperCase().replace(/\s/g, ''),
                                                    type: viewingType,
                                                    viewingLength: viewingType === 'BLOCK' ? 60 : 15,
                                                },
                                            });

                                            const slots = JSON.parse(res.data.bookingAvailableTime);
                                            if (slots.availableCalendar[format(formData.calendar.dateTime.value, 'HH:mm')]) {
                                                console.log('book');
                                                console.log(formData);
                                                onBook({ ...formData, viewingType });
                                            } else {
                                                console.log('time taken');
                                                setTimeError(`Snap! ${format(formData.calendar.dateTime.value, 'HH:mm')} is not available anymore - Please select a new time or date`);
                                                handleFormData([{
                                                    section: 'calendar',
                                                    key: 'dateTime',
                                                    value: '',
                                                    valid: false,
                                                }]);
                                            }
                                        }
                                        setLoadingBook(false);
                                    }
                                }
                            ]}
                    />
                ) : null
            }
        />
    );
};

export default NewViewing;