import { useEffect, useState } from 'react';
import Chat from './Chat';
import Text from '../../Components/library/Typography/Text';
import TwoPanelsLayout from "../../Components/library/Layout/TwoPanels";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Card from '../../Components/library/Cards/Generic';
import Header from '../../Components/library/Cards/Headers/Standard';
import { HeaderContainer } from '../../Components/AgentReport/AgentReportRenderer.styled';
import ActionFooter from '../../Components/library/Cards/Footers/Action';
import Tag from '../../Components/library/Tag/Tag';
import ChatInto from './Into';
import PropertyCard from './PropertyCard';
import CompletionCard from './CompletionCard';
import { createMessage } from './messages/utils';
import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { useNavigate, useParams } from 'react-router-dom';

const queryAI = async (op, payload) => {
    console.log(payload);
    return JSON.parse((await API.graphql({
        query: queries.aiPrequal,
        variables: {
            op,
            payload: JSON.stringify({ ...payload, model: 'gpt-3.5-turbo' }),
        },
    })).data.aiPrequal);
};

const ClemPage = () => {
    const [messages, setMessages] = useState([]);
    const [waitingResponse, setWaitingResponse] = useState(true);
    const [hasStarted, setHasStarted] = useState(false);
    const [agentView, setAgentView] = useState(false);

    const params = useParams();
    const prequalId = params['*'].startsWith('conversation/') ? params['*'].split('/')[1] : null;

    const init = async () => {
        if (prequalId) {
            const newMessages = await queryAI('chat', { prequalId: prequalId });
            console.log(newMessages);
            setMessages([
                ...messages,
                ...newMessages,
            ]);

            setWaitingResponse(false);
        }
    };

    const sendMessage = async (message, answerTo = null) => {
        setWaitingResponse(true);

        const newMessages = [
            ...messages,
            createMessage({
                role: "user",
                raw: message,
                content: JSON.stringify({ message, answerTo }),
            }),
        ];

        setMessages([
            ...newMessages,
            createMessage({
                role: "system",
                raw: 'Thinking...',
                content: 'Thinking...',
            }),
        ]);

        const newAnswers = await queryAI('chat', { prequalId: prequalId, message });

        setMessages([
            ...newMessages,
            ...newAnswers,
        ]);

        setWaitingResponse(false);
    };

    return (
        <TwoPanelsLayout
            leftPanel={
                <Card
                    sx={{
                        width: '800px',
                        backgroundColor: 'background.default',
                    }}
                    header={
                        <HeaderContainer
                            sx={{ flexDirection: 'column' }}
                        >
                            <Header
                                emoji={agentView ? '🧑' : '👋'}
                                title={agentView ? `John is ${!messages.find((m) => m.role === 'assistant' && m.parsed.command.name === 'task_complete') ? 'In Progress' : messages.find((m) => m.role === 'assistant' && m.parsed.command.name === 'task_complete').parsed.command.args.reason.qualified ? 'Qualified' : 'Rejected'}` : 'Hello John!'}
                                sx={{ width: '100%' }}
                                tags={[
                                    <Tag key="demo">Demo</Tag>,
                                    <Button
                                        key="switch-view"
                                        // size="small"
                                        variant="outlined"
                                        sx={{
                                            width: 'fit-content',
                                        }}
                                        onClick={() => setAgentView(!agentView)}
                                    >
                                        Switch to
                                        {!agentView ? <Tag sx={{ marginLeft: '12px' }}>Agent View</Tag> : <Tag sx={{ marginLeft: '12px' }}>Customer View</Tag>}
                                    </Button>
                                ]}
                            />
                            {
                                hasStarted ? (
                                    <Stack
                                        direction="row"
                                    >
                                        {
                                            agentView ? (
                                                <CompletionCard
                                                    messages={messages}
                                                />
                                                // <Text>done: true/false</Text>
                                            ) : (
                                                <PropertyCard
                                                    sx={{
                                                        maxWidth: '500px',
                                                        margin: '0px 20px 20px 20px',
                                                    }}
                                                />
                                            )
                                        }
                                    </Stack>
                                ) : null
                            }
                        </HeaderContainer>
                    }
                    withBodyContainer={false}
                    body={
                        hasStarted ? (
                            <Chat
                                onSendMessage={sendMessage}
                                disabled={waitingResponse}
                                messages={messages}
                                isAgent={agentView}
                            />
                        ) : (
                            <ChatInto
                                start={() => {
                                    setHasStarted(true);
                                    init();
                                }}
                            />
                        )

                    }
                />
            }
            rightPanel={
                <Stack
                    sx={{
                        height: '100%',
                        overflow: 'scroll',
                    }}
                >
                    {/* <LocalConversations
                        messages={messages}
                        newConversation={() => {
                            setMessages([
                                { role: 'system', raw: PREQUAL_DEFAULT_SYSTEM_PROMPT, content: 'sysInfo' },
                            ]);
                            setWaitingResponse(true);
                            setHasStarted(false);
                            setAgentView(false);
                        }}
                        loadMessages={(_messages) => {
                            setMessages(_messages);
                            setHasStarted(true);
                            setWaitingResponse(false);
                        }}
                    /> */}
                </Stack>
            }
            sxSecondary={{
                minWidth: '600px',
                width: '100%',
                maxWidth: '800px',
            }}
            showRightPanel={false}
        />
    );
};

export default ClemPage;