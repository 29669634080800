import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const DateSlotContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    // width: 'calc(50% - 40px)',
    // height: '100%',
    overflow: 'auto',
    alignItems: 'center',
    height: '400px',
}));

export const DateSlotWrapper = styled('div')(({ theme }) => ({
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    alignItems: 'center',
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: '12px',
    borderRadius: '12px',
}));

export const DateSlotButton = styled(Button)(({ theme, variant }) => ({
    width: 'calc(100% - 40%)',
    pointerEvents: variant === 'contained' ? 'none' : 'all',

    '& > div > span': {
        fontWeight: 'lighter',
        textTransform: 'none',
        fontSize: '12px',
    },
}));
