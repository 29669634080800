import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import Card from "../../../Components/library/Cards/Generic";
import Header from '../../../Components/library/Cards/Headers/Standard';
import Footer from '../../../Components/library/Cards/Footers/Action';
import Text from "../../../Components/library/Typography/Text";
import Tag from "../../../Components/library/Tag/Tag";

import MarketPlacesIntegrations from "./MarketPlaces";

import APPS_CONFIG from "../../config";

import {
    BackButton,
    CardTitleText,
} from '../../Leads/leads.styled';

const validatePropertyData = (propertyData) => {
    const marketPlaces = propertyData.marketPlaces || [{ valid: false }];

    const validField = Object.keys(propertyData).filter(k => k !== 'marketPlaces').reduce((final, curr) => (final && propertyData[curr].valid), true);
    const validMarketPlaces = marketPlaces.reduce((final, curr) => (final && curr.valid), true);

    return validField && validMarketPlaces;
};

const UserIntegrations = ({
    integrations,
    color = 'secondary.light',
    onSave = () => { },
    onCancel = () => { },
    onBack = () => { },
    create = false,
}) => {
    const navigate = useNavigate();
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [propertyData, setPropertyData] = useState({});

    useEffect(() => {
        if (!create) {
            setEditing(false);
            setSaved(false);
            setPropertyData({});
        }
    }, []);

    return (
        <Card
            sx={{
                backgroundColor: 'background.default',
                pointerEvents: !saving ? 'all' : 'none',
                opacity: !saving ? 1 : 0.7,
                height: '100%',
            }}
            header={
                <Header
                    title={
                        <Stack
                            direction="row"
                            alignItems="center"
                        >
                            <BackButton
                                sx={{ padding: 0 }}
                                onClick={onBack}
                            >
                                <ArrowBackOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                                Back
                            </BackButton>
                            <CardTitleText size="large" weight="bold" sx={{
                                flexShrink: 0,
                                display: 'flex',
                                alignItems: 'center',
                                alignSelf: 'center',
                                textAlign: 'center',
                                pointerEvents: 'none',
                                gap: 1,
                            }}>🔗 Integrations</CardTitleText>
                        </Stack>
                    }
                    tags={[
                        ...(integrations ? [
                            <Tag
                                color="primary.main"
                                key={`tag-integrations`}
                            >
                                {`${integrations.length} Integration${integrations.length === 1 ? '' : 's'}`}
                            </Tag>
                        ] : []),
                        ...(!create && editing ? [
                            <Tag
                                color="primary.main"
                                key={`tag-editing`}
                            >
                                🖊️ Editing
                            </Tag>
                        ] : []),
                        ...(saving ? [
                            <Tag
                                color="primary.main"
                                key={`tag-saving`}
                            >
                                💾 Saving
                            </Tag>
                        ] : []),
                        ...(!editing && !saving && saved ? [
                            <Tag
                                color="primary.main"
                                key={`tag-saved`}
                            >
                                👍 Saved
                            </Tag>
                        ] : []),
                    ]}
                />
            }
            withBodyContainer={false}
            body={
                <Stack
                    gap={2.5}
                    sx={{
                        p: 2.5,
                        maxHeight: '100%',
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    <MarketPlacesIntegrations
                        integrations={integrations}
                        onSave={async (data) => await onSave('marketplace', data)}
                    />
                    {/* <Card
                        header={
                            <Header
                                emoji="🏭"
                                title="Marketplaces"
                                sx={{ backgroundColor: APPS_CONFIG.user.color }}
                            />
                        }
                        body={
                            <Stack>
                                {
                                    integrations && integrations.length ? (
                                        <Stack>
                                            {
                                                integrations.map((integration) => (
                                                    <MarketPlaceIntegration
                                                        integration={integration}
                                                        key={integration.id}
                                                    />
                                                ))
                                            }
                                        </Stack>
                                    ) : (
                                        <Text
                                            color="primary.light"
                                            span
                                            sx={{ width: '100%', textAlign: 'center', pt: 2, pb: 2 }}
                                        >
                                            No <Text weight="bold" span>MARKETPLACE INTEGRATIONS</Text>
                                            <Text color="primary.light">Add one below</Text>
                                        </Text>
                                    )
                                }
                            </Stack>
                        }
                        footer={
                            <Footer
                                align='flex-end'
                                actions={[
                                    {
                                        button: true,
                                        buttonVariant: 'text',
                                        value: (
                                            <Stack
                                                spacing={1}
                                                direction="row"
                                                alignItems="center"
                                                sx={{ color: 'primary.light' }}
                                            >
                                                <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px' }} />
                                                <Text weight='bold' color="primary.light">Marketplace</Text>
                                            </Stack>
                                        ),
                                        onClick: () => {
                                            // setNotes('');
                                        },
                                        sx: {
                                            marginRight: 'auto',
                                            // transition: 'all 0.2s',
                                            // opacity: !!notes || notes === '' ? 0 : 1,
                                            // pointerEvents: !!notes || notes === '' ? 'none' : 'all',
                                        },
                                    },
                                    {
                                        button: true,
                                        buttonVariant: 'contained',
                                        value: 'Save',
                                        onClick: async () => {
                                            setEditing(false);
                                            setSaving(true);

                                            // await onSave('newProperty', propertyData);

                                            setSaving(false);
                                            setSaved(true);
                                        },
                                        sx: {
                                            ...(validatePropertyData(propertyData) ? {
                                                opacity: 1,
                                                pointerEvents: 'all',
                                            } : {
                                                opacity: 0.3,
                                                pointerEvents: 'none',
                                                cursor: 'not-allowed',
                                            }),
                                        },
                                    },
                                ]}
                            />
                        }
                    /> */}
                </Stack>
            }
            footer={
                create ? (
                    <Footer
                        align='flex-end'
                        actions={[
                            {
                                button: true,
                                buttonVariant: 'outlined',
                                value: 'Cancel',
                                onClick: onCancel,
                            },
                            {
                                button: true,
                                buttonVariant: 'contained',
                                value: 'Save',
                                onClick: async () => {
                                    setEditing(false);
                                    setSaving(true);
                                    await onSave('newProperty', propertyData);
                                    setSaving(false);
                                    setSaved(true);
                                },
                                sx: {
                                    ...(validatePropertyData(propertyData) ? {
                                        opacity: 1,
                                        pointerEvents: 'all',
                                    } : {
                                        opacity: 0.3,
                                        pointerEvents: 'none',
                                        cursor: 'not-allowed',
                                    }),
                                },
                            },
                        ]}
                    />
                ) : null
            }
        />
    );
};

export default UserIntegrations;