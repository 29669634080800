import { formatInTimeZone } from "date-fns-tz";

import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Session from "../../Clem/Sessions/Session";
import SessionInfo from "./SessionInfo";
import Text from "../../../Components/library/Typography/Text";
import Card from "../../../Components/library/Cards/Generic";
import Header from "../../../Components/library/Cards/Headers/Standard";
import Tag from "../../../Components/library/Tag/Tag";
import APPS_CONFIG from "../../config";
import { useState } from "react";
import LeadDisplay from "../../Leads/Lead/LeadDisplay";
import FutureViewing from "../../Viewings/Viewing/Future_v2";
import Keys from "../../Keys/Keys";
import { addMinutes } from "date-fns";
import KeysDisplay from "../../Keys/KeysDisplay";

const Pill = ({ left, right }) => (
    <Stack
        direction="row"
        sx={{ borderRadius: '4px', overflow: 'hidden', border: '1px solid gray', borderColor: 'primary.light', width: 'fit-content' }}
    >
        <Text color="primary.main" sx={{ backgroundColor: 'secondary.main', padding: '4px 8px' }}>{left}</Text>
        <Text weight="bold" sx={{ backgroundColor: 'secondary.light', padding: '4px 8px' }}>{right}</Text>
    </Stack>
);

const SessionAdmin = ({
    session,
    clems,
    reassignSessionToClem,
    updateSessionInfo,
}) => {
    const [colapsed, setColapsed] = useState(true);
    const [clemView, setClemView] = useState(false);

    const emoji = () => {
        if (!session.id) {
            return '👻';
        } else if (!session.clem) {
            return '⚠️';
        } else if (session.viewings && (session.viewings[0].keys === 'keynest' || session.viewings[0].keys === 'other') && !session.keys) {
            return '⚠️';
        }

        return '✅';
    }

    console.log(session);
    const cancelled = !session.viewings.find(v => !v.cancelled);

    return (
        <Stack
            sx={{ position: 'relative' }}
        >
            <Card
                sx={{ opacity: !session.id ? 0.7 : 1 }}
                header={
                    clemView ? (
                        <Stack>
                            <Text
                                weight="bold"
                                color="primary.contrastText"
                                sx={{ p: 1.5, textAlign: 'center', cursor: 'pointer', backgroundColor: 'primary.main' }}
                                onClick={() => setClemView(false)}
                            >Back to Admin View</Text>
                            <Session
                                session={session}
                            />
                        </Stack>
                    ) : (
                        <Header
                            emoji={emoji()}
                            title={
                                <Stack
                                    gap={1}
                                >
                                    <Text
                                        size="large"
                                        weight="bold"
                                        color={session.clem ? 'primary.main' : 'primary.contrastText'
                                        }
                                    >
                                        {`${session.property.address} - ${session.property.postcode}`}
                                    </Text >
                                    <Stack
                                        direction="column"
                                        flexWrap="wrap"
                                        gap={0.5}
                                    >
                                        {
                                            session.clem ? (
                                                <Pill left="CLEM" right={clems.find(c => c.id === session.clem.id).name} />
                                            ) : (
                                                <Pill left="CLEM" right="⚠️ Missing" />
                                            )
                                        }
                                        {
                                            session.info ? (
                                                <Pill left="ADDITIONAL INFO" right="✅" />
                                            ) : null
                                        }
                                        <KeysDisplay
                                            selected={false}
                                            selectable={false}
                                            deactivatable={false}
                                            keys={session.keysObj}
                                        />
                                    </Stack>
                                </Stack >
                            }
                            actions={
                                [
                                    {
                                        value: 'See Clem View',
                                        onClick: () => {
                                            setClemView(true)
                                        },
                                    }
                                ]}
                            onClick={() => setColapsed(!colapsed)}
                            sx={{
                                backgroundColor: !session.id ? '#eee' : (session.clem ? APPS_CONFIG.user.color : '#ff6969'),
                                cursor: 'pointer',
                            }}
                        />
                    )
                }
                withBodyContainer={false}
                body={
                    !colapsed && !clemView ? (
                        <Stack>
                            {
                                session.id ? (
                                    <Stack
                                        sx={{ p: 1.5, pb: 2.5 }}
                                        gap={1.5}
                                    >
                                        {
                                            clems ? (
                                                <FormControl sx={{ minWidth: 120 }} size="small">
                                                    <InputLabel id="clem-selector">Clem</InputLabel>
                                                    <Select
                                                        labelId="clem-selector"
                                                        id="clem-selector"
                                                        value={session.clem ? session.clem.id : ''}
                                                        label="Clem"
                                                        onChange={(e) => {
                                                            reassignSessionToClem(e.target.value, session.id);
                                                            console.log(e.target.value);
                                                        }}
                                                    >
                                                        {
                                                            clems.map((clem, index) => (
                                                                <MenuItem key={`${session.id}${clem.id}`} value={clem.id}>
                                                                    {clem.name}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                        <MenuItem value="">
                                                            <em>Not assigned yet</em>
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            ) : null
                                        }
                                        <SessionInfo
                                            admin={true}
                                            keys={session.keys}
                                            info={session.info}
                                            onSave={(value) => updateSessionInfo(value, session.id, session)}
                                        />
                                    </Stack>
                                ) : null
                            }
                            {
                                session.id ? <Divider /> : null
                            }
                            <Stack
                                sx={{ p: 1.5, pt: 2.5 }}
                                gap={1.5}
                            >
                                {
                                    session.agent ? (
                                        <Stack
                                            gap={1.5}
                                            direction="row"
                                            alignItems="center"
                                        >
                                            <Text weight="bold" size="medium">Agent</Text>
                                            <LeadDisplay
                                                sx={{ width: '100%', flexShrink: 1 }}
                                                noEmailPhone
                                                lead={{
                                                    name: session.agent.name,
                                                    phone: session.agent.phone,
                                                    email: session.agent.email,
                                                }}
                                            />
                                        </Stack>
                                    ) : null
                                }
                                {
                                    session.property.occupied || session.property.info ? (
                                        <Card
                                            header={<Text weight="bold" size="medium" sx={{ p: 1.5, backgroundColor: 'secondary.light' }}>Property</Text>}
                                            body={
                                                <>
                                                    {
                                                        session.property.occupied ? (
                                                            <Text weight="bold" size="medium">The Property is OCCUPIED</Text>
                                                        ) : null
                                                    }
                                                    {
                                                        session.property.info ? (
                                                            <Stack
                                                                gap={1}
                                                            >
                                                                <Text weight="bold" size="medium">Property Notes</Text>
                                                                <Text multiline sxContainer={{ gap: 0 }} sx={{ pl: 1.5 }}>{session.property.info}</Text>
                                                            </Stack>
                                                        ) : null
                                                    }
                                                </>
                                            }
                                        />
                                    ) : null
                                }
                                <Card
                                    header={<Text weight="bold" size="medium" sx={{ p: 1.5, backgroundColor: 'secondary.light' }}>Viewings</Text>}
                                    body={
                                        session.viewings.map((viewing, idx) => (
                                            <Stack
                                                key={`i-${idx}`}
                                                sx={{ position: 'relative' }}
                                            >
                                                <Stack
                                                    direction="row"
                                                >
                                                    <Stack sx={{ flexShrink: 0, p: 1.5, pl: 0, width: '64px' }}>
                                                        <Text>
                                                            {formatInTimeZone(new Date(viewing.startsAt), 'Etc/UTC', 'kk:mm')}
                                                        </Text>
                                                        <Text>
                                                            {formatInTimeZone(addMinutes(new Date(viewing.startsAt), viewing.type === 'BLOCK' ? 60 : 15), 'Etc/UTC', 'kk:mm')}
                                                        </Text>
                                                    </Stack>
                                                    <Stack
                                                        sx={{ width: '100%' }}
                                                    >
                                                        <FutureViewing
                                                            key={`i-${idx}`}
                                                            noActions
                                                            // withProperty
                                                            viewing={{
                                                                ...viewing,
                                                                Lead: {
                                                                    ...viewing.viewer,
                                                                    id: session.id ? session.id : '---',
                                                                },
                                                                Property: {
                                                                    ...session.property,
                                                                    id: session.id ? session.id : '---',
                                                                },
                                                            }}
                                                        />
                                                    </Stack>
                                                    {
                                                        viewing.cancelled ? (
                                                            <Stack
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                sx={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    backgroundColor: 'rgba(255,255,255, 0.5)',
                                                                    position: 'absolute'
                                                                }}
                                                            >
                                                                <Tag sx={{ border: '1px solid gray', borderColor: 'primary.light', }}>🙅 CANCELLED</Tag>
                                                            </Stack>
                                                        ) : null
                                                    }
                                                </Stack>
                                            </Stack>

                                        ))
                                    }
                                />
                                {/* <Card
                                    header={<Text weight="bold" size="medium" sx={{ p: 1.5, backgroundColor: 'secondary.light' }}>Viewings</Text>}
                                    body={
                                        <Stack
                                            sx={{ border: '1px solid gray', borderColor: 'secondary.main', borderBottom: 'none', borderRadius: '8px', overflow: 'hidden' }}
                                        >
                                            <Stack
                                                direction="row"
                                                sx={{ borderBottom: '1px solid gray', borderColor: 'secondary.main' }}
                                            >
                                                <Text weight="bold" size="medium" sx={{ p: 1.5, width: '64px', borderRight: '1px solid gray', borderColor: 'secondary.main' }}>Time</Text>
                                                <Text weight="bold" size="medium" sx={{ p: 1.5, width: '200px', borderRight: '1px solid gray', borderColor: 'secondary.main' }}>Name</Text>
                                                <Text weight="bold" size="medium" sx={{ p: 1.5, }}>Notes</Text>
                                            </Stack>
                                            <Stack
                                                key={`viewing-${idx}`}
                                                direction="row"
                                                sx={{ borderBottom: '1px solid gray', borderColor: 'secondary.main' }}
                                            >
                                                <Text sx={{ flexShrink: 0, p: 1.5, width: '64px', borderRight: '1px solid gray', borderColor: 'secondary.main' }}>{formatInTimeZone(new Date(viewing.startsAt), 'Etc/UTC', 'kk:mm')}</Text>
                                                <Text sx={{ flexShrink: 0, p: 1.5, width: '200px', borderRight: '1px solid gray', borderColor: 'secondary.main' }}>{viewing.viewer.name}</Text>
                                                {
                                                    viewing.notes ? (
                                                        <Stack sx={{ p: 1.5, }}>
                                                            {
                                                                viewing.notes.split('\n').map((n, i) => <Text key={`line-${i}`}>{n}</Text>)
                                                            }
                                                        </Stack>
                                                    ) : null
                                                }
                                            </Stack>
                                        </Stack>
                                    }
                                /> */}
                            </Stack>
                        </Stack>
                    ) : null
                }
            />
            {
                cancelled ? (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255,255,255, 0.7)',
                            position: 'absolute'
                        }}
                    >
                        <Tag sx={{ border: '1px solid gray', borderColor: 'primary.light', }}>🙅 CANCELLED</Tag>
                    </Stack>
                ) : null
            }
        </Stack>
    );
};

export default SessionAdmin;