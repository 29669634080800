import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { LargeContainer, Logo, FieldContainer, Email, Password, ActionButton } from './Login.styled';
import ConfirmRegister from './ConfirmRegister';

// const createUser = async ({ id, name, email, phone, keySecureLocation }) => {
//     const user = await DataStore.save(
//         new User({
//             id,
//             name,
//             email,
//             phone,
//             keySecureLocation,
//         })
//     );

//     return user;
// };

const Register = () => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [codeValidation, setCodeValidation] = useState(false);
    const [formData, setFormData] = useState({
        email: {
            value: '',
            valid: false,
        },
        password: {
            value: '',
            valid: false,
        },
    });
    const navigate = useNavigate();

    const handleFormData = (key, value, valid) => {
        const newFormData = { ...formData, [key]: { value, valid } };
        setFormData(newFormData);
    };

    const handleSignUp = async () => {
        setLoading(true);

        try {
            const {
                user,
                userConfirmed,
                userSub,
            } = await Auth.signUp({
                username: formData.email.value,
                password: formData.password.value,
                attributes: {
                    email: formData.email.value,
                    family_name: '-',
                }
            });
            console.log(user, userConfirmed, userSub);
            setUser(user);
            setCodeValidation(true);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }

    const handleValidUser = async () => {
        navigate('/app');
    };

    return (
        <LargeContainer>
            <Logo src='/app/logo-white.svg' />
            {codeValidation ? (
                <ConfirmRegister user={user} username={user.username} onUser={handleValidUser} />
            ) : (
                <FieldContainer>
                    <Email
                        required
                        name="Register-email"
                        label="Email"
                        helperText=""
                        placeholder="paul.tyler@gmail.com"
                        errorHelperText="Invalid email address"
                        mask={/^\S*@?\S*$/}
                        validator={/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/} // eslint-disable-line no-control-regex
                        onChange={(value, valid) => handleFormData('email', value, valid)}
                    />
                    <Password
                        required
                        name="Register-password"
                        label="Password"
                        helperText=""
                        placeholder=""
                        type="password"
                        onChange={(value, valid) => handleFormData('password', value, valid)}
                    />
                    {error}
                    <ActionButton
                        loading={loading}
                        disabled={!Object.keys(formData).reduce((final, curr) => (final && formData[curr].valid), true)}
                        variant="outlined"
                        size="large"
                        onClick={handleSignUp}
                    >
                        Register
                    </ActionButton>
                </FieldContainer >
            )}
        </LargeContainer>
    );
};

export default Register;