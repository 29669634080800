import { getData, distanceFromViewingStart } from "../utils";
import DataElem from "../DataElem";
import { SplitContent } from '../SessionReport.styled';

export default { // eslint-disable-line
    name: 'enroute',
    emoji: '🛫',
    time: (reports) => {
        return getData(reports.sessionReport, 'times', 'enroute') || 'Not yet';
    },
    title: (reports) => {
        return (
            <>
                Clem on their way
            </>
        );
    },
    subtitle: (reports) => {
        return (
            <>
                <DataElem
                    report={reports.sessionReport}
                    attr="times"
                    objkey="enroute"
                    format={(enroute) => {
                        if (enroute) {
                            return distanceFromViewingStart({ time: enroute, viewingReport: reports.viewingReports[0], suffix: false });
                        }
                        return null;
                    }}
                    sx={{ marginLeft: 0 }}
                />
                in advance
            </>
        );
    },
    content: (reports) => {
        return (
            <SplitContent sx={{ width: '100%' }}>
                <div>
                    🛫
                    <DataElem
                        report={reports.sessionReport}
                        attr="info"
                        objkey="clem-name"
                    />
                    started their journey at
                    <DataElem
                        report={reports.sessionReport}
                        attr="times"
                        objkey="enroute"
                    />
                    <DataElem
                        text={
                            distanceFromViewingStart({ time: getData(reports.sessionReport, 'times', 'enroute'), viewingReport: reports.viewingReports[0] })
                        }
                        sx={{ marginLeft: 0 }}
                    />
                    the first viewing
                </div>
            </SplitContent>
        );
    },
};