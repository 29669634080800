import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { searchParamsToObject } from '../../utils/urlSearchParams';
import { eachHourOfInterval, isBefore } from 'date-fns';
import CircularProgress from "@mui/material/CircularProgress";
import Button from '@mui/material/Button';
import { Container, BottomContainer, WizzardContainer, SuccessWrapper, SuccessPill, SuccessActionWrapper } from "./Reporter.styled";

import * as queries from '../../graphql/queries';
import SessionReport from "../SessionReport/SessionReport";

import Wizard from '../Forms/Wizard/Wizard';
import Wrapper from './Form/Wrapper';
import EnRoute from './Form/Steps/EnRoute';
import KeyPickup from './Form/Steps/KeyPickup';
import Arrival from './Form/Steps/Arrival';
import PreViewing from './Form/Steps/PreViewing';
import PostViewing from './Form/Steps/PostViewing';
import Exit from './Form/Steps/Exit';
import Viewings from './Form/Steps/Viewings';
import KeyDropoff from './Form/Steps/KeyDropoff';
import { useOutletContext } from "../../Auth";
import MoreInfo from "./Form/Steps/MoreInfo";

const queryReportManager = async (op, payload) => (
    JSON.parse((await API.graphql({
        query: queries.reportManager,
        variables: {
            op,
            payload: JSON.stringify(payload),
        },
    })).data.reportManager)
);

const addDataReportManager = async ({ type, data, key, sessionId, viewingReportId = null }) => {
    let params = {
        dataType: type,
        ...(viewingReportId ? { viewingReportId } : { sessionId }),
    };

    let _data = [];
    if (type === 'files') {
        _data = data.map((file) => ({
            location: `s3://${file.s3}`,
            position: file.position,
            key,
            type: data.type,
        }));
    } else if (type === 'times') {
        _data = [{
            time: data.time,
            key,
        }];
    } else if (type === 'info') {
        _data = [{
            value: data.value,
            key,
        }];
    }

    const res = await queryReportManager('addData', {
        ...params,
        data: _data,
    });

    return res;
};

const validateStep = (validationArr, report) => (
    validationArr.reduce((t, v) => {
        let isValid = !!report[v.field];
        if (Array.isArray(report[v.field])) {
            isValid = !!report[v.field].find(e => e.key === v.key);
        }

        return t && isValid;
    }, true)
);

const getStepStatus = (reports, isBlockViewing) => ({
    enroute: validateStep([
        { field: 'times', key: 'enroute' }
    ], reports.sessionReport),
    keyspickup: validateStep([
        { field: 'times', key: 'keypickup' },
        { field: 'files', key: 'images-keypickup' },
        { field: 'info', key: 'keypickup-location' },
    ], reports.sessionReport),
    arrival: validateStep([
        { field: 'times', key: 'access' },
        { field: 'files', key: 'images-access' },
        { field: 'files', key: 'videos-access' },
    ], reports.sessionReport),
    previewing: validateStep([
        { field: 'times', key: 'previewing' },
        { field: 'files', key: 'images-previewing' },
    ], reports.sessionReport),
    ...(!isBlockViewing ? {
        viewings: reports.viewingReports.reduce((t, v) => (
            t &&
            (
                validateStep([
                    { field: 'times', key: 'viewing-start' },
                    { field: 'times', key: 'viewing-end' },
                ], v)
                ||
                validateStep([
                    { field: 'times', key: 'viewing-cancel' },
                ], v)
                ||
                v.viewingStatus === 'NOSHOW'
            ) &&
            v.viewingStatus !== 'PENDING'
        ), true),
    } : {
        info: true,
    }),
    postviewing: validateStep([
        { field: 'times', key: 'postviewing' },
        { field: 'files', key: 'images-postviewing' },
    ], reports.sessionReport),
    exit: validateStep([
        { field: 'times', key: 'exit' },
        { field: 'files', key: 'videos-exit' },
    ], reports.sessionReport),
    keysdropoff: validateStep([
        { field: 'times', key: 'keydropoff' },
        { field: 'files', key: 'images-keydropoff' },
        { field: 'info', key: 'keydropoff-location' },
    ], reports.sessionReport),
})

const Reporter = () => {
    const params = useParams();
    const context = useOutletContext();
    const [reports, setReports] = useState({});
    const [reportView, setReportView] = useState(false);
    const [complete, setComplete] = useState(false);
    const [completeView, setCompleteView] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isBlockViewing, setIsBlockViewing] = useState(false);

    useEffect(() => {
        const createOrFetchReports = async () => {
            let res = await queryReportManager('get', { sessionId: params.sessionId });
            if (!res.length) {
                res = await queryReportManager('create', { sessionId: params.sessionId });
            }

            const infoClemName = res.sessionReport.info.find(t => t.key === 'clem-name');
            if (!infoClemName) {
                res.sessionReport = await addDataReportManager({ type: 'info', data: { value: context.userData.name }, key: 'clem-name', sessionId: params.sessionId });
            }

            setReports(res);

            const _isBlock = !!res.viewingReports.find((vr) => {
                const cn = vr.info.find((i) => i.key === 'customer-name').value;
                console.log(cn);
                return cn === 'BLOCK VIEWING';
            })

            setIsBlockViewing(_isBlock);

            const status = getStepStatus(res, _isBlock);
            const complete = Object.values(status).filter(v => v === false).length === 0;

            if (searchParams.get('compress') !== 'false') {
                setSearchParams({
                    ...searchParamsToObject(searchParams),
                    compress: 'true',
                });
            }
            handleSetComplete(complete, res);
        };

        createOrFetchReports();
    }, []); // eslint-disable-line

    const handleSetComplete = async (complete, _reports) => {
        if (complete) {
            setComplete(true);
            setCompleteView(true);

            if (_reports.sessionReport.reportStatus !== 'COMPLETE' && _reports.sessionReport.reportStatus !== 'SENT') {
                const newReport = await queryReportManager('update', { sessionId: params.sessionId, data: { reportStatus: 'COMPLETE' } });
                setReports({ ..._reports, sessionReport: newReport });
            }
        } else {
            setComplete(false);

            if (_reports.sessionReport.reportStatus !== 'PENDING') {
                const newReport = await queryReportManager('update', { sessionId: params.sessionId, data: { reportStatus: 'PENDING' } });
                setReports({ ..._reports, sessionReport: newReport });
            }
        }
    };

    const handleUpdate = async ({ type, data, key, viewingReportId = null, id = null }) => {
        let newReport = null;

        if (type === 'files') {
            newReport = await addDataReportManager({ type, data, key, sessionId: params.sessionId, viewingReportId });
        } else if (type === 'times' || type === 'info') {
            if (id) {
                newReport = await handleUpdateList({ type, data, id, viewingReportId });
            } else {
                newReport = await addDataReportManager({ type, data, key, sessionId: params.sessionId, viewingReportId });
            }
        } else if (type === 'update') {
            newReport = await queryReportManager('update', {
                ...(viewingReportId ? { viewingReportId } : { sessionId: params.sessionId }), data
            });
        }

        let _reports = reports
        if (newReport && !viewingReportId) {
            _reports = { ...reports, sessionReport: newReport };
        } else if (newReport) {
            _reports = { ...reports, viewingReports: [...reports.viewingReports.map(v => v.id !== viewingReportId ? v : newReport)] };
        }

        setReports(_reports);
        const status = getStepStatus(_reports, isBlockViewing);
        const complete = Object.values(status).filter(v => v === false).length === 0;

        handleSetComplete(complete, _reports);
    };

    const handleUpdateList = async ({ data, id, viewingReportId = null, type }) => {
        const newSessionReport = await queryReportManager('update', {
            ...(viewingReportId ? { viewingReportId } : { sessionId: params.sessionId }),
            data: {
                [type]: [...(
                    [...(viewingReportId ? reports.viewingReports.find(v => v.id === viewingReportId) : reports.sessionReport)[type]].reduce(
                        (tot, t) => (
                            t.id === id ? ([...tot, { ...t, ...data }]) : [...tot, t]
                        ),
                        []
                    )
                )]
            }
        });

        return newSessionReport;
    };

    if (!reports.sessionReport) {
        return <CircularProgress sx={{ color: 'center', margin: '40px' }} color="primary" />
    }

    if (completeView) {
        const viewingDate = new Date(reports.viewingReports[0].times.find(i => i.key === 'viewing-scheduled-start'));
        return (
            <Container>
                <SuccessWrapper>
                    This report is now complete 🎉
                    {
                        context.userData.isAdmin ? (
                            <div>by {reports.sessionReport.info.find(i => i.key === 'clem-name').value}</div>
                        ) : null
                    }
                    <SuccessActionWrapper>
                        {
                            context.userData.isAdmin || !isBefore(viewingDate, new Date()) || eachHourOfInterval({ start: viewingDate, end: new Date() }).length < 24 ? (
                                <Button
                                    sx={{ marginLeft: '12px' }}
                                    variant="contained"
                                    onClick={() => {
                                        setCompleteView(false);
                                        setReportView(true);
                                    }}
                                >
                                    👀 View report
                                </Button>
                            ) : null
                        }
                    </SuccessActionWrapper>
                </SuccessWrapper>
            </Container>
        );
    }

    // console.log(reports, isBlockViewing);

    return (
        <Container>
            {
                reportView ? (
                    <SessionReport reports={reports} />
                ) : null
            }
            <WizzardContainer data-visible={!reportView}>
                <Wizard
                    name="report-a-viewing"
                    emoji={{
                        enroute: '🛫',
                        keyspickup: '🔑',
                        arrival: '🛬',
                        previewing: '🤳',
                        ...(!isBlockViewing ? { viewings: '🆚' } : { info: 'ℹ️' }),
                        postviewing: '🤳',
                        exit: '🔒',
                        keysdropoff: '🔑',
                    }}
                    stepStatus={getStepStatus(reports, isBlockViewing)}
                    onStepChange={(step) => setCurrentStep(step)}
                >
                    <Wrapper isVisible={currentStep === 1}>
                        <EnRoute
                            reports={reports}
                            sessionId={params.sessionId}
                            onUpdate={handleUpdate}
                        />
                    </Wrapper>
                    <Wrapper isVisible={currentStep === 2}>
                        <KeyPickup
                            reports={reports}
                            sessionId={params.sessionId}
                            onUpdate={handleUpdate}
                        />
                    </Wrapper>
                    <Wrapper isVisible={currentStep === 3}>
                        <Arrival
                            reports={reports}
                            sessionId={params.sessionId}
                            onUpdate={handleUpdate}
                        />
                    </Wrapper>
                    <Wrapper isVisible={currentStep === 4}>
                        <PreViewing
                            reports={reports}
                            sessionId={params.sessionId}
                            onUpdate={handleUpdate}
                        />
                    </Wrapper>
                    {
                        !isBlockViewing ? (
                            <Wrapper isVisible={currentStep === 5}>
                                <Viewings
                                    reports={reports}
                                    sessionId={params.sessionId}
                                    onUpdate={handleUpdate}
                                />
                            </Wrapper>
                        ) : (
                            <Wrapper isVisible={currentStep === 5}>
                                <MoreInfo
                                    reports={reports}
                                    sessionId={params.sessionId}
                                    onUpdate={handleUpdate}
                                />
                            </Wrapper>
                        )
                    }
                    <Wrapper isVisible={currentStep === 6}>
                        <PostViewing
                            reports={reports}
                            sessionId={params.sessionId}
                            onUpdate={handleUpdate}
                        />
                    </Wrapper>
                    <Wrapper isVisible={currentStep === 7}>
                        <Exit
                            reports={reports}
                            sessionId={params.sessionId}
                            onUpdate={handleUpdate}
                        />
                    </Wrapper>
                    <Wrapper isVisible={currentStep === 8}>
                        <KeyDropoff
                            reports={reports}
                            sessionId={params.sessionId}
                            onUpdate={handleUpdate}
                        />
                    </Wrapper>
                    <Wrapper isVisible={currentStep === 9}>step9</Wrapper>
                </Wizard>
            </WizzardContainer>
            <BottomContainer data-complete={reportView}>
                {
                    complete ? (
                        <SuccessPill onClick={() => setCompleteView(true)}>Complete ✅</SuccessPill>
                    ) : null
                }
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setReportView(!reportView)}
                >
                    {reportView ? '🖊️ Edit Report' : '👀 View Report'}
                </Button>
            </BottomContainer>
        </Container >
    );
};

export default Reporter;