import React, { useEffect, useState } from 'react';
import { Container, Footer, Content } from './form.styled';
import Button from '@mui/material/Button';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const Wrapper = ({ nextStep, previousStep, children, isVisible }) => {
    const [alreadyDisplayed, setAlreadyDisplayed] = useState(false);

    useEffect(() => {
        if (!alreadyDisplayed && isVisible === true) {
            setAlreadyDisplayed(true);
        }
    }, [isVisible]); // eslint-disable-line

    if (!isVisible && !alreadyDisplayed) {
        return null;
    }

    return (
        <Container>
            <Content>
                {children}
            </Content>
            <Footer>
                <Button
                    size="small"
                    onClick={previousStep}
                    startIcon={<ArrowBackIosOutlinedIcon />}
                >
                    Back
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={nextStep}
                    endIcon={<ArrowForwardIosOutlinedIcon />}
                >
                    Next
                </Button>
            </Footer>
        </Container>
    );
};

export default Wrapper;