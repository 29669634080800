import { styled } from '@mui/material/styles';
import { Link as _Link } from 'react-router-dom';

export const Link = styled(_Link)(({ theme }) => ({
    textDecoration: 'none',
    width: 'fit-content',
    alignSelf: 'center',
}));

export const StatContainer = styled('div')(({ theme }) => ({
    marginTop: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: '12px',
    borderRadius: '12px',
    fontSize: '16px',
}));

export const StatTitle = styled('div')(({ theme }) => ({
    fontWeight: 'bold',
    fontStyle: 'normal',
}));

export const StatValue = styled('div')(({ theme }) => ({
    fontWeight: 'normal',
    marginRight: '12px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 8px',
    borderRadius: '20px',
    fontStyle: 'normal',
}));

export const SubStatValue = styled('div')(({ theme }) => ({
    fontWeight: 'lighter',
    width: '100%',
    paddingLeft: '36px',
    marginTop: '8px',
    fontStyle: 'italic',
    fontSize: '12px',
}));

export const Value = styled('div')(({ theme }) => ({
    display: 'inline-block',
    margin: '0px 8px',
    borderBottom: `1px dashed`,
}));

export const DescriptionText = styled('div')(({ theme }) => ({
    color: theme.palette.primary.light,
    padding: '12px',
    fontSize: '14px',
}));