import React, { useState } from 'react';
import { formatDistanceStrict, secondsToHours, secondsToMinutes } from 'date-fns'
import Slider from '@mui/material/Slider';
import MenuIcon from '@mui/icons-material/Menu';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Wrapper } from "@googlemaps/react-wrapper";
import Map from './Map';
import Button from '@mui/material/Button';
import Tooltip from './Tooltip';
import {
    PageContainer,
    Container,
    ContainerLeft,
    HeaderContainer,
    Header,
    HeaderLeft,
    Section,
    SummaryTitleSection,
    TitleSection,
    DataCardsContainer,
    DataCardContainer,
    DataCardContainerRow,
    DataCardContainerFooter,
    DataCardContainerHeader,
    DataCardTitle,
    DataCardSubTitle,
    DataCardValue,
    DataCardValueRow,
    GMapContainer,
    CTA,
    Hamburger,
    Drawer,
    DrawerHeader,
    Headlines,
    Property,
    Settings,
} from './AgentReportRenderer.styled';

const readableTime = (timeInS) => (
    `${secondsToHours(timeInS)} h ${secondsToMinutes(timeInS % 3600)} min`
);

const manDays = (timeInS) => (
    <span>{`${Math.ceil((timeInS / 3600) / 7)}`} <span>work days</span></span>
);

const manDaysTime = (timeInS) => (
    Math.ceil((timeInS / 3600) / 7)
);

const VARIABLES = {
    avgListingTime: {
        text: 'Average Listing Time',
        value: null,
    },
    avgListingPrice: {
        text: 'Average Listing Price',
        value: null,
    },
    propCount: {
        text: 'Current Listing',
        value: null,
    },
    sessionMultipler: {
        text: 'Number of viewing visits',
        value: 2,
    },
    propertiesTotalTimeCurrent: {
        text: 'Viewing Time (Current listings)',
        value: null,
    },
    propertiesTotalTimeYearly: {
        text: 'Viewing Time (Yearly)',
        value: null,
    },
    propertiesTotalTravelTimeCurrent: {
        text: 'Time spent driving (Current listings)',
        value: null,
    },
    propertiesTotalTravelTimeYearly: {
        text: 'Time spent driving (Yearly)',
        value: null,
    },
    missedRevenue: {
        text: 'Missed revenue',
        value: null,
    },
    commission: {
        text: 'Commission',
        value: null,
    },
    monthsYear: {
        text: 'Months per year',
        value: '12',
    },
    timeToWin: {
        text: 'Time to win a new instruction',
        value: null,
    },
    newInstructions: {
        text: 'New instruction',
        value: null,
    },
    annualSalary: {
        text: 'Annual Salary',
        value: null,
    },
    savedSalary: {
        text: 'Annual saved Salary',
        value: null,
    },
    workDaysYear: {
        text: 'Work days per year',
        value: '252 work days',
    },
    gMapDrivingDataCurrent: {
        text: 'Google Maps Driving data',
        value: 'all current listings',
    },
    avgSessionLength: {
        text: 'Average viewings visit time',
        value: '1 hour',
    },
    dayYear: {
        text: 'Days per year',
        value: '365 days',
    },
};

const DataCard = ({ title, subtitle = null, value, row = false }) => {
    const Cont = row ? DataCardContainerRow : DataCardContainer;
    const Val = row ? DataCardValueRow : DataCardValue;
    return (
        <Cont>
            {
                row ? (
                    <div>
                        <DataCardTitle>
                            {title}
                        </DataCardTitle>
                        {
                            subtitle ? (
                                <DataCardSubTitle>
                                    {subtitle}
                                </DataCardSubTitle>
                            ) : null
                        }
                    </div>
                ) : (
                    <>
                        <DataCardTitle>
                            {title}
                        </DataCardTitle>
                        {
                            subtitle ? (
                                <DataCardSubTitle>
                                    {subtitle}
                                </DataCardSubTitle>
                            ) : null
                        }
                    </>
                )
            }
            <Val>
                {value}
            </Val>
        </Cont >
    );
};

const getDisplayPrice = (price) => (
    (new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'GBP',
    })).format(price).split('.')[0]
);

const AgentReportRenderer = ({ agentReportData }) => {
    const [open, setOpen] = useState(false);
    const [sessionMultipler, setSessionMultipler] = useState(2);
    const [annualSalary, setAnnualSalary] = useState(35000);
    const [timeToWin, setTimeToWin] = useState(15);
    const [commission, setCommission] = useState(8);


    const propertiesTotalTime = agentReportData.propertiesTotalTime.driving * sessionMultipler;
    const propertiesTotalTravelTime = agentReportData.propertiesTotalTravelTime.driving * sessionMultipler;
    const missedRevenue = Math.floor((((propertiesTotalTime * (365 / Math.floor(agentReportData.avgListingTime))) / 3600 / 7) / timeToWin) * agentReportData.currentAvgPrice * (commission / 100) * 12);
    const annualSaved = propertiesTotalTime * (365 / Math.floor(agentReportData.avgListingTime));

    VARIABLES.avgListingPrice.value = getDisplayPrice(Math.floor(agentReportData.currentAvgPrice));
    VARIABLES.avgListingTime.value = `${Math.floor(agentReportData.avgListingTime)} days`;
    VARIABLES.propCount.value = agentReportData.propCount;
    VARIABLES.sessionMultipler.value = sessionMultipler;
    VARIABLES.propertiesTotalTimeCurrent.value = manDays(propertiesTotalTime);
    VARIABLES.propertiesTotalTimeYearly.value = manDays(annualSaved);
    VARIABLES.propertiesTotalTravelTimeCurrent.value = manDays(propertiesTotalTravelTime);
    VARIABLES.propertiesTotalTravelTimeYearly.value = manDays(propertiesTotalTravelTime * (365 / Math.floor(agentReportData.avgListingTime)))
    VARIABLES.missedRevenue.value = getDisplayPrice(missedRevenue);
    VARIABLES.newInstructions.value = Math.floor((((propertiesTotalTime * (365 / Math.floor(agentReportData.avgListingTime))) / 3600 / 7) / timeToWin));
    VARIABLES.timeToWin.value = `${timeToWin} work days`;
    VARIABLES.annualSalary.value = getDisplayPrice(annualSalary);
    VARIABLES.commission.value = `${commission}%`

    VARIABLES.savedSalary.value = getDisplayPrice((manDaysTime(annualSaved) / 252) * annualSalary);

    return (
        <Wrapper apiKey={"AIzaSyDPtaYB08vHm_NVuidIMcnbgLHy3lul8kQ"}>
            <PageContainer data-open={open}>
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                        <a href="https://clemviews.com" target="_blank">
                            <img src="/app/logo192.png" width="80px" />
                            <div>On demand <br /> physical viewings</div>
                        </a>
                    </DrawerHeader>
                    <Settings
                        title={`🔎 Your Settings`}
                        subtitle={`Change them based on your agency`}
                        expanded={true}
                    >
                        <Section sx={{ width: '100%', padding: '8px', marginTop: '30px !important' }}>
                            <SummaryTitleSection>Your average visits per property for viewings</SummaryTitleSection>
                            <Slider
                                sx={{ marginTop: '28px' }}
                                aria-label="sessions_nb"
                                defaultValue={sessionMultipler}
                                value={sessionMultipler}
                                valueLabelDisplay="on"
                                onChange={(e) => setSessionMultipler(e.target.value)}
                                step={1}
                                marks
                                min={1}
                                max={5}
                            />
                        </Section>
                        <Section sx={{ width: '100%', padding: '8px' }}>
                            <SummaryTitleSection>Your average lettings negotiator salary (inc OTE)</SummaryTitleSection>
                            <Slider
                                sx={{ marginTop: '28px' }}
                                aria-label="sessions_nb"
                                defaultValue={annualSalary}
                                value={annualSalary}
                                valueLabelDisplay="on"
                                onChange={(e) => setAnnualSalary(e.target.value)}
                                step={1000}
                                marks
                                min={25000}
                                max={45000}
                            />
                        </Section>
                        <Section sx={{ width: '100%', padding: '8px' }}>
                            <SummaryTitleSection>Your average time to win a new instruction (work days)</SummaryTitleSection>
                            <Slider
                                sx={{ marginTop: '28px' }}
                                aria-label="sessions_nb"
                                defaultValue={timeToWin}
                                value={timeToWin}
                                valueLabelDisplay="on"
                                onChange={(e) => setTimeToWin(e.target.value)}
                                step={1}
                                marks
                                min={5}
                                max={30}
                            />
                        </Section>
                        <Section sx={{ width: '100%', padding: '8px' }}>
                            <SummaryTitleSection>Your Average Commision (%)</SummaryTitleSection>
                            <Slider
                                sx={{ marginTop: '28px' }}
                                aria-label="sessions_nb"
                                defaultValue={commission}
                                value={commission}
                                valueLabelDisplay="on"
                                onChange={(e) => setCommission(e.target.value)}
                                step={1}
                                marks
                                min={5}
                                max={15}
                            />
                        </Section>
                    </Settings>
                </Drawer>
                <ContainerLeft>
                    <HeaderContainer>
                        <Hamburger
                            aria-label="open drawer"
                            onClick={() => setOpen(!open)}
                            edge="start"
                        >
                            {!open ? <MenuIcon /> : <CloseOutlinedIcon />}
                        </Hamburger>
                        <HeaderLeft>
                            {agentReportData.name}
                            <DataCardSubTitle>
                                📍 {agentReportData.address}
                            </DataCardSubTitle>
                        </HeaderLeft>
                    </HeaderContainer>
                    <Headlines
                        title={`🔎 Headlines`}
                        subtitle={`${(new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' })).format(missedRevenue)} missed revenue`}
                        expanded={true}
                    >
                        <TitleSection sx={{ paddingTop: '10px' }}>We can help you</TitleSection>
                        <Tooltip
                            calc={[
                                "$newInstructions = $propertiesTotalTimeYearly / $timeToWin",
                                "$missedRevenue = $newInstructions x $avgListingPrice x $commission x $monthsYear",
                            ]}
                            variables={VARIABLES}
                        >
                            <DataCardContainerHeader>
                                <div>
                                    <DataCardTitle>
                                        Generate Extra Revenue
                                    </DataCardTitle>
                                    <DataCardSubTitle>
                                        (Expected Annually)
                                    </DataCardSubTitle>
                                </div>
                                <DataCardValueRow>
                                    <div>
                                        {VARIABLES.missedRevenue.value}
                                    </div>
                                    <div>
                                        {VARIABLES.newInstructions.value} new instructions
                                    </div>
                                </DataCardValueRow>
                            </DataCardContainerHeader>
                        </Tooltip>
                        <Tooltip
                            calc="$savedSalary = ($propertiesTotalTimeYearly / $workDaysYear) * $annualSalary"
                            variables={VARIABLES}
                        >
                            <DataCardContainerHeader sx={{ marginBottom: '20px' }}>
                                <div>
                                    <DataCardTitle>
                                        Save Fixed Salary
                                    </DataCardTitle>
                                    <DataCardSubTitle>
                                        (Expected Annually)
                                    </DataCardSubTitle>
                                </div>
                                <DataCardValueRow>
                                    <div>
                                        {VARIABLES.savedSalary.value}
                                    </div>
                                    <div>
                                        {VARIABLES.propertiesTotalTimeYearly.value}
                                    </div>
                                </DataCardValueRow>
                            </DataCardContainerHeader>
                        </Tooltip>
                        <TitleSection>Your listings data</TitleSection>
                        <DataCardsContainer sx={{ marginBottom: '40px' }}>
                            <DataCard
                                title="🏘️ Listings"
                                subtitle="currenlty in London"
                                value={
                                    VARIABLES.propCount.value
                                }
                            />
                            <DataCard
                                title="🤝 Listing age"
                                subtitle="Average in days"
                                value={
                                    Math.floor(agentReportData.avgListingTime)
                                }
                            />
                            <DataCard
                                title="💷 Average Price"
                                subtitle="With current listings"
                                value={
                                    VARIABLES.avgListingPrice.value
                                }
                            />
                        </DataCardsContainer>
                        <Section>
                            <SummaryTitleSection>To let your current listings it will take 👇</SummaryTitleSection>
                            <Tooltip
                                calc="$propertiesTotalTravelTimeCurrent = SUM($gMapDrivingDataCurrent) x $sessionMultipler"
                                variables={VARIABLES}
                            >
                                <DataCard
                                    title="🚗 Travel Time"
                                    value={
                                        <>
                                            <div>
                                                {VARIABLES.propertiesTotalTravelTimeCurrent.value}
                                            </div>
                                            <div>
                                                {`(${readableTime(propertiesTotalTravelTime)})`}
                                            </div>
                                        </>
                                    }
                                    row
                                />
                            </Tooltip>
                            <Tooltip
                                calc="$propertiesTotalTimeCurrent = SUM($gMapDrivingDataCurrent + $avgSessionLength) x $sessionMultipler"
                                variables={VARIABLES}
                            >
                                <DataCard
                                    title="⏱️ Viewing Time"
                                    value={
                                        <>
                                            <div>
                                                {VARIABLES.propertiesTotalTimeCurrent.value}
                                            </div>
                                            <div>
                                                {`(${readableTime(propertiesTotalTime)})`}
                                            </div>
                                        </>
                                    }
                                    row
                                />
                            </Tooltip>
                        </Section>
                        <Section>
                            <SummaryTitleSection>Annually Clem can save you 📈</SummaryTitleSection>
                            <Tooltip
                                calc="$propertiesTotalTravelTimeYearly = $propertiesTotalTravelTimeCurrent x ($dayYear / $avgListingTime) "
                                variables={VARIABLES}
                            >
                                <DataCard
                                    title="🚗 Travel Time"
                                    value={
                                        <>
                                            <div>
                                                {VARIABLES.propertiesTotalTravelTimeYearly.value}
                                            </div>
                                            <div>
                                                {`(${readableTime(propertiesTotalTravelTime * (365 / Math.floor(agentReportData.avgListingTime)))})`}
                                            </div>
                                        </>
                                    }
                                    row
                                />
                            </Tooltip>
                            <Tooltip
                                calc="$propertiesTotalTimeYearly = $propertiesTotalTimeCurrent x ($dayYear / $avgListingTime) "
                                variables={VARIABLES}
                            >
                                <DataCard
                                    title="⏱️ Viewing Time"
                                    value={
                                        <>
                                            <div>
                                                {VARIABLES.propertiesTotalTimeYearly.value}
                                            </div>
                                            <div>
                                                {`(${readableTime(annualSaved)})`}
                                            </div>
                                        </>
                                    }
                                    row
                                />
                            </Tooltip>
                        </Section>
                    </Headlines>
                </ContainerLeft>
                <Container sx={{ width: '100%' }}>
                    <Header sx={{ maxWidth: '600px' }}>
                        Your Current Listings 👇
                        <DataCardSubTitle>
                            {`You have ${agentReportData.properties.length} listings currently in London`}
                        </DataCardSubTitle>
                    </Header>
                    {
                        agentReportData.properties.map((property) => (
                            <Property
                                title={`🏡 ${property.address}`}
                                subtitle={`${(new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' })).format(Math.floor((((property.distance.driving.duration_in_traffic.value * 2) + 3600 + (20 * 60)) * sessionMultipler) * (missedRevenue / annualSaved)))} missed revenue`}
                                expanded={true}
                                sx={{ width: '100%', maxWidth: '600px' }}
                            >
                                <DataCardsContainer sx={{ paddingTop: '10px' }}>
                                    <DataCard
                                        title="⏱️ Listed since"
                                        subtitle="In days"
                                        value={
                                            formatDistanceStrict(new Date(property.listed), new Date(), { unit: 'day' }).replace(' days', '')
                                        }
                                    />
                                    <DataCard
                                        title="💷 Price"
                                        subtitle="Currently listed"
                                        value={
                                            (new Intl.NumberFormat('en-UK', {
                                                style: 'currency',
                                                currency: 'GBP',
                                            })).format(property.price).replace('.00', '')
                                        }
                                    />
                                </DataCardsContainer>
                                <GMapContainer>
                                    <Map
                                        data={property}
                                        agencyName={agentReportData.name}
                                    />
                                </GMapContainer>
                                <TitleSection>To let this property you will spend</TitleSection>
                                <DataCard
                                    title="🚗 Driving"
                                    subtitle={`Across ${sessionMultipler} viewing visits`}
                                    value={
                                        readableTime(property.distance.driving.duration_in_traffic.value * 2 * sessionMultipler)
                                    }
                                    row
                                />
                                <DataCard
                                    title="👀 Viewing"
                                    subtitle={`Across ${sessionMultipler} viewing visits`}
                                    value={
                                        <>
                                            <div>
                                                {
                                                    `${readableTime(((property.distance.driving.duration_in_traffic.value * 2) + 3600 + (20 * 60)) * sessionMultipler)} ${property.distance.driving.duration_in_traffic.value * 2 > 2700 ? '🛑' : property.distance.driving.duration_in_traffic.value * 2 > 1800 ? '🟠' : '🟢'}`
                                                }
                                            </div>
                                            <div>
                                                {
                                                    property.distance.driving.duration_in_traffic.value * 2 > 2700 ? 'Highly innefficient' : ''
                                                }
                                            </div>
                                        </>
                                    }
                                    row
                                />
                                <DataCard
                                    title="💰 Fixed Salary"
                                    subtitle={`Across ${sessionMultipler} viewing visits`}
                                    value={
                                        (new Intl.NumberFormat('en-UK', {
                                            style: 'currency',
                                            currency: 'GBP',
                                        })).format(
                                            ((((property.distance.driving.duration_in_traffic.value * 2) + 3600 + (20 * 60)) * sessionMultipler) / 3600) * (35000 / 252 / 7)
                                        )
                                    }
                                    row
                                />
                                <DataCardContainerFooter>
                                    <div>
                                        <DataCardTitle>
                                            Missed Revenue
                                        </DataCardTitle>
                                        <DataCardSubTitle>
                                            (If you do the viewing yourself)
                                        </DataCardSubTitle>
                                    </div>
                                    <DataCardValueRow>
                                        {(new Intl.NumberFormat('en-UK', {
                                            style: 'currency',
                                            currency: 'GBP',
                                        })).format(Math.floor((((property.distance.driving.duration_in_traffic.value * 2) + 3600 + (20 * 60)) * sessionMultipler) * (missedRevenue / annualSaved)))}
                                    </DataCardValueRow>
                                </DataCardContainerFooter>
                            </Property>
                        ))
                    }
                </Container>
                <CTA>
                    <div>
                        <DataCardTitle>
                            🛎️ Book a call with Dhiren (CEO) to learn more
                        </DataCardTitle>
                        <DataCardSubTitle sx={{ fontStyle: 'normal' }}>
                            <Button href="https://www.linkedin.com/in/dhirengami/" target="_blank" size="small" variant="outlined">Linkedin</Button> | <Button href="https://clemviews.com" target="_blank" size="small" variant="outlined">Website</Button>
                        </DataCardSubTitle>
                    </div>
                    <Button variant="contained" href="https://calendly.com/dhiren-clem/30min" target="_blank" sx={{ color: '#4c3492 !important' }}>
                        Book now
                    </Button>
                </CTA>
            </PageContainer >
        </Wrapper>
    );
};

export default AgentReportRenderer;