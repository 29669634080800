import Card from '../../../Components/library/Cards/Generic';
import Header from '../../../Components/library/Cards/Headers/Standard';
import Tag from '../../../Components/library/Tag/Tag';

import Stack from '@mui/material/Stack';
import { Divider } from '@mui/material';

import Skeleton from '@mui/material/Skeleton';

const LeadSkeleton = () => (
    <Card
        header={
            <Header
                emoji={<Skeleton variant="rounded" width={22} height={16} sx={{ borderRadius: '4px' }} />}
                title={<Skeleton width="150px" />}
                tags={[
                    <Tag key="skeleton-tag-1"><Skeleton width="50px" /></Tag>,
                    <Tag key="skeleton-tag-2"><Skeleton width="50px" /></Tag>
                ]}
                action={<Skeleton variant="rounded" width={20} height={20} sx={{ borderRadius: '4px' }} />}
            />
        }
        withBodyContainer={false}
        body={
            <>
                <Stack
                    spacing={1}
                    direction="row"
                    sx={{ p: 1.5 }}
                >
                    {

                        <>
                            <Skeleton variant="rounded" width="120px" height="30px" sx={{ borderRadius: '4px' }} />
                            <Skeleton variant="rounded" width="120px" height="30px" sx={{ borderRadius: '4px' }} />
                        </>
                    }
                </Stack>
                <Divider />
            </>
        }
        footer={
            <Stack
                spacing={1}
                direction="row"
                justifyContent="flex-end"
                sx={{ p: 1.5 }}
            >
                <Skeleton variant="rounded" width="80px" height="30px" sx={{ borderRadius: '4px' }} />
                <Skeleton variant="rounded" width="80px" height="30px" sx={{ borderRadius: '4px' }} />
            </Stack>
        }
    />
);

export default LeadSkeleton;