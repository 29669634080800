import React, { useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { Storage } from 'aws-amplify';
import FileComp from './File';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';
import { Container, FilesContainer, FilesInput, AddFiles, Title, SubTitle } from './inputs.styled';

const getLocalFilesUrl = (file) => {
    if (!file) {
        return null;
    }

    return URL.createObjectURL(file);
};

const compressFile = async (file) => {
    const imageFile = file;
    console.log(`originalFile size ${(imageFile.size / 1024 / 1024).toFixed(2)} MB`);

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }

    try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log(`\tcompressedFile size ${(compressedFile.size / 1024).toFixed(2)} KB`);

        return compressedFile;
    } catch (error) {
        console.log(error);

        return null;
    }
}

const uploadFile = async (file, name, onProgress, type, compression = true) => {
    let fileToUpload = null;

    if (type === 'image' && compression) {
        fileToUpload = await compressFile(file.file);
    } else {
        fileToUpload = file.file;
    }

    try {
        const res = await Storage.put(
            name,
            fileToUpload,
            {
                resumable: false,
                progressCallback: (progress) => {
                    onProgress({ file, name, progress });
                },
            }
        );

        return res;
    } catch (error) {
        console.log("Error uploading file: ", error);
    }
}

const Files = ({
    files,
    sessionId,
    name,
    type,
    multi = true,
    title,
    subtitle,
    onChange = () => { },
    onDelete = () => { },
}) => {
    const [localFiles, setLocalFiles] = useState(
        files.map((f) => ({
            name: f.location,
            s3: f.location.replace('s3://', ''),
            progress: 1,
            position: f.position,
            id: f.id,
        }))
    );
    const [searchParams] = useSearchParams();
    const shouldCompress = searchParams.get('compress');
    console.log(shouldCompress);

    const inputRef = useRef(null);

    const handleUploadProgress = (data) => {
        setLocalFiles((currentLocalFiles) => {
            const file = currentLocalFiles.find((f) => f.name === data.file.name);

            return [
                ...currentLocalFiles.filter((f) => f.name !== data.file.name),
                {
                    ...file,
                    progress: data.progress.loaded / data.progress.total,
                },
            ];
        });
    };

    const handleFile = async (data) => {
        const newLocalFiles = [...data.files].map((file, index) => ({
            name: file.name,
            src: getLocalFilesUrl(file),
            progress: 0.01,
            type,
            position: localFiles.length + index,
            file,
        }));

        const newFiles = [
            ...localFiles,
            ...newLocalFiles,
        ];

        setLocalFiles(newFiles);

        const newRemoteFiles = await newLocalFiles.reduce(async (p, file, index) => {
            const tot = await p;
            const randnum = Math.ceil(Math.random() * 10000);
            const key = `${sessionId}/${name}-${randnum}.${file.name.split('.').pop().toLowerCase()}`;

            await uploadFile(
                file,
                key,
                handleUploadProgress,
                type,
                shouldCompress === 'true',
            );

            return [
                ...tot,
                {
                    ...file,
                    progress: 1,
                    s3: key,
                }
            ]
        }, Promise.resolve([]));

        onChange(newRemoteFiles);

        setLocalFiles([
            ...localFiles.filter((f) => !newRemoteFiles.find((rf) => rf.name === f.name)),
            ...newRemoteFiles,
        ]);
    };

    const handleDelete = (file) => {
        if (file.id) {
            setLocalFiles((currentLocalFiles) => currentLocalFiles.filter((cf) => cf.id !== file.id));
            onDelete(file);
        }
    }

    return (
        <Container>
            <div>
                <Title>{title}</Title>
                <SubTitle>{subtitle}</SubTitle>
            </div>
            <FilesContainer>
                {
                    localFiles.sort((a, b) => a.position - b.position).map((file) => (
                        <FileComp file={file} key={`image${file.name}`} onDelete={handleDelete} type={type} />
                    ))
                }
                <AddFiles onClick={() => inputRef.current.click()}>
                    {
                        type === 'image' ? (
                            <AddAPhotoOutlinedIcon sx={{ fontSize: 'inherit' }} />
                        ) : (
                            <VideoCallOutlinedIcon sx={{ fontSize: 'inherit' }} />
                        )
                    }
                </AddFiles>
            </FilesContainer>
            <FilesInput
                type="file"
                aria-label="add files"
                ref={inputRef}
                multiple="multiple"
                onChange={(e) => handleFile(e.target)}
                accept={type === 'image' ? 'image/*' : 'video/*'}
            />
        </Container>
    );
};

export default Files;