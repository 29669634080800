import { styled } from '@mui/material/styles';
import { invert } from '../../theme';
import IconButton from '@mui/material/IconButton';
import _Drawer from '@mui/material/Drawer';
import DashboardCard from '../Dashboard/Cards/DashboardCard/DashboardCard';

export const PageContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    width: '100%',
    height: '100%',
    transition: 'margin-left 0.3s',

    '&[data-open="true"]': {
        marginLeft: '420px',

        '& > .MuiDrawer-root': {
            height: '100%',
            overflow: 'auto',
        },
    },

    [theme.breakpoints.down(1130)]: {
        flexDirection: 'column',

        '&[data-open="true"]': {
            flexDirection: 'row',
            marginLeft: '320px',
        },
    },

    [theme.breakpoints.down(800)]: {
        flexDirection: 'column',

        '&[data-open="true"]': {
            flexDirection: 'row',
            marginLeft: '320px',
        },
    },
}));

export const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    padding: '0px 40px 110px 60px',
    backgroundColor: theme.palette.background.default,
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.down(1250)]: {
        padding: '0px 22px 110px 22px',
    },

    [theme.breakpoints.down(800)]: {
        padding: '0px 20px 110px 20px',
    }
}));

export const ContainerLeft = styled(Container)(({ theme }) => ({
    position: 'sticky',
    top: '0px',
    backgroundColor: theme.palette.background.light,
    height: '100%',
    padding: '0px 60px 110px 40px',
    overflow: 'auto',
    flexShrink: 0,
    width: '50%',
    minWidth: '750px',

    [theme.breakpoints.down(1350)]: {
        minWidth: '650px',
        width: '650px',
    },

    [theme.breakpoints.down(1250)]: {
        padding: '0px 22px 110px 22px',
        minWidth: '600px',
        width: '600px',
    },


    [theme.breakpoints.down(1130)]: {
        width: '100%',
        minWidth: '100%',
        position: 'relative',
        padding: '0px 20px 80px 20px',
        height: 'fit-content',
    },
}));

export const TitleSection = styled('div')(({ theme }) => ({
    width: '100%',
    alignSelf: 'center',
    marginBottom: '12px',
    textAlign: 'center',
    fontSize: '14px',
    color: theme.palette.primary.light,
    fontStyle: 'italic',
}));

export const Section = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',

    [theme.breakpoints.down(800)]: {
        margin: '0px 10px 30px',
        width: 'calc(100% - 20px)',
    },
}));

export const SummaryTitleSection = styled('div')(({ theme }) => ({
    width: 'fit-content',
    maxWidth: '80%',
    alignSelf: 'center',
    marginBottom: '12px',
    textAlign: 'center',
    fontSize: '14px',
    color: theme.palette.primary.light,
    fontStyle: 'italic',
    position: 'relative',
    padding: '4px 12px',
    marginTop: '-16px',
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: '12px',
}));

export const DataCardsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    margin: '0px 0px 20px',
    justifyContent: 'center',

    [theme.breakpoints.down(800)]: {
        flexWrap: 'wrap',
    }
}));

export const DataCardContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 12px 12px',
    backgroundColor: theme.palette.secondary.main,
    marginBottom: '12px',
    borderRadius: '12px',
    width: '200px',
    height: '160px',

    [theme.breakpoints.down(800)]: {
        padding: '12px',
        height: 'fit-content',
        marginBottom: '0px',
    },
}));

export const DataCardContainerRow = styled(DataCardContainer)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% - 40px)',
    height: '66px',
    margin: '0px 20px 12px',
    justifyContent: 'space-between',
    padding: '12px 40px',
    backgroundColor: theme.palette.background.light,

    [theme.breakpoints.down(800)]: {
        height: 'fit-content',
        flexDirection: 'column',
        marginBottom: '12px',
    },
}));

export const DataCardContainerHeader = styled(DataCardContainerRow)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,

    '& > div, & > div > div': {
        color: theme.palette.primary.contrastText,
    },
}));

export const DataCardContainerFooter = styled(DataCardContainerRow)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: 'calc(100% + 24px)',
    margin: '20px -12px -12px',
    borderRadius: '0px 0px 20px 20px',
    height: '86px',
    color: theme.palette.primary.contrastText,

    '& > div, & > div > div': {
        color: theme.palette.primary.contrastText,
    },

    [theme.breakpoints.down(800)]: {
        width: '100%',
        margin: '20px 0px 0px',
    }
}));

export const DataCardTitle = styled('div')(({ theme }) => ({
    fontSize: '16px',
    color: theme.palette.primary.main,

    [theme.breakpoints.down(800)]: {
        textAlign: 'center',
    },
}));

export const DataCardSubTitle = styled('div')(({ theme }) => ({
    fontSize: '12px',
    fontWeight: 'lighter',
    fontStyle: 'italic',
    marginTop: '4px',

    [theme.breakpoints.down(800)]: {
        textAlign: 'center',
    },
}));

export const DataCardValue = styled('div')(({ theme }) => ({
    fontSize: '42px',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
    margin: '20px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.down(800)]: {
        margin: '10px 0px 0px',
    },
}));

export const DataCardValueRow = styled(DataCardValue)(({ theme }) => ({
    fontSize: '22px',
    textAlign: 'right',

    '& > div:nth-child(1)': {
        width: '100%',

        '& > span > span': {
            fontSize: '14px',
            lineHeight: '22px',
        },
    },

    '& > div:nth-child(2)': {
        width: '100%',
        fontSize: '14px',
        fontStyle: 'italic',
        fontWeight: 'lighter',
    },

    [theme.breakpoints.down(800)]: {
        margin: '20px 0px 0px',
        textAlign: 'center',
    },
}));

export const CalcContainer = styled('div')(({ theme }) => ({
    height: '40px',
    display: 'flex',
    marginTop: '40px',

    '&:nth-child(1)': {
        marginTop: '0px',
    },
}));

export const TokenContainer = styled('span')(({ theme }) => ({
    display: 'inline-flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: '8px',
}));

export const Token = styled('span')(({ theme }) => ({
    color: theme.palette.primary.main,
    borderRadius: '4px',
    padding: '4px 8px',
    width: '100%',
    textAlign: 'center',
    fontSize: '10px',
    fontStyle: 'italic',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '4px 4px 0px 0px',
}));

export const TokenValue = styled(Token)(({ theme }) => ({
    fontSize: '12px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    color: theme.palette.primary.main,
    borderRadius: '0px 0px 4px 4px',
}));

export const TokenString = styled('span')(({ theme }) => ({
    fontSize: '16px',
    paddingBottom: '2px',
    color: theme.palette.primary.main,
}));

export const GMapContainer = styled('div')(({ theme }) => ({
    width: 'calc(100% + 24px)',
    margin: '0px -12px 20px',

    [theme.breakpoints.down(800)]: {
        width: '100%',
        margin: '0px 0px 30px',
    }
}));

export const HeaderContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',

    [theme.breakpoints.down(800)]: {
        flexDirection: 'column',
        width: '100%',

        '& > button': {
            margin: '20px 0px 0px',
        },
    },
}));

export const Header = styled('div')(({ theme }) => ({
    width: '100%',
    height: '84px',
    padding: '12px 40px',
    width: '100%',
    margin: '20px 0px 0px',
    borderRadius: '20px',
    fontWeight: 'bold',
    fontSize: '18px',
    color: theme.palette.primary.main,

    [theme.breakpoints.down(800)]: {
        padding: '0px',
        textAlign: 'left',
        height: 'fit-content',

        '& > div': {
            textAlign: 'left',
        },
    },
}));

export const HeaderLeft = styled(Header)(({ theme }) => ({
    fontSize: '22px',
    width: '540px',

    [theme.breakpoints.down(800)]: {
        width: '100%',
    },
}));

export const Headlines = styled(DashboardCard)(({ theme }) => ({
    width: '100%',
    maxWidth: '700px',

    '& .MuiCollapse-wrapper': {
        paddingTop: '20px',
    }
}));

export const Property = styled(DashboardCard)(({ theme }) => ({
    width: '100%',
    maxWidth: '600px',
}));

export const Settings = styled(DashboardCard)(({ theme }) => ({
    width: '100%',
    maxWidth: '340px',
    margin: '0px 40px !important',

    [theme.breakpoints.down(800)]: {
        margin: '0px 20px !important',
    }
}));

export const Hamburger = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[18],
    height: '54px',
    width: '54px',
    borderRadius: '12px',
    margin: '35px 0px 0px',

    [theme.breakpoints.down(1300)]: {
        margin: '35px 0px 0px',
    },

    [theme.breakpoints.down(800)]: {
        height: '40px',
        width: '40px',
    },
}));


export const CTA = invert(styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    border: `solid 1px ${theme.palette.primary.ultraLight}`,
    boxShadow: theme.shadows[18],
    position: 'fixed',
    bottom: '20px',
    width: '600px',
    display: 'flex',
    left: 'calc(50% - 300px)',
    padding: '12px',
    borderRadius: '12px',
    justifyContent: 'space-between',
    color: theme.palette.primary.main,

    '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
    },

    [theme.breakpoints.down(800)]: {
        width: '100%',
        left: '0px',
        bottom: '0px',
        border: 'none',
        borderTop: `solid 1px ${theme.palette.primary.ultraLight}`,
        borderRadius: '0px',
        padding: '20px 20px',
        gap: '8px',

        '& > div > div': {
            textAlign: 'left',
        },

        '& > div > div:nth-child(1)': {
            paddingBottom: '8px',
            fontSize: '14px',
        },

        '& a': {
            width: '100px',
            flexShrink: 0,
            textAlign: 'center',
        }
    },

    [theme.breakpoints.down(600)]: {
        padding: '20px 12px',
    },
})));

export const Drawer = styled(_Drawer)(({ theme }) => ({
    '& > div': {
        backgroundColor: theme.palette.background.light,
        border: 'none',
        height: '100%',
        overflow: 'auto',
    },

    [theme.breakpoints.down(800)]: {
        '& > div > div': {
            width: '280px',
        },
    }
}));

export const DrawerHeader = styled(Container)(({ theme }) => ({
    width: '420px',
    gap: '12px',
    height: '144px',
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
    padding: 0,

    '& > a': {
        margin: '30px 40px',
        display: 'flex',
        gap: '30px',
        textDecoration: 'none',

        '& > div': {
            fontWeight: 'bold',
            fontSize: '18px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.main,
        },
    },

    [theme.breakpoints.down(800)]: {
        width: '100%',

        '& > a': {
            margin: '12px 0px',
        }
    }
}));
