import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import useMediaQuery from '@mui/material/useMediaQuery';

import Stack from '@mui/material/Stack';

import Card from '../Generic';
import Header from './Standard';

import {
    ContainerSelectDisplay,
    CardSelectDisplayTitle,
    CardSelectDisplayBody,
    CardSelectDisplayValue,
} from './StatusSelector.styled';

const StatusSelector = ({
    currentStatus,
    color = 'secondary.main',
    statusCount = {},
    statuses = [],
    onSelect = () => { },
}) => {
    const isMobile = useMediaQuery('(max-width: 1000px)');

    return (
        <ContainerSelectDisplay
            direction="row"
            gap={2.5}
            justifyContent="space-around"
        >
            {
                isMobile ? (
                    <ButtonGroup>
                        {
                            statuses.map((s) => (
                                <Button
                                    key={s.status}
                                    variant={currentStatus === s.status ? 'contained' : 'outlined'}
                                    color={currentStatus === s.status ? 'secondary' : 'primary'}
                                    onClick={() => onSelect(s.status)}
                                    sx={{ fontSize: '12px', boxShadow: 'none !important' }}
                                >
                                    <Stack
                                        direction="row"
                                        gap={1}
                                    >
                                        <div>
                                            {statusCount[s.status]}
                                        </div>
                                        <div>
                                            {s.title.slice(2)}
                                        </div>
                                    </Stack>
                                </Button>
                            ))
                        }
                    </ButtonGroup>
                ) : (
                    <>
                        {
                            statuses.map((s) => (
                                <Card
                                    key={s.status}
                                    selected={currentStatus === s.status}
                                    sx={{
                                        cursor: 'pointer',
                                        flexGrow: 1,
                                        backgroundColor: currentStatus === s.status ? color : 'transparent',
                                    }}
                                    header={
                                        <Header
                                            title={
                                                <CardSelectDisplayTitle
                                                    color={currentStatus === s.status ? `${color.split('.')[0]}.contrastText` : 'primary.main'}
                                                    weight={currentStatus === s.status ? 'bold' : 'normal'}
                                                >
                                                    {s.title}
                                                </CardSelectDisplayTitle>
                                            }
                                        />
                                    }
                                    withBodyContainer={false}
                                    body={
                                        <CardSelectDisplayBody>
                                            <CardSelectDisplayValue
                                                weight="bold"
                                                color={currentStatus === s.status ? `${color.split('.')[0]}.contrastText` : 'primary.main'}
                                            >
                                                {statusCount[s.status]}
                                            </CardSelectDisplayValue>
                                        </CardSelectDisplayBody>
                                    }
                                    onClick={() => onSelect(s.status)}
                                />
                            ))
                        }
                    </>
                )
            }
        </ContainerSelectDisplay>
    );
};

export default StatusSelector;