import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    position: 'absolute',
}));

export const _Container = styled('div')(({ theme }) => ({
    width: '80%',
    height: '80%',
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    top: '10%',
    left: 'calc(50% - 40%)',
    borderRadius: '40px',
    boxShadow: theme.shadows[24],
    [theme.breakpoints.down(900)]: {
        top: '5%',
        left: 'calc(50% - 45%)',
        width: '90%',
        height: '90%',
        borderRadius: '30px',
    }
}));

export const Container = _Container;

export const Logo = styled('img')(({ theme }) => ({
    width: '100px',
    height: '100px',
    position: 'absolute',
    top: '40px',
    left: '40px',
}));

export const Login = styled('div')(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'lighter',
    color: theme.palette.text.primary,
    position: 'absolute',
    top: '75px',
    right: '60px',
}));

export const TagLineContainer = styled('div')(({ theme }) => ({
    width: '100%',
    textAlign: 'center',
    top: '30%',
    position: 'absolute',
    padding: '0px 20px',
}));

export const TagLine = styled('div')(({ theme }) => ({
    fontSize: '32px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,

    [theme.breakpoints.down(900)]: {
        fontSize: '28px',
    },
    // width: '100%',
    // textAlign: 'center',
    // top: '30%',
    // position: 'absolute',
}));

export const SubTagLine = styled('div')(({ theme }) => ({
    fontSize: '20px',
    fontWeight: 'lighter',
    color: theme.palette.text.primary,
    width: '100%',
    textAlign: 'center',
    marginTop: '20px',

    [theme.breakpoints.down(900)]: {
        fontSize: '16px',
    },
    // top: '30%',
    // position: 'absolute',
}));

export const Screen = styled('div')(({ theme }) => ({
    width: '80%',
    minHeight: '180px',
    maxHeight: '450px',
    height: '40%',
    backgroundImage: 'url("clem-screen.png")',
    backgroundSize: 'cover',
    position: 'absolute',
    bottom: 0,
    left: '10%',
    borderRadius: '25px 25px 0px 0px',
    boxShadow: theme.shadows[24],

    [theme.breakpoints.between(500, 900)]: {
        maxHeight: '300px',
    },

    [theme.breakpoints.down(500)]: {
        maxHeight: '200px',
    },
}));