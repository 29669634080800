import { useState, useRef, useEffect } from "react";
import Stack from "@mui/material/Stack";

import Card from "../../Components/library/Cards/Generic";
import ControlledInput from "../../Components/Input/ControlledInput";

import Text from "../../Components/library/Typography/Text";
import { Button, Divider } from "@mui/material";
import { Message, MessageWithDebug } from "./Message";

const Chat = ({
    onSendMessage = () => { },
    disabled = false,
    messages = [],
    isAgent = false,
}) => {
    const messagesEndRef = useRef(null);
    const [currentInput, setCurrentInput] = useState('');

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [messages]);

    const _Message = isAgent ? MessageWithDebug : Message;

    return (
        <Card
            sx={{
                backgroundColor: 'background.default',
                height: '100%',
                minWidth: '600px',
                maxWidth: '800px',
                flexShrink: 1,
                border: 'none',
            }}
            withBodyContainer={false}
            body={
                <Stack
                    gap={2.5}
                    sx={{
                        p: 2.5,
                        maxHeight: '100%',
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    {
                        messages.length === 0 ? (
                            <Message
                                key={`msg-system-start`}
                                message={{
                                    role: 'system',
                                    content: 'Connecting...'
                                }}
                                isActive={false}
                            />
                        ) : null
                    }
                    {
                        messages.map((msg, messageIdx) => (
                            <Stack
                                key={`message-${msg._id}`}
                                direction={msg.role === 'user' ? 'row-reverse' : 'row'}
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    width: '100%',
                                }}
                                gap={1.5}
                            >
                                <_Message
                                    key={`msg-${msg._id}`}
                                    message={msg}
                                    isActive={messageIdx === messages.length - 1}
                                    onSendMessage={onSendMessage}
                                    scrollIntoView={() => messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })}
                                />
                                {
                                    messageIdx > 0 && msg.role === 'user' && msg.content && JSON.parse(msg.content).answerTo === messages[messageIdx - 1].parsed.command.args.id ? (
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-end"
                                            sx={{
                                                width: '100%',
                                            }}
                                        >
                                            <Text>Selected answer ➡️</Text>
                                        </Stack>
                                    ) : null
                                }
                            </Stack>
                        ))
                    }
                    <div ref={messagesEndRef} />
                </Stack>
            }
            footer={
                <Stack
                    sx={{
                        p: 1.5,
                    }}
                >
                    <ControlledInput
                        fullWidth
                        disabled={disabled}
                        value={currentInput}
                        onChange={(value) => setCurrentInput(value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onSendMessage(currentInput);
                                setCurrentInput('');
                                e.preventDefault();
                            }
                        }}
                        placeholder={'Type anything'}
                    />
                </Stack>
            }
        />
    );
};

export default Chat;