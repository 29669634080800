const EMOJIS = [
    "😀",
    "😃",
    "🙂",
    "🙃",
    "🤓",
    "😎",
    "🤩",
    "🥳",
    "😶",
    "🤠",
    "👽",
    "👾",
    "🤖",
    "👮‍♀️",
    "👮",
    "👮‍♂️",
    "👷‍♀️",
    "👷",
    "👷‍♂️",
    "💂‍♀️",
    "💂",
    "💂‍♂️",
    "🕵️‍♀️",
    "🕵️",
    "🕵️‍♂️",
    "👩‍⚕️",
    "👨‍⚕️",
    "👩‍🌾",
    "👨‍🌾",
    "👩‍🍳",
    "👨‍🍳",
    "👩‍🎓",
    "👨‍🎓",
    "👩‍🎤",
    "👨‍🎤",
    "👩‍🏫",
    "👨‍🏫",
    "👩‍🏭",
    "👨‍🏭",
    "👩‍💻",
    "👨‍💻",
    "👩‍💼",
    "👨‍💼",
    "👩‍🔧",
    "👨‍🔧",
    "👩‍🔬",
    "👨‍🔬",
    "👩‍🎨",
    "👨‍🎨",
    "👩‍🚒",
    "👨‍🚒",
    "👩‍✈️",
    "👨‍✈️",
    "👩‍🚀",
    "👨‍🚀",
    "👩‍⚖️",
    "👨‍⚖️",
    "👰",
    "🤵",
    "👸",
    "🤴",
    "🦸‍♀️",
    "🦸",
    "🦸‍♂️",
    "🦹‍♀️",
    "🦹",
    "🦹‍♂️",
    "🤶",
    "🎅",
    "🧙‍♀️",
    "🧙",
    "🧙‍♂️",
    "🧝‍♀️",
    "🧝",
    "🧝‍♂️",
    "🧛‍♀️",
    "🧛",
    "🧛‍♂️",
    "🧟‍♀️",
    "🧟",
    "🧟‍♂️",
    "🧞‍♀️",
    "🧞",
    "🧞‍♂️",
    "🧜‍♀️",
    "🧜",
    "🧜‍♂️",
    "🧚‍♀️",
    "🧚",
    "🧚‍♂️",
    "🧶",
    "👓",
    "🕶",
    "🥽",
    "👑",
    "🎩",
    "🎓",
    "🧢",
    "💄",
    "💍",
    "💂🏻‍♂️",
    "🕵🏻‍♀️",
    "🕵🏻",
    "🍏",
    "🍎",
    "🍐",
    "🍊",
    "🍋",
    "🍌",
    "🍉",
    "🍇",
    "🍓",
    "🍈",
    "🍒",
    "🍑",
    "🥭",
    "🍍",
    "🥥",
    "🥝",
    "🍅",
    "🥑",
    "🥦",
    "🥬",
    "🥒",
    "🌶",
    "🌽",
    "🥕",
    "🥔",
    "🥐",
    "🥟",
    "🦪",
    "🍥",
    "🥠",
    "🍮",
    "🍭",
    "🍬",
    "🍫",
    "🍿",
    "🍩",
    "🍪",
    "🌰",
    "🥢",
    "🔆",
    "🔱",
    "🚹",
    "🃏",
    "🎴",
    "🀄️",
];

export const emojiFromString = (str) => {
    const s = `${str}`;
    const num = ([...new Array(s.length)]).reduce((tot, _, index) => (
        tot + s.charCodeAt(index)
    ), 0);

    return EMOJIS[(num * 2) % EMOJIS.length];
};