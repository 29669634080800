import React from 'react';
import { Container, FieldContainer, Header, Footer } from './form.styled';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const FailedMessage = styled('div')(() => ({
    fontSize: '28px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
}));

const failedEmojis = ['⛔', '❌'];

const FailedBooking = ({ onClose }) => {
    return (
        <Container>
            <Header />
            <FieldContainer>
                <FailedMessage>{`${failedEmojis[Math.floor(Math.random() * failedEmojis.length)]} Snap somenthing went wrong!`}</FailedMessage>
                <FailedMessage>{`Refresh the page and book again!`}</FailedMessage>
            </FieldContainer>
            <Footer>
                <Button
                    variant="outlined"
                    size="large"
                    onClick={onClose}
                >
                    Close
                </Button>
            </Footer>
        </Container>
    );
};

export default FailedBooking;