import { useState } from 'react';
import { formatInTimeZone } from 'date-fns-tz';

import Footer from '../../../Components/library/Cards/Footers/Action';
import Text from '../../../Components/library/Typography/Text';
import TimerButton from '../../../Components/library/Button/TimerButton';

import Stack from '@mui/material/Stack';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const LeadFooter = ({
    lead,
    notes,
    setNotes,
    disabled = false,
    onSave = () => { },
    onApprove = () => { },
    onReject = () => { },
    onCancel = () => { },
    onBooking = () => { },
    onCancelTimeout = () => { },
}) => {
    const [movedTo, setMovedTo] = useState(null);

    const _actions = {
        notes: {
            button: true,
            buttonVariant: 'text',
            value: (
                <Stack
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    sx={{ color: 'primary.light' }}
                >
                    <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px' }} />
                    <Text weight='bold' color="primary.light">notes</Text>
                </Stack>
            ),
            onClick: () => {
                setNotes('');
            },
            sx: {
                marginRight: 'auto',
                transition: 'all 0.2s',
                opacity: !!notes || notes === '' ? 0 : 1,
                pointerEvents: !!notes || notes === '' ? 'none' : 'all',
            },
        },
        booked: {
            value: (<><Text span>Viewing Booked </Text><Text weight="bold" span>{lead.viewing ? formatInTimeZone(new Date(lead.viewing.startsAt), 'Etc/UTC', 'MMMM do kk:mm') : null}</Text></>),
        },
        edit: {
            button: true,
            buttonVariant: 'contained',
            value: 'Save',
            onClick: () => {
                onSave();
            }
        },
        movedTo: {
            value: (<><Text span>Lead moved to </Text><Text weight="bold" span>{movedTo}</Text></>),
        },
        cancelMovedTo: {
            value: (
                <TimerButton
                    time={2000}
                    onTimeEnd={onCancelTimeout}
                    onClick={() => {
                        onCancel(movedTo);
                        setMovedTo(null);
                    }}
                >Cancel</TimerButton>
            ),
        },
        approve: {
            button: true,
            buttonVariant: 'contained',
            value: 'Approve',
            onClick: () => {
                onApprove();
                setMovedTo('APPROVED');
            }
        },
        reject: {
            button: true,
            buttonVariant: 'outlined',
            value: 'Reject',
            onClick: () => {
                onReject();
                setMovedTo('REJECTED');
            }
        },
        bookViewing: {
            button: true,
            buttonVariant: 'contained',
            value: 'Book a viewing',
            onClick: () => {
                onBooking();
            }
        },
    }

    const bindActions = () => {
        if (lead.viewing) {
            return [
                _actions.notes,
                _actions.booked,
            ];
        }

        if (lead.mode === 'EDIT') {
            return [
                _actions.edit,
            ];
        }

        if (movedTo) {
            return [
                _actions.movedTo,
                _actions.cancelMovedTo,
            ];
        }

        if (lead.status === 'PENDING') {
            return [
                _actions.notes,
                _actions.reject,
                _actions.approve,
            ];
        }

        if (lead.status === 'APPROVED') {
            return [
                _actions.notes,
                _actions.reject,
                _actions.bookViewing,
            ];
        }

        if (lead.status === 'REJECTED') {
            return [
                _actions.notes,
                _actions.approve,
            ];
        }
    };

    return (
        <Footer
            align='flex-end'
            sx={{
                pt: 1.5,
                opacity: disabled ? 0.5 : 1,
                pointerEvents: disabled ? 'none' : 'all',
            }}
            actions={
                bindActions()
            //     [
            //     ...(lead.viewing ? [
            //         {
            //             button: true,
            //             buttonVariant: 'text',
            //             value: (
            //                 <Stack
            //                     spacing={1}
            //                     direction="row"
            //                     alignItems="center"
            //                     sx={{ color: 'primary.light' }}
            //                 >
            //                     <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px' }} />
            //                     <Text weight='bold' color="primary.light">notes</Text>
            //                 </Stack>
            //             ),
            //             onClick: () => {
            //                 setNotes('');
            //             },
            //             sx: {
            //                 marginRight: 'auto',
            //                 transition: 'all 0.2s',
            //                 opacity: !!notes || notes === '' ? 0 : 1,
            //                 pointerEvents: !!notes || notes === '' ? 'none' : 'all',
            //             },
            //         },
            //         {
            //             value: (<><Text span>Viewing Booked </Text><Text weight="bold" span>{formatInTimeZone(new Date(lead.viewing.startsAt), 'Etc/UTC', 'MMMM do kk:mm')}</Text></>),
            //         },
            //     ] : []),
            //     ...(!lead.viewing && lead.mode === 'EDIT' ? [
            //         {
            //             button: true,
            //             buttonVariant: 'contained',
            //             value: 'Save',
            //             onClick: () => {
            //                 onSave();
            //             }
            //         }
            //     ] : []),
            //     ...(!lead.viewing && movedTo && lead.mode !== 'EDIT' ? [
            //         {
            //             value: (<><Text span>Lead moved to </Text><Text weight="bold" span>{movedTo}</Text></>),
            //         },
            //         {
            //             value: (
            //                 <TimerButton
            //                     time={2000}
            //                     onTimeEnd={onCancelTimeout}
            //                     onClick={() => {
            //                         onCancel(movedTo);
            //                         setMovedTo(null);
            //                     }}
            //                 >Cancel</TimerButton>
            //             ),
            //         }
            //     ] : []),
            //     ...(!lead.viewing && !movedTo && lead.mode !== 'EDIT' && lead.status === 'PENDING' ? [
            //         {
            //             button: true,
            //             buttonVariant: 'text',
            //             value: (
            //                 <Stack
            //                     spacing={1}
            //                     direction="row"
            //                     alignItems="center"
            //                     sx={{ color: 'primary.light' }}
            //                 >
            //                     <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px' }} />
            //                     <Text weight='bold' color="primary.light">notes</Text>
            //                 </Stack>
            //             ),
            //             onClick: () => {
            //                 setNotes('');
            //             },
            //             sx: {
            //                 marginRight: 'auto',
            //                 transition: 'all 0.2s',
            //                 opacity: !!notes || notes === '' ? 0 : 1,
            //                 pointerEvents: !!notes || notes === '' ? 'none' : 'all',
            //             },
            //         },
            //         {
            //             button: true,
            //             buttonVariant: 'outlined',
            //             value: 'Reject',
            //             onClick: () => {
            //                 onReject();
            //                 setMovedTo('REJECTED');
            //             }
            //         }, {
            //             button: true,
            //             buttonVariant: 'contained',
            //             value: 'Approve',
            //             onClick: () => {
            //                 onApprove();
            //                 setMovedTo('APPROVED');
            //             }
            //         }
            //     ] : []),
            //     ...(!lead.viewing && !movedTo && lead.mode !== 'EDIT' && lead.status === 'APPROVED' ? [
            //         {
            //             button: true,
            //             buttonVariant: 'text',
            //             value: (
            //                 <Stack
            //                     spacing={1}
            //                     direction="row"
            //                     alignItems="center"
            //                     sx={{ color: 'primary.light' }}
            //                 >
            //                     <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px' }} />
            //                     <Text weight='bold' color="primary.light">notes</Text>
            //                 </Stack>
            //             ),
            //             onClick: () => {
            //                 setNotes('');
            //             },
            //             sx: {
            //                 marginRight: 'auto',
            //                 transition: 'all 0.2s',
            //                 opacity: !!notes || notes === '' ? 0 : 1,
            //                 pointerEvents: !!notes || notes === '' ? 'none' : 'all',
            //             },
            //         },
            //         {
            //             button: true,
            //             buttonVariant: 'outlined',
            //             value: 'Reject',
            //             onClick: () => {
            //                 onReject();
            //                 setMovedTo('REJECTED');
            //             }
            //         },
            //         {
            //             button: true,
            //             buttonVariant: 'contained',
            //             value: 'Book a viewing',
            //             onClick: () => {
            //                 onBooking();
            //             }
            //         }
            //     ] : []),
            //     ...(!lead.viewing && !movedTo && lead.mode !== 'EDIT' && lead.status === 'REJECTED' ? [
            //         {
            //             button: true,
            //             buttonVariant: 'text',
            //             value: (
            //                 <Stack
            //                     spacing={1}
            //                     direction="row"
            //                     alignItems="center"
            //                     sx={{ color: 'primary.light' }}
            //                 >
            //                     <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px' }} />
            //                     <Text weight='bold' color="primary.light">notes</Text>
            //                 </Stack>
            //             ),
            //             onClick: () => {
            //                 setNotes('');
            //             },
            //             sx: {
            //                 marginRight: 'auto',
            //                 transition: 'all 0.2s',
            //                 opacity: !!notes || notes === '' ? 0 : 1,
            //                 pointerEvents: !!notes || notes === '' ? 'none' : 'all',
            //             },
            //         },
            //         {
            //             button: true,
            //             buttonVariant: 'contained',
            //             value: 'Approve',
            //             onClick: () => {
            //                 onApprove();
            //                 setMovedTo('APPROVED');
            //             }
            //         }
            //     ] : []),
            // ]
        }
        />
    );
};

export default LeadFooter;