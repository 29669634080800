import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import Layout from './Components/Layout/Layout';
import Landing from './Components/Landing/Landing';
import Auth, { useOutletContext } from './Auth';
import Report from './Components/Report/Report';
import Register from './Components/Auth/Register';
import Reporter from './Components/Reporter/Reporter';
import SessionReportPage from './Components/SessionReport/SessionReportPage';
import Dashboard from './Components/Dashboard/Dashboard';
import AgentReport from './Components/AgentReport/AgentReport';

import Leads from './Apps/Leads';
import Properties from './Apps/Properties';
import Viewings from './Apps/Viewings';
import PreQual from './Apps/PreQual';
import User from './Apps/User';
import Admin from './Apps/Admin';
import Clem from './Apps/Clem';

import Maintanance from './Apps/Maintanance';

import ShowCase from './Components/library/Showcase';
import { useEffect } from 'react';

const BASE_PATH = 'app'; // 'app';
const REPORT_PATH = 'report'; // 'report';
const REGISTER_PATH = 'register'; // 'register';

const AuthRouting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useOutletContext();

  useEffect(() => {
    if (location.pathname === '/app' || location.pathname === '/app/') {
      console.log(location);
      if (context.userData.isClem && !context.userData.isAdmin) {
        navigate('/app/clem');
      } else if (context.userData.isAdmin) {
        navigate('/app/admin');
      } else {
        navigate('/app/properties');
      }
    }
  }, []);

  return null;
}

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Landing />} />
          <Route path={`${BASE_PATH}`} element={<Auth />}>
            <Route index element={<AuthRouting />} />
            {/* <Route index element={<Maintanance />} /> */}

            <Route path={`*`} element={<Layout />}>

              {/* <Route path="dashboard" element={<Dashboard />} /> */}
              <Route path="reports/:sessionReportId" element={<SessionReportPage />} />

              {/* <Route path="clem">
                <Route index element={<Sessions />} />
                <Route path="reports" element={<ReportsAdmin />} />
                <Route path="report/:sessionId" element={<Reporter />} />
              </Route> */}

              {/* <Route path="admin">
                <Route index path="schedule" element={<Availability />} />
                <Route path="sessions" element={<SessionsAdmin />} />
                <Route path="reports" element={<ReportsAdmin admin />} />
              </Route> */}

              <Route path="showcase">
                <Route index element={<ShowCase />} />
              </Route>

              {/* APPS */}

              <Route path="properties">
                <Route index element={<Properties />} />
                <Route path=":propertyId" element={<Properties />} />
              </Route>

              <Route path="leads">
                <Route index element={<Leads />} />
                <Route path=":propertyId" element={<Leads />} />
              </Route>

              <Route path="viewings">
                <Route index element={<Viewings />} />
                <Route path=":propertyId" element={<Viewings />} />
                <Route path=":propertyId/:view" element={<Viewings />} />
                <Route path="new" element={<Viewings />} />
                <Route path="calendar" element={<Viewings />} />
                <Route path="calendar/:date" element={<Viewings />} />
              </Route>

              <Route path="pre-qualification">
                <Route index element={<PreQual />} />
                <Route path="*" element={<PreQual />} />
              </Route>

              <Route path="user">
                <Route index element={<User />} />
                <Route path=":view" element={<User />} />
              </Route>

              <Route path="admin">
                <Route index element={<Admin />} />
                <Route path=":view" element={<Admin />} />
              </Route>

              <Route path="clem">
                <Route index element={<Clem />} />
                <Route path=":view" element={<Clem />} />
                <Route path="report/:sessionId" element={<Reporter />} />
              </Route>

            </Route>
          </Route>
          <Route path={`/${REGISTER_PATH}`} element={<Register />} />
          <Route path={`/${REPORT_PATH}/agent/:reportId`} element={<AgentReport />} />
          <Route path={`/${REPORT_PATH}/:reportId`} element={<Report />} />
        </Route>
      </Routes>
    </BrowserRouter >
  );
}

export default Router;
