import { DataStore } from '@aws-amplify/datastore';
import { Viewer } from '../models';

export const createViewer = async (data) => {
    try {
        const viewer = await DataStore.save(
            new Viewer({
                email: data.email,
                phone: data.phone,
                name: data.name,
            })
        );

        return viewer;
    } catch (e) {
        console.log(e);
        return null;
    }
};

export default { // eslint-disable-line
    create: createViewer,
};