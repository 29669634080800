import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { FieldContainer, Email, Password, ActionButton } from './Login.styled';

const Login = ({ onUser }) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: {
            value: '',
            valid: false,
        },
        password: {
            value: '',
            valid: false,
        },
    });

    const handleFormData = (key, value, valid) => {
        const newFormData = { ...formData, [key]: { value, valid } };
        setFormData(newFormData);
    };

    const handleSignIn = async () => {
        setLoading(true);
        try {
            let _user = await Auth.signIn(formData.email.value, formData.password.value);
            onUser(_user);
        } catch (error) {
            if (error.name === 'PasswordResetRequiredException') {
                onUser(formData.email.value);
            } else {
                setError(error.message);
                setLoading(false);
            }
        }
    }

    return (
        <FieldContainer>
            <Email
                required
                name="login-email"
                label="Email"
                helperText=""
                placeholder="paul.tyler@gmail.com"
                errorHelperText="Invalid email address"
                mask={/^\S*@?\S*$/}
                validator={/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/} // eslint-disable-line no-control-regex
                onChange={(value, valid) => handleFormData('email', value, valid)}
            />
            <Password
                required
                name="login-password"
                label="Password"
                helperText=""
                placeholder=""
                type="password"
                onChange={(value, valid) => handleFormData('password', value, valid)}
            />
            {error}
            <ActionButton
                loading={loading}
                disabled={!Object.keys(formData).reduce((final, curr) => (final && formData[curr].valid), true)}
                variant="outlined"
                size="large"
                onClick={handleSignIn}
            >
                Login
            </ActionButton>
        </FieldContainer >
    );
};

export default Login;