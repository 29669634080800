import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import Stack from '@mui/material/Stack';

import Property from '../../data/property';
import FeatureFlags from '../../data/featureFlags';
import Lead from '../../data/lead';
import Viewing from '../../data/viewing';
import MarketPlace from '../../data/marketPlace';
import AIPrequal from '../../data/aiPrequal';

import { useOutletContext } from '../../Auth';

import TwoPanelsLayout from '../../Components/library/Layout/TwoPanels';
import Text from '../../Components/library/Typography/Text';

import LeadsList from './LeadsList/LeadsList';
import PropertyList from '../Properties/PropertyList/PropertyList';
import LeadsListSkeleton from './LeadsList/LeadsListSkeleton';

const Leads = () => {
    const navigate = useNavigate();
    const params = useParams();
    const context = useOutletContext();
    const [leads, setLeads] = useState(null);
    const [property, setProperty] = useState(null);
    const [properties, setProperties] = useState(null);
    const [fetchingLeads, setFetchingLeads] = useState(false);
    const [leadsSubscription, setLeadsSubscription] = useState(null);
    const [aiPrequalSubscription, setAiPrequalSubscription] = useState(null);
    const [hasAiPrequalFeature, setHasAiPrequalFeature] = useState(false);


    useEffect(() => {
        const initialFetch = async () => {
            const props = await Property.getAll();
            const prepedProps = props.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(p => ({
                ...p,
                marketPlaces: [{ url: p.listingUrl, type: 'unknown', createdAt: p.createdAt }]
            }));
            setProperties(prepedProps);

            const prop = prepedProps.find(p => p.id === params.propertyId);
            if (prop) {
                await handleFetchLeads(prop);
            }
        };
        initialFetch();

        return () => {
            if (leadsSubscription) {
                leadsSubscription.unsubscribe();
            }
            if (aiPrequalSubscription) {
                aiPrequalSubscription.unsubscribe();
            }
        };
    }, []);

    const handleFetchLeads = async (_property) => {
        if (fetchingLeads && property && property.id === _property.id) {
            return;
        }

        navigate(`/app/leads/${_property.id}`);

        setFetchingLeads(true);
        setProperty(_property);

        const hasAiPrequal = await FeatureFlags.get('ai-prequal');
        setHasAiPrequalFeature(hasAiPrequal);

        await new Promise((resolve) => setTimeout(resolve, 500)); // not so sure anymore, I belive it's useless... last famous words

        if (leadsSubscription) {
            leadsSubscription.unsubscribe();
            setLeadsSubscription(null);
        }

        // const AIPrequalSub = AIPrequal.subscribeToProperty(async (items, isSynced) => {
        //     if (isSynced) {
        //         console.log('sub items', items);
        //     }
        // }, _property.id);

        // setAiPrequalSubscription(AIPrequalSub);

        const subscription = Lead.subscribeToProperty(async (items, isSynced) => {
            if (isSynced) {
                let aiPrequals = [];

                if (hasAiPrequal) {
                    aiPrequals = await AIPrequal.getAll();
                    console.log(aiPrequals);
                }

                let leads = items.map(l => ({ ...l }));
                const viewings = await Viewing.getByLeads(leads.map(l => l.status === 'APPROVED' ? l.id : null).filter(l => l !== null));
                const marketPlaces = await MarketPlace.property.getByProperty(_property.id);

                leads = leads.map(l => ({
                    ...l,
                    viewing: viewings.find(v => v.leadId === l.id),
                    marketPlace: marketPlaces.find(mp => mp.id === l.enquiredFrom),
                    aiPrequal: hasAiPrequal ? aiPrequals.find((p) => p.leadId === l.id) : null,
                    aiPrequalAvailable: hasAiPrequal,
                }));

                setLeads({
                    PENDING: leads.filter((l) => l.status === 'PENDING'),
                    APPROVED: leads.filter((l) => l.status === 'APPROVED'),
                    REJECTED: leads.filter((l) => l.status === 'REJECTED'),
                });

                setFetchingLeads(false);
            }
        }, _property.id);

        setLeadsSubscription(subscription);
    };

    const handleSaveLead = async (lead) => {
        if (!property) {
            return;
        }

        let _lead = null;
        if (lead.id.startsWith('new-')) {
            const newLead = await Lead.create({
                email: lead.email,
                phone: lead.phone,
                name: lead.name,
                propertyId: property.id,
                status: lead.status,
                owner: context.userData.id,
            });

            console.log(newLead);
            _lead = newLead;
        } else {
            const modifiedLead = await Lead.update(lead.id, lead);
            _lead = { ...modifiedLead };
            _lead.viewing = lead.viewing;
        }

        setLeads((currentLeads) => ({
            ...currentLeads,
            [lead.status]: [
                ...currentLeads[lead.status].map((l) => (
                    l.id !== lead.id ? l : {
                        ..._lead,
                        mode: 'SAVED',
                    }
                )),
            ]
        }));

        return _lead;
    };

    const handleLeadAction = async (lead, action) => {
        console.log(lead, action);

        if (action === 'APPROVED' || action === 'REJECTED') {
            const _lead = await handleSaveLead({
                id: lead.id,
                status: action,
            });
            setLeads((currentLeads) => ({
                ...currentLeads,
                [action]: [
                    ...currentLeads[action],
                    {
                        ..._lead,
                    },
                ]
            }));
        }

        if (action === 'CANCEL') {
            console.log('cancelling change', lead);
            const _lead = await handleSaveLead({ id: lead.id, status: lead.status });
            setLeads((currentLeads) => (
                Object.keys(currentLeads).filter(k => k !== lead.status).reduce((tot, key) => ({
                    ...tot,
                    [key]: currentLeads[key].filter(l => l.id !== _lead.id),
                }), {
                    [lead.status]: [
                        ...currentLeads[lead.status],
                    ]
                })
            ));
        }

        if (action === 'SAVE') {
            await handleSaveLead(lead);
        }

        if (action === 'CANCELTIMEOUT') {
            setLeads((currentLeads) => ({
                ...currentLeads,
                [lead.status]: [
                    ...(currentLeads[lead.status].filter(l => l.id !== lead.id)),
                ]
            }));
        }

        if (action === 'EDIT') {
            setLeads((currentLeads) => ({
                ...currentLeads,
                [lead.status]: [
                    ...currentLeads[lead.status].map((l) => {
                        if (l.id === lead.id) {
                            l.mode = 'EDIT';
                        }
                        return l;
                    }),
                ]
            }));
        }

        if (action === 'BOOKING') {
            navigate(`/app/viewings/${property.id}/new?leadId=${lead.id}`);
        }

        if (action === 'CREATE_AIPREQUAL') {
            await AIPrequal.createForLead(lead);
        }
    };

    const handleAddLead = (status) => {
        setLeads((currentLeads) => ({
            ...currentLeads,
            [status]: [
                {
                    id: `new-${uuidv4()}`,
                    name: '',
                    phone: '',
                    email: '',
                    mode: 'EDIT',
                    status: status,
                },
                ...currentLeads[status],
            ]
        }));
    };

    return (
        <TwoPanelsLayout
            leftPanel={
                <PropertyList
                    properties={properties || []}
                    selectedProperty={property}
                    onSelectProperty={handleFetchLeads}
                    onAddProperty={() => navigate('/app/properties/new')}
                    color="leads_app.main"
                />
            }
            rightPanel={
                !!(fetchingLeads || leads) ? (
                    <>
                        {
                            fetchingLeads ? (
                                <>
                                    <LeadsListSkeleton
                                        propertyAddress={property ? `${property.address} - ${property.postcode}` : ''}
                                        color="leads_app.main"//{theme.palette.leads_app.main}//APPS_CONFIG.leads.color}
                                    />
                                </>
                            ) : null
                        }
                        {
                            !fetchingLeads && leads ? (
                                <>
                                    <LeadsList
                                        leads={leads}
                                        propertyAddress={property ? `${property.address} - ${property.postcode}` : ''}
                                        onAddLead={handleAddLead}
                                        onAction={handleLeadAction}
                                        onBack={() => {
                                            setLeads(null);
                                            setProperty(null);
                                        }}
                                        color="leads_app.main"//{theme.palette.leads_app.main}//APPS_CONFIG.leads.color}
                                    />
                                </>
                            ) : null
                        }
                    </>
                ) : null
            }
            rightPanelPlaceholder={
                <Stack
                    sx={{ height: '100%', maxWidth: '600px' }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Text size="medium" span sx={{ width: 'fit-content' }}>👈 Select a property to see <Text size="medium" weight="bold" span>LEADS</Text></Text>
                </Stack>
            }
            showRightPanel={!!(fetchingLeads || leads)}
        />
    );
}

export default Leads;