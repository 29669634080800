import { DataStore } from '@aws-amplify/datastore';
import { ViewingReport, SessionReport, Viewing } from '../models';

export const fetch = async (date) => {
    const sessionReports = await DataStore.query(SessionReport); //, p => p.createdAt('gt', '2022-08-01T00:00:00.000Z'));
    const viewingReports = await DataStore.query(ViewingReport);//, p => p.createdAt('gt', '2022-08-01T00:00:00.000Z'));

    return {
        sessionReports,
        viewingReports,
    };
};


export const fetchByProperty = async (propertyId) => {
    const viewings = await DataStore.query(Viewing, v => v.propertyId('eq', propertyId));
    const viewingReports = await DataStore.query(ViewingReport, vr => vr.or(vr => viewings.map(v => vr.viewingId('eq', v.id))));
    const sessionReportsIds = [...new Set(viewingReports.map(vr => vr.sessionReportId))];
    if (!viewings.length || !sessionReportsIds.length) {
        return [];
    }

    let sessionReports = await DataStore.query(SessionReport, r => r.or(r => sessionReportsIds.map(id => r.id('eq', id))));

    sessionReports = sessionReports.map(sr => ({
        ...sr,
        viewingReports: viewingReports.filter(vr => vr.sessionReportId === sr.id),
    }));

    return sessionReports;
};

export default { // eslint-disable-line
    fetch,
    fetchByProperty,
};