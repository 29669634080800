import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AgentReportRenderer from './AgentReportRenderer';
import MOCK_DATA from './mock3.json';

const AgentReport = () => {
    const [agentReportData, setAgentReportData] = useState(null);
    const [error, setError] = useState(null);
    const params = useParams();

    useEffect(() => {
        const fetchAgentReportData = async (id) => {
            try {
                const data = await fetch(`https://s3.eu-west-2.amazonaws.com/clem-reports/${id}.json`).then(response => response.json());
                setAgentReportData(data);
            } catch (e) {
                setError(e);
            }
        };

        fetchAgentReportData(params.reportId);
    }, []); // eslint-disable-line

    if (!agentReportData) {
        return null;
    }

    return (
        <AgentReportRenderer agentReportData={agentReportData} error={error} />
    );
};

export default AgentReport;