import Stack from '@mui/material/Stack';

const CardBody = ({ children, ...otherProps }) => {
    return (
        <Stack spacing={1} sx={{ p: 2.5 }} {...otherProps}>
            {children}
        </Stack>
    );
};

export default CardBody;