import { useEffect, useState } from "react";
import * as queries from '../../graphql/queries';
import { API } from 'aws-amplify';

import Stack from "@mui/material/Stack";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import Card from "../../Components/library/Cards/Generic";
import Header from "../../Components/library/Cards/Headers/Standard";
import Text from "../../Components/library/Typography/Text";
import ControlledInput from "../../Components/Input/ControlledInput";
import Footer from "../../Components/library/Cards/Footers/Action";

import Keys from "../Keys/KeysDisplay";

import {
    BackButton,
    CardTitleText,
} from '../Leads/leads.styled';
import PropertyDisplay from "../Properties/Property/PropertyDisplay";
import { Button, CircularProgress } from "@mui/material";

const AdminKeys = ({
    onBack = () => { },
}) => {
    const [keys, setKeys] = useState({});
    const [keynestData, setKeynestData] = useState({});
    const [currentType, setCurrentType] = useState('requestKeynest');
    const [fetching, setFetching] = useState(true);

    const fetchKeys = async (type) => {
        setFetching(true);
        const resKeys = await API.graphql({
            query: queries.keysManager,
            variables: {
                op: 'get',
                payload: JSON.stringify({
                    type: type,
                }),
            },
        });

        let _keys = JSON.parse(resKeys.data.keysManager);
        const keysByAgent = _keys.reduce((tot, key) => ({
            ...tot,
            [key.owner.split(':')[0]]: [
                ...(tot[key.owner.split(':')[0]] || []),
                key,
            ],
        }), {});
        const keysByAgentAndProp = Object.keys(keysByAgent).reduce((tot, k) => ({
            ...tot,
            [k]: keysByAgent[k].reduce((t, kk) => ({
                ...t,
                [kk.propertyId]: [
                    ...(t[kk.propertyId] || []),
                    kk,
                ],
            }), {}),
        }), {});
        console.log(keysByAgentAndProp);
        setKeys(keysByAgentAndProp);
        setFetching(false);
    };

    useEffect(() => {
        fetchKeys(currentType);
    }, []);

    const saveKey = async (key, data) => {
        setFetching(true);
        const resUpdatedKeys = await API.graphql({
            query: queries.keysManager,
            variables: {
                op: 'updateKeynest',
                payload: JSON.stringify({
                    keysId: key.id,
                    data,
                }),
            },
        });

        const updatedKeys = JSON.parse(resUpdatedKeys.data.keysManager)

        console.log(updatedKeys);

        await fetchKeys(currentType);
        setFetching(false);
    };

    const deactivateKey = async (key) => {
        setFetching(true);
        const resUpdatedKeys = await API.graphql({
            query: queries.keysManager,
            variables: {
                op: 'changeStatus',
                payload: JSON.stringify({
                    keysId: key.id,
                    status: 'DEACTIVATED',
                }),
            },
        });

        await fetchKeys(currentType);
        setFetching(false);
    };

    return (
        <Card
            sx={{
                backgroundColor: 'background.default',
                height: '100%',
            }}
            header={
                <Header
                    title={
                        <Stack
                            direction="row"
                            alignItems="center"
                        >
                            <BackButton
                                sx={{ padding: 0 }}
                                onClick={onBack}
                            >
                                <ArrowBackOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                                Back
                            </BackButton>
                            <CardTitleText size="large" weight="bold" sx={{
                                flexShrink: 0,
                                display: 'flex',
                                alignItems: 'center',
                                alignSelf: 'center',
                                textAlign: 'center',
                                pointerEvents: 'none',
                                gap: 1,
                            }}>🔑 Keys</CardTitleText>
                        </Stack>
                    }
                />
            }
            withBodyContainer={false}
            body={
                <Stack
                    gap={2.5}
                    sx={{
                        p: 2.5,
                        maxHeight: '100%',
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    <Stack direction="row" gap={1}>
                        <Button
                            size="small"
                            variant={currentType === 'requestKeynest' ? 'contained' : 'outlined'}
                            disabled={currentType === 'requestKeynest'}
                            onClick={() => {
                                setCurrentType('requestKeynest');
                                fetchKeys('requestKeynest');
                            }}
                        >
                            Request Keynest
                        </Button>
                        <Button
                            size="small"
                            variant={currentType === 'keynest' ? 'contained' : 'outlined'}
                            disabled={currentType === 'keynest'}
                            onClick={() => {
                                setCurrentType('keynest');
                                fetchKeys('keynest');
                            }}
                        >
                            At Keynest
                        </Button>
                    </Stack>
                    {
                        fetching ? (
                            <CircularProgress />
                        ) : Object.keys(keys).map((agentId) => (
                            <Card
                                key={agentId}
                                header={
                                    <Header
                                        title={Object.values(keys[agentId])[0][0].agent.name}
                                        titleSize="medium"
                                    />
                                }
                                sx={{ backgroundColor: 'secondary.light' }}
                                body={
                                    <Stack
                                        gap={2}
                                    >
                                        {
                                            Object.keys(keys[agentId]).map((propId) => (
                                                <Card
                                                    key={propId}
                                                    header={
                                                        <PropertyDisplay
                                                            property={keys[agentId][propId][0].property}
                                                            titleSize="medium"
                                                        />
                                                    }
                                                    sx={{ backgroundColor: 'white' }}
                                                    body={
                                                        <Stack gap={1}>
                                                            {
                                                                keys[agentId][propId].map((key) => (
                                                                    <Card
                                                                        key={key.id}
                                                                        header={
                                                                            <Keys
                                                                                keys={key}
                                                                                propertyId={key.propertyId}
                                                                                selectedId={key.id}
                                                                                selectedOnly={true}
                                                                                deactivatable={true}
                                                                                headerProps={{ titleSize: 'medium' }}
                                                                                onDeactivate={() => {
                                                                                    deactivateKey(key);
                                                                                }}
                                                                            />
                                                                        }
                                                                        body={
                                                                            <Stack>
                                                                                <Text
                                                                                    size="medium"
                                                                                    weight="bold"
                                                                                    sx={{ mb: '12px' }}
                                                                                >🔗 Keynest Magic Link (dropoff)</Text>
                                                                                <ControlledInput
                                                                                    required
                                                                                    placeholder="https://keynest.com/keys/1987387510"
                                                                                    defaultValue={currentType === 'keynest' && key.data ? key.data.dropoffUrl : null}
                                                                                    onChange={(value, valid) => {
                                                                                        setKeynestData((kData) => ({
                                                                                            ...kData,
                                                                                            [key.id]: {
                                                                                                ...(kData[key.id] || { pickup: { value: '', valid: false }, dropoff: { value: '', valid: false } }),
                                                                                                dropoff: { value, valid },
                                                                                            }
                                                                                        }));
                                                                                    }}
                                                                                    size="small"
                                                                                />
                                                                                <Text
                                                                                    size="medium"
                                                                                    weight="bold"
                                                                                    sx={{ m: '12px 0px' }}
                                                                                >🔗 Keynest Magic Link (pickup)</Text>
                                                                                <ControlledInput
                                                                                    required
                                                                                    placeholder="https://keynest.com/keys/1987387510"
                                                                                    defaultValue={currentType === 'keynest' && key.data ? key.data.linkUrl : null}
                                                                                    onChange={(value, valid) => {
                                                                                        setKeynestData((kData) => ({
                                                                                            ...kData,
                                                                                            [key.id]: {
                                                                                                ...(kData[key.id] || { pickup: { value: '', valid: false }, dropoff: { value: '', valid: false } }),
                                                                                                pickup: { value, valid },
                                                                                            }
                                                                                        }));
                                                                                    }}
                                                                                    size="small"
                                                                                />
                                                                            </Stack>
                                                                        }
                                                                        footer={
                                                                            <Footer
                                                                                align='flex-end'
                                                                                actions={
                                                                                    [
                                                                                        {
                                                                                            button: true,
                                                                                            buttonVariant: 'outlined',
                                                                                            value: 'SAVE',
                                                                                            sx: (
                                                                                                currentType === 'requestKeynest' &&
                                                                                                keynestData[key.id] &&
                                                                                                keynestData[key.id].pickup.valid &&
                                                                                                keynestData[key.id].dropoff.valid
                                                                                            ) ||
                                                                                                (currentType === 'keynest' && keynestData[key.id] && (keynestData[key.id].pickup.valid || keynestData[key.id].dropoff.valid))
                                                                                                ? {
                                                                                                    opacity: 1,
                                                                                                    pointerEvents: 'all',
                                                                                                } : {
                                                                                                    opacity: 0.3,
                                                                                                    pointerEvents: 'none',
                                                                                                    cursor: 'not-allowed',
                                                                                                },
                                                                                            onClick: () => {
                                                                                                if (currentType === 'requestKeynest') {
                                                                                                    saveKey(key, {
                                                                                                        pickup: keynestData[key.id].pickup.value,
                                                                                                        dropoff: keynestData[key.id].dropoff.value,
                                                                                                    });
                                                                                                } else if (currentType === 'keynest') {
                                                                                                    saveKey(key, {
                                                                                                        ...(keynestData[key.id].pickup.valid ? { pickup: keynestData[key.id].pickup.value } : {}),
                                                                                                        ...(keynestData[key.id].dropoff.valid ? { dropoff: keynestData[key.id].dropoff.value } : {}),
                                                                                                    });
                                                                                                }
                                                                                            },
                                                                                        },
                                                                                    ]}
                                                                            />
                                                                        }
                                                                    />
                                                                ))
                                                            }
                                                        </Stack>
                                                    }
                                                />
                                            ))
                                        }
                                    </Stack>
                                }
                            />
                        ))
                    }
                </Stack>
            }
        />
    );
};

export default AdminKeys;