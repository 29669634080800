import React, { useState } from 'react';
import { IMaskMixin } from 'react-imask';
import TextField from '@mui/material/TextField';

const MaskedTextField = IMaskMixin(
    ({ inputRef, value, ...otherProps }) => {
        return <TextField {...otherProps} inputRef={inputRef} value={value} />
    }
)

const ControlledInput = ({ name, label, validator, errorHelperText, helperText, defaultValue, onChange = () => { }, ...otherProps }) => {
    const [value, setValue] = useState(defaultValue || otherProps.value || '');
    const [error, setError] = useState(false);

    return (
        <MaskedTextField
            error={error}
            id={name}
            name={name}
            helperText={error ? errorHelperText : helperText}
            label={label}
            value={value}
            defaultValue={defaultValue}
            onBlur={(e) => {
                if (validator) {
                    setError(!validator.test(value));
                }
            }}
            onAccept={value => {
                let valid = true;
                if (validator) {
                    valid = validator.test(value);
                    if (error) {
                        setError(!valid);
                    }
                }

                setValue(value);
                onChange(value, valid);
            }}
            onChange={
                otherProps.mask
                    ? () => { }
                    : (e) => {
                        const value = e.target.value;
                        let valid = true;
                        if (validator) {
                            valid = validator.test(value);
                            if (error) {
                                setError(!valid);
                            }
                        }
                        if (otherProps.required && value === '') {
                            valid = false;
                        }
                        setValue(value);
                        onChange(value, valid);
                    }
            }
            {...otherProps}
        />
    )
};

export default ControlledInput;