import { useParams } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Card from "../../Components/library/Cards/Generic";
import Header from "../../Components/library/Cards/Headers/Standard";
import Footer from "../../Components/library/Cards/Footers/Action";
import Text from "../../Components/library/Typography/Text";

import APPS_CONFIG from "../config";

import {
    ContainerCard,
    CardHeaderContainer,
} from '../Leads/leads.styled';

const AdminLeftPanel = ({
    onSelectView = () => { },
}) => {
    const params = useParams();

    return (
        <ContainerCard
            header={
                <CardHeaderContainer>
                    <Header
                        title={
                            <Text size="large" weight="bold" sx={{
                                flexShrink: 0,
                                display: 'flex',
                                alignItems: 'center',
                                alignSelf: 'flex-start',
                                textAlign: 'left',
                                justifyContent: 'flex-start',
                                pointerEvents: 'none',
                                gap: 1,
                                position: 'relative',
                                paddingBottom: 1,
                            }}><img src={APPS_CONFIG.user.logo} width="20px" /> Admin</Text>
                        }
                    />
                </CardHeaderContainer>
            }
            withBodyContainer={false}
            body={
                <Stack
                    gap={2.5}
                    sx={{
                        p: 2.5,
                        maxHeight: '100%',
                        height: '100%',
                        overflow: 'auto',
                        transition: 'opacity 0.5s ease-out',
                    }}
                >
                    <Card
                        selected={params.view === 'schedule'}
                        header={
                            <Header
                                emoji='📅'
                                title="Schedule"
                                sx={{ backgroundColor: APPS_CONFIG.user.color }}
                            />
                        }
                        body={
                            <Text
                                color="primary.light"
                                span
                                sx={{ width: '100%', textAlign: 'center' }}
                            >
                                Shedule the next <Text weight="bold" span>2 weeks</Text> of clem availability
                            </Text>
                        }
                        footer={
                            <Footer
                                align='flex-end'
                                actions={[
                                    {
                                        button: true,
                                        buttonVariant: 'contained',
                                        value: 'See Schedule',
                                        sx: {
                                            opacity: params.view === 'schedule' ? 0.5 : 1,
                                            pointerEvents: params.view === 'schedule' ? 'none' : 'all',
                                        },
                                        onClick: () => {
                                            onSelectView('schedule')
                                        }
                                    },
                                ]}
                            />
                        }
                    />
                    <Card
                        selected={params.view === 'sessions'}
                        header={
                            <Header
                                emoji='👀'
                                title="Sessions"
                                sx={{ backgroundColor: APPS_CONFIG.user.color }}
                            />
                        }
                        body={
                            <Text
                                color="primary.light"
                                span
                                sx={{ width: '100%', textAlign: 'center' }}
                            >
                                Create & Manage <Text weight="bold" span>sessions</Text>
                            </Text>
                        }
                        footer={
                            <Footer
                                align='flex-end'
                                actions={[
                                    {
                                        button: true,
                                        buttonVariant: 'contained',
                                        value: 'See Sessions',
                                        sx: {
                                            opacity: params.view === 'sessions' ? 0.5 : 1,
                                            pointerEvents: params.view === 'sessions' ? 'none' : 'all',
                                        },
                                        onClick: () => {
                                            onSelectView('sessions')
                                        }
                                    },
                                ]}
                            />
                        }
                    />
                    <Card
                        selected={params.view === 'reports'}
                        header={
                            <Header
                                emoji='📂'
                                title="Reports"
                                sx={{ backgroundColor: APPS_CONFIG.user.color }}
                            />
                        }
                        body={
                            <Text
                                color="primary.light"
                                span
                                sx={{ width: '100%', textAlign: 'center' }}
                            >
                                Create & Manage <Text weight="bold" span>reports</Text>
                            </Text>
                        }
                        footer={
                            <Footer
                                align='flex-end'
                                actions={[
                                    {
                                        button: true,
                                        buttonVariant: 'contained',
                                        value: 'See Reports',
                                        sx: {
                                            opacity: params.view === 'reports' ? 0.5 : 1,
                                            pointerEvents: params.view === 'reports' ? 'none' : 'all',
                                        },
                                        onClick: () => {
                                            onSelectView('reports')
                                        }
                                    },
                                ]}
                            />
                        }
                    />
                    <Card
                        selected={params.view === 'keys'}
                        header={
                            <Header
                                emoji='🔑'
                                title="Keys"
                                sx={{ backgroundColor: APPS_CONFIG.user.color }}
                            />
                        }
                        body={
                            <Text
                                color="primary.light"
                                span
                                sx={{ width: '100%', textAlign: 'center' }}
                            >
                                Manage <Text weight="bold" span>keys</Text>
                            </Text>
                        }
                        footer={
                            <Footer
                                align='flex-end'
                                actions={[
                                    {
                                        button: true,
                                        buttonVariant: 'contained',
                                        value: 'See Keys',
                                        sx: {
                                            opacity: params.view === 'keys' ? 0.5 : 1,
                                            pointerEvents: params.view === 'keys' ? 'none' : 'all',
                                        },
                                        onClick: () => {
                                            onSelectView('keys')
                                        }
                                    },
                                ]}
                            />
                        }
                    />
                    <Card
                        selected={params.view === 'billing'}
                        header={
                            <Header
                                emoji='🧾'
                                title="Bills"
                                sx={{ backgroundColor: APPS_CONFIG.user.color }}
                            />
                        }
                        body={
                            <Text
                                color="primary.light"
                                span
                                sx={{ width: '100%', textAlign: 'center' }}
                            >
                                Manage <Text weight="bold" span>bills</Text>
                            </Text>
                        }
                        footer={
                            <Footer
                                align='flex-end'
                                actions={[
                                    {
                                        button: true,
                                        buttonVariant: 'contained',
                                        value: 'See Bills',
                                        sx: {
                                            opacity: params.view === 'billing' ? 0.5 : 1,
                                            pointerEvents: params.view === 'billing' ? 'none' : 'all',
                                        },
                                        onClick: () => {
                                            onSelectView('billing')
                                        }
                                    },
                                ]}
                            />
                        }
                    />
                </Stack>
            }
        />
    );
};

export default AdminLeftPanel;