import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { FieldContainer, Email, ActionButton } from './Login.styled';

const ConfirmRegister = ({ user, username, onUser }) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        code: {
            value: '',
            valid: false,
        }
    });

    const handleFormData = (key, value, valid) => {
        const newFormData = { ...formData, [key]: { value, valid } };
        setFormData(newFormData);
    };

    const handleConfirmRegister = async () => {
        try {
            await Auth.confirmSignUp(username, formData.code.value);
            setLoading(false);
            onUser(user);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }

    return (
        <FieldContainer>
            <Email
                required
                name="register-code-validation"
                label="Signup code"
                helperText="Enter the signup code sent to you by email"
                placeholder=""
                onChange={(value, valid) => handleFormData('code', value, valid)}
            />
            {error}
            <ActionButton
                loading={loading}
                disabled={!Object.keys(formData).reduce((final, curr) => (final && formData[curr].valid), true)}
                variant="outlined"
                size="large"
                onClick={handleConfirmRegister}
            >
                Validate
            </ActionButton>
        </FieldContainer >
    );
};

export default ConfirmRegister;