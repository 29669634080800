import { styled } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) => ({
    position: 'relative',
}));

export const StatusContainer = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    width: '28px',
    height: '28px',
    borderRadius: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '22px',
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    zIndex: 2,
}));

export const InputStatusContainer = styled(StatusContainer)(({ theme }) => ({
    top: '8px',
    right: '8px',
}));
