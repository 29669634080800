import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Card from '../../Components/library/Cards/Generic';
import Header from '../../Components/library/Cards/Headers/Standard';
import Text from '../../Components/library/Typography/Text';
import Tag from '../../Components/library/Tag/Tag';

export const ContainerCard = styled(Card)(({ theme }) => ({
    // minWidth: '600px',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    maxHeight: '100%',
    overflow: 'hidden',
    position: 'relative',
    flexShrink: 1,

    [theme.breakpoints.down(800)]: {
        minWidth: '100%',
        maxWidth: '100%',
        border: 'none',
    },
}));

export const CardHeaderContainer = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down(800)]: {
        padding: '8px 8px 0px',
    },
}));

export const MobileBackContainer = styled(Stack)(({ theme }) => ({
    display: 'none',
    backgroundColor: 'white',
    padding: '8px',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,

    [theme.breakpoints.down(800)]: {
        display: 'flex',
    }
}));

export const ContainerSelectDisplay = styled(Stack)(({ theme }) => ({
    padding: '20px',
    width: '100%',
    gap: '20px',

    [theme.breakpoints.down(800)]: {
        padding: '12px',
        paddingTop: '0px', // '8px',
        gap: '8px',
    },
}));

export const CardSelectDisplayTitle = styled(Text)(({ theme }) => ({
    fontSize: '14px',
    width: '100%',

    [theme.breakpoints.down(800)]: {
        fontSize: '12px',
    },
}));

export const CardSelectDisplayBody = styled(Stack)(({ theme }) => ({
    padding: '20px',

    [theme.breakpoints.down(800)]: {
        padding: '12px',
    },
}));

export const CardSelectDisplayValue = styled(Text)(({ theme }) => ({
    fontSize: '30px',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down(800)]: {
        fontSize: '20px',
    },
}));

export const BackButton = styled(Button)(({ theme }) => ({
    display: 'none',

    [theme.breakpoints.down(1000)]: {
        display: 'flex',
    },
}));

export const CardTitleText = styled(Text)(({ theme }) => ({
    position: 'relative',
    justifyContent: 'center',

    [theme.breakpoints.down(1000)]: {
        position: 'absolute',
        width: 'calc(100% - 24px)',
    },
}));