import { useNavigate } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import { subMinutes, addMinutes } from 'date-fns';
import copy from 'copy-to-clipboard';

import Stack from '@mui/material/Stack';

import Card from "../../../Components/library/Cards/Generic";
import Header from "../../../Components/library/Cards/Headers/Standard";
import Tag from "../../../Components/library/Tag/Tag";
import FeedbackButton from '../../../Components/library/Button/FeedbackButton';
import ActionFooter from '../../../Components/library/Cards/Footers/Action';
import Text from '../../../Components/library/Typography/Text';
import { Divider } from '@mui/material';
import getViewingMessage from '../../../Components/Clem/Session/ViewingMessage';
import Keys from '../../Keys/Keys';

import APPS_CONFIG from '../../config';
import KeysDisplay from '../../Keys/KeysDisplay';

const gMapUrl = (addr, postcode) => (
    `https://www.google.co.uk/maps/search/${addr.replace(/ /gi, '+')}+${postcode.replace(/ /gi, '+')}`
);

const Session = ({
    session,
}) => {
    const navigate = useNavigate();

    console.log(session);

    session.viewings = session.viewings.sort((a, b) => new Date(a.startsAt) - new Date(b.startsAt));
    const cancelled = !session.viewings.find(v => !v.cancelled);

    return (
        <Card
            sx={{
                opacity: !cancelled ? 1 : 0.5,
            }}
            header={
                <Header
                    emoji="🏡"
                    title={
                        <FeedbackButton
                            revertAfter={2000}
                            variant="outlined"
                            size="large"
                            sx={{
                                textTransform: 'none',
                                maxWidth: 'calc(100% - 4px)',
                                padding: '4px 8px',
                                border: '1px solid transparent',
                                textAlign: 'left',
                            }}
                            feedback={<div>{`${session.property.address} - ${session.property.postcode} (copied to clipboard)`}</div>}
                        >
                            {`${session.property.address} - ${session.property.postcode}`}
                        </FeedbackButton>
                    }
                    tags={[
                        ...(cancelled ? ([
                            <Tag
                                key='cancelled'
                                sx={{
                                    backgroundColor: 'white',
                                }}
                            >Cancelled</Tag>
                        ]) : []),
                        <Tag
                            key='viewingsNum'
                            sx={{
                                backgroundColor: 'white',
                            }}
                        >{`${session.viewings.length} viewing${session.viewings.length > 1 ? 's' : ''}`}</Tag>
                    ]}

                    sx={{
                        backgroundColor: APPS_CONFIG.clem.color,
                    }}
                />
            }
            body={
                <Stack
                    gap={2.5}
                >
                    {
                        session.keys || session.info ? (
                            <Card
                                header={
                                    <Header
                                        title="Information"
                                        sx={{
                                            backgroundColor: APPS_CONFIG.clem.color,
                                        }}
                                        tags={[
                                            <Tag
                                                key="from"
                                                sx={{
                                                    backgroundColor: 'white',
                                                }}
                                            >From Clem</Tag>
                                        ]}
                                    />
                                }
                                body={
                                    <Stack
                                        gap={2.5}
                                    >
                                        {
                                            session.keys ? (
                                                <Stack
                                                    gap={1}
                                                >
                                                    <Text size="medium" weight="bold" color="primary.light">🔑 Keys information</Text>
                                                    <Stack>
                                                        {
                                                            session.keys.split('\n').map((n, i) => <Text key={`line-${i}`}>{n}</Text>)
                                                        }
                                                    </Stack>
                                                </Stack>
                                            ) : null
                                        }
                                        {
                                            session.info ? (
                                                <Stack
                                                    gap={1}
                                                >
                                                    <Text size="medium" weight="bold" color="primary.light">⭕ Additional Information</Text>
                                                    <Stack>
                                                        {
                                                            session.info.split('\n').map((n, i) => <Text key={`line-${i}`}>{n}</Text>)
                                                        }
                                                    </Stack>
                                                </Stack>
                                            ) : null
                                        }
                                    </Stack >
                                }
                            />
                        ) : null
                    }
                    <Card
                        header={
                            <Header
                                title="Keys"
                                sx={{
                                    backgroundColor: APPS_CONFIG.clem.color,
                                }}
                            />
                        }
                        body={
                            <KeysDisplay
                                selected={false}
                                selectable={false}
                                deactivatable={false}
                                keys={session.keysObj}
                            />
                        }
                    />
                    <Card
                        header={
                            <Header
                                title="Property"
                                sx={{
                                    backgroundColor: APPS_CONFIG.clem.color,
                                }}
                                tags={[
                                    <Tag
                                        key="from"
                                        sx={{
                                            backgroundColor: 'white',
                                        }}
                                    >From Customer</Tag>
                                ]}
                            />
                        }
                        body={
                            <Stack
                                gap={1.5}
                            >
                                {
                                    [
                                        { key: '🌍 Google Maps', value: gMapUrl(session.property.address, session.property.postcode) },
                                        { key: '💻 Listing URL', value: session.property.listingUrl },
                                        { key: '🛬 Arrival', value: formatInTimeZone(subMinutes(new Date(session.viewings[0].startsAt), 15), 'Etc/UTC', 'kk:mm') },
                                        { key: '🛫 Departure', value: formatInTimeZone(addMinutes(new Date(session.viewings[session.viewings.length - 1].startsAt), 20), 'Etc/UTC', 'kk:mm') },
                                        { key: '👥 Occupied', value: session.property.occupied ? 'Yes' : 'No' },
                                        { key: '💷 Price', value: session.property.price ? `£${session.property.price} (pcm)` : 'See listing' },
                                    ].map((obj) => (
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={1}
                                            key={obj.key}
                                        >
                                            <Tag sx={{ width: '150px' }}>{obj.key}</Tag>
                                            {
                                                obj.value.startsWith('https://') ? (
                                                    <Text href={obj.value} target="_blank" weight="bold">Link ↗️</Text>
                                                ) : (
                                                    <Text>{obj.value}</Text>
                                                )
                                            }
                                        </Stack>
                                    ))
                                }
                                {
                                    session.property.info ? (
                                        <Stack
                                            gap={1.5}
                                            sx={{ pt: 1.5 }}
                                        >
                                            <Text size="medium" weight="bold" color="primary.light">Notes</Text>
                                            <Stack>
                                                {
                                                    session.property.info.split('\n').map((n, i) => <Text key={`line-${i}`}>{n}</Text>)
                                                }
                                            </Stack>
                                        </Stack>
                                    ) : null
                                }
                            </Stack>
                        }
                    />
                    <Stack
                        gap={2.5}
                        sx={{ pt: 2.5 }}
                    >
                        <Text size="large" weight="bold">Viewings</Text>
                        <Divider />
                        <Stack
                            gap={1.5}
                        >
                            {
                                session.viewings.map((viewing, idx) => (
                                    <Card
                                        key={`viewing-${idx}`}
                                        sx={{
                                            opacity: viewing.cancelled ? 0.5 : 1,
                                            pointerEvents: viewing.cancelled ? 'none' : 'all',
                                        }}
                                        header={
                                            <Header
                                                title={viewing.viewer.name}
                                                tags={[
                                                    ...(viewing.cancelled ? ([
                                                        <Tag
                                                            key='cancelled'
                                                            sx={{
                                                                backgroundColor: 'white',
                                                            }}
                                                        >Cancelled</Tag>
                                                    ]) : []),
                                                    ...(viewing.viewer.presence ? ([
                                                        <Tag
                                                            key='presence'
                                                            sx={{
                                                                backgroundColor: 'white',
                                                            }}
                                                        >{viewing.viewer.presence}</Tag>
                                                    ]) : []),
                                                    <Tag
                                                        key='time'
                                                        sx={{
                                                            backgroundColor: 'white',
                                                        }}
                                                    >
                                                        {formatInTimeZone(new Date(viewing.startsAt), 'Etc/UTC', 'kk:mm')}
                                                    </Tag>,
                                                    ...(viewing.type === 'BLOCK' ? ([
                                                        <Tag
                                                            key='timeend'
                                                            sx={{
                                                                backgroundColor: 'white',
                                                            }}
                                                        >
                                                            End: {formatInTimeZone(addMinutes(new Date(viewing.endsAt), 1), 'Etc/UTC', 'kk:mm')}
                                                        </Tag>
                                                    ]) : []),
                                                ]}
                                                sx={{
                                                    backgroundColor: APPS_CONFIG.clem.color,
                                                }}
                                            />
                                        }
                                        body={
                                            viewing.type !== 'BLOCK' ? (
                                                <Stack
                                                    gap={2.5}
                                                >
                                                    <FeedbackButton
                                                        revertAfter={2000}
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{ textTransform: 'none' }}
                                                        feedback={<div>Copied to clipboard</div>}
                                                    >
                                                        {viewing.viewer.phone}
                                                    </FeedbackButton>

                                                    {
                                                        !viewing.cancelled && viewing.type !== 'BLOCK' ? (<Divider />) : null
                                                    }
                                                    {
                                                        viewing.notes ? (
                                                            <Stack
                                                                gap={1.5}
                                                            >
                                                                <Text size="medium" weight="bold" color="primary.light">Notes</Text>
                                                                <Stack>
                                                                    {
                                                                        viewing.notes.split('\n').map((n, i) => <Text key={`line-${i}`}>{n}</Text>)
                                                                    }
                                                                </Stack>
                                                            </Stack>
                                                        ) : null
                                                    }
                                                </Stack>
                                            ) : (
                                                viewing.notes ? (
                                                    <Stack
                                                        gap={1.5}
                                                    >
                                                        <Text size="medium" weight="bold" color="primary.light">Notes</Text>
                                                        <Stack>
                                                            {
                                                                viewing.notes.split('\n').map((n, i) => <Text key={`line-${i}`}>{n}</Text>)
                                                            }
                                                        </Stack>
                                                    </Stack>
                                                ) : null
                                            )
                                        }
                                        footer={
                                            !viewing.cancelled && viewing.type !== 'BLOCK' ? (
                                                <ActionFooter
                                                    align='flex-end'
                                                    actions={[
                                                        {
                                                            button: true,
                                                            buttonVariant: 'outlined',
                                                            value: 'Copy Message',
                                                            onClick: () => {
                                                                copy(getViewingMessage(viewing, session.property))
                                                            },
                                                        },
                                                        {
                                                            button: true,
                                                            buttonVariant: 'outlined',
                                                            href: viewing.viewer ? `https://api.whatsapp.com/send/?phone=${viewing.viewer.phone.replace('+44 0', '44')}&text=${encodeURIComponent(getViewingMessage(viewing, session.property))}&type=phone_number` : '',
                                                            value: 'Send Message',
                                                        },
                                                    ]}
                                                />
                                            ) : null
                                        }
                                    />
                                ))
                            }
                        </Stack>
                    </Stack>
                </Stack >
            }
            footer={
                !cancelled ? (
                    <ActionFooter
                        align='flex-end'
                        actions={
                            [
                                {
                                    button: true,
                                    buttonVariant: 'contained',
                                    value: 'Report on Session',
                                    onClick: () => {
                                        navigate(`/app/clem/report/${session.id}`);
                                    },
                                },
                            ]}
                    />
                ) : null
            }
        />
    );
};

export default Session;