import { styled } from "@mui/material";
import Tag from "./Tag";

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    backgroundColor: theme.palette.secondary.light,
    height: '24px',
    borderRadius: '20px',
    minWidth: '80px',
    width: '100px',
    justifyContent: 'center',
}))

const MarketPlaceTag = ({ marketPlace = '', sx = {}, ...otherProps }) => {
    return (
        <Container sx={{ padding: marketPlace.toLowerCase() === 'rightmove' ? '0 8px' : '0 12px', ...sx }} {...otherProps}>
            {marketPlace.toLowerCase() === 'rightmove' ? <img src="/app/rightmove-logo.svg" height="20px" /> : null}
            {marketPlace.toLowerCase() === 'zoopla' ? <img src="/app/zoopla-logo.png" height="10px" /> : null}
            {marketPlace.toLowerCase() === 'onthemarket' ? <img src="/app/onthemarket-logo.png" height="14px" /> : null}
        </Container>
    );
};

export default MarketPlaceTag;