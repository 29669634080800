import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container, Content } from './layout.styled.js';
import NavBar from '../NavBar/NavBar';
import NavBarV2 from '../NavBar/NavBar_V2';

const Layout = ({ admin }) => {
    return (
        <Container>
            {/* <NavBar admin={admin} /> */}
            <NavBarV2 admin={admin} />
            <Content>
                <Outlet />
            </Content>
        </Container>
    )
};

export default Layout;