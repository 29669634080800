import Card from "../../Components/library/Cards/Generic";
import Header from "../../Components/library/Cards/Headers/Standard";
import Tag from "../../Components/library/Tag/Tag";
import Text from "../../Components/library/Typography/Text";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider'

const CompletionCard = ({ messages }) => {
    const completedMessage = messages.find((m) => m.role === 'assistant' && m.parsed.command.name === 'task_complete');
    const completedMessageObj = completedMessage ? completedMessage.parsed.command.args.reason : null;

    if (!completedMessage) {
        return null;
    }

    return (
        <Card
            sx={{
                margin: '12px',
                mt: '0px',
                width: 'calc(100% - 24px)',
            }}
            header={
                <Header
                    title="Conversation Summary"
                    tags={[
                        <Tag>{completedMessage ? (
                            completedMessageObj.qualified ?
                                '✅ Qualified' : '❌ Rejected'
                        ) : '👨‍💻 In Progress'}</Tag>
                    ]}
                />
            }
            body={
                completedMessageObj ? (
                    <Stack
                        gap={1.5}
                        direction="row"
                        flexWrap="wrap"
                    >
                        {
                            Object.keys(completedMessageObj.requirements).filter(key => completedMessageObj.requirements[key].relevant).map(key => (
                                <Stack
                                    sx={{ width: 'fit-content', flexShrink: 0 }}
                                >
                                    <Divider flexItem />
                                    <Stack
                                        direction="row"
                                        gap={1}
                                        alignItems="center"
                                    >
                                        <Divider orientation="vertical" flexItem />
                                        <Text>{key.toUpperCase()}</Text>
                                        <Divider orientation="vertical" flexItem />
                                        <Stack
                                            direction="row"
                                            gap={1}
                                            alignItems="center"
                                            sx={{ padding: '4px' }}
                                        >
                                            <Text>{`${completedMessageObj.requirements[key].passed ? '✅' : '❌'}`}</Text>
                                            <Text>{`${completedMessageObj.requirements[key].response}`}</Text>
                                        </Stack>
                                        <Divider orientation="vertical" flexItem />
                                    </Stack>
                                    <Divider flexItem />
                                </Stack>
                            ))
                        }
                    </Stack>
                ) : null
            }
        />
    );
};

export default CompletionCard;