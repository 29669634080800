import { DataStore } from '@aws-amplify/datastore';
import { AIPrequal } from '../models';
import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';

const queryAI = async (op, payload) => {
    console.log(payload);
    return JSON.parse((await API.graphql({
        query: queries.aiPrequal,
        variables: {
            op,
            payload: JSON.stringify({ ...payload }),
        },
    })).data.aiPrequal);
};

export const getAiPrequals = async () => {
    return await DataStore.query(AIPrequal);
};

export const createAiPrequalForLead = async (lead) => {
    const res = await queryAI('createPrequalChatAppSync', { // 'createPrequalChat', {
        leadId: lead.id,
        propertyId: lead.propertyId,
        userName: lead.name
    });

    return res;
};

export const aiPrequalSubscription = (cb, predicate) => {
    const q = DataStore.observeQuery(AIPrequal, predicate);
    const subscription = q.subscribe(async ({ items, isSynced }) => {
        cb(items, isSynced);
    });

    return subscription;
};

export default { // eslint-disable-line
    getAll: getAiPrequals,
    createForLead: createAiPrequalForLead,
    subscribeToProperty: (cb, propId) => aiPrequalSubscription(cb, (v) => v.propertyId('eq', propId)),
};