import React from 'react';
import { useOutlet } from 'react-router-dom';
import { DataStore } from '@aws-amplify/datastore';
import { Hub } from 'aws-amplify';
import Login from './Components/Auth/Auth';

const Context = React.createContext();

export const Outlet = ({ data }) => {
    const el = useOutlet();
    return (
        <Context.Provider value={data}>{el}</Context.Provider>
    );
};

export const useOutletContext = () => {
    const context = React.useContext(Context);
    if (!context) {
        throw Error('Using context while not in an Outlet Provider');
    }
    return context;
};

Hub.listen('auth', async (data) => {
    if (data.payload.event === 'signOut') {
        console.log('clearing db');
        await DataStore.clear();
    }
});


const Auth = () => {
    return (
        <Login>
            {({ signOut, user, userData }) => {
                return (
                    <Outlet data={{
                        signOut,
                        user,
                        userData,
                        userAttributes: user.challengeParam ? user.challengeParam.userAttributes : user.attributes,
                    }} />
                );
            }}
        </Login>
    );
};

export default Auth;