import { getData, distanceFromViewingStart } from "../utils";
import DataElem from "../DataElem";
import FileComp from "../../Reporter/Form/Inputs/File";
import { SplitContentContainer, SplitContent, SplitContentSingle } from '../SessionReport.styled';

export default { // eslint-disable-line
    name: 'keyspickup',
    emoji: '🔑',
    time: (reports) => {
        return getData(reports.sessionReport, 'times', 'keypickup') || 'Not yet';
    },
    title: (reports) => {
        return (
            <>
                Clem picked up the keys
            </>
        );
    },
    subtitle: (reports) => {
        return (
            <>
                from
                <DataElem
                    report={reports.sessionReport}
                    attr="info"
                    objkey="keypickup-location"
                />
            </>
        );
    },
    content: (reports) => {
        const s3URLS = getData(reports.sessionReport, 'files', 'images-keypickup');

        return (
            <SplitContentContainer>
                <SplitContent>
                    {
                        s3URLS.map((url, idx) => (
                            <FileComp file={{ s3: url }} type="image" display key={`images-keypickup${idx}`} />
                        ))
                    }
                </SplitContent>
                <SplitContentSingle>
                    🔑
                    <DataElem
                        report={reports.sessionReport}
                        attr="info"
                        objkey="clem-name"
                    />
                    picked up the keys
                    <DataElem
                        text={
                            distanceFromViewingStart({ time: getData(reports.sessionReport, 'times', 'keypickup'), viewingReport: reports.viewingReports[0] })
                        }
                    />
                    the first viewing
                </SplitContentSingle>
            </SplitContentContainer>
        );
    },
};