import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { FileContainerImg, FileContainerVideo, Image, Video, FileUploadProgress, StatusContainer, DeleteActionContainer } from './inputs.styled';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Button from '@mui/material/Button';

const FileComp = ({ file, onDelete, type, display = false }) => {
    const [s3URL, setS3URL] = useState(null);
    const [showDeleteStatus, setShowDeleteStatus] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
        const getUrl = async () => {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const signedURL = await Storage.get(file.s3.replace('s3://', ''));
            setS3URL(signedURL);
        }

        if (file.s3 && !s3URL) {
            getUrl();
        }
    }, [file]); // eslint-disable-line

    const Container = type === 'image' ? FileContainerImg : FileContainerVideo;

    return (
        <Container data-display={display}>
            {
                s3URL || file.src ? (
                    type === 'image' ? (
                        <Image src={s3URL ? s3URL : file.src} />
                    ) : (
                        <Video controls >
                            <source src={s3URL ? s3URL : file.src} />
                            Your browser does not support the video tag.
                        </Video>
                    )
                ) : null
            }
            {
                !display ? (
                    <>
                        {
                            !s3URL || (file.progress && file.progress !== 1) ? (
                                <FileUploadProgress>{`${Math.ceil(file.progress * 100)}%`}</FileUploadProgress>
                            ) : null
                        }
                        <StatusContainer
                            onMouseEnter={() => setShowDeleteStatus(true)}
                            onMouseLeave={() => setShowDeleteStatus(false)}
                            onClick={() => setShowDelete(!showDelete)}
                        >
                            {
                                showDeleteStatus ? (
                                    <DeleteForeverOutlinedIcon sx={{ fontSize: 'inherit' }} />
                                ) : (
                                    !s3URL ? (
                                        <BackupOutlinedIcon sx={{ fontSize: 'inherit' }} />
                                    ) : <CloudDoneOutlinedIcon sx={{ fontSize: 'inherit' }} />
                                )
                            }
                        </StatusContainer>
                        {
                            showDelete ? (
                                <DeleteActionContainer>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        startIcon={<CloseOutlinedIcon sx={{ fontSize: 'inherit' }} />}
                                        onClick={() => { setShowDelete(false) }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        startIcon={<DeleteForeverOutlinedIcon sx={{ fontSize: 'inherit' }} />}
                                        onClick={() => { onDelete(file) }}
                                    >
                                        Delete
                                    </Button>
                                </DeleteActionContainer>
                            ) : null
                        }
                    </>
                ) : null
            }
        </Container>
    );
};

export default FileComp;