import { createUseStyles } from 'react-jss';

export default createUseStyles({
    fadeIn: {
        opacity: 1,
        height: '100%',
        transition: 'opacity 0.4s linear 0.4s',
    },
    fadeOut: {
        opacity: 0,
        transition: 'opacity 0.4s',
    },
});