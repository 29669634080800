import { DataStore } from '@aws-amplify/datastore';
import { format } from 'date-fns';
import { Viewing, Property, Viewer, Lead } from '../models';

export const createViewing = async (data, viewer, property) => {
    const viewing = await DataStore.save(
        new Viewing({
            startsAt: data.startsAt,
            endsAt: data.endsAt,
            viewerId: viewer.id,
            propertyId: property.id,
            leadId: data.leadId,
            keysId: data.keysId,
            date: data.date,
            postcode: data.postcode.toUpperCase().replace(/\s/g, ''),
            notes: data.notes && data.notes !== '' ? data.notes : null,
            keys: data.keys,
            type: data.type ? data.type : 'SINGLE',
        })
    );

    return viewing;
};

export const updateViewing = async (viewingId, data) => {
    const original = await DataStore.query(Viewing, viewingId);
    const viewing = await DataStore.save(
        Viewing.copyOf(original, updated => {
            updated.notes = data.notes ? data.notes : original.notes;
        })
    );

    return viewing;
}

export const getViewingsByLeads = async (leadIds) => {
    const viewings = await DataStore.query(Viewing, v => v.or(v => leadIds.map(id => v.leadId('eq', id))));
    return viewings;
};

export const deleteViewing = async (viewingId) => {
    const original = await DataStore.query(Viewing, viewingId);
    await DataStore.delete(original);
};

export const viewingsSubscription = (cb, predicate) => {
    const q = DataStore.observeQuery(Viewing, predicate);
    const subscription = q.subscribe(async ({ items, isSynced }) => {
        if (isSynced) {
            const synchedItems = await Promise.all(items.map(async (item) => {
                if (!item.Viewer || !item.Property) {
                    let property = null;
                    if (item.viewingPropertyId) {
                        property = await DataStore.query(Property, item.viewingPropertyId);
                    } else if (item.propertyId) {
                        property = await DataStore.query(Property, item.propertyId);
                    }

                    let viewer = null;
                    if (item.viewingViewerId) {
                        viewer = await DataStore.query(Viewer, item.viewingViewerId);
                    } else if (item.viewerId) {
                        viewer = await DataStore.query(Viewer, item.viewerId);
                    }

                    let lead = null;
                    if (item.leadId) {
                        lead = await DataStore.query(Lead, item.leadId);
                    }

                    if (!property || !viewer || Array.isArray(viewer)) {
                        return item;
                    }
                    const newItem = Viewing.copyOf(item, updated => {
                        updated.Property = property;
                        updated.Viewer = viewer;
                        updated.Lead = lead;
                    });
                    return newItem;
                }
                return item;
            }));

            cb(synchedItems, isSynced);
        } else {
            cb(items, isSynced);
        }
    });

    return subscription;
};

export default { // eslint-disable-line
    create: createViewing,
    update: updateViewing,
    delete: deleteViewing,
    getByLeads: getViewingsByLeads,
    viewingsSubscription,
    subscribeToProperty: (cb, propId) => viewingsSubscription(cb, (v) => v.and(v => [v.propertyId('eq', propId), v.date('ge', format(new Date(), 'yyyy-MM-dd'))])),
    subscribeToDay: (cb, day) => viewingsSubscription(cb, (v) => v.date('eq', format(day, 'yyyy-MM-dd'))),
};