import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import { API } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import FeatureFlags from '../../../data/featureFlags';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import PublicOffOutlinedIcon from '@mui/icons-material/PublicOffOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';

import Card from "../../../Components/library/Cards/Generic";
import Header from '../../../Components/library/Cards/Headers/Standard';
import Footer from '../../../Components/library/Cards/Footers/Action';

import Tag from "../../../Components/library/Tag/Tag";
import Text from "../../../Components/library/Typography/Text";
import ControlledInput from "../../../Components/Input/ControlledInput";

import PropertyInfo from './PropertyInfo';
import PropertyMarketPlaces from './PropertyMarketPlace';

import APPS_CONFIG from "../../config";

import {
    BackButton,
    CardTitleText,
} from '../../Leads/leads.styled';
import Keys from "../../Keys/Keys";

const queryAI = async (op, payload) => {
    console.log(payload);
    return JSON.parse((await API.graphql({
        query: queries.aiPrequal,
        variables: {
            op,
            payload: JSON.stringify({ ...payload }),
        },
    })).data.aiPrequal);
};

const validatePropertyData = (propertyData) => {
    const marketPlaces = propertyData.marketPlaces || [{ valid: false }];

    const validField = Object.keys(propertyData).filter(k => k !== 'marketPlaces').reduce((final, curr) => (final && propertyData[curr].valid), true);
    const validMarketPlaces = marketPlaces.reduce((final, curr) => (final && curr.valid), true);

    return validField && validMarketPlaces;
};

const Property = ({
    property,
    color = 'secondary.light',
    onSave = () => { },
    onCancel = () => { },
    onBack = () => { },
    create = false,
}) => {
    const navigate = useNavigate();
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [loadingAiPrequal, setLoadingAiPrequal] = useState(true);
    const [isAiPrequalActive, setIsAiPrequalActive] = useState(false);
    const [hasAiPrequalFeature, setHasAiPrequalFeature] = useState(false);
    const [propertyData, setPropertyData] = useState(!create ? { ...property } : {});

    useEffect(() => {
        const changeProp = async () => {
            setIsAiPrequalActive(false);
            if (!create) {
                const hasAiPrequal = await FeatureFlags.get('ai-prequal');
                setHasAiPrequalFeature(hasAiPrequal);
                if (hasAiPrequal) {
                    setLoadingAiPrequal(true);
                    const res = await queryAI('getPrequalProperty', { propertyId: property.id });
                    setIsAiPrequalActive(res && res.length > 0);
                    setLoadingAiPrequal(false);
                }
                setEditing(false);
                setSaved(false);
            }

            setPropertyData(!create ? { ...property } : {});
        };

        changeProp();
    }, [property]);

    const handleSetAiPrequal = async () => {
        setLoadingAiPrequal(true);
        const res = await queryAI('createPropertyPrompt', { propertyId: property.id });
        if (res && res.id) {
            setIsAiPrequalActive(true);
        }
        setLoadingAiPrequal(false);
    };

    return (
        <Card
            sx={{
                backgroundColor: 'background.default',
                pointerEvents: !saving ? 'all' : 'none',
                opacity: !saving ? 1 : 0.7,
                height: '100%',
            }}
            header={
                <Header
                    // emoji="🏡"
                    title={
                        <Stack
                            direction="row"
                            alignItems="center"
                        >
                            <BackButton
                                sx={{ padding: 0 }}
                                onClick={onBack}
                            >
                                <ArrowBackOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                                Back
                            </BackButton>
                            <CardTitleText size="large" weight="bold" sx={{
                                flexShrink: 0,
                                display: 'flex',
                                alignItems: 'center',
                                alignSelf: 'center',
                                textAlign: 'center',
                                pointerEvents: 'none',
                                gap: 1,
                            }}><img src={APPS_CONFIG.properties.logo} width="20px" /> Property</CardTitleText>
                            {/* <CardTitleText size="large" weight="bold" sx={{
                                flexShrink: 0,
                                alignSelf: 'center',
                                textAlign: 'center',
                                pointerEvents: 'none',
                            }}>🏡 Property</CardTitleText> */}
                        </Stack>
                    }
                    // title="Property"
                    tags={[
                        (
                            !create ? (
                                <Tag key={`${property.id}-tag-addr`}>{`${property.postcode} - ${property.address}`}</Tag>
                            ) : (
                                <Tag key={`${property.id}-tag-new`}>New Property</Tag>
                            )
                        ),
                        ...(!create && editing ? [
                            <Tag
                                color="primary.main"
                                key={`${property.id}-tag-editing`}
                            >
                                🖊️ Editing
                            </Tag>
                        ] : []),
                        ...(saving ? [
                            <Tag
                                color="primary.main"
                                key={`${property.id}-tag-saving`}
                            >
                                💾 Saving
                            </Tag>
                        ] : []),
                        ...(!editing && !saving && saved ? [
                            <Tag
                                color="primary.main"
                                key={`${property.id}-tag-saved`}
                            >
                                👍 Saved
                            </Tag>
                        ] : []),
                    ]}
                />
            }
            withBodyContainer={false}
            body={
                <Stack
                    gap={2.5}
                    sx={{
                        p: 2.5,
                        maxHeight: '100%',
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    <PropertyInfo
                        color={color}
                        property={property}
                        onSave={async (data) => {
                            setEditing(false);
                            setSaving(true);
                            await onSave('info', data);
                            setSaving(false);
                            setSaved(true);
                        }}
                        onEdit={() => setEditing(true)}
                        onCancel={() => setEditing(false)}
                        onChange={(data) => {
                            setPropertyData((pData) => ({ ...pData, ...data }));
                        }}
                        create={create}
                    />
                    <PropertyMarketPlaces
                        color={color}
                        property={property}
                        onSave={async (data) => {
                            setEditing(false);
                            setSaving(true);
                            await onSave('marketPlaces', data);
                            setSaving(false);
                            setSaved(true);
                        }}
                        onEdit={() => setEditing(true)}
                        onCancel={() => setEditing(false)}
                        onChange={(data) => setPropertyData((pData) => ({ ...pData, marketPlaces: data.newMarketPlaces }))}
                        create={create}
                    />
                    {
                        !create ? (
                            <>
                                <Keys
                                    propertyId={property.id}
                                    color={color}
                                />
                                <Card
                                    header={
                                        <Header
                                            emoji="🧑"
                                            title={
                                                <Text
                                                    size="large"
                                                    weight="bold"
                                                    color={`${color.split('.')[0]}.contrastText`}
                                                    sx={{ flexShrink: 0 }}
                                                >
                                                    Leads
                                                </Text>
                                            }
                                            sx={{ backgroundColor: color }}
                                        />
                                    }
                                    body={
                                        <Stack
                                            gap={2}
                                        >
                                            {
                                                hasAiPrequalFeature ? (
                                                    <Card
                                                        header={
                                                            <Header
                                                                emoji="❇️"
                                                                title="AI Pre-qualification"
                                                                sx={{ backgroundColor: color }}
                                                            />
                                                        }
                                                        body={
                                                            <Stack
                                                                gap={1.5}
                                                            >
                                                                <Text color="primary.light" >Prequalify the leads of this property before viewing</Text>
                                                                {
                                                                    loadingAiPrequal ? (
                                                                        <Button
                                                                            variant="outlined"
                                                                            disabled
                                                                            sx={{ width: 'fit-content' }}
                                                                            endIcon={
                                                                                <HourglassEmptyOutlinedIcon />
                                                                            }
                                                                        >
                                                                            Loading ...
                                                                        </Button >
                                                                    ) : (
                                                                        <Button
                                                                            variant="outlined"
                                                                            disabled={!property.price}
                                                                            sx={{ width: 'fit-content' }}
                                                                            endIcon={
                                                                                isAiPrequalActive ? <PublicOffOutlinedIcon /> : <AutoAwesomeOutlinedIcon />
                                                                            }
                                                                            onClick={() => {
                                                                                if (!isAiPrequalActive) {
                                                                                    handleSetAiPrequal();
                                                                                }
                                                                            }}
                                                                        >
                                                                            {isAiPrequalActive ? 'Turn OFF' : 'Turn ON'}
                                                                        </Button >
                                                                    )
                                                                }
                                                                {
                                                                    !property.price ? (
                                                                        <Text weight="bold" span><Text size="large" span>⚠️</Text> Missing price information (set in INFO section)</Text>
                                                                    ) : null
                                                                }
                                                            </Stack>
                                                        }
                                                    />
                                                ) : null
                                            }
                                        </Stack>
                                    }
                                    footer={
                                        <Footer
                                            align='flex-end'
                                            actions={[
                                                {
                                                    button: true,
                                                    buttonVariant: 'outlined',
                                                    buttonProps: {
                                                        endIcon: <ArrowForwardOutlinedIcon />,
                                                    },
                                                    value: 'See All leads',
                                                    onClick: () => navigate(`/app/leads/${property.id}`),
                                                }
                                            ]}
                                        />
                                    }
                                />
                                <Card
                                    header={
                                        <Header
                                            emoji="👀"
                                            title={
                                                <Text
                                                    size="large"
                                                    weight="bold"
                                                    color={`${color.split('.')[0]}.contrastText`}
                                                    sx={{ flexShrink: 0 }}
                                                >
                                                    Viewings
                                                </Text>
                                            }
                                            sx={{ backgroundColor: color }}
                                        />
                                    }
                                    body={
                                        <Stack></Stack>
                                    }
                                    footer={
                                        <Footer
                                            align='flex-end'
                                            actions={[
                                                {
                                                    button: true,
                                                    buttonVariant: 'outlined',
                                                    buttonProps: {
                                                        endIcon: <ArrowForwardOutlinedIcon />,
                                                    },
                                                    value: 'See All viewings',
                                                    onClick: () => navigate(`/app/viewings/${property.id}/upcoming`),
                                                }
                                            ]}
                                        />
                                    }
                                />
                            </>
                        ) : null
                    }
                </Stack >
            }
            footer={
                create ? (
                    <Footer
                        align='flex-end'
                        actions={
                            [
                                {
                                    button: true,
                                    buttonVariant: 'outlined',
                                    value: 'Cancel',
                                    onClick: onCancel,
                                },
                                {
                                    button: true,
                                    buttonVariant: 'contained',
                                    value: 'Save',
                                    onClick: async () => {
                                        setEditing(false);
                                        setSaving(true);
                                        await onSave('newProperty', propertyData);
                                        setSaving(false);
                                        setSaved(true);
                                    },
                                    sx: {
                                        ...(validatePropertyData(propertyData) ? {
                                            opacity: 1,
                                            pointerEvents: 'all',
                                        } : {
                                            opacity: 0.3,
                                            pointerEvents: 'none',
                                            cursor: 'not-allowed',
                                        }),
                                    },
                                },
                            ]}
                    />
                ) : null
            }
        />
    );
};

export default Property;