import Header from "../../../Components/library/Cards/Headers/Standard";
import Text from "../../../Components/library/Typography/Text";
import Stack from '@mui/material/Stack';
import APPS_CONFIG from '../../config';

const PropertyDisplay = ({
    property,
    color = APPS_CONFIG.properties.color,
    selected = false,
    ...props
}) => {
    return (
        <Header
            emoji="🏡"
            title={
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                >
                    <Text weight="bold" size="medium" span color={!selected ? 'primary.main' : 'primary.contrastText'}>{property.postcode}</Text>
                    <Text color={!selected ? 'primary.main' : 'primary.contrastText'} span> - {property.address}</Text>
                </Stack>
            }
            sx={{
                backgroundColor: selected ? 'primary.main' : color,
                borderRadius: '8px',
                cursor: 'pointer',
            }}
            {...props}
        />
    );
};

export default PropertyDisplay;