import Stack from '@mui/material/Stack';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Header from '../../../Components/library/Cards/Headers/Standard';
import {
    ContainerCard,
    BackButton,
    CardTitleText,
} from './RightPanel.styled';

const RightPanel = ({
    title,
    header,
    tags,
    body,
    footer,
    onBack = () => { },
}) => {
    return (
        <ContainerCard
            header={
                <Stack>
                    <Header
                        title={
                            <Stack
                                direction="row"
                                alignItems="center"
                            >
                                <BackButton
                                    sx={{ padding: 0 }}
                                    onClick={onBack}
                                >
                                    <ArrowBackOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                                    Back
                                </BackButton>
                                <CardTitleText size="large" weight="bold" sx={{
                                    flexShrink: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    textAlign: 'center',
                                    pointerEvents: 'none',
                                    gap: 1,
                                }}>
                                    {title}
                                </CardTitleText>
                            </Stack>
                        }
                        tags={tags}
                    />
                    {header}
                </Stack >
            }
            withBodyContainer={false}
            body={ body }
            footer={ footer }
        />
    );
};

export default RightPanel;