import { TodayOutlined, AccountCircleOutlined, PlaceOutlined, LibraryBooksOutlined } from '@mui/icons-material';
import CalendarViewMonthOutlinedIcon from '@mui/icons-material/CalendarViewMonthOutlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

const ITEMS = [
    {
        name: 'Properties',
        link: '/properties',
        icon: PlaceOutlined,
        logo: '/app/services-logos/property.svg',
        enabled: true,
    },
    {
        name: 'Leads',
        link: '/leads',
        icon: AccountCircleOutlined,
        logo: '/app/services-logos/lead.svg',
        enabled: true,
    },
    {
        name: 'Viewings',
        link: '/viewings',
        icon: VisibilityOutlinedIcon,
        logo: '/app/services-logos/viewing.svg',
        enabled: true,
    },
];

const ITEMS_CLEM = [
    {
        name: 'Clem',
        link: '/clem',
        icon: DirectionsRunOutlinedIcon,
        logo: '/app/services-logos/report.svg',
        enabled: true,
    },
];

const getItems = (isClem, isAdmin) => {
    let items = ITEMS;

    if (isClem) {
        items = isAdmin ? [...ITEMS, ...ITEMS_CLEM] : ITEMS_CLEM;
    }

    return items;

    if (isAdmin) {
        items = [...ITEMS, ...ITEMS_CLEM];
    }

    return items;
};

export default getItems