import * as React from 'react';
import Menu from '@mui/material/Menu';

const MenuButton = ({ children, Button, stayOpen = false, onClose = () => {}, ...otherProps }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        onClose();
    };

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            const _onClick = child.props.onClick ? child.props.onClick : () => { };
            return React.cloneElement(child, {
                onClick: (e) => {
                    if (stayOpen) {
                        handleClose();
                    }
                    _onClick(e);
                }
            });
        }
        return child;
    });

    const button_onClick = Button.props.onClick ? Button.props.onClick : () => { };
    const ButtonWithProps = React.cloneElement(Button, {
        'aria-controls': open ? 'basic-menu' : undefined,
        'aria-haspopup': 'true',
        'aria-expanded': open ? 'true' : undefined,
        onClick: (e) => {
            handleClick(e);
            button_onClick(e);
        }
    });

    return (
        <div styles="position: relative;">
            {ButtonWithProps}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{ '& .MuiPaper-root': otherProps.className }}
            >
                {childrenWithProps}
            </Menu>
        </div>
    );
}

export default MenuButton;
