import Tag from '../../../Components/library/Tag/Tag';
import HeaderWithSearch from '../../../Components/library/Cards/Headers/WithSearch';

import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import HeaderStatusSelector from '../../../Components/library/Cards/Headers/StatusSelector';
import LeadSkeleton from '../Lead/LeadSkeleton';
import APPS_CONFIG from '../../config';

import {
    ContainerCard,
    CardTitleText,
    BackButton,
} from '../leads.styled';

const LeadsListSkeleton = ({ propertyAddress = '', color = '' }) => {
    const display = 'PENDING';
    const isMobile = useMediaQuery('(max-width: 1000px)');

    return (
        <ContainerCard
            header={
                <Stack
                >
                    <HeaderWithSearch
                        title={
                            <Stack
                                direction="row"
                                alignItems="center"
                            >
                                <BackButton
                                    sx={{ padding: 0 }}
                                >
                                    <ArrowBackOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                                    Back
                                </BackButton>
                                <CardTitleText size="large" weight="bold" sx={{
                                    flexShrink: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    textAlign: 'center',
                                    pointerEvents: 'none',
                                    gap: 1,
                                }}><img src={APPS_CONFIG.leads.logo} width="20px" /> Leads</CardTitleText>
                            </Stack>
                        }
                        tags={[
                            <Tag key="skeleton-tag-addr">
                                {propertyAddress}
                            </Tag>
                        ]}
                    />
                    <HeaderStatusSelector
                        currentStatus={display}
                        color={color}
                        statuses={
                            [{
                                status: 'PENDING',
                                title: '👀 Pending',
                            },
                            {
                                status: 'APPROVED',
                                title: '✅ Approved',
                            },
                            {
                                status: 'REJECTED',
                                title: '❌ Rejected',
                            }]
                        }
                        statusCount={{
                            PENDING: <Skeleton variant="rounded" width={isMobile ? '20px' : '30px'} height={isMobile ? '22px' : '39px'} sx={{ borderRadius: '4px' }} />,
                            APPROVED: <Skeleton variant="rounded" width={isMobile ? '20px' : '30px'} height={isMobile ? '22px' : '39px'} sx={{ borderRadius: '4px' }} />,
                            REJECTED: <Skeleton variant="rounded" width={isMobile ? '20px' : '30px'} height={isMobile ? '22px' : '39px'} sx={{ borderRadius: '4px' }} />,
                        }}
                    />
                    <Stack sx={{ width: '100%', height: 'fit-content', p: 2.5, pl: 2.5, pt: 0 }}>
                        <Skeleton variant="rounded" width="102px" height="30px" sx={{ borderRadius: '4px' }} />
                    </Stack>
                </Stack >
            }
            withBodyContainer={false}
            body={
                <Stack
                    spacing={2.5}
                    direction="column"
                    sx={{ p: 2.5, overflow: 'auto', height: '100%' }}

                >
                    <LeadSkeleton />
                    <LeadSkeleton />
                </Stack >
            }
        />
    );
};

export default LeadsListSkeleton;