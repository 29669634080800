import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export const DashboardCardContainer = styled(Accordion)(({ theme }) => ({
    padding: 0,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[18],
    borderRadius: '20px',
    height: 'fit-content',
    maxWidth: '560px',
    width: '100%',

    '&:before': {
        display: 'none',
    },

    '&.MuiAccordion-root:first-of-type': {
        borderRadius: '20px',
    },

    '&.MuiAccordion-root:last-of-type': {
        borderRadius: '20px',
    },

    '&.Mui-expanded': {
        margin: 0,
    },
}));

export const DashboardCardTitle = styled(AccordionSummary)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 'bold',
    textDecoration: 'italic',
    backgroundColor: theme.palette.background.default,
    borderRadius: '12px',
    minHeight: '48px',
    padding: '20px',

    '& > div': {
        margin: 0,
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '4px',
    },

    '&.Mui-expanded': {
        minHeight: '48px',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '12px 12px 0px 0px',

        '& > div': {
            margin: 0,
        },
    },

    [theme.breakpoints.down(800)]: {
        fontSize: '16px',
        padding: '12px',
    },

    [theme.breakpoints.down(600)]: {
        '& > div': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 0,
            gap: '4px',
        }
    },
}));

export const DashboardCardSubtitle = styled('div')(({ theme }) => ({
    fontSize: '12px',
    fontWeight: 'lighter',
    color: `${theme.palette.primary.main}B3`,
    backgroundColor: `${theme.palette.secondary.main}80`,
    borderRadius: '12px',
    marginLeft: '20px',
    padding: '4px 12px',

    [theme.breakpoints.down(600)]: {
        fontSize: '12px',
    }
}))


export const DashboardCardContent = styled(AccordionDetails)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0px',
    padding: '12px',

    [theme.breakpoints.down(800)]: {
        padding: '0px',
    }
}));