import React from "react";
import { PropertyContainer, PropertyTitle, PropertyAddressContainer, PropertyAddress, PropertyViewingsInfo, PropertyContent, PropertyPostcode, PropertyEmoji } from "./Property.styled";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Property = ({ property, children, viewings, expanded, subtitle = null }) => {
    const p = (expanded ? { expanded: true } : {});

    return (
        <PropertyContainer {...p}>
            <PropertyTitle expandIcon={<ExpandMoreIcon />}>
                <PropertyAddressContainer>
                    <PropertyEmoji>
                        🏡
                    </PropertyEmoji>
                    <PropertyPostcode>
                        {property.postcode}
                    </PropertyPostcode>
                    <PropertyAddress>
                        {`${property.address}`}
                    </PropertyAddress>
                </PropertyAddressContainer>
                <PropertyViewingsInfo>{viewings ? `${viewings.length} Viewing${viewings.length > 1 ? 's' : ''}` : (subtitle ? subtitle : null)}</PropertyViewingsInfo>
            </PropertyTitle>
            <PropertyContent>
                {children}
            </PropertyContent>
        </PropertyContainer>
    );
};

export default Property;