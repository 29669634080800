import { format, differenceInMinutes, addMinutes, eachMinuteOfInterval, set, subMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const getSlotsBefore = (calendar, firstViewingIndex, targetMinSlots) => {
    let res = [];
    for (let i = 1; firstViewingIndex - i >= 0; i++) {
        if (
            firstViewingIndex - i >= 0 &&
            Math.abs(differenceInMinutes(
                new Date(`2023-06-01T${calendar[firstViewingIndex - i].time}`),
                new Date(`2023-06-01T${calendar[firstViewingIndex - (i - 1)].time}`)
            )) === 15 &&
            res.length < targetMinSlots
        ) {
            res = [...res, { ...calendar[firstViewingIndex - i], sandwich: ((firstViewingIndex - (i - 1)) === firstViewingIndex) }];
        } else {
            break;
        }
    }

    return res;
};

export const getSlotsAfter = (calendar, lastViewingIndex, targetMinSlots) => {
    let res = [];

    for (let i = 1; i <= targetMinSlots; i++) {
        if (lastViewingIndex + i < calendar.length &&
            differenceInMinutes(
                new Date(`2023-06-01T${calendar[lastViewingIndex + i].time}`),
                new Date(`2023-06-01T${calendar[lastViewingIndex + (i - 1)].time}`)
            ) === 15) {
            res = [...res, { ...calendar[lastViewingIndex + i], sandwich: ((lastViewingIndex + (i - 1)) === lastViewingIndex) }];
        } else {
            break;
        }
    }

    return res;
}

export const contigousBeforeAfter = (calendar, firstViewingIndex, lastViewingIndex, targetMinSlots = 6) => {
    let res = [
        ...new Set([calendar[firstViewingIndex], calendar[lastViewingIndex]]),
        ...calendar.filter((_, i) => i > firstViewingIndex && i < lastViewingIndex).map((c) => ({ ...c, sandwich: true })),
    ];

    let before = getSlotsBefore(calendar, firstViewingIndex, targetMinSlots / 2);
    let target = targetMinSlots - [...res, ...before].filter(c => c.type !== 'viewing').length;
    target = target > targetMinSlots / 2 ? target : targetMinSlots / 2;
    let after = getSlotsAfter(calendar, lastViewingIndex, target);

    if (targetMinSlots - [...res, ...before, ...after].filter(c => c.type !== 'viewing').length > 0) {
        before = getSlotsBefore(calendar, firstViewingIndex, targetMinSlots - [...res, ...after].filter(c => c.type !== 'viewing').length);
    }

    res = [...res, ...before, ...after];

    return res.sort((a, b) => a.time < b.time ? -1 : 1);
}

export const getContigous = (calendar, maxBeforeAfter = 3) => {
    let firstViewingIndex = null;
    let lastViewingIndex = null;
    for (let index = 0; index < calendar.length; index++) {
        const element = calendar[index];
        if (element.type === 'viewing' && firstViewingIndex === null) {
            firstViewingIndex = index;
        }
        if (element.type === 'viewing') {
            lastViewingIndex = index;
        }
    }

    const res = contigousBeforeAfter(calendar, firstViewingIndex, lastViewingIndex, maxBeforeAfter * 2);

    return res;
};

export const eachSlot = ({
    day,
    startsAt,
    endsAt,
    viewings,
    padding = 15,
    slotsLength = 15,
}) => {
    const dayTimeStart = subMinutes(
        set(day, { hours: parseInt(startsAt.split(':')[0], 10), minutes: parseInt(startsAt.split(':')[1], 10), seconds: 0 }),
        padding,
    );
    const dayTimeEnd = addMinutes(
        set(day, { hours: parseInt(endsAt.split(':')[0], 10), minutes: parseInt(endsAt.split(':')[1], 10), seconds: 59 }),
        padding,
    );
    const slotTimes = eachMinuteOfInterval({ start: dayTimeStart, end: dayTimeEnd })
        .filter((_, index) => !(index % slotsLength))
        .map((date) => {
            const dateKey = format(date, 'HH:mm');
            if (viewings.find(v => dateKey === v.time)) {
                return null;
            }

            return {
                type: 'slot',
                sandwich: dateKey > startsAt && dateKey < endsAt ? true : false,
                time: dateKey,
            };
        }).filter(s => !!s);

    return slotTimes;
}

export const createSlotsFromViewings = (viewings, fill = false) => {
    const _viewings = viewings.map(v => ({
        type: 'viewing',
        time: formatInTimeZone(new Date(v.startsAt), 'Etc/UTC', 'kk:mm'),
        viewing: v,
    }));
    const groupedViewings = Object.values(_viewings.reduce((tot, v) => ({
        ...tot,
        [v.time]: [
            ...(tot[v.time] || []),
            v,
        ],
    }), {})).map(g => g.length === 1 ? g[0] : {
        type: 'viewings',
        time: g[0].time,
        viewings: g.map(s => s.viewing),
    });

    const calendar = [
        ...(fill ? (
            eachSlot({
                day: new Date(viewings[0].startsAt),
                startsAt: formatInTimeZone(new Date(viewings[0].startsAt), 'Etc/UTC', 'kk:mm'),
                endsAt: formatInTimeZone(new Date(viewings[viewings.length - 1].startsAt), 'Etc/UTC', 'kk:mm'),
                viewings: _viewings,
            })
        ) : []),
        ...groupedViewings,
    ].sort((a, b) => a.time < b.time ? -1 : 1);

    return calendar;
};

export const groupByDay = (viewings) => {
    const byDay = viewings.sort((a, b) => new Date(a.startsAt) - new Date(b.startsAt)).reduce((tot, v) => ({
        ...tot,
        [v.date]: [
            ...(tot[v.date] || []),
            v,
        ],
    }), {});

    const byDayFlat = Object.keys(byDay).map(key => byDay[key]);

    return byDayFlat;
};