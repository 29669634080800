/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const bookingAvailableTime = /* GraphQL */ `
  query BookingAvailableTime(
    $date: String
    $postcode: String
    $type: String
    $viewingLength: Int
  ) {
    bookingAvailableTime(
      date: $date
      postcode: $postcode
      type: $type
      viewingLength: $viewingLength
    )
  }
`;
export const createUserData = /* GraphQL */ `
  query CreateUserData(
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $keySecureLocation: String
  ) {
    createUserData(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      keySecureLocation: $keySecureLocation
    )
  }
`;
export const getViewingsSessionForClem = /* GraphQL */ `
  query GetViewingsSessionForClem($date: String) {
    getViewingsSessionForClem(date: $date)
  }
`;
export const getClems = /* GraphQL */ `
  query GetClems($date: String) {
    getClems(date: $date)
  }
`;
export const createViewingsSessions = /* GraphQL */ `
  query CreateViewingsSessions(
    $date: String
    $create: Boolean
    $cleanup: Boolean
  ) {
    createViewingsSessions(date: $date, create: $create, cleanup: $cleanup)
  }
`;
export const reassignViewingsSession = /* GraphQL */ `
  query ReassignViewingsSession($clemId: String, $sessionId: String) {
    reassignViewingsSession(clemId: $clemId, sessionId: $sessionId)
  }
`;
export const clemAvailabilityManager = /* GraphQL */ `
  query ClemAvailabilityManager(
    $op: String
    $clem: String
    $id: String
    $weight: String
    $date: String
    $dateStart: String
    $dateEnd: String
  ) {
    clemAvailabilityManager(
      op: $op
      clem: $clem
      id: $id
      weight: $weight
      date: $date
      dateStart: $dateStart
      dateEnd: $dateEnd
    )
  }
`;
export const sessionManager = /* GraphQL */ `
  query SessionManager($op: String, $payload: String) {
    sessionManager(op: $op, payload: $payload)
  }
`;
export const reportManager = /* GraphQL */ `
  query ReportManager($op: String, $payload: String) {
    reportManager(op: $op, payload: $payload)
  }
`;
export const aiPrequal = /* GraphQL */ `
  query AiPrequal($op: String, $payload: String) {
    aiPrequal(op: $op, payload: $payload)
  }
`;
export const billingManager = /* GraphQL */ `
  query BillingManager($op: String, $payload: String) {
    billingManager(op: $op, payload: $payload)
  }
`;
export const keysManager = /* GraphQL */ `
  query KeysManager($op: String, $payload: String) {
    keysManager(op: $op, payload: $payload)
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      phone
      keySecureLocation
      isClem
      isAdmin
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        keySecureLocation
        isClem
        isAdmin
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        phone
        keySecureLocation
        isClem
        isAdmin
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getViewingsSession = /* GraphQL */ `
  query GetViewingsSession($id: ID!) {
    getViewingsSession(id: $id) {
      id
      clem
      Property {
        id
        occupied
        info
        postcode
        address
        city
        price
        listingUrl
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      viewings
      date
      keys
      info
      clemNotifiedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      viewingsSessionPropertyId
    }
  }
`;
export const listViewingsSessions = /* GraphQL */ `
  query ListViewingsSessions(
    $filter: ModelViewingsSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViewingsSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clem
        viewings
        date
        keys
        info
        clemNotifiedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        viewingsSessionPropertyId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncViewingsSessions = /* GraphQL */ `
  query SyncViewingsSessions(
    $filter: ModelViewingsSessionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncViewingsSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clem
        viewings
        date
        keys
        info
        clemNotifiedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        viewingsSessionPropertyId
      }
      nextToken
      startedAt
    }
  }
`;
export const sessionsByDay = /* GraphQL */ `
  query SessionsByDay(
    $date: String!
    $sortDirection: ModelSortDirection
    $filter: ModelViewingsSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionsByDay(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clem
        viewings
        date
        keys
        info
        clemNotifiedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        viewingsSessionPropertyId
      }
      nextToken
      startedAt
    }
  }
`;
export const getViewing = /* GraphQL */ `
  query GetViewing($id: ID!) {
    getViewing(id: $id) {
      id
      startsAt
      endsAt
      propertyId
      viewerId
      leadId
      keysId
      date
      postcode
      notes
      keys
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listViewings = /* GraphQL */ `
  query ListViewings(
    $filter: ModelViewingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViewings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startsAt
        endsAt
        propertyId
        viewerId
        leadId
        keysId
        date
        postcode
        notes
        keys
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncViewings = /* GraphQL */ `
  query SyncViewings(
    $filter: ModelViewingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncViewings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        startsAt
        endsAt
        propertyId
        viewerId
        leadId
        keysId
        date
        postcode
        notes
        keys
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const viewingsByDay = /* GraphQL */ `
  query ViewingsByDay(
    $date: String!
    $sortDirection: ModelSortDirection
    $filter: ModelViewingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    viewingsByDay(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startsAt
        endsAt
        propertyId
        viewerId
        leadId
        keysId
        date
        postcode
        notes
        keys
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getViewer = /* GraphQL */ `
  query GetViewer($id: ID!) {
    getViewer(id: $id) {
      id
      email
      phone
      name
      presence
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listViewers = /* GraphQL */ `
  query ListViewers(
    $filter: ModelViewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViewers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phone
        name
        presence
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncViewers = /* GraphQL */ `
  query SyncViewers(
    $filter: ModelViewerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncViewers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        phone
        name
        presence
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getProperty = /* GraphQL */ `
  query GetProperty($id: ID!) {
    getProperty(id: $id) {
      id
      occupied
      info
      postcode
      address
      city
      price
      listingUrl
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listProperties = /* GraphQL */ `
  query ListProperties(
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProperties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        occupied
        info
        postcode
        address
        city
        price
        listingUrl
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProperties = /* GraphQL */ `
  query SyncProperties(
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        occupied
        info
        postcode
        address
        city
        price
        listingUrl
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPropertyMarketPlace = /* GraphQL */ `
  query GetPropertyMarketPlace($id: ID!) {
    getPropertyMarketPlace(id: $id) {
      id
      propertyId
      externalId
      platform
      url
      deletedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listPropertyMarketPlaces = /* GraphQL */ `
  query ListPropertyMarketPlaces(
    $filter: ModelPropertyMarketPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertyMarketPlaces(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyId
        externalId
        platform
        url
        deletedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPropertyMarketPlaces = /* GraphQL */ `
  query SyncPropertyMarketPlaces(
    $filter: ModelPropertyMarketPlaceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPropertyMarketPlaces(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        propertyId
        externalId
        platform
        url
        deletedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const propertyMarketPlaceByExternalId = /* GraphQL */ `
  query PropertyMarketPlaceByExternalId(
    $externalId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPropertyMarketPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    propertyMarketPlaceByExternalId(
      externalId: $externalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyId
        externalId
        platform
        url
        deletedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getKeys = /* GraphQL */ `
  query GetKeys($id: ID!) {
    getKeys(id: $id) {
      id
      propertyId
      type
      info
      data
      pickupLocation
      location
      dropoffLocation
      status
      externalId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listKeys = /* GraphQL */ `
  query ListKeys(
    $filter: ModelKeysFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        propertyId
        type
        info
        data
        pickupLocation
        location
        dropoffLocation
        status
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncKeys = /* GraphQL */ `
  query SyncKeys(
    $filter: ModelKeysFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKeys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        propertyId
        type
        info
        data
        pickupLocation
        location
        dropoffLocation
        status
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const keysByType = /* GraphQL */ `
  query KeysByType(
    $type: String!
    $sortDirection: ModelSortDirection
    $filter: ModelKeysFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keysByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyId
        type
        info
        data
        pickupLocation
        location
        dropoffLocation
        status
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getReservedSlot = /* GraphQL */ `
  query GetReservedSlot($id: ID!) {
    getReservedSlot(id: $id) {
      id
      Property {
        id
        occupied
        info
        postcode
        address
        city
        price
        listingUrl
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      date
      postcode
      startsAt
      endsAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reservedSlotPropertyId
      owner
    }
  }
`;
export const listReservedSlots = /* GraphQL */ `
  query ListReservedSlots(
    $filter: ModelReservedSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservedSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        postcode
        startsAt
        endsAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        reservedSlotPropertyId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReservedSlots = /* GraphQL */ `
  query SyncReservedSlots(
    $filter: ModelReservedSlotFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReservedSlots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        postcode
        startsAt
        endsAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        reservedSlotPropertyId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const slotsByDay = /* GraphQL */ `
  query SlotsByDay(
    $date: String!
    $sortDirection: ModelSortDirection
    $filter: ModelReservedSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slotsByDay(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        postcode
        startsAt
        endsAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        reservedSlotPropertyId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getClemAvailability = /* GraphQL */ `
  query GetClemAvailability($id: ID!) {
    getClemAvailability(id: $id) {
      id
      date
      clem
      weight
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listClemAvailabilities = /* GraphQL */ `
  query ListClemAvailabilities(
    $filter: ModelClemAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClemAvailabilities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        clem
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClemAvailabilities = /* GraphQL */ `
  query SyncClemAvailabilities(
    $filter: ModelClemAvailabilityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClemAvailabilities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        clem
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const availabilityByDay = /* GraphQL */ `
  query AvailabilityByDay(
    $date: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClemAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    availabilityByDay(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        clem
        weight
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getViewingReport = /* GraphQL */ `
  query GetViewingReport($id: ID!) {
    getViewingReport(id: $id) {
      id
      viewingId
      sessionReportId
      viewingSuccess
      viewingStatus
      files
      info
      times
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listViewingReports = /* GraphQL */ `
  query ListViewingReports(
    $filter: ModelViewingReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViewingReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        viewingId
        sessionReportId
        viewingSuccess
        viewingStatus
        files
        info
        times
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncViewingReports = /* GraphQL */ `
  query SyncViewingReports(
    $filter: ModelViewingReportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncViewingReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        viewingId
        sessionReportId
        viewingSuccess
        viewingStatus
        files
        info
        times
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const reportByViewing = /* GraphQL */ `
  query ReportByViewing(
    $viewingId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelViewingReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportByViewing(
      viewingId: $viewingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        viewingId
        sessionReportId
        viewingSuccess
        viewingStatus
        files
        info
        times
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const reportBySessionReport = /* GraphQL */ `
  query ReportBySessionReport(
    $sessionReportId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelViewingReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportBySessionReport(
      sessionReportId: $sessionReportId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        viewingId
        sessionReportId
        viewingSuccess
        viewingStatus
        files
        info
        times
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getSessionReport = /* GraphQL */ `
  query GetSessionReport($id: ID!) {
    getSessionReport(id: $id) {
      id
      sessionId
      clem
      report
      files
      info
      times
      reportStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listSessionReports = /* GraphQL */ `
  query ListSessionReports(
    $filter: ModelSessionReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sessionId
        clem
        report
        files
        info
        times
        reportStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSessionReports = /* GraphQL */ `
  query SyncSessionReports(
    $filter: ModelSessionReportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSessionReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sessionId
        clem
        report
        files
        info
        times
        reportStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const reportBySession = /* GraphQL */ `
  query ReportBySession(
    $sessionId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSessionReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportBySession(
      sessionId: $sessionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sessionId
        clem
        report
        files
        info
        times
        reportStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      owner
      email
      phone
      name
      propertyId
      status
      notes
      enquiredFrom
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        email
        phone
        name
        propertyId
        status
        notes
        enquiredFrom
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLeads = /* GraphQL */ `
  query SyncLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        email
        phone
        name
        propertyId
        status
        notes
        enquiredFrom
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const leadByEmail = /* GraphQL */ `
  query LeadByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        email
        phone
        name
        propertyId
        status
        notes
        enquiredFrom
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const leadByDay = /* GraphQL */ `
  query LeadByDay(
    $createdDate: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LeadByDay(
      createdDate: $createdDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        email
        phone
        name
        propertyId
        status
        notes
        enquiredFrom
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMarketPlaceConfig = /* GraphQL */ `
  query GetMarketPlaceConfig($id: ID!) {
    getMarketPlaceConfig(id: $id) {
      id
      type
      platform
      authConfig
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listMarketPlaceConfigs = /* GraphQL */ `
  query ListMarketPlaceConfigs(
    $filter: ModelMarketPlaceConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarketPlaceConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        platform
        authConfig
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMarketPlaceConfigs = /* GraphQL */ `
  query SyncMarketPlaceConfigs(
    $filter: ModelMarketPlaceConfigFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMarketPlaceConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        platform
        authConfig
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getBillDay = /* GraphQL */ `
  query GetBillDay($id: ID!) {
    getBillDay(id: $id) {
      value
      items
      creditUsed
      date
      userId
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listBillDays = /* GraphQL */ `
  query ListBillDays(
    $filter: ModelBillDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillDays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        value
        items
        creditUsed
        date
        userId
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBillDays = /* GraphQL */ `
  query SyncBillDays(
    $filter: ModelBillDayFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBillDays(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        value
        items
        creditUsed
        date
        userId
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const billsByDay = /* GraphQL */ `
  query BillsByDay(
    $date: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBillDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    billsByDay(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        value
        items
        creditUsed
        date
        userId
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const dailyBillsByOwner = /* GraphQL */ `
  query DailyBillsByOwner(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBillDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyBillsByOwner(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        value
        items
        creditUsed
        date
        userId
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getBillMonth = /* GraphQL */ `
  query GetBillMonth($id: ID!) {
    getBillMonth(id: $id) {
      value
      items
      creditUsed
      billsDay
      date
      status
      userId
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listBillMonths = /* GraphQL */ `
  query ListBillMonths(
    $filter: ModelBillMonthFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillMonths(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        value
        items
        creditUsed
        billsDay
        date
        status
        userId
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBillMonths = /* GraphQL */ `
  query SyncBillMonths(
    $filter: ModelBillMonthFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBillMonths(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        value
        items
        creditUsed
        billsDay
        date
        status
        userId
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const billsByMonth = /* GraphQL */ `
  query BillsByMonth(
    $date: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBillMonthFilterInput
    $limit: Int
    $nextToken: String
  ) {
    billsByMonth(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        value
        items
        creditUsed
        billsDay
        date
        status
        userId
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const monthlyBillsByOwner = /* GraphQL */ `
  query MonthlyBillsByOwner(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBillMonthFilterInput
    $limit: Int
    $nextToken: String
  ) {
    monthlyBillsByOwner(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        value
        items
        creditUsed
        billsDay
        date
        status
        userId
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAIPrompt = /* GraphQL */ `
  query GetAIPrompt($id: ID!) {
    getAIPrompt(id: $id) {
      prompt
      contexts
      commands
      constrains
      body
      type
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listAIPrompts = /* GraphQL */ `
  query ListAIPrompts(
    $filter: ModelAIPromptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAIPrompts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        prompt
        contexts
        commands
        constrains
        body
        type
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAIPrompts = /* GraphQL */ `
  query SyncAIPrompts(
    $filter: ModelAIPromptFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAIPrompts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        prompt
        contexts
        commands
        constrains
        body
        type
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAIChat = /* GraphQL */ `
  query GetAIChat($id: ID!) {
    getAIChat(id: $id) {
      messages
      promptId
      user
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listAIChats = /* GraphQL */ `
  query ListAIChats(
    $filter: ModelAIChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAIChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        messages
        promptId
        user
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAIChats = /* GraphQL */ `
  query SyncAIChats(
    $filter: ModelAIChatFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAIChats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        messages
        promptId
        user
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAIPrequalProperty = /* GraphQL */ `
  query GetAIPrequalProperty($id: ID!) {
    getAIPrequalProperty(id: $id) {
      promptId
      propertyId
      custom
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listAIPrequalProperties = /* GraphQL */ `
  query ListAIPrequalProperties(
    $filter: ModelAIPrequalPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAIPrequalProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        promptId
        propertyId
        custom
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAIPrequalProperties = /* GraphQL */ `
  query SyncAIPrequalProperties(
    $filter: ModelAIPrequalPropertyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAIPrequalProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        promptId
        propertyId
        custom
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const prequalByProperty = /* GraphQL */ `
  query PrequalByProperty(
    $propertyId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAIPrequalPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    prequalByProperty(
      propertyId: $propertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        promptId
        propertyId
        custom
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAIPrequal = /* GraphQL */ `
  query GetAIPrequal($id: ID!) {
    getAIPrequal(id: $id) {
      id
      owner
      aiPropertyPrequalId
      aiChatId
      summary
      sentAt
      startedAt
      completedAt
      qualified
      propertyId
      leadId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAIPrequals = /* GraphQL */ `
  query ListAIPrequals(
    $filter: ModelAIPrequalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAIPrequals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        aiPropertyPrequalId
        aiChatId
        summary
        sentAt
        startedAt
        completedAt
        qualified
        propertyId
        leadId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAIPrequals = /* GraphQL */ `
  query SyncAIPrequals(
    $filter: ModelAIPrequalFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAIPrequals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        aiPropertyPrequalId
        aiChatId
        summary
        sentAt
        startedAt
        completedAt
        qualified
        propertyId
        leadId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
