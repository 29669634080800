import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReportRenderer from './ReportRenderer';

const Report = () => {
    const [reportData, setReportData] = useState(null);
    const [error, setError] = useState(null);
    const params = useParams();

    useEffect(() => {
        const fetchReportData = async (id) => {
            try {
                const data = await fetch(`https://s3.eu-west-2.amazonaws.com/clem-reports/${id}/viewing.json`).then(response => response.json());
                setReportData(data);
            } catch (e) {
                setError(e);
            }
        };

        fetchReportData(params.reportId);
    }, []); // eslint-disable-line

    return (
        <ReportRenderer reportData={reportData} error={error} />
    );
};

export default Report;