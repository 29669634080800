import { useState } from 'react';
import TagInput from './TagInput';

const AutoSaveInput = ({
    statuses,
    onSave = () => { },
    onBlur = () => { },
    onChange = () => { },
    saveDebounceTime = 2000,
    ...otherProps
}) => {
    const [currentValue, setCurrentValue] = useState(null);
    const [currentTag, setCurrentTag] = useState(statuses.default);
    const [lastStroke, setLastStroke] = useState(0);
    const [currentTimeout, setCurrentTimeout] = useState(null);

    const handleBlur = async () => {
        onBlur();

        if (currentTimeout) {
            clearTimeout(currentTimeout);
            setCurrentTimeout(null);

            setCurrentTag(statuses.saving);
            await onSave(currentValue);
            setCurrentTag(statuses.saved);
        }
    }

    const handleChange = (value) => {
        setCurrentValue(value);
        setCurrentTag(statuses.changed);
        onChange(value);
        setLastStroke(Date.now());
        if (currentTimeout) {
            clearTimeout(currentTimeout);
        }

        setCurrentTimeout(setTimeout(async () => {
            clearTimeout(currentTimeout);
            setCurrentTimeout(null);
            setCurrentTag(statuses.saving);
            await onSave(value);
            setCurrentTag(statuses.saved);
        }, saveDebounceTime));
    };

    return (
        <TagInput
            onChange={handleChange}
            onBlur={handleBlur}
            tag={currentTag}
            {...otherProps}
        />
    );
};

export default AutoSaveInput;