import { Stack } from '@mui/material';
import Text from '../../../../Components/library/Typography/Text';

const ViewingTypeSelector = ({
    onSelect = () => {},
}) => {
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                height: '100%'
            }}
        >
            <Stack
                direction="row"
                justifyContent="center"
                gap={2}
            >
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{

                        width: '200px',
                        height: '200px',
                        backgroundColor: 'secondary.light',
                        p: '20px',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        border: '1px solid white',
                        borderColor: 'secondary.main',
                    }}
                    onClick={() => onSelect('SINGLE')}
                >
                    <Text size="title" weight="bold" sx={{ mb: '12px' }}>
                        SINGLE
                    </Text>
                    <Text weight={300}>Single viewing</Text>
                    <Text weight={300}>15 min slot</Text>
                </Stack>
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{

                        width: '200px',
                        height: '200px',
                        backgroundColor: 'secondary.light',
                        p: '20px',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        border: '1px solid white',
                        borderColor: 'secondary.main',
                    }}
                    onClick={() => onSelect('BLOCK')}
                >
                    <Text size="title" weight="bold" sx={{ mb: '12px' }}>
                        BLOCK
                    </Text>
                    <Text weight={300}>Block viewing</Text>
                    <Text weight={300}>1h slot</Text>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default ViewingTypeSelector;