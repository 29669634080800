import React from "react";
import { formatInTimeZone } from 'date-fns-tz';
import { formatDistanceToNowStrict, endOfDay, isToday } from "date-fns";
import { DayContainer, DayTitle, DayContent } from "./Day.styled";

const Day = ({ day, children, title = null }) => {
    return (
        <DayContainer>
            <DayTitle>
                {title ? title : (
                    `${isToday(new Date(day)) ? 'Today' : formatDistanceToNowStrict(endOfDay(new Date(day)), {
                        unit: 'day',
                        roundingMethod: 'round',
                        addSuffix: true,
                    })} | ${formatInTimeZone(new Date(day), 'Etc/UTC', 'EEE MMM do')}`
                )}
            </DayTitle>
            <DayContent>
                {children}
            </DayContent>
        </DayContainer>
    );
};

export default Day;