import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

const SecondaryContainer = styled(Stack)(({ theme }) => ({
    height: '100%',
    width: '100%',
    maxWidth: '600px',

    [theme.breakpoints.down(1000)]: {
        display: 'none',

        '&[data-mobile-visible="true"]': {
            display: 'flex',
            position: 'absolute',
            height: 'calc(100% - 40px)',

            [theme.breakpoints.down(800)]: {
                top: 0,
                left: 0,
                maxWidth: '100%',
                height: '100%',
            }
        }
    },
}));

const TwoPanelsLayout = ({
    leftPanel = null,
    leftPanelPlaceholder = null,
    rightPanel = null,
    rightPanelPlaceholder = null,
    showRightPanel = true,
    disabled = false,
    sxSecondary = {},
}) => {
    const isSmall = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 800px)');

    return (
        <Stack
            direction={isMobile ? 'column' : 'row'}
            sx={{ p: isMobile ? 0 : 2.5, width: '100%', position: 'relative', pointerEvents: disabled ? 'none' : 'all' }}
            gap={isSmall ? 2.5 : isMobile ? 0 : 2.5}
        >
            {leftPanel ? leftPanel : leftPanelPlaceholder}
            {
                rightPanel ? (
                    <SecondaryContainer
                        data-mobile-visible={true}
                        sx={{
                            maxWidth: rightPanel ? '600px' : '0px',
                            maxHeight: rightPanel ? '100%' : '0px',
                            transition: 'opacity 0.3s',
                            opacity: rightPanel ? 1 : 0,
                            ...sxSecondary,
                        }}
                    >
                        {rightPanel ? rightPanel : null}
                    </SecondaryContainer>
                ) : null
            }
            {
                !rightPanel ? (
                    <SecondaryContainer
                        data-mobile-visible={showRightPanel}
                        sx={{
                            opacity: rightPanel ? 0 : 1,
                            maxWidth: rightPanel ? '0px' : '600px',
                            maxHeight: rightPanel ? '0px' : '100%',
                            transition: 'opacity 0.5s',
                        }}
                    >
                        {rightPanelPlaceholder}
                    </SecondaryContainer>
                ) : null
            }
        </Stack >
    );
};

export default TwoPanelsLayout;