import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import LinearProgress from '@mui/material/LinearProgress';

const TimerButton = ({ children, time = 0, onTimeEnd = () => { }, onClick = () => { }, sx = {}, ...props }) => {
    const [timer, setTimer] = useState(null);
    const [progress, setProgress] = useState(0);


    useEffect(() => {
        if (time === 0) {
            return;
        }

        setProgress(0);

        if (timer) {
            clearInterval(timer);
            setTimer(null);
        }

        const t = setInterval(() => {
            setProgress((oldProgress) => {
                const _progress = oldProgress + ((200 / time) * 100);

                if (_progress > 100) {
                    onTimeEnd();
                    clearInterval(t);

                    return 100;
                }

                return _progress;
            });
        }, 200);

        setTimer(t);

        return () => {
            clearInterval(timer);
        };
    }, [time]);

    return (
        <Button
            variant="outlined"
            sx={{ width: 'fit-content', overflow: 'hidden', ...sx }}
            onClick={onClick}
            {...props}
        >
            <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ width: '100%', position: 'absolute', height: '3px', top: 0 }}
            />
            {children}
        </Button>
    );
};

export default TimerButton;