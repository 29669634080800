import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import Switch from '@mui/material/Switch';

const shadowKeyUmbraOpacity = 0.2;
const shadowKeyPenumbraOpacity = 0.14;
const shadowAmbientShadowOpacity = 0.12;

function createShadow(...px) {
  return [
    `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(76,52,146, ${shadowKeyUmbraOpacity})`,
    `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(76,52,146, ${shadowKeyPenumbraOpacity})`,
    `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(76,52,146, ${shadowAmbientShadowOpacity})`,
  ].join(',');
}

const invertedInputs = ({ ownerState, theme }) => ({
  '& .Mui-disabled': {
    pointerEvents: 'none',
    opacity: '0.8',
  },
  '& .Mui-disabled label.MuiInputLabel-root': {
    color: theme.palette.secondary.main,
  },

  '& .Mui-disabled input': {
    color: theme.palette.primary.contrastText,
    '-webkit-text-fill-color': theme.palette.primary.contrastText,
  },

  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.2)',
  },
  '& label.MuiInputLabel-root': {
    color: theme.palette.secondary.main,
  },

  '& div.MuiOutlinedInput-root': {
    color: theme.palette.primary.contrastText,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.2)',
  },

  '& div.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.5)',
  },

  '& div.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },

  '& .MuiFormHelperText-root': {
    color: 'rgba(255, 255, 255, 0.5)',
  },

  '& .MuiFormHelperText-root.Mui-error': {
    color: 'red',
  },
  '& label.MuiInputLabel-root.Mui-error': {
    color: 'red',
  }
});

const createColor = ({ rgb, rgbContrast }) => ({
  main: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1)`,
  light: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.5)`, //'rgba(76,52,146,0.5)',
  ultraLight: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.3)`, //'rgba(76,52,146,0.3)',
  dark: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1)`,
  opacity: (opacity) => `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`,
  contrastText: `rgba(${rgbContrast[0]}, ${rgbContrast[1]}, ${rgbContrast[2]}, 1)`,
});

const themeOptions = {
  palette: {
    type: 'light',
    primary: createColor({ rgb: [76, 52, 146], rgbContrast: [255, 255, 255] }),
    // primary: {
    //   main: '#4c3492',
    //   light: 'rgba(76,52,146,0.5)',
    //   ultraLight: 'rgba(76,52,146,0.3)',
    //   dark: '#4c3492',
    //   contrastText: '#ffffff',
    // },
    properties_app: createColor({ rgb: [255, 239, 220], rgbContrast: [76, 52, 146] }),
    leads_app: createColor({ rgb: [238, 250, 247], rgbContrast: [76, 52, 146] }),
    // properties_app: {
    //   main: '#ffefdc', //'#FFE4C5'
    // },
    // leads_app: {
    //   main: '#EEFAF7',
    // },
    viewings_app: createColor({ rgb: [249, 246, 255], rgbContrast: [76, 52, 146] }),
    // viewings_app: {
    //   main: '#f9f6ff', //'secondary.light',
    // },
    user_app: {
      main: '#FFF9DC',
    },
    clem_app: {
      main: '#FCEBEB',
    },

    secondary: {
      main: '#e5dffb',
      light: '#f9f6ff',
      contrastText: '#4c3492',
    },
    text: {
      primary: '#4c3492',
      secondary: 'rgba(76,52,146,0.5)',
      disabled: 'rgba(76,52,146,0.3)',
      hint: 'rgba(76,52,146,0.5)',
    },
    background: {
      default: '#ffffff',
      paper: 'rgb(213, 211, 236)', //'rgba(76,52,146,0.05)',
      light: '#f2f2f9',
    },
    divider: '#e5dffb', //'rgba(76,52,146,0.2)',
    action: {
      activatedOpacity: 0.12,
      active: "rgba(76,52,146, 0.54)",
      disabled: "rgba(76,52,146, 0.26)",
      disabledBackground: "rgba(76,52,146, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(76,52,146, 0.12)",
      focusOpacity: 0.12,
      hover: "rgba(76,52,146, 0.04)",
      hoverOpacity: 0.04,
      selected: "rgba(76,52,146, 0.08)",
      selectedOpacity: 0.08,
    },
  },
  typography: {
    fontFamily: 'Roboto Mono, Segoe UI Emoji, Apple Color Emoji, Noto Color Emoji',
    // fontSize: '12px',
  },
  shadows: {
    0: 'none',
    1: createShadow(0, 2, 1, -1, 0, 1, 1, 0, 0, 1, 3, 0),
    2: createShadow(0, 3, 1, -2, 0, 2, 2, 0, 0, 1, 5, 0),
    3: createShadow(0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0),
    4: createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
    5: createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
    6: createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
    7: createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
    8: createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
    9: createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
    10: createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
    11: createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
    12: createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
    13: createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
    14: createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
    15: createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
    16: createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
    17: createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
    18: createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
    19: createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
    20: createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
    21: createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
    22: createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
    23: createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
    24: createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
  },
  blur: {
    0: 'none',
    1: 'blur(10px)',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(!!ownerState.inverted && {
            ...invertedInputs({ ownerState, theme }),
          }),
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(!!ownerState.inverted && {
            ...invertedInputs({ ownerState, theme }),
          }),
        }),
      },
    },
    // MuiBackdrop: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: 'transparent',
    //     },
    //   },
    // },
  },
};

const themeInvertedOptions = {
  palette: {
    type: 'dark',
    primary: createColor({ rgb: [209, 198, 250], rgbContrast: [76, 52, 146] }),
    // primary: createColor({ rgb: [255, 255, 255], rgbContrast: [76, 52, 146] }),
    // secondary: createColor({ rgb: [229, 223, 251], rgbContrast: [76, 52, 146] }),
    secondary: createColor({ rgb: [89, 60, 173], rgbContrast: [76, 52, 146] }),
    properties_app: createColor({ rgb: [241, 140, 24], rgbContrast: [76, 52, 146] }),
    leads_app: createColor({ rgb: [16, 205, 158], rgbContrast: [76, 52, 146] }),
    viewings_app: createColor({ rgb: [62, 40, 126], rgbContrast: [76, 52, 146] }),

    user_app: {
      main: '#FFF9DC',
    },
    clem_app: {
      main: '#FCEBEB',
    },
    // primary: {
    //   main: '#ffffff',
    //   light: 'rgba(255,255,255,0.8)',
    //   ultraLight: 'rgba(255,255,255,0.5)',
    //   dark: 'rgba(255,255,255,0.3)',
    //   contrastText: '#4c3492',
    // },
    // secondary: {
    //   main: '#e5dffb',
    //   contrastText: '#4c3492',
    // },
    text: {
      primary: '#fff',
      secondary: 'rgba(255,255,255,0.5)',
      disabled: 'rgba(255,255,255,0.3)',
      hint: 'rgba(255,255,255,0.5)',
    },
    background: {
      default: '#21114e', //'#4c3492',
      light: 'rgba(76,52,146,0.6)',
      paper: '#5f499e',
      // light: '#5f499e',
    },
    divider: 'rgba(255,255,255,0.2)',
    action: {
      activatedOpacity: 0.12,
      active: "rgba(255,255,255, 0.54)",
      disabled: "rgba(255,255,255, 0.4)",
      disabledBackground: "rgba(255,255,255, 0.3)",
      disabledOpacity: 0.38,
      focus: "rgba(255,255,255, 0.12)",
      focusOpacity: 0.12,
      hover: "rgba(255,255,255, 0.04)",
      hoverOpacity: 0.04,
      selected: "rgba(255,255,255, 0.08)",
      selectedOpacity: 0.08,
    },
  },
  typography: {
    fontFamily: 'Roboto Mono',
  },
  shadows: {
    0: 'none',
    1: createShadow(0, 2, 1, -1, 0, 1, 1, 0, 0, 1, 3, 0),
    2: createShadow(0, 3, 1, -2, 0, 2, 2, 0, 0, 1, 5, 0),
    3: createShadow(0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0),
    4: createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
    5: createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
    6: createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
    7: createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
    8: createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
    9: createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
    10: createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
    11: createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
    12: createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
    13: createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
    14: createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
    15: createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
    16: createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
    17: createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
    18: createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
    19: createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
    20: createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
    21: createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
    22: createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
    23: createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
    24: createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
  },
  blur: {
    0: 'none',
    1: 'blur(10px)',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.8)',
          },
        }),
      },
    },
    // MuiBackdrop: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: 'transparent',
    //     },
    //   },
    // },
  },
};

export const mainTheme = createTheme(themeOptions);
export const invertedTheme = createTheme(themeInvertedOptions);

export const Provider = ({ children }) => {
  const [current, setCurrent] = useState({ type: 'light', theme: mainTheme });
  // const [current, setCurrent] = useState({ type: 'dark', theme: invertedTheme });

  const toggleTheme = () => {
    setCurrent(
      current.type === 'light'
        ? { type: 'dark', theme: invertedTheme }
        : { type: 'light', theme: mainTheme }
    );
  };

  return (
    <ThemeProvider theme={current.theme}>
      {children}
      {/* <Switch
        inputProps={{ 'aria-label': 'Switch theme' }}
        defaultChecked
        sx={{ position: 'absolute', top: 0, right: 0 }}
        onChange={() => toggleTheme()}
      /> */}
    </ThemeProvider>
  )
}

const currentTheme = mainTheme;
export default currentTheme;

export const invert = (Component) => ({ children, ...otherProps }) => (
  <ThemeProvider theme={invertedTheme}>
    <Component {...otherProps}>
      {children}
    </Component>
  </ThemeProvider>
);