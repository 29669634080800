import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export const PropertyContainer = styled(Accordion)(({ theme }) => ({
    borderRadius: '12px',
    border: `1px solid ${theme.palette.secondary.main}`,
    boxShadow: 'none',

    '&:before': {
        display: 'none',
    },

    '&.MuiAccordion-root:last-of-type': {
        borderRadius: '12px',
    },

    '&.MuiAccordion-root:first-of-type': {
        borderRadius: '12px',
    },

    '&.Mui-expanded': {
        margin: 0,
        marginBottom: '12px',
    },
}));

export const PropertyTitle = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '12px',
    alignItems: 'flex-start',
    paddingTop: '8px',

    '& > div': {
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        margin: 0,
    },

    '&.Mui-expanded': {
        borderRadius: '12px 12px 0px 0px',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        minHeight: '48px',

        '& > div': {
            margin: 0,

            '& > div:last-child': {
                borderRadius: '0px',
            },
        },
    },
}));

export const PropertyAddressContainer = styled('div')(({ theme }) => ({
    fontSize: '12px',
    fontWeight: 'lighter',
    display: 'flex',
    color: theme.palette.primary.main,
    alignItems: 'center',

    [theme.breakpoints.down(800)]: {
        fontSize: '12px',
        fontWeight: 'normal',
    },
}));

export const PropertyEmoji = styled('div')(({ theme }) => ({
    fontSize: '16px',
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
    borderRadius: '30px',
    width: '30px',
    height: '30px',
    padding: '4px',
}));

export const PropertyAddress = styled('div')(({ theme }) => ({
    lineHeight: '22px',
    display: 'inline-block',
}));

export const PropertyPostcode = styled('div')(({ theme }) => ({
    fontSize: '14px',
    lineHeight: '22px',
    display: 'inline-block',
    fontWeight: 'bold',
    margin: '0px 12px',
    flexShrink: 0,

    [theme.breakpoints.down(800)]: {
        fontSize: '14px',
    },
}));

export const PropertyViewingsInfo = styled('div')(({ theme }) => ({
    fontSize: '12px',
    width: 'calc(100% + 56px)',
    color: theme.palette.primary.light,
    // fontWeight: 'lighter',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255, 0.3)',
    padding: '4px 0px',
    marginTop: '8px',
    marginLeft: '-16px',
    borderRadius: '0px 0px 12px 12px',
    flexShrink: 0,
}));

export const PropertyContent = styled(AccordionDetails)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '20px',
    backgroundColor: theme.palette.background.default,
    borderRadius: '0px 0px 12px 12px',

    [theme.breakpoints.down(800)]: {
        padding: '12px',
    },
}));