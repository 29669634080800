import PropertyDisplay from "../../../Properties/Property/PropertyDisplay";
import Stack from '@mui/material/Stack';

const PropertySelector = ({
    displayOnly,
    propertyId,
    properties,
    onSelectProperty = () => { },
}) => {
    if (!properties) {
        return "loading";
    }
    return (
        <Stack
            sx={{ height: 'calc(100% - 48px)', p: 2.5, overflow: 'auto' }}
            gap={1}
        >
            {
                displayOnly ? (
                    <PropertyDisplay
                        property={properties.find(p => p.id === propertyId)}
                        color="secondary.light"
                        selected
                    />
                ) : (
                    properties.map((property, idx) => (
                        <PropertyDisplay
                            key={`property-sel-${idx}`}
                            property={property}
                            selected={property.id === propertyId}
                            color="secondary.light"
                            onClick={() => onSelectProperty(property)}
                        />
                    ))
                )
            }
        </Stack>
    );
};

export default PropertySelector;