import { styled } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) => ({
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down(800)]: {
        flexDirection: 'column-reverse',
    }
}));

export const Content = styled('div')(({ theme }) => ({
    // position: 'absolute',
    // left: '74px',
    // height: 'calc(100% - 40px)',
    // width: 'calc(100% - 94px)',
    // transition: 'all 1000ms ease-out',
    // opacity: 1,

    display: 'flex',
    justifyContent: 'flex-start',
    flexGrow: 1,
    backgroundColor: theme.palette.primary.opacity(0.05),
    overflowY: 'scroll',
    margin: '20px',
    marginLeft: '0px',
    borderRadius: '8px',
    // zIndex: 1,

    // '&.fade-enter': {
    //     opacity: 0,
    //     zIndex: 2,
    // },

    // '&.fade-exit': {
    //     opacity: 1,
    // },

    // '&.fade-exit-active': {
    //     opacity: 0,
    //     transition: 'all 1000ms ease-out',
    // },

    // '&.fade-enter-active': {
    //     opacity: 1,
    //     transition: 'all 1000ms ease-out',
    // },

    // '&.fade-enter-done': {
    //     opacity: 1,
    // },

    [theme.breakpoints.down(800)]: {
        marginLeft: '20px',
    },

    [theme.breakpoints.down(800)]: {
        margin: 0,
    },
}));