import { useEffect, useState } from "react";
import { Stack } from "@mui/material";

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import RoomServiceRoundedIcon from '@mui/icons-material/RoomServiceRounded';

import Text from "../../Components/library/Typography/Text";
import ControlledInput from "../../Components/Input/ControlledInput";

const KeynestOptions = ({ onDataChange = () => { } }) => {
    const [data, setData] = useState({
        linkUrl: {
            value: '',
            valid: false,
        },
    });

    useEffect(() => {
        onDataChange(data);
    }, []);

    return (
        <Stack>
            <Text
                size="medium"
                weight="bold"
                sx={{ m: '20px 0px 12px' }}
            >🔗 Keynest Magic Link *</Text>
            <ControlledInput
                required
                placeholder="https://keynest.com/keys/1987387510"
                defaultValue={data.linkUrl.value || ''}
                value={data.linkUrl.value || ''}
                onChange={(value, valid) => {
                    const _newData = { ...data, linkUrl: { value, valid } };
                    setData(_newData);
                    onDataChange(_newData);
                }}
                size="small"
            />
        </Stack>
    );
};

const AtPropertyOptions = ({ onDataChange = () => { } }) => {
    const [data, setData] = useState({
        name: {
            value: '',
            valid: false,
        },
        phone: {
            value: '',
            valid: false,
        },
    });

    useEffect(() => {
        onDataChange(data);
    }, []);

    return (
        <Stack>
            <Text
                size="medium"
                weight="bold"
                sx={{ m: '20px 0px 12px' }}
            >📞 Contact Information *</Text>
            <Stack
                direction="row"
                gap={2}
            >
                <ControlledInput
                    label="Name"
                    required
                    placeholder="Jane Doe"
                    defaultValue={data.name.value || ''}
                    onChange={(value, valid) => {
                        const _newData = { ...data, name: { value, valid } };
                        setData(_newData);
                        onDataChange(_newData);
                    }}
                    size="small"
                    sx={{ width: '50%' }}
                />
                <ControlledInput
                    label="Phone"
                    required
                    placeholder="+44 07123456789"
                    autoComplete="off"
                    mask={'+44 00000000000'}
                    validator={/^\+44 (07\d{9})$/}
                    defaultValue={data.phone.value || ''}
                    onChange={(value, valid) => {
                        const _newData = { ...data, phone: { value, valid } };
                        setData(_newData);
                        onDataChange(_newData);
                    }}
                    size="small"
                    sx={{ width: '50%' }}
                />
            </Stack>
        </Stack>
    );
};

const KEYS_OPTIONS = [
    {
        type: 'keynest',
        icon: <img width={55} src="/app/keynest-logo.gif" />,
        display: 'Already at a Keynest',
        info: 'Keys are currently at a Keynest',
        options: KeynestOptions,
        data: [
            {
                key: 'dropoffUrl',
                display: 'Dropoff Link',
                type: 'link',
            },
            {
                key: 'linkUrl',
                display: 'Pickup Link',
                type: 'link',
            },
        ],
    },
    {
        type: 'requestKeynest',
        icon: <img width={55} src="/app/keynest-logo.gif" />,
        display: 'Request a Keynest',
        info: 'Request a Keynest dropoff link',
    },
    {
        type: 'concierge',
        icon: <RoomServiceRoundedIcon />,
        display: 'At the Concierge',
        info: 'Keys are with the concierge (always inform them beforehand)',
    },
    {
        type: 'atProperty',
        icon: <HomeRoundedIcon />,
        display: 'At the Property',
        info: 'Tenant or landlord access (no keys needed)',
        options: AtPropertyOptions,
        data: [
            {
                key: 'name',
                display: 'Name',
            },
            {
                key: 'phone',
                display: 'Phone Number',
            },
        ],
    },
];

export default KEYS_OPTIONS;