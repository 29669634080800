import Booked from "./Booked";
import Notified from "./Notified";
import EnRoute from "./EnRoute";
import KeyPickup from "./KeyPickup";
import Arrival from "./Arrival";
import PreViewing from "./PreViewing";
import Viewings from "./Viewings";
import PostViewing from "./PostViewing";
import Exit from "./Exit";
import KeyDropoff from "./KeyDropoff";

export default [ // eslint-disable-line
    { ...Viewings, expanded: true, name: 'viewings-header' },
    Booked,
    Notified,
    EnRoute,
    KeyPickup,
    Arrival,
    PreViewing,
    { ...Viewings, expanded: false },
    PostViewing,
    Exit,
    KeyDropoff,
];