import { getData, getEmojiForViewingStatus, distanceFromViewingStart, distance } from "../utils";
import { ViewingList, ViewingSubtitleContainer, List } from "../SessionReport.styled";
import DataElem from "../DataElem";
import ViewingCard from "../../Viewing/Card/ViewingCard";
import { addHours, format } from "date-fns";
import Text from "../../library/Typography/Text";

const isBlockViewing = (viewingReport) => {
    const cn = viewingReport.info.find((i) => i.key === 'customer-name').value;
    return cn === 'BLOCK VIEWING';
};

export default { // eslint-disable-line
    name: 'viewings',
    emoji: '👀',
    time: () => {
        return 'Multi';
    },
    title: (reports) => {
        return (
            <>
                <DataElem
                    text={`${reports.viewingReports.length}`}
                    sx={{ marginLeft: 0 }}
                />
                Viewings
            </>
        );
    },
    subtitle: (reports) => {
        return (
            <>
                <DataElem
                    text={`${reports.success.length}`}
                    sx={{ marginLeft: 0 }}
                />
                successful
            </>
        );
    },
    content: (reports) => {
        console.log(reports);
        return (
            <ViewingList>
                {
                    reports.viewingReports.map((viewingReport) => (
                        <ViewingCard
                            key={`viewing-report-viewing-${viewingReport.id}`}
                            simple
                            avatar={getEmojiForViewingStatus(viewingReport)}
                            title={
                                <>
                                    {
                                        viewingReport.viewingStatus !== 'CANCEL' && viewingReport.viewingStatus !== 'NOSHOW' ? (
                                            <List>
                                                <div>
                                                    Started:
                                                    {
                                                        isBlockViewing(viewingReport) ? (
                                                            <DataElem
                                                                report={viewingReport}
                                                                attr="times"
                                                                objkey="viewing-scheduled-start"
                                                                format={(start) => {
                                                                    return format(new Date(start), 'kk:mm');
                                                                }}
                                                            />
                                                        ) : (
                                                            <DataElem
                                                                report={viewingReport}
                                                                attr="times"
                                                                objkey="viewing-start"
                                                            />
                                                        )
                                                    }
                                                </div>
                                                <div>
                                                    Ended:
                                                    {
                                                        isBlockViewing(viewingReport) ? (
                                                            <DataElem
                                                                report={viewingReport}
                                                                attr="times"
                                                                objkey="viewing-scheduled-start"
                                                                format={(start) => {
                                                                    return format(addHours(new Date(start), 2), 'kk:mm');
                                                                }}
                                                            />
                                                        ) : (
                                                            <DataElem
                                                                report={viewingReport}
                                                                attr="times"
                                                                objkey="viewing-end"
                                                            />
                                                        )
                                                    }
                                                </div>
                                                <div>
                                                    Lasted:
                                                    {
                                                        isBlockViewing(viewingReport) ? (
                                                            <DataElem
                                                                report={viewingReport}
                                                                attr="times"
                                                                objkey="viewing-end"
                                                                format={() => {
                                                                    return '2 hours';
                                                                }}
                                                            />
                                                        ) : (
                                                            <DataElem
                                                                report={viewingReport}
                                                                attr="times"
                                                                objkey="viewing-end"
                                                                format={(end) => {
                                                                    return distance({
                                                                        timeStart: getData(viewingReport, 'times', 'viewing-start'),
                                                                        timeEnd: end,
                                                                        suffix: false,
                                                                    });
                                                                }}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </List>
                                        ) : null
                                    }
                                    {
                                        viewingReport.viewingStatus === 'CANCEL' ? (
                                            <div>
                                                Cancelled at:
                                                <DataElem
                                                    report={viewingReport}
                                                    attr="times"
                                                    objkey="viewing-cancel"
                                                />
                                            </div>
                                        ) : null
                                    }
                                </>
                            }
                            subheader={
                                <ViewingSubtitleContainer>
                                    {
                                        viewingReport.viewingStatus === 'NOSHOW' ? (
                                            <div>{`${getData(viewingReport, 'info', 'customer-name')} didn't show up`}</div>
                                        ) : null
                                    }
                                    {
                                        viewingReport.viewingStatus === 'CANCEL' ? (
                                            <div>Cancelled by the customer</div>
                                        ) : null
                                    }
                                    {
                                        viewingReport.viewingStatus === 'LATE' ? (
                                            <div>
                                                The cutomer was
                                                <DataElem
                                                    report={viewingReport}
                                                    attr="times"
                                                    objkey="viewing-start"
                                                    format={(data) => {
                                                        return distanceFromViewingStart({ time: data, viewingReport, suffix: false })
                                                    }}
                                                />
                                                late
                                            </div>
                                        ) : null
                                    }
                                    {
                                        isBlockViewing(viewingReport) ? (
                                            <>
                                                <div style={{ marginTop: '20px' }}>Block viewing info</div>
                                                {
                                                    (reports.sessionReport.info.find((t) => t.key === 'more-info') || { value: '' }).value.split('\n').map(line => (
                                                        <Text>
                                                            {line}
                                                        </Text>
                                                    ))
                                                }
                                            </>
                                        ) : null
                                    }
                                </ViewingSubtitleContainer>
                            }
                            viewing={{
                                startsAt: getData(viewingReport, 'times', 'viewing-scheduled-start'),
                                Viewer: { name: `${viewingReport.viewingStatus !== 'CANCEL' && viewingReport.viewingStatus !== 'NOSHOW' ? '✅ ' : '🛑 '} ${getData(viewingReport, 'info', 'customer-name')}` },
                            }}
                        />
                    ))
                }
            </ViewingList>
        );
    },
    expanded: true,
}