import { useState, useEffect } from 'react';
import Fuse from 'fuse.js';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import HeaderWithSearch from '../../../Components/library/Cards/Headers/WithSearch';
import HeaderStatusSelector from '../../../Components/library/Cards/Headers/StatusSelector';
import Text from '../../../Components/library/Typography/Text';
import Tag from '../../../Components/library/Tag/Tag';

import Lead from '../Lead/Lead';

import APPS_CONFIG from '../../config';

import {
    ContainerCard,
    BackButton,
    CardTitleText,
} from '../leads.styled';

const fuseOptions = {
    includeScore: true,
    minMatchCharLength: 2,
    keys: ['name']
};

const LeadsList = ({
    leads,
    propertyAddress = '',
    color = 'secondary.light',
    onAction = () => { },
    onAddLead = () => { },
    onBack = () => { }
}) => {
    const [display, setDisplay] = useState('PENDING');
    const [fuse, setFuse] = useState(null);
    const [filteredLeads, setFilteredLeads] = useState(leads);

    useEffect(() => {
        setFuse({
            PENDING: new Fuse(leads.PENDING, fuseOptions),
            APPROVED: new Fuse(leads.APPROVED, fuseOptions),
            REJECTED: new Fuse(leads.REJECTED, fuseOptions),
        });
        setFilteredLeads(leads);
    }, [leads]);

    return (
        <ContainerCard
            header={
                <Stack>
                    <HeaderWithSearch
                        title={
                            <Stack
                                direction="row"
                                alignItems="center"
                            >
                                <BackButton
                                    sx={{ padding: 0 }}
                                    onClick={onBack}
                                >
                                    <ArrowBackOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                                    Back
                                </BackButton>
                                <CardTitleText size="large" weight="bold" sx={{
                                    flexShrink: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    textAlign: 'center',
                                    pointerEvents: 'none',
                                    gap: 1,
                                }}><img src={APPS_CONFIG.leads.logo} width="20px"/> Leads</CardTitleText>
                            </Stack>
                        }
                        tags={[
                            <Tag key="lead-tag-addr">
                                {propertyAddress}
                            </Tag>
                        ]}
                        onSearchChange={(value) => {
                            if (value.length === 0) {
                                setFilteredLeads(leads);
                                return;
                            }

                            if (value.length < 2) {
                                return;
                            }

                            const res = {
                                PENDING: fuse.PENDING.search(value).map((r) => r.item),
                                APPROVED: fuse.APPROVED.search(value).map((r) => r.item),
                                REJECTED: fuse.REJECTED.search(value).map((r) => r.item),
                            };

                            setFilteredLeads(res);
                        }}
                        onSearchClose={() => {
                            setFilteredLeads({
                                PENDING: leads.PENDING,
                                APPROVED: leads.APPROVED,
                                REJECTED: leads.REJECTED,
                            });
                        }}
                    />
                    <HeaderStatusSelector
                        currentStatus={display}
                        onSelect={(status) => setDisplay(status)}
                        color={color}
                        statuses={
                            [{
                                status: 'PENDING',
                                title: '👀 Pending',
                            },
                            {
                                status: 'APPROVED',
                                title: '✅ Approved',
                            },
                            {
                                status: 'REJECTED',
                                title: '❌ Rejected',
                            }]
                        }
                        statusCount={{
                            PENDING: filteredLeads.PENDING.length,
                            APPROVED: filteredLeads.APPROVED.length,
                            REJECTED: filteredLeads.REJECTED.length,
                        }}
                    />
                    <Stack sx={{ width: '100%', height: 'fit-content' }}>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{ width: 'fit-content', m: 2.5, ml: 2.5, mt: 0 }}
                            onClick={() => onAddLead(display)}
                        >
                            <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                            Add Lead
                        </Button>
                    </Stack>
                </Stack >
            }
            withBodyContainer={false}
            body={
                <Stack
                    spacing={2.5}
                    direction="column"
                    sx={{ p: 2.5, overflow: 'auto', height: '100%' }}

                >
                    {
                        filteredLeads[display].length ? filteredLeads[display].map((lead) => (
                            <Lead
                                key={`${display}-${lead.id}`}
                                lead={lead}
                                color={color}
                                onAction={async (action, newLead) => await onAction(newLead ? newLead : lead, action)}
                                onNoteChange={async (notes) => await onAction({ ...lead, notes }, 'SAVE')}
                            />
                        )) : (
                            <Text
                                color="primary.light"
                                span
                                sx={{ width: '100%', textAlign: 'center', pt: 4, pb: 4 }}
                            >No leads in <Text weight="bold" span>{display}</Text></Text>
                        )
                    }
                </Stack >
            }
        />
    );
};

export default LeadsList;