import React, { useState, useMemo, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
    '& .gmapMarker': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: '8px',
        borderRadius: '12px',
        maxWidth: '150px',
        marginTop: '80px',
        whiteSpace: 'break-spaces',
    },
}));

const useIsInViewport = (ref) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );

    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}

const Map = ({ data, agencyName }) => {
    const ref = useRef(null);
    const [map, setMap] = useState(null);
    const [directionsRenderer, setDirectionsRenderer] = useState(null);

    const isInViewport = useIsInViewport(ref);

    useEffect(() => {
        if (!directionsRenderer && map && isInViewport) {
            const directionsService = new window.google.maps.DirectionsService();
            const _directionsRenderer = new window.google.maps.DirectionsRenderer({
                suppressMarkers: true,
            });

            _directionsRenderer.setMap(map);

            const request = {
                origin: data.distance.driving.originAddress,
                destination: data.distance.driving.destinationAddress,
                travelMode: 'DRIVING'
            };

            directionsService.route(request, (result, status) => {
                _directionsRenderer.setDirections(result);
            });

            new window.google.maps.Marker({
                position: data.distance.driving.originLocation,
                map: map,
                title: 'Start',
                label: { text: agencyName, className: 'gmapMarker', fontFamily: 'Roboto Mono', fontWeight: 'bold', color: 'white' },
            });

            new window.google.maps.Marker({
                position: data.distance.driving.destinationLocation,
                map: map,
                title: 'End',
                label: { text: 'Property', className: 'gmapMarker', fontFamily: 'Roboto Mono', color: 'white' },
            });

            setDirectionsRenderer(_directionsRenderer);
        }
    }, [isInViewport, map]);

    useEffect(() => {
        if (ref.current && !map) {
            const london = new window.google.maps.LatLng(51.546164, -0.1017919);
            const mapOptions = {
                zoom: 12,
                center: london,
                disableDefaultUI: true,
            };

            const map = new window.google.maps.Map(ref.current, mapOptions);
            setMap(map);
        }
    }, [ref, map]);

    return (
        <Container ref={ref} style={{ height: '300px' }} />
    )
};

export default Map;