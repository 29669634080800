import { getData, getEmojiForViewingStatus, distanceFromViewingStart, distance } from "../../SessionReport/utils";
import { ViewingSubtitleContainer, List } from "../../SessionReport/SessionReport.styled";
import DataElem from "../../SessionReport/DataElem";
import ViewingCard from "./ViewingCard";

const ViewingReportCard = ({ viewingReport }) => {
    return (
        <ViewingCard
            key={`viewing-report-viewing-${viewingReport.id}`}
            simple
            avatar={getEmojiForViewingStatus(viewingReport)}
            title={
                <>
                    {
                        viewingReport.viewingStatus !== 'CANCEL' && viewingReport.viewingStatus !== 'NOSHOW' ? (
                            <List>
                                <div>
                                    Started:
                                    <DataElem
                                        report={viewingReport}
                                        attr="times"
                                        objkey="viewing-start"
                                    />
                                </div>
                                <div>
                                    Ended:
                                    <DataElem
                                        report={viewingReport}
                                        attr="times"
                                        objkey="viewing-end"
                                    />
                                </div>
                                <div>
                                    Lasted:
                                    <DataElem
                                        report={viewingReport}
                                        attr="times"
                                        objkey="viewing-end"
                                        format={(end) => {
                                            return distance({
                                                timeStart: getData(viewingReport, 'times', 'viewing-start'),
                                                timeEnd: end,
                                                suffix: false,
                                            });
                                        }}
                                    />
                                </div>
                            </List>
                        ) : null
                    }
                    {
                        viewingReport.viewingStatus === 'CANCEL' ? (
                            <div>
                                Cancelled at:
                                <DataElem
                                    report={viewingReport}
                                    attr="times"
                                    objkey="viewing-cancel"
                                />
                            </div>
                        ) : null
                    }
                </>
            }
            subheader={
                <ViewingSubtitleContainer>
                    {
                        viewingReport.viewingStatus === 'NOSHOW' ? (
                            <div>{`${getData(viewingReport, 'info', 'customer-name')} didn't show up`}</div>
                        ) : null
                    }
                    {
                        viewingReport.viewingStatus === 'CANCEL' ? (
                            <div>Cancelled by the customer</div>
                        ) : null
                    }
                    {
                        viewingReport.viewingStatus === 'LATE' ? (
                            <div>
                                The cutomer was
                                <DataElem
                                    report={viewingReport}
                                    attr="times"
                                    objkey="viewing-start"
                                    format={(data) => {
                                        return distanceFromViewingStart({ time: data, viewingReport, suffix: false })
                                    }}
                                />
                                late
                            </div>
                        ) : null
                    }
                </ViewingSubtitleContainer>
            }
            viewing={{
                startsAt: getData(viewingReport, 'times', 'viewing-scheduled-start'),
                Viewer: { name: `${viewingReport.viewingStatus !== 'CANCEL' && viewingReport.viewingStatus !== 'NOSHOW' ? '✅ ' : '🛑 '} ${getData(viewingReport, 'info', 'customer-name')}` },
            }}
        />
    );
};

export default ViewingReportCard;