import { lightFormat, subMonths } from 'date-fns';
import { DataStore } from '@aws-amplify/datastore';
import { BillDay, BillMonth } from '../models';

export const getBillDayMonthToDate = async () => {
    const currentMonth = lightFormat(new Date(), 'yyyy-MM');
    return await DataStore.query(BillDay, b => b.date('beginsWith', currentMonth));
};

export const getBillMonthLastSixMonth= async () => {
    return await DataStore.query(BillMonth, b => b.createdAt('gt', subMonths(new Date(), 7).toISOString()));
};

export default { // eslint-disable-line
    day: {
        getMonthToDate: getBillDayMonthToDate,
    },
    month: {
        getLastSixMonth:  getBillMonthLastSixMonth,
    },
};