import { useState } from "react";
import { lightFormat } from "date-fns";
import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import Card from "../../Components/library/Cards/Generic";
import Header from "../../Components/library/Cards/Headers/Standard";
import Text from "../../Components/library/Typography/Text";

import DatePicker from "../../Components/Input/DatePicker/DatePicker";

import {
    BackButton,
    CardTitleText,
} from '../Leads/leads.styled';
import Tag from "../../Components/library/Tag/Tag";

import APPS_CONFIG from "../config";
import ActionFooter from "../../Components/library/Cards/Footers/Action";

const Billing = ({
    onBack = () => { },
}) => {
    const [isFecthing, setIsFecthing] = useState(false);
    const [currentBills, setCurrentBills] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = async (date) => {
        console.log(date);
        setIsFecthing(true);
        setSelectedDate(date);
        const res = await API.graphql({
            query: queries.billingManager,
            variables: {
                op: 'monthlyAdmin',
                payload: JSON.stringify({
                    month: lightFormat(date, 'yyyy-MM'),
                }),
            },
        });

        const _bills = JSON.parse(res.data.billingManager);
        setCurrentBills(_bills.body);
        setIsFecthing(false);
    };

    const handleStatusChange = async (bill, status) => {
        setIsFecthing(true);
        const res = await API.graphql({
            query: queries.billingManager,
            variables: {
                op: 'billStatusAdmin',
                payload: JSON.stringify({
                    billId: bill.id,
                    status,
                }),
            },
        });

        const _bill = JSON.parse(res.data.billingManager);
        const newCurrentBills = currentBills.map(b => {
            if (b.id === bill.id) {
                return {
                    ...b,
                    ..._bill.body,
                };
            }
            return b;
        });

        setCurrentBills(newCurrentBills);
        setIsFecthing(false);
    };

    return (
        <Card
            sx={{
                backgroundColor: 'background.default',
                height: '100%',
            }}
            header={
                <Header
                    title={
                        <Stack
                            direction="row"
                            alignItems="center"
                        >
                            <BackButton
                                sx={{ padding: 0 }}
                                onClick={onBack}
                            >
                                <ArrowBackOutlinedIcon sx={{ fontSize: '16px', mr: 0.5 }} />
                                Back
                            </BackButton>
                            <CardTitleText size="large" weight="bold" sx={{
                                flexShrink: 0,
                                display: 'flex',
                                alignItems: 'center',
                                alignSelf: 'center',
                                textAlign: 'center',
                                pointerEvents: 'none',
                                gap: 1,
                            }}>🧾 Billing</CardTitleText>
                        </Stack>
                    }
                />
            }
            withBodyContainer={false}
            body={
                <Stack
                    gap={2.5}
                    sx={{
                        p: 2.5,
                        maxHeight: '100%',
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    <Stack
                        sx={{
                            opacity: isFecthing ? 0.5 : 1,
                            pointerEvents: isFecthing ? 'none' : 'all',
                        }}
                    >
                        <DatePicker
                            maxDate={new Date()}
                            views={['month']}
                            openTo="month"
                            value={selectedDate}
                            onChange={handleDateChange}
                            inputFormat="dd/MM/yyyy hh:mm a"
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Stack>
                    <Stack
                        gap={2.5}
                    >
                        {currentBills ? currentBills.sort((a, b) => (a.status === 'PAID') - (b.status === 'PAID')).filter(b => b.value > 0).map((bill) => (
                            <Card
                                key={bill.id}
                                header={
                                    <Header
                                        title={bill.user.name}
                                        tags={[
                                            <Tag key="val">{`£${bill.value / 100}`}</Tag>,
                                            <Tag key="paid/unpaid">{bill.status === 'PAID' ? '🟢 PAID' : '🔴 UNPAID'}</Tag>
                                        ]}
                                    />
                                }
                                body={
                                    <Stack
                                        gap={1.5}
                                    >
                                        {
                                            bill.items.map((item) => (
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    key={item.type}
                                                >
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        gap={1.5}
                                                    >
                                                        <img width="20px" src={APPS_CONFIG[item.type === 'property' ? 'properties' : `${item.type}s`].logo} />
                                                        <Tag>{item.count}</Tag>
                                                        <Text>
                                                            {(item.type === 'property' ? 'properties' : `${item.type}s`).toUpperCase()}
                                                        </Text>
                                                    </Stack>
                                                    <Tag>{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.charge / 100)}</Tag>
                                                </Stack>
                                            ))
                                        }
                                        <Text>Activity Days: {bill.billsDay.length}</Text>
                                    </Stack>
                                }
                                footer={
                                    <ActionFooter
                                        align='flex-end'
                                        actions={[
                                            {
                                                button: true,
                                                buttonVariant: 'contained',
                                                value: `Mark as ${bill.status === 'PAID' ? 'UNPAID' : 'PAID'}`,
                                                sx: {
                                                    opacity: isFecthing ? 0.5 : 1,
                                                    pointerEvents: isFecthing ? 'none' : 'all',
                                                },
                                                onClick: () => {
                                                    handleStatusChange(bill, bill.status === 'PAID' ? 'UNPAID' : 'PAID');
                                                }
                                            },
                                        ]}
                                    />
                                }
                            />
                        )) : null}
                        {
                            currentBills && currentBills.length === 0 ? (
                                <Text>No bills for this month yet</Text>
                            ) : null
                        }
                    </Stack>
                </Stack >
            }
        />
    );
};

export default Billing;