import React, { useState } from 'react';
import { addHours, addMinutes, format } from 'date-fns';
import { Container, Header, Footer, Title, Subtitle, FieldContainer, HalfInputContainer, DateSlotContainer, DateSlotWrapper, DateSlotButton } from './form.styled.js';
import Button from '@mui/material/Button';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import enLocale from 'date-fns/locale/en-GB';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';


export const TextFieldCustom = styled(TextField)(() => ({
    marginBottom: '300px',
}));


const getExtensionTimes = (extension) => {
    const h = Math.floor(extension / 4);
    const m = (extension % 4) * 15;

    return {
        h,
        m,
    };
};

const getTotalEndTime = (end, extension) => {
    if (extension === -1) {
        return end;
    }

    let d = new Date(2022, 6, 22, parseInt(end.split(':')[0], 10), parseInt(end.split(':')[1], 10), 0, 0);
    const extTime = getExtensionTimes(extension + 1);
    d = addMinutes(addHours(d, extTime.h), extTime.m);
    return format(d, 'HH:mm');
};

const CalendarForm = ({
    onUpdate,
    nextStep,
    previousStep,
    initialFormState,
    postcode,
    isPropertySet,
    title = 'Book a viewing',
    subtitle = 'Select a time',
    backButton = 'Back',
    slot = false,
}) => {
    const [formData, setFormData] = useState(initialFormState);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null); // eslint-disable-line
    const [currentPostcode, setCurrentPostcode] = useState(null);
    const [timeValue, setTimeValue] = useState(null);
    const [extension, setExtension] = useState(-1);
    const [calendar, setCalendar] = useState({});
    const [loading, setLoading] = useState(false);

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);

    if (postcode !== currentPostcode && startTime) {
        setStartTime(null);
        setCalendar({});
    }

    // const handleFormData = (key, value, valid) => {
    //     const newFormData = { ...formData, [key]: { value, valid } };
    //     setFormData(newFormData);
    //     onUpdate(newFormData)
    // };

    const fetchAvailableCalendar = async (startTime) => {
        setCurrentPostcode(postcode);

        if (!slot) {
            const res = await API.graphql({
                query: queries.bookingAvailableTime,
                variables: {
                    date: startTime.toISOString(),
                    postcode: postcode.toUpperCase().replace(/\s/g, ''),
                },
            });

            return JSON.parse(res.data.bookingAvailableTime);
        }

        const test = {
            "availableCalendar": {
                "18:00": {
                    start: "18:00",
                    end: "19:00",
                    endMax: "20:00",
                    extension: 5,
                },
                "18:15": {
                    start: "18:15",
                    end: "19:15",
                    endMax: "22:00",
                    extension: 3,
                },
                "18:30": {
                    start: "18:30",
                    end: "19:30",
                    endMax: "22:00",
                    extension: 2,
                },
                "18:45": {
                    start: "18:45",
                    end: "19:45",
                    endMax: "22:00",
                    extension: 1,
                },
                "19:00": {
                    start: "19:00",
                    end: "20:00",
                    endMax: "22:00",
                    extension: 4,
                },
                "19:15": {
                    start: "19:15",
                    end: "20:15",
                    endMax: "22:00",
                    extension: 4,
                },
                "19:30": {
                    start: "19:30",
                    end: "19:00",
                    endMax: "22:00",
                    extension: 4,
                },
                "19:45": {
                    start: "19:45",
                    end: "19:00",
                    endMax: "22:00",
                    extension: 4,
                },
                "20:00": {
                    start: "20:00",
                    end: "19:00",
                    endMax: "22:00",
                    extension: 4,
                },
                "20:15": {
                    start: "20:15",
                    end: "19:00",
                    endMax: "22:00",
                    extension: 4,
                },
                "20:30": {
                    start: "20:30",
                    end: "19:00",
                    endMax: "22:00",
                    extension: 4,
                },
                "20:45": {
                    start: "20:45",
                    end: "19:00",
                    endMax: "22:00",
                    extension: 4,
                },
                "21:00": {
                    start: "21:00",
                    end: "19:00",
                    endMax: "22:00",
                    extension: 4,
                },
                "21:15": {
                    start: "21:15",
                    end: "19:00",
                    endMax: "22:00",
                    extension: 4,
                },
                "21:30": {
                    start: "21:30",
                    end: "19:00",
                    endMax: "22:00",
                    extension: 4,
                },
                "21:45": {
                    start: "21:45",
                    end: "19:00",
                    endMax: "22:00",
                    extension: 4,
                },
            },
            "params": {
                "dayStartTime": 18,
                "dayEndTime": 22,
                "viewers": 2,
                "postcode": "E5",
                "date": "2022-06-22T13:39:00.000Z"
            }
        };

        return test;
    };

    const handleDayChange = async (newValue) => {
        const date = new Date(newValue);
        date.setSeconds(0);
        date.setMilliseconds(0);
        setStartTime(date);
        setTimeValue(null);
        setLoading(true);
        const availableCalendar = await fetchAvailableCalendar(date);

        setCalendar(availableCalendar);
        setLoading(false);
    };

    const handleTimeChange = async (newValue) => {
        let newFormData;
        setTimeValue(newValue);
        const [hours, minutes] = newValue.split(':');
        const date = new Date(startTime);
        date.setHours(hours);
        date.setMinutes(minutes);

        if (slot) {
            // setTimeValue(newValue);
            setExtension(-1);
            // const [hours, minutes] = newValue.split(':');
            // const date = new Date(startTime);
            // date.setHours(hours);
            // date.setMinutes(minutes);

            const _endTime = addHours(date, 1);
            setStartTime(date);
            setEndTime(_endTime);

            newFormData = {
                ...formData,
                startTime: { value: date, valid: true },
                endTime: { value: _endTime, valid: true },
            };
        } else {
            newFormData = {
                ...formData,
                dateTime: { value: date, valid: true },
            };
        }

        setFormData(newFormData);
        onUpdate(newFormData);
    };

    const handleEndTimeChange = async (extensionValue) => {
        setExtension(extensionValue);
        let _endTime = addHours(startTime, 1);

        if (extensionValue !== -1) {
            _endTime = addMinutes(_endTime, ((extensionValue + 1) * 15));
        }

        setEndTime(_endTime);

        const newFormData = {
            ...formData,
            endTime: { value: _endTime, valid: true },
        };

        setFormData(newFormData);
        onUpdate(newFormData);
    };

    const formatSlot = (key, value) => {
        if (slot) {
            return (
                <div>
                    {`${value.start} - ${key === timeValue ? getTotalEndTime(value.end, extension) : value.end} `}
                    <span>{key === timeValue ? ` (${4 + (extension !== -1 ? extension + 1 : 0)} viewings)` : '(4 viewings)'}</span>
                    <br />
                    {
                        key === timeValue ? (
                            <span>{`add up to: ${value.extension - (extension !== -1 ? extension + 1 : 0)} more viewings`}</span>
                        ) : (
                            <span>{`add up to: ${value.extension} more viewings`}</span>
                        )
                    }
                </div>
            );
        }
        return `${key}${value === 1 ? ' 🔥' : ''}`;
    };

    return (
        <Container>
            <Header>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </Header>
            {!isPropertySet ? (
                <FieldContainer>
                    <Subtitle>
                        Please enter or select a property first
                    </Subtitle>
                </FieldContainer>
            ) : (
                <FieldContainer>
                    <HalfInputContainer>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
                            <StaticDatePicker
                                displayStaticWrapperAs="desktop"
                                minDate={tomorrow}
                                views={['day']}
                                openTo="day"
                                value={startTime}
                                onChange={handleDayChange}
                                inputFormat="dd/MM/yyyy hh:mm a"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <DateSlotContainer>
                            <div>{startTime ? format(new Date(startTime), 'MMMM, eeee do') : null}</div>
                            <DateSlotWrapper>
                                {loading ? <CircularProgress /> :
                                    Object.keys(calendar.availableCalendar || {}).sort().map((key) => (
                                        <>
                                            <DateSlotButton
                                                key={`${startTime}-${key}`}
                                                variant={key === timeValue ? 'contained' : 'outlined'}
                                                size="large"
                                                onClick={() => handleTimeChange(key)}
                                            >
                                                {formatSlot(key, calendar.availableCalendar[key])}
                                            </DateSlotButton>
                                            {
                                                slot && key === timeValue ? (
                                                    <FormControl sx={{ minWidth: '200px', marginBottom: '20px', marginTop: '10px' }} key={`form-${startTime}-${key}`}>
                                                        <InputLabel id="book-calendar-extension-label">Number of viewings</InputLabel>
                                                        <Select
                                                            labelId="book-calendar-extension-label"
                                                            id="book-calendar-extension"
                                                            label="Number of viewings"
                                                            value={extension}
                                                            onChange={(e) => handleEndTimeChange(e.target.value)}
                                                        >
                                                            <MenuItem id={`book-calendar-extension-val-${-1}`} value={-1}>
                                                                4 viewings
                                                            </MenuItem>
                                                            {
                                                                [...(new Array(calendar.availableCalendar[timeValue].extension))].map((_, index) => {
                                                                    return (
                                                                        <MenuItem id={`book-calendar-extension-val-${index}`} value={index}>
                                                                            {`${index + 5} viewings`}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                ) : null
                                            }
                                        </>
                                    ))
                                }
                            </DateSlotWrapper>
                        </DateSlotContainer>
                    </HalfInputContainer>
                </FieldContainer>
            )}
            <Footer>
                <Button
                    size="small"
                    onClick={previousStep}
                >
                    {backButton}
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    onClick={nextStep}
                >
                    Next
                </Button>
            </Footer>
        </Container >
    );
};

export default CalendarForm;