import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DataStore } from '@aws-amplify/datastore';
import { ViewingReport, SessionReport } from '../../models';
import SessionReportView from './SessionReport';

const SessionReportPage = () => {
    const [reports, setReports] = useState(null);
    const params = useParams();

    useEffect(() => {
        const fetchReport = async (id) => {
            const sessionReport = await DataStore.query(SessionReport, id);
            const viewingReports = await DataStore.query(ViewingReport, vr => vr.sessionReportId('eq', id));

            console.log(sessionReport, viewingReports);

            setReports({
                sessionReport,
                viewingReports,
            });
        };

        fetchReport(params.sessionReportId);
    }, []); // eslint-disable-line

    console.log(reports);

    if (!reports || !reports.sessionReport || !reports.viewingReports) {
        return null;
    }

    return (
        <SessionReportView reports={reports} />
    );
};

export default SessionReportPage;