import Stack from '@mui/material/Stack';
import { addMinutes, eachMinuteOfInterval, format, set, subMinutes, endOfDay, isToday, formatDistanceToNowStrict } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import Text from '../Typography/Text';

const CalendarDay = ({
    day,
    startsAt = '00:00',
    endsAt = '23:59',
    slotsLength = 15,
    header = null,
    data = [],
    padding = 15,
    bindData = () => { },
    sx = {},
}) => {

    const dayTimeStart = subMinutes(
        set(day, { hours: parseInt(startsAt.split(':')[0], 10), minutes: parseInt(startsAt.split(':')[1], 10), seconds: 0 }),
        padding,
    );
    const dayTimeEnd = addMinutes(
        set(day, { hours: parseInt(endsAt.split(':')[0], 10), minutes: parseInt(endsAt.split(':')[1], 10), seconds: 59 }),
        padding,
    );
    const slotTimes = eachMinuteOfInterval({ start: dayTimeStart, end: dayTimeEnd })
        .filter((_, index) => !(index % slotsLength))
        .map((date) => {
            const dateKey = format(date, 'HH:mm');
            return {
                date,
                dateTime: dateKey,
                data: data.find(d => dateKey === d.dateTime),
            };
        });

    return (
        <Stack
            direction="column"
            sx={{ ...sx }}
        >
            <Text
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '1px solid gray',
                    borderColor: 'secondary.light',
                    padding: '20px 0px',
                    textTransform: 'capitalize',
                    backgroundColor: 'secondary.main',
                    // borderRadius: '8px 8px 0px 0px',
                }}
                weight="bold"
                size="medium"
            >
                {`${isToday(day) ? 'Today' : formatDistanceToNowStrict(endOfDay(day), {
                    unit: 'day',
                    roundingMethod: 'round',
                    addSuffix: true,
                })} | ${formatInTimeZone(day, 'Etc/UTC', 'EEE MMM do')}`}
            </Text>
            {
                header ? header : null
            }
            {
                slotTimes.map((slot, idx) => {
                    const bindedData = bindData(slot) || slot.data;
                    console.log(bindedData);
                    return (
                        <Stack
                            key={`slot-${idx}`}
                            direction="row"
                            // key={slot.dateTime}
                            sx={{
                                borderRight: '1px solid gray',
                                borderLeft: '1px solid gray',
                                borderBottom: '1px solid gray',
                                borderColor: 'secondary.light',
                                width: '100%',
                            }}
                        >
                            <Text
                                sx={{
                                    borderRight: '1px solid gray',
                                    borderColor: 'secondary.light',
                                    p: bindedData ? 1.5 : 0.5,
                                    paddingLeft: 2.5,
                                    width: '80px',
                                    flexShrink: 0,
                                }}
                                size={bindedData && !bindedData.info ? 'medium' : '10px'}
                                weight={bindedData && !bindedData.info ? 'bold' : 'normal'}
                                color={bindedData && !bindedData.info ? 'primary.main' : 'primary.light'}
                            >{bindedData && bindedData.dateTimeDisplay ? bindedData.dateTimeDisplay : slot.dateTime}</Text>
                            <Stack sx={{ p: bindedData ? 1.5 : 0.5, width: '100%', paddingRight: 2.5 }}>{bindedData ? bindedData.component : null}</Stack>
                        </Stack>
                    );
                })
            }
        </Stack >
    );
};

export default CalendarDay;