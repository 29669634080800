import { styled } from '@mui/material/styles';

export const PropertyContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.primary.dark}`,
    padding: '12px',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',

    '&:hover': {
        border: `1px solid ${theme.palette.text.primary}`,
        backgroundColor: theme.palette.background.paper,

        '> div': {
            color: theme.palette.text.primary,
        }
    },

    '&[data-selected="true"]': {
        border: `1px solid ${theme.palette.text.primary}`,
        cursor: 'auto',
        paddingBottom: '18px',

        '&:hover': {
            transform: 'none',
        },

        backgroundColor: theme.palette.background.paper,

        '> div': {
            color: theme.palette.text.primary,
            fontSize: '14px',
            fontWeight: 'bold',
        }
    }
}));

export const PropertyContent = styled('div')(({ theme }) => ({

}));

export const PropertyPostcode = styled('div')(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: '14px',
    marginBottom: '8px',
    fontWeight: 'bold',
}));

export const PropertyAddress = styled('div')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '12px',
    fontWeight: 'lighter',
}));

export const PropertyAttr = styled('div')(({ theme }) => ({
    marginTop: '12px',
}));

export const PropertyAttrTitle = styled('p')(({ theme }) => ({
    display: 'inline',
    fontSize: '12px',
    fontWeight: 'lighter',
    backgroundColor: theme.palette.background.default,
    borderRadius: '8px',
    padding: '5px 10px',
}));

export const PropertyAttrValue = styled('p')(({ theme }) => ({
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: 'lighter',
    padding: '8px 8px 0px 8px',
    margin: 0,
}));

export const PropertyAttrValueLink = styled('a')(({ theme }) => ({
    color: theme.palette.text.primary,
    textDecoration: 'none',
}));

export const SlotContainer = styled('div')(({ theme }) => ({
    width: '180px',
    fontSize: '12px',
    textAlign: 'center',
}));

export const SlotsTitle = styled('div')(({ theme }) => ({
    fontSize: '12px',
    fontWeight: 'lighter',
    backgroundColor: 'rgba(255,255,255,0.3)',
    color: theme.palette.primary.text,
    borderRadius: '8px',
    padding: '4px 8px',
    // textAlign: 'center',
    marginBottom: '8px',
}));

export const Slot = styled('div')(({ theme }) => ({
    fontSize: '12px',
    fontWeight: 'lighter',
}));