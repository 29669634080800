import React from 'react';
import { Container, FieldContainer, Header, Footer } from './form.styled';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const ConfirmationMessage = styled('div')(() => ({
    fontSize: '28px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
}));

const confirmationEmojis = ['🔥', '✨', '👍', '🙌', '🚀', '🖖'];

const ConfirmBooking = ({ onClose }) => {
    return (
        <Container>
            <Header />
            <FieldContainer>
                <ConfirmationMessage>{`${confirmationEmojis[Math.floor(Math.random() * confirmationEmojis.length)]} Your booking is confirmed`}</ConfirmationMessage>
            </FieldContainer>
            <Footer>
                <Button
                    variant="outlined"
                    size="large"
                    onClick={onClose}
                >
                    Close
                </Button>
            </Footer>
        </Container>
    );
};

export default ConfirmBooking;