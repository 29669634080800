import { lightFormat } from 'date-fns';
import { DataStore } from '@aws-amplify/datastore';
import { Keys } from '../models';

export const createKeys = async (data) => {
    const keys = await DataStore.save(
        new Keys({
            propertyId: data.propertyId,
            type: data.type,
            data: data.data,
            info: data.info ? data.info : null,
            pickupLocation: data.pickupLocation ? data.pickupLocation : null,
            location: data.location ? data.location : null,
            dropoffLocation: data.dropoffLocation ? data.dropoffLocation : null,
            status: data.status ? data.status : null,
            externalId: data.externalId ? data.externalId : null,
        })
    );

    return keys;
};

export const updateKeys = async (keysId, data) => {
    const original = await DataStore.query(Keys, keysId);
    const keys = await DataStore.save(
        Keys.copyOf(original, updated => {
            updated.type = data.type ? data.type : original.type;
            updated.data = data.data ? data.data : original.data;
            updated.info = data.info ? data.info : original.info;
            updated.pickupLocation = data.pickupLocation ? data.pickupLocation : original.pickupLocation;
            updated.location = data.location ? data.location : original.location;
            updated.dropoffLocation = data.dropoffLocation ? data.dropoffLocation : original.dropoffLocation;
            updated.status = data.status ? data.status : original.status;
            updated.externalId = data.externalId ? data.externalId : original.externalId;
        })
    );

    return keys;
};

export const getKeys = async (keysId) => {
    return await DataStore.query(Keys, keysId);
};

export const getKeysByProperty = async (propertyId) => {
    return await DataStore.query(Keys, l => l.propertyId('eq', propertyId));
};

export const getAllKeys = async () => {
    return await DataStore.query(Keys, p => p.createdAt('gt', '2022-07-20T14:00:00.000Z'));
};

export const keysSubscription = (cb, predicate) => {
    const q = DataStore.observeQuery(Keys, predicate);
    const subscription = q.subscribe(async ({ items, isSynced }) => {
        cb(items, isSynced);
    });

    return subscription;
};

export default { // eslint-disable-line
    create: createKeys,
    get: getKeys,
    update: updateKeys,
    getByProperty: getKeysByProperty,
    getAll: getAllKeys,
    keysSubscription,
    subscribeToProperty: (cb, propId) => keysSubscription(cb, (v) => v.propertyId('eq', propId)),
};