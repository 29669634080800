import Text from './Typography/Text';
import GenericCard from './Cards/Generic';
import StandardHeader from './Cards/Headers/Standard';
import ActionFooter from './Cards/Footers/Action';
import Tag from './Tag/Tag';
import TimerButton from './Button/TimerButton';
import FeedbackButton from './Button/FeedbackButton';
import TagInput from './Inputs/TagInput';
import AutoSaveInput from './Inputs/AutoSaveInput';
import MarketPlaceTag from './Tag/MarketPlaceTag';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { useState } from 'react';
import HeaderWithSearch from './Cards/Headers/WithSearch';

const ShowCase = () => {
    const [time, setTime] = useState(0);

    return (
        <Container sx={{ p: 2.5 }}>
            <Stack spacing={2.5} >
                <GenericCard
                    sx={{ backgroundColor: 'white' }}
                    header={
                        <StandardHeader
                            emoji="🖊️"
                            title="Typography"
                            tags={['stable']}
                        />
                    }
                    body={
                        <Stack spacing={1}>
                            <Text>Typography default</Text>
                            <Text size="medium">Typography medium</Text>
                            <Text size="large">Typography large</Text>
                            <Text size="title">Typography title</Text>
                            <Text weight="bold">Typography default bold</Text>
                            <Text color="primary.light">Typography default primary.light color</Text>
                            <div>
                                <Text span>Typography default span</Text><Text span weight="bold"> Typography default span bold</Text>
                            </div>
                        </Stack>
                    }
                />
                <GenericCard
                    sx={{ backgroundColor: 'white' }}
                    header={
                        <StandardHeader
                            emoji="🖊️"
                            title="Input"
                            tags={['stable']}
                        />
                    }
                    body={
                        <Stack spacing={1}>
                            <TagInput
                                name="tag-input"
                                label="Input with tag"
                                helperText="This is some helper text"
                                // defaultValue={info}
                                // multiline
                                // rows={4}
                                onFocus={() => console.log('focus')}
                                onBlur={() => console.log('blur')}
                                onChange={(value) => console.log(value)}
                                placeholder="This is a placeholder"
                                tag={
                                    <Tag>Some status</Tag>
                                }
                            />
                            <AutoSaveInput
                                name="auto-save"
                                label="Auto save"
                                helperText="This is some helper text"
                                // defaultValue={info}
                                // multiline
                                // rows={4}
                                onFocus={() => console.log('focus')}
                                onBlur={() => console.log('blur')}
                                onChange={(value) => console.log(value)}
                                placeholder="This is a placeholder"

                                saveDebounceTime={4000}
                                onSave={async (value) => (
                                    new Promise((resolve) => setTimeout(resolve, 2000))
                                )}
                                statuses={{
                                    // loading: (<Tag>Loading</Tag>),
                                    saving: (<Tag color="primary.main">💾 Saving...</Tag>),
                                    saved: (<Tag color="primary.main">👍 Saved!</Tag>),
                                    changed: (<Tag color="primary.main">📄 New Changes</Tag>),
                                    default: null,
                                }}
                            />
                        </Stack>
                    }
                />
                <GenericCard
                    sx={{ backgroundColor: 'white' }}
                    header={
                        <StandardHeader
                            emoji="🧑‍🦲"
                            title="Headers"
                            tags={['stable']}
                        />
                    }
                    body={
                        <Stack spacing={1}>
                            <StandardHeader
                                sx={{ backgroundColor: 'background.light' }}
                                emoji="😃"
                                title="Header Title"
                                tags={['optional tags', 'second one']}
                                actions={[
                                    {
                                        value: 'some action',
                                        onClick: () => console.log('clicked some action'),
                                    },
                                    {
                                        value: 'some action 2',
                                        onClick: () => console.log('clicked some action 2'),
                                    }
                                ]}
                            />
                            <HeaderWithSearch
                                emoji="😃"
                                title="Header With Search"
                            />
                        </Stack>
                    }
                />
                <GenericCard
                    sx={{ backgroundColor: 'white' }}
                    header={
                        <StandardHeader
                            emoji="🦶"
                            title="Footers"
                            tags={['stable']}
                        />
                    }
                    body={
                        <Stack spacing={1}>
                            <ActionFooter
                                sx={{ backgroundColor: 'background.light' }}
                                actions={[
                                    {
                                        value: 'else',
                                        buttonVariant: 'outlined',
                                        button: true,
                                        onClick: () => console.log('clicked footer action 2'),
                                        sx: { marginRight: 'auto' },
                                    },
                                    {
                                        value: <Text>some text</Text>
                                    },
                                    {
                                        value: 'else',
                                        buttonVariant: 'outlined',
                                        button: true,
                                        onClick: () => console.log('clicked footer action 2'),
                                    },
                                    {
                                        value: 'do action',
                                        button: true,
                                        onClick: () => console.log('clicked footer action'),
                                    },
                                ]}
                                align="flex-end"
                            />
                        </Stack>
                    }
                />
                <GenericCard
                    sx={{ backgroundColor: 'white' }}
                    header={
                        <StandardHeader
                            emoji="🎴"
                            title="Cards"
                            tags={['stable']}
                        />
                    }
                    body={
                        <Stack spacing={1.5}>
                            <GenericCard
                                header={<div><Text size="title">This is the header</Text></div>}
                                body={<div><Text>This is the body</Text></div>}
                                footer={<div><Text color="primary.light">This is the footer</Text></div>}
                            />
                            <Text weight="bold">Example:</Text>
                            <GenericCard
                                sx={{ backgroundColor: 'white' }}
                                header={
                                    <StandardHeader
                                        emoji="😃"
                                        title="Main title of the card"
                                        tags={['first tag', 'second one']}
                                        actions={[
                                            {
                                                value: 'some action',
                                                onClick: () => console.log('clicked some action'),
                                            },
                                            {
                                                value: 'some action 2',
                                                onClick: () => console.log('clicked some action 2'),
                                            }
                                        ]}
                                    />
                                }
                                body={<div><Text>This is the body</Text></div>}
                                footer={
                                    <ActionFooter
                                        actions={[
                                            {
                                                value: 'else',
                                                buttonVariant: 'outlined',
                                                button: true,
                                                onClick: () => console.log('clicked footer action 2'),
                                                sx: { marginRight: 'auto' },
                                            },
                                            {
                                                value: <Text>some text</Text>
                                            },
                                            {
                                                value: 'else',
                                                buttonVariant: 'outlined',
                                                button: true,
                                                onClick: () => console.log('clicked footer action 2'),
                                            },
                                            {
                                                value: 'do action',
                                                button: true,
                                                onClick: () => console.log('clicked footer action'),
                                            },
                                        ]}
                                        align="flex-end"
                                    />
                                }
                            />
                        </Stack>
                    }
                />
                <GenericCard
                    sx={{ backgroundColor: 'white' }}
                    header={
                        <StandardHeader
                            emoji="🏷️"
                            title="Tags"
                            tags={['stable']}
                        />
                    }
                    body={
                        <Stack spacing={1}>
                            <Tag>This is a tag</Tag>
                            <Tag color="primary.main">This is a tag</Tag>
                            <MarketPlaceTag marketPlace='rightmove' />
                            <MarketPlaceTag marketPlace='zoopla' />
                            <MarketPlaceTag marketPlace='onthemarket' />
                        </Stack>
                    }
                />
                <GenericCard
                    sx={{ backgroundColor: 'white' }}
                    header={
                        <StandardHeader
                            emoji="🖱️"
                            title="Buttons"
                            tags={['stable']}
                        />
                    }
                    body={
                        <Stack spacing={1}>
                            <TimerButton
                                time={time}
                                onTimeEnd={() => setTime(0)}
                                onClick={() => setTime(10000)}
                            >Timer Button</TimerButton>
                            <FeedbackButton
                                feedback={<div>hello</div>}
                                revertAfter={3000}
                                variant="outlined"
                            >Click me</FeedbackButton>
                        </Stack>
                    }
                />
            </Stack>
        </Container>
    );
};

export default ShowCase;