import React from 'react';
import Files from '../Inputs/Files';
import Time from '../Inputs/Time';
import { FormContainer } from '../form.styled';
import { format } from 'date-fns';

const ExitForm = ({ sessionId, onUpdate, reports }) => {
    const handleFormData = async ({ type, id, data, key }) => {
        await onUpdate({ type, id, data, key });
    };

    return (
        <FormContainer>
            <Time
                name={'exit'}
                title={'Exit time'}
                subtitle={'At what time did you exit the property?'}
                currentTime={reports.sessionReport ? reports.sessionReport.times.filter((t) => t.key === 'exit')[0] : null}
                onChange={async (newTime, id) => {
                    await handleFormData({ type: 'times', id, data: { time: format(newTime, 'HH:mm') }, key: 'exit' })
                }}
            />
            <Files
                title={'Door closing video'}
                subtitle={'Take a video of the door when you lockit it'}
                files={reports.sessionReport ? reports.sessionReport.files.filter((f) => f.key === 'videos-exit') : []}
                type="video"
                name={'videos-exit'}
                sessionId={sessionId}
                onChange={async (newFiles) => await handleFormData({ type: 'files', data: newFiles, key: 'videos-exit' })}
                onDelete={async (file) => await handleFormData({
                    type: 'update', data: {
                        files: [...reports.sessionReport.files.filter((f) => f.id !== file.id)]
                    }
                })}
            />
        </FormContainer>
    );
};

export default ExitForm;