import { Stack } from "@mui/material";
import { formatDistance } from "date-fns";
import Card from "../../Components/library/Cards/Generic";
import Header from "../../Components/library/Cards/Headers/Standard";
import Tag from "../../Components/library/Tag/Tag";
import Text from "../../Components/library/Typography/Text";

import KEYS_OPTIONS from "./KeysOptions";

const KeysDisplay = ({
    keys,
    deactivatable = true,
    selectable = false,
    selected = false,
    onSelect = () => { },
    onDeactivate = () => { },
}) => {

    const option = KEYS_OPTIONS.find(ko => ko.type === keys.type);

    if (!option) {
        return null;
    }

    return (
        <Card
            onClick={selectable ? onSelect : () => { }}
            sx={{
                borderWidth: selected ? '2px' : '1px',
                borderStyle: 'solid',
                borderColor: selected ? 'primary.main' : 'secondary.main',
                cursor: selectable ? 'pointer' : 'normal',
            }}
            header={
                <Header
                    title={
                        <Text
                            sx={{
                                width: '100%',
                                color: selected ? 'primary.contrastText' : 'primary.main'
                            }}
                            weight="bold"
                        >{option.display.toUpperCase()}</Text>
                    }
                    emoji={
                        <Stack
                            sx={{
                                backgroundColor: 'primary.contrastText',
                                flexShrink: 0,
                                borderRadius: '8px',
                                p: '4px 8px',

                                '& > img': {
                                    height: '14px',
                                    width: 'auto',
                                },

                                '& > svg': {
                                    fontSize: '14px',
                                }
                            }}
                            justifyContent="center"
                        >
                            {option.icon}
                        </Stack>
                    }
                    tags={[
                        <Tag
                            backgroundColor="primary.contrastText"
                            color="primary.main"
                            key="createdAt"
                        >
                            {formatDistance(new Date(keys.createdAt), new Date(), { addSuffix: true })}
                        </Tag>,
                    ]}
                    actions={
                        deactivatable ?
                            [{
                                value: 'Deactivate',
                                onClick: () => onDeactivate(),
                            }]
                            : null
                    }
                    backgroundColor={selected ? 'primary.main' : 'secondary.light'}
                />
            }
            body={
                keys.data || keys.info ? (
                    <Stack gap={2} >
                        {
                            keys.data ? (
                                <Stack
                                    gap="4px"
                                >
                                    {
                                        option.data.map(od => (
                                            keys.data[od.key] ? (
                                                <Stack
                                                    direction="row"
                                                    key={od.key}
                                                    alignItems="center"
                                                    gap={1}
                                                >
                                                    <Tag><Text weight="bold">{od.display}</Text></Tag>
                                                    {
                                                        od.type === 'link' ? (
                                                            <Text
                                                                href={keys.data[od.key]}
                                                                target="_blank"
                                                                // weight="bold"
                                                                sx={{
                                                                    borderBottomWidth: '1px',
                                                                    borderBottomStyle: 'dashed',
                                                                    borderBottomColor: 'primary.main',
                                                                }}
                                                            >
                                                                link ↗
                                                            </Text>
                                                        ) : (
                                                            <Text>{keys.data[od.key]}</Text>
                                                        )
                                                    }
                                                </Stack>
                                            ) : null
                                        ))
                                    }
                                </Stack >
                            ) : null
                        }
                        {
                            keys.info ? (
                                <Stack>
                                    <Text weight="bold">Notes</Text>
                                    <Text sx={{ ml: '8px' }}>{keys.info}</Text>
                                </Stack>
                            ) : null
                        }
                    </Stack>
                ) : null
            }
        />
    );
};

export default KeysDisplay;