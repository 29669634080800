import React, { useState } from "react";
import { DashboardCardContainer, DashboardCardTitle, DashboardCardSubtitle, DashboardCardContent } from './DashboardCard.styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DashboardCard = ({ title, subtitle = null, children, expanded = false, sx = {}, className = '' }) => {
    const [isExpanded, setIsExpanded] = useState(expanded);

    return (
        <DashboardCardContainer
            expanded={isExpanded}
            onChange={() => setIsExpanded(!isExpanded)}
            sx={sx}
            className={className}
        >
            <DashboardCardTitle expandIcon={<ExpandMoreIcon />}>
                <span>{title}</span>
                <DashboardCardSubtitle>{subtitle}</DashboardCardSubtitle>
            </DashboardCardTitle>
            <DashboardCardContent>{children}</DashboardCardContent>
        </DashboardCardContainer>
    );
}

export default DashboardCard;