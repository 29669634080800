import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import CardBody from './Body/Body';

const Card = ({
    header = null,
    body = null,
    footer = null,
    selected = false,
    withBodyContainer = true,
    sx = {},
    ...otherProps
}) => {
    return (
        <Stack
            sx={{
                borderWidth: '1px',
                borderColor: !selected ? 'secondary.main' : 'primary.main',
                borderStyle: 'solid',
                borderRadius: 2,
                overflow: 'hidden',
                flexShrink: 0,
                ...sx,
            }}
            {...otherProps}
        >
            {header}
            {header && (body || footer) ? (<Divider sx={{ borderColor: !selected ? 'secondary.main' : 'primary.ultraLight' }} />) : null}
            {body ? (
                withBodyContainer ? <CardBody>{body}</CardBody> : body
            ) : null}
            {footer}
        </Stack>
    );
};

export default Card;