import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const SectionContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '580px',
    overflow: 'auto',
    gap: 20,
}));

export const Section = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    width: '640px',
    padding: '8px 20px 12px 20px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
}));

export const SectionTitle = styled('span')(({ theme }) => ({
    fontSize: '20px',
    marginBottom: '12px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    gap: 20,
}));

export const SectionElement = styled('div')(({ theme }) => ({
    fontSize: '14px',
}));

export const SectionElementName = styled('span')(({ theme }) => ({
    '&:after': {
        content: '":"',
        marginRight: '8px',
    },
}));

export const SectionElementValue = styled('span')(({ theme, valid }) => ({
    color: valid === 'true' ? theme.palette.primary.light : 'red',
    fontWeight: 'lighter',
}));

export const SectionElementMissingValue = styled(SectionElementValue)(({ theme }) => ({
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
}));

export const SectionButton = styled(Button)(({ theme }) => ({
    alignSelf: 'center',
    flexGrow: 0,
    marginTop: '12px',
}));

export const SectionTitleButton = styled(Button)(({ theme }) => ({
    position: 'absolute',
    right: '20px',
    top: '12px',
}));