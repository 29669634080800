import React from 'react';
import { formatInTimeZone } from 'date-fns-tz';
import { addMinutes } from 'date-fns';
import { getData } from './utils';
import DataElem from './DataElem';
import {
    PropertyContainer,
    PropertyAddressContainer,
    PropertyEmoji,
    PropertyAddress,
    HeaderSummaryContainer,
    HeaderElem,
    HeaderStatic,
    SplitContentSingle,
} from './SessionReport.styled';

const SessionReportHeader = ({ reports }) => (
    <PropertyContainer>
        <PropertyAddressContainer>
            <PropertyEmoji>🏡</PropertyEmoji>
            <PropertyAddress>{getData(reports.sessionReport, 'info', 'property-address')}</PropertyAddress>
        </PropertyAddressContainer>
        <HeaderSummaryContainer>
            <SplitContentSingle sx={{ width: 'calc(60% - 10px)' }}>
                <HeaderElem>
                    <HeaderStatic>Date:</HeaderStatic>
                    <DataElem
                        report={reports.viewingReports[0]}
                        attr="times"
                        objkey="viewing-scheduled-start"
                        format={(data) => formatInTimeZone(new Date(data), 'Etc/UTC', 'MMMM do')}
                    />
                </HeaderElem>
                <HeaderElem>
                    <HeaderStatic>From</HeaderStatic>
                    <DataElem
                        report={reports.viewingReports[0]}
                        attr="times"
                        objkey="viewing-scheduled-start"
                        format={(data) => formatInTimeZone(new Date(data), 'Etc/UTC', 'kk:mm')}
                    />
                </HeaderElem>
                <HeaderElem>
                    <HeaderStatic>To</HeaderStatic>
                    <DataElem
                        report={reports.viewingReports[reports.viewingReports.length - 1]}
                        attr="times"
                        objkey="viewing-scheduled-start"
                        format={(data) => formatInTimeZone(addMinutes(new Date(data), !reports.isBlockViewings ? 15 : 120), 'Etc/UTC', 'kk:mm')}
                    />
                </HeaderElem>
            </SplitContentSingle>
            <SplitContentSingle sx={{ width: 'calc(40% - 10px)' }}>
                <HeaderElem>
                    <DataElem sx={{ marginLeft: 0 }} text={reports.viewingReports.length} />
                    <HeaderStatic>Viewings</HeaderStatic>
                </HeaderElem>
                <HeaderElem>
                    {reports.success.length ? (
                        <>
                            <HeaderStatic>✅</HeaderStatic>
                            <DataElem text={reports.success.length} />
                            <HeaderStatic>{`Completed`}</HeaderStatic>
                        </>
                    ) : null}
                </HeaderElem>
                <HeaderElem>
                    {reports.fail.length ? (
                        <>
                            <HeaderStatic>🛑</HeaderStatic>
                            <DataElem text={reports.fail.length} />
                            <HeaderStatic>{`Failed`}</HeaderStatic>
                        </>
                    ) : null}
                </HeaderElem>
            </SplitContentSingle>
        </HeaderSummaryContainer>
    </PropertyContainer>
);

export default SessionReportHeader;