import DataElem from "../DataElem";
import { getData, distanceFromViewingStart } from "../utils";
import { ViewingList, ViewingSubtitleContainer } from "../SessionReport.styled";
import ViewingCard from "../../Viewing/Card/ViewingCard";
import { formatInTimeZone } from 'date-fns-tz';

export default { // eslint-disable-line
    name: 'booked',
    emoji: '🛫',
    time: () => {
        return 'Multi';
    },
    title: (reports) => {
        return (
            <>
                <DataElem
                    text={`${reports.viewingReports.length}`}
                    sx={{ marginLeft: 0 }}
                />
                Viewings booked
            </>
        );
    },
    subtitle: (reports) => {
        return (
            <>
                by
                <DataElem
                    report={reports.sessionReport}
                    attr="info"
                    objkey="agent-name"
                />
            </>
        );
    },
    content: (reports) => {
        return (
            <ViewingList>
                {
                    reports.viewingReports.map((viewingReport) => (
                        <ViewingCard
                            key={`viewing-report-booking-${viewingReport.id}`}
                            simple
                            avatar={'🖥️'}
                            title={
                                <div>
                                    Booked on
                                    <DataElem
                                        report={viewingReport}
                                        attr="times"
                                        objkey="booked"
                                        format={(data) => formatInTimeZone(new Date(data), 'Etc/UTC', 'MMMM do kk:mm')}
                                    />
                                </div>
                            }
                            subheader={
                                <ViewingSubtitleContainer>
                                    Booked
                                    <DataElem
                                        text={
                                            distanceFromViewingStart({ date: getData(viewingReport, 'times', 'booked'), viewingReport })
                                        }
                                    />
                                </ViewingSubtitleContainer>
                            }
                            viewing={{
                                startsAt: getData(viewingReport, 'times', 'viewing-scheduled-start'),
                                Viewer: { name: getData(viewingReport, 'info', 'customer-name') },
                            }}
                        />
                    ))
                }
            </ViewingList>
        );
    },
};