import ControlledInput from '../../Input/ControlledInput';
import { styled } from '@mui/material/styles';

const TagContainer = styled('div')(() => ({
    position: 'absolute',
    top: '-12px',
    right: '-8px',
}));

const Container = styled('div')(() => ({
    position: 'relative',
}));

const TagInput = ({ currentStatus, tag = null, ...otherProps }) => {
    return (
        <Container>
            <ControlledInput
                fullWidth
                size="small"
                {...otherProps}
            />
            <TagContainer>
                {tag}
            </TagContainer>
        </Container>
    );
};

export default TagInput;