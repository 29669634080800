import React from 'react';
import Time from '../Inputs/Time';
import { FormContainer } from '../form.styled';
import { format } from 'date-fns';

const EnRouteForm = ({ onUpdate, reports }) => {
    const handleFormData = async ({ type, id, data, key }) => {
        await onUpdate({ type, id, data, key });
    };

    return (
        <FormContainer>
            <Time
                name={'enroute'}
                title={'Journey start time'}
                subtitle={'At what time did you start your journey toward the property?'}
                currentTime={reports.sessionReport ? reports.sessionReport.times.filter((t) => t.key === 'enroute')[0] : null}
                onChange={async (newTime, id) => {
                    await handleFormData({ type: 'times', id, data: { time: format(newTime, 'HH:mm') }, key: 'enroute' })
                }}
            />
        </FormContainer>
    );
};

export default EnRouteForm;