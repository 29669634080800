import { formatInTimeZone } from 'date-fns-tz';
import Stack from '@mui/material/Stack';
import Text from '../../../Components/library/Typography/Text';
import FutureViewing from '../Viewing/Future_v2';
import CalendarDay from '../../../Components/library/Calendar/Day';
import { createSlotsFromViewings, groupByDay } from '../Viewing/utils';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { isToday, set } from 'date-fns';
import Keys from '../../Keys/Keys';

const UpcomingViewings = ({
    viewings,
    onlyViewings = false,
    withProperties = false,
    noKeys = false,
    onNoteChange = () => { },
    onCancel = () => { },
    onAddViewing = () => { },
}) => {
    const [byDay, setByDay] = useState([]);

    useEffect(() => {
        if (viewings && viewings.length) {
            const _byDay = groupByDay(viewings).map((viewings) => ({
                viewings,
                isToday: isToday(new Date(viewings[0].startsAt)),
                date: set(new Date(viewings[0].startsAt), { hours: 2, minutes: 0, seconds: 0 }),
                slots: createSlotsFromViewings(viewings, true),
            }));
            setByDay(_byDay);
        } else {
            setByDay([]);
        }
    }, [viewings]);

    const bindSlot = (slot, day) => {
        if (slot.type === 'viewing' || slot.type === 'viewings') {
            let _viewings = slot.type === 'viewing' ? [slot.viewing] : slot.viewings;
            return {
                dateTime: slot.time,
                dateTimeDisplay: _viewings[0].type === 'BLOCK' ? `${slot.time} - ${parseInt(slot.time.split(':')[0]) + 1}:${slot.time.split(':')[1]}` : slot.time,
                component: (
                    <Stack
                        gap={1.5}
                    >
                        {
                            _viewings.map((viewing) => (
                                <FutureViewing
                                    key={`future-viewing-${viewing.id}`}
                                    viewing={viewing}
                                    withProperty={withProperties}
                                    noActions={onlyViewings}
                                    clickable={onlyViewings && withProperties}
                                    onNoteChange={async (notes) => await onNoteChange(viewing, notes)}
                                    onCancel={async () => await onCancel(viewing)}
                                />
                            ))
                        }
                    </Stack>
                ),
            };
        }

        if (day.isToday || onlyViewings) {
            return null;
        }

        if (slot.type === 'slot') {
            return {
                info: true,
                dateTime: slot.time,
                component: (
                    <Stack
                        direction="row"
                        alignItems="center"
                    >
                        <Stack
                            sx={{
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            {
                                slot.sandwich ? (
                                    <Text color="primary.light" size="10px">⚠️ Unoptimised session - book a viewing ➡️</Text>
                                ) : null
                            }
                            {
                                !slot.sandwich && viewings.length < 5 ? (
                                    <>
                                        <Text color="primary.light" size="10px">🔥 Book {5 - viewings.length} more viewings</Text>
                                        <Text color="primary.light" size="10px">and reach 70% chance of receiving an offer</Text>
                                    </>
                                ) : null
                            }
                            {
                                !slot.sandwich && viewings.length >= 5 ? (
                                    <>
                                        <Text color="primary.light" size="10px">📈 Back-to-back viewings are more successfull</Text>
                                        <Text color="primary.light" size="10px">Book a viewing ➡️</Text>
                                    </>
                                ) : null
                            }
                        </Stack>
                        <IconButton
                            aria-label="book a viewing"
                            size="small"
                            sx={{ color: 'primary.main' }}
                            onClick={() => {
                                onAddViewing({ day: day.date, time: slot.time });
                                console.log('book a viewing for', day.date, slot.time)
                            }}
                        >
                            <AddCircleOutlineOutlinedIcon fontSize="12px" color="inherit" />
                        </IconButton>
                    </Stack>
                ),
            };
        }
    };

    return (
        <Stack
            gap={2.5}
            direction="column"
            sx={{ p: viewings && viewings.length ? 0 : 2.5, overflow: 'auto', height: '100%', paddingBottom: 2.5 }}
        >
            {
                byDay.length ? (
                    <Stack
                        gap={5}
                    >
                        {
                            byDay.map((day, dIndex) => (
                                <CalendarDay
                                    key={`future-viewing-${dIndex}`}
                                    day={new Date(day.viewings[0].startsAt)}
                                    startsAt={formatInTimeZone(new Date(day.viewings[0].startsAt), 'Etc/UTC', 'kk:mm')}
                                    endsAt={formatInTimeZone(new Date(day.viewings[day.viewings.length - 1].startsAt), 'Etc/UTC', 'kk:mm')}
                                    data={day.slots.map((slot) => bindSlot(slot, day)).filter(s => !!s)}
                                    slotsLength={day.viewings[0].type === 'BLOCK' ? 60 : 15}
                                    padding={day.viewings[0].type === 'BLOCK' ? 0 : 15}
                                    header={
                                        !noKeys ? (
                                            <Stack
                                                sx={{
                                                    p: '20px',
                                                    borderBottom: `1px solid #eee`,
                                                }}
                                            >
                                                <Keys
                                                    propertyId={day.viewings[0].propertyId}
                                                    selectedId={day.viewings[0].keysId}
                                                    selectedOnly={true}
                                                    deactivatable={false}
                                                    headerProps={{ titleSize: 'medium' }}
                                                />
                                            </Stack>
                                        ) : null
                                    }
                                />
                            ))
                        }
                    </Stack>
                ) : (
                    <Text
                        color="primary.light"
                        span
                        sx={{ width: '100%', textAlign: 'center', pt: 4, pb: 4 }}
                    >No <Text weight="bold" span>UPCOMING</Text> viewings yet</Text>
                )
            }
        </Stack>
    );
};

export default UpcomingViewings;