import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';


import Card from "../../../Components/library/Cards/Generic";
import Header from '../../../Components/library/Cards/Headers/Standard';
import Footer from '../../../Components/library/Cards/Footers/Action';
import Text from "../../../Components/library/Typography/Text";
import Tag from "../../../Components/library/Tag/Tag";
import ControlledInput from "../../../Components/Input/ControlledInput";

import APPS_CONFIG from "../../config";

import {
    BackButton,
    CardTitleText,
} from '../../Leads/leads.styled';

import MarketPlaceTag from "../../../Components/library/Tag/MarketPlaceTag";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 150,
        },
    },
};

const validateIntegration = (integration) => {
    return (
        integration.type !== '' &&
        integration.platform !== '' &&
        integration.authConfig &&
        integration.authConfig.apiKey && integration.authConfig.apiKey !== ''
    );
};

const MarketPlacesIntegrations = ({
    integrations,
    onSave = () => { },
    onDelete = () => { },
}) => {
    const [create, setCreate] = useState(false);
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [currentIntegrations, setCurrentIntegrations] = useState(integrations);

    useEffect(() => {
        setCurrentIntegrations(integrations);
    }, [integrations]);

    return (
        <Card
            header={
                <Header
                    emoji="🏭"
                    title="Marketplaces"
                    // tags={[
                    //     ...(currentIntegrations ? [
                    //         <Tag
                    //             color="primary.main"
                    //             key={`tag-currentIntegrations`}
                    //         >
                    //             {`${currentIntegrations.length} Integration${currentIntegrations.length === 1 ? '' : 's'}`}
                    //         </Tag>
                    //     ] : []),
                    //     // ...(!create && editing ? [
                    //     //     <Tag
                    //     //         color="primary.main"
                    //     //         key={`tag-editing`}
                    //     //     >
                    //     //         🖊️ Editing
                    //     //     </Tag>
                    //     // ] : []),
                    //     // ...(saving ? [
                    //     //     <Tag
                    //     //         color="primary.main"
                    //     //         key={`tag-saving`}
                    //     //     >
                    //     //         💾 Saving
                    //     //     </Tag>
                    //     // ] : []),
                    //     // ...(!editing && !saving && saved ? [
                    //     //     <Tag
                    //     //         color="primary.main"
                    //     //         key={`tag-saved`}
                    //     //     >
                    //     //         👍 Saved
                    //     //     </Tag>
                    //     // ] : []),
                    // ]}
                    sx={{ backgroundColor: APPS_CONFIG.user.color }}
                />
            }
            body={
                <Stack
                    gap={2.5}
                >
                    {
                        currentIntegrations && currentIntegrations.length ? (
                            <Stack
                                gap={2.5}
                            >
                                {
                                    currentIntegrations.filter(i => !i.id.startsWith('new-')).map((integration) => (
                                        <Card
                                            key={integration.id}
                                            body={
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    gap={1.5}
                                                >
                                                    <MarketPlaceTag marketPlace={integration.platform.toLowerCase()} />
                                                    {
                                                        integration.authConfig ? (
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                gap={1}
                                                            >
                                                                <Text span>API KEY:</Text>
                                                                <Text span weight="bold">{`${integration.authConfig.apiKey.slice(0, 3)}**********${integration.authConfig.apiKey.slice(-3)}`}</Text>
                                                            </Stack>
                                                        ) : null
                                                    }
                                                </Stack>
                                            }
                                            footer={
                                                <Footer
                                                    align='flex-end'
                                                    actions={
                                                        deleting === integration.id ?
                                                            [
                                                                {
                                                                    value: (<><Text span>Are you sure you want to <Text weight="bold" span>DELETE</Text>?</Text></>),
                                                                },
                                                                {
                                                                    button: true,
                                                                    buttonVariant: 'contained',
                                                                    value: 'Yes',
                                                                    onClick: () => {
                                                                        onDelete(integration.id);
                                                                    },
                                                                },
                                                                {
                                                                    button: true,
                                                                    buttonVariant: 'outlined',
                                                                    value: 'No',
                                                                    onClick: () => {
                                                                        console.log('cancel');
                                                                        setDeleting(null);
                                                                    },
                                                                },
                                                            ] : [
                                                                {
                                                                    button: true,
                                                                    buttonVariant: 'contained',
                                                                    value: 'Edit',
                                                                    onClick: async () => {

                                                                    },
                                                                    sx: {
                                                                        opacity: validateIntegration(integration) ? 1 : 0.5,
                                                                        pointerEvents: validateIntegration(integration) ? 'all' : 'none',
                                                                    },
                                                                },
                                                                {
                                                                    button: true,
                                                                    buttonVariant: 'outlined',
                                                                    value: 'Delete',
                                                                    onClick: () => {
                                                                        setDeleting(integration.id);
                                                                    },
                                                                },
                                                            ]
                                                    }
                                                />
                                            }
                                        />
                                    ))
                                }
                                {
                                    currentIntegrations.filter(i => i.id.startsWith('new-')).map((integration, idx) => (
                                        <Card
                                            body={
                                                <Stack
                                                    direction="row"
                                                    gap={1.5}
                                                >
                                                    <FormControl sx={{ width: '150px' }}>
                                                        <InputLabel
                                                            size="small"
                                                            id="demo-multiple-checkbox-label"
                                                            required
                                                        >Platform</InputLabel>
                                                        <Select
                                                            required
                                                            labelId="demo-multiple-checkbox-label"
                                                            id="demo-multiple-checkbox"
                                                            value={integration.platform}
                                                            onChange={(e) => {
                                                                setCurrentIntegrations(currentIntegrations => (
                                                                    currentIntegrations.map(i => (
                                                                        i.id === integration.id ? {
                                                                            ...i,
                                                                            platform: e.target.value,
                                                                            type: e.target.value === 'zoopla' ? 'PUSH' : 'PULL',
                                                                        } : i
                                                                    ))
                                                                ));
                                                            }}
                                                            input={
                                                                <OutlinedInput
                                                                    size="small"
                                                                    label="Platform"
                                                                    required
                                                                />
                                                            }
                                                            MenuProps={MenuProps}
                                                        >
                                                            <MenuItem key="zoopla" value="zoopla">
                                                                <MarketPlaceTag marketPlace="zoopla" />
                                                            </MenuItem>
                                                            <MenuItem key="rightmove" value="rightmove">
                                                                <MarketPlaceTag marketPlace="rightmove" />
                                                            </MenuItem>
                                                            <MenuItem key="onthemarket" value="onthemarket">
                                                                <MarketPlaceTag marketPlace="onthemarket" />
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <Stack>
                                                        {
                                                            integration.platform ? (
                                                                <ControlledInput
                                                                    required
                                                                    autoFocus
                                                                    size="small"
                                                                    name="api-key"
                                                                    label="API Key"
                                                                    onChange={(value) => {
                                                                        setCurrentIntegrations(currentIntegrations => (
                                                                            currentIntegrations.map(i => (
                                                                                i.id === integration.id ? {
                                                                                    ...i,
                                                                                    authConfig: {
                                                                                        ...(i.authConfig || {}),
                                                                                        apiKey: value,
                                                                                    },
                                                                                } : i
                                                                            ))
                                                                        ));
                                                                    }}
                                                                />
                                                            ) : null
                                                        }
                                                    </Stack>
                                                </Stack>
                                            }
                                            footer={
                                                <Footer
                                                    align='flex-end'
                                                    actions={[
                                                        {
                                                            button: true,
                                                            buttonVariant: 'contained',
                                                            value: 'Save',
                                                            onClick: async () => {
                                                                await onSave(integration);
                                                            },
                                                            sx: {
                                                                opacity: validateIntegration(integration) ? 1 : 0.5,
                                                                pointerEvents: validateIntegration(integration) ? 'all' : 'none',
                                                            },
                                                        },
                                                    ]}
                                                />
                                            }
                                        />
                                    ))
                                }
                            </Stack>
                        ) : (
                            <Text
                                color="primary.light"
                                span
                                sx={{ width: '100%', textAlign: 'center', pt: 2, pb: 2 }}
                            >
                                No <Text weight="bold" span>MARKETPLACE INTEGRATIONS</Text>
                                <Text color="primary.light">Add one below</Text>
                            </Text>
                        )
                    }
                </Stack>
            }
            footer={
                <Footer
                    align='flex-end'
                    actions={[
                        {
                            button: true,
                            buttonVariant: 'text',
                            value: (
                                <Stack
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    sx={{ color: 'primary.light' }}
                                >
                                    <AddCircleOutlineOutlinedIcon sx={{ fontSize: '16px' }} />
                                    <Text weight='bold' color="primary.light">Add Marketplace</Text>
                                </Stack>
                            ),
                            onClick: () => {
                                setCurrentIntegrations([
                                    ...currentIntegrations,
                                    {
                                        id: `new-${uuidv4()}`,
                                        type: '',
                                        platform: '',
                                        authData: '',
                                    }
                                ]);
                            },
                            sx: {
                                marginRight: 'auto',
                            },
                        },
                        // {
                        //     button: true,
                        //     buttonVariant: 'contained',
                        //     value: 'Save',
                        //     onClick: async () => {
                        //         setEditing(false);
                        //         setSaving(true);

                        //         // save

                        //         setSaving(false);
                        //         setSaved(true);
                        //     },
                        //     sx: {
                        //     },
                        // },
                    ]}
                />
            }
        />
    );
};

export default MarketPlacesIntegrations;