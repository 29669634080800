import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { FieldContainer, Email, Password, ActionButton } from './Login.styled';

const ResetPassword = ({ user, onUser }) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        code: {
            value: '',
            valid: false,
        },
        password: {
            value: '',
            valid: false,
        },
    });

    const handleFormData = (key, value, valid) => {
        const newFormData = { ...formData, [key]: { value, valid } };
        setFormData(newFormData);
    };

    const handleConfirmReset = async () => {
        try {
            await Auth.forgotPasswordSubmit(
                user,
                formData.code.value,
                formData.password.value
            );
            onUser(user);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }

    const handleNewPassword = async () => {
        try {
            const _user = await Auth.completeNewPassword(
                user,               // the Cognito User Object
                formData.password.value,       // the new password
                // // OPTIONAL, the required attributes
                // {
                //   email: 'xxxx@example.com',
                //   phone_number: '1234567890'
                // }
            );
            console.log(_user);
            // setUser(_user);
            setLoading(false);
            onUser(user);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    return (
        <FieldContainer>
            {typeof user === 'string' ? (
                <Email
                    required
                    name="login-new-password-code"
                    label="Reset code"
                    helperText="Enter the reset code sent to you by email"
                    placeholder=""
                    onChange={(value, valid) => handleFormData('code', value, valid)}
                />
            ) : null}
            <Password
                required
                name="login-new-password"
                label="New Password"
                helperText="Enter your new password used for all subsequent logins"
                placeholder=""
                type="password"
                defaultValue={formData.password.value}
                value={formData.password.value}
                onChange={(value, valid) => handleFormData('password', value, valid)}
            />
            {error}
            <ActionButton
                loading={loading}
                disabled={
                    typeof user === 'string'
                        ? !Object.keys(formData).reduce((final, curr) => (final && formData[curr].valid), true)
                        : !formData.password.valid
                }
                variant="outlined"
                size="large"
                onClick={typeof user === 'string' ? handleConfirmReset : handleNewPassword}
            >
                Login
            </ActionButton>
        </FieldContainer >
    );
};

export default ResetPassword;