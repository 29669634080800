import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Property from "../../data/property";
import MarketPlace from "../../data/marketPlace";

import TwoPanelsLayout from "../../Components/library/Layout/TwoPanels";
import PropertyList from "./PropertyList/PropertyList";
import PropertyCard from './Property/Property';

const getExternalIdFromUrl = (url = '') => {
    if (url.includes('zoopla.co.uk')) {
        const res = url.match(/(?:details\/)([0-9]*)/);
        console.log(res);
        return res && res.length && res[1] ? res[1] : '_';
    } else if (url.includes('rightmove.co.uk') || url.includes('rightmove.com')) {
        const res = url.match(/(?:properties\/)([0-9]*)/);
        console.log(res);
        return res && res.length && res[1] ? res[1] : '_';
    } else if (url.includes('onthemarket.co.uk') || url.includes('onthemarket.com')) {
        return '**';
    }

    return 'unknown';
};

const Properties = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [properties, setProperties] = useState([]);
    const [currentProperty, setCurrentProperty] = useState(null);
    const [newProperty, setNewProperty] = useState(null);

    useEffect(() => {
        const fetchProperties = async () => {
            const prop = await Property.getAll();
            const mps = await MarketPlace.property.getAll();

            const prepedProps = prop.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(p => {
                const marketPlaces = mps.filter((m) => m.propertyId === p.id);

                return {
                    ...p,
                    marketPlaces: [
                        ...marketPlaces,
                        ...(!marketPlaces.length && p.listingUrl ? [{ url: p.listingUrl, platform: 'unknown', createdAt: p.createdAt }] : []),
                    ],
                };
            });

            setProperties(prepedProps);

            if (params.propertyId === 'new') {
                setNewProperty({});
            } else {
                setCurrentProperty(prepedProps.find(p => p.id === params.propertyId));
            }
        };

        fetchProperties();
    }, []);

    const handleSaveNewMarketPlaces = async (newMarketPlaces, property) => {
        console.log(newMarketPlaces, property);
        // return;
        const newMPs = await newMarketPlaces.reduce(async (p, nmp) => {
            const tot = await p;
            console.log(getExternalIdFromUrl(nmp.url));

            const newMP = await MarketPlace.property.create({
                propertyId: property.id,
                platform: nmp.platform.toUpperCase(),
                externalId: getExternalIdFromUrl(nmp.url),
                url: nmp.url.split('?')[0],
            });

            return [
                ...tot,
                newMP
            ];
        }, Promise.resolve([]));

        return newMPs;
    }

    const handleSaveProperty = async (type, propData) => {
        if (type === 'marketPlaces') {
            console.log(propData);

            await handleSaveNewMarketPlaces(propData.newMarketPlaces, currentProperty);

            await propData.toDelete.reduce(async (p, mp) => {
                await p;
                await MarketPlace.property.delete(mp.id);
            }, Promise.resolve());


            const modifiedProperty = {
                ...currentProperty,
                marketPlaces: [
                    ...(propData.modifiedMarketPlaces || []),
                    ...propData.newMarketPlaces,
                ],
            };

            setCurrentProperty(modifiedProperty);
            setProperties((currentProperties) => currentProperties.map((cp) => {
                if (cp.id === currentProperty.id) {
                    return modifiedProperty;
                }

                return cp;
            }));
        } else if (type === 'info') {
            const newPropData = Object.keys(propData).reduce((tot, key) => ({
                ...tot,
                [key]: propData[key].value,
            }), {});

            console.log(newPropData);

            const updatedProp = await Property.update(currentProperty.id, newPropData);

            const newCurrentProp = {
                ...currentProperty,
                ...updatedProp,
            };

            setCurrentProperty(newCurrentProp);

            setProperties((currentProperties) => currentProperties.map((cp) => {
                if (cp.id === currentProperty.id) {
                    console.log(cp, newCurrentProp);
                    return newCurrentProp;
                }

                return cp;
            }));
        }
    };

    return (
        <TwoPanelsLayout
            leftPanel={
                <PropertyList
                    properties={properties.map((p) => ({ ...p, marketPlaces: p.marketPlaces || [] }))}
                    onSelectProperty={(p) => {
                        if (!newProperty) {
                            setCurrentProperty(p);
                            navigate(`/app/properties/${p.id}`);
                        }
                    }}
                    addPropertyDisabled={!!newProperty}
                    selectedProperty={currentProperty}
                    compactDisplay={false}
                    onAddProperty={() => {
                        setCurrentProperty(null);
                        setNewProperty({});
                    }}
                    color="properties_app.main"
                />
            }
            rightPanel={
                currentProperty ? (
                    <PropertyCard
                        property={currentProperty}
                        color="properties_app.main"
                        onBack={() => setCurrentProperty(null)}
                        onSave={handleSaveProperty}
                    />
                ) : (
                    newProperty ? (
                        <PropertyCard
                            color="properties_app.main"
                            property={newProperty}
                            create
                            onBack={() => {
                                setCurrentProperty(null);
                                setNewProperty(null);
                            }}
                            onSave={async (type, propData) => {
                                if (type === 'newProperty') {
                                    const property = await Property.create({
                                        occupied: propData.occupied.value,
                                        postcode: propData.postcode.value,
                                        address: propData.address.value,
                                        city: 'London',
                                        price: propData.price.value,
                                        info: propData.info.value,
                                        listingUrl: propData.marketPlaces && propData.marketPlaces.length ? propData.marketPlaces[0].url : '',
                                    });

                                    const marketPlaces = await handleSaveNewMarketPlaces(propData.marketPlaces, property);

                                    const _property = {
                                        ...property,
                                        marketPlaces,
                                    };

                                    setProperties([
                                        _property,
                                        ...properties,
                                    ]);
                                    setCurrentProperty(_property);
                                    setNewProperty(null)
                                }
                            }}
                            onCancel={() => setNewProperty(null)}
                        />
                    ) : null
                )
            }
            showRightPanel={!!currentProperty}
        />
    );
};

export default Properties;