import React, { useState } from 'react';
import Files from '../Inputs/Files';
import Time from '../Inputs/Time';
import { FormContainer, ViewingStatusContainer } from '../form.styled';
import { StatusContainer } from '../Inputs/inputs.styled';
import { format } from 'date-fns';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';

const KeyPickupForm = ({ sessionId, onUpdate, reports }) => {
    const [loading, setLoading] = useState(false);

    const handleFormData = async ({ type, id, data, key }) => {
        await onUpdate({ type, id, data, key });
    };

    return (
        <FormContainer>
            <ViewingStatusContainer sx={{ width: 'calc(100% - 10px)' }}>
                <FormControl fullWidth>
                    <InputLabel>Pickup Location</InputLabel>
                    <Select
                        value={(reports.sessionReport.info.find((t) => t.key === 'keypickup-location') || { value: '' }).value}
                        onChange={async (e) => {
                            setLoading(true);
                            const id = (reports.sessionReport.info.find((t) => t.key === 'keypickup-location') || { id: null }).id;
                            await handleFormData({ type: 'info', id, data: { value: e.target.value }, key: 'keypickup-location' })
                            setLoading(false);
                        }}
                        disabled={loading}
                        label="Pickup Location"
                        sx={{ width: 'calc(100% - 10px)' }}
                    >
                        <MenuItem value={'KEYNEST'}>🗿 Keynest</MenuItem>
                        <MenuItem value={'AGENCY'}>🏢 Agency</MenuItem>
                        <MenuItem value={'OTHER'}>❓ Other</MenuItem>
                    </Select>
                </FormControl>
                <StatusContainer>
                    {
                        loading ? (
                            <BackupOutlinedIcon fontSize='inherit' />
                        ) : (
                            <CloudDoneOutlinedIcon fontSize='inherit' />
                        )
                    }
                </StatusContainer>
            </ViewingStatusContainer>
            <Time
                name={'keypickup'}
                title={'Key pickup time'}
                subtitle={'At what time did you pick up the keys?'}
                currentTime={reports.sessionReport ? reports.sessionReport.times.filter((t) => t.key === 'keypickup')[0] : null}
                onChange={async (newTime, id) => {
                    await handleFormData({ type: 'times', id, data: { time: format(newTime, 'HH:mm') }, key: 'keypickup' })
                }}
            />
            <Files
                title={'Key Pickup Picture'}
                subtitle={'Take a picture of the key pickup'}
                files={reports.sessionReport ? reports.sessionReport.files.filter((f) => f.key === 'images-keypickup') : []}
                type="image"
                name={'images-keypickup'}
                sessionId={sessionId}
                onChange={async (newFiles) => await handleFormData({ type: 'files', data: newFiles, key: 'images-keypickup' })}
                onDelete={async (file) => await handleFormData({
                    type: 'update', data: {
                        files: [...reports.sessionReport.files.filter((f) => f.id !== file.id)]
                    }
                })}
            />
        </FormContainer>
    );
};

export default KeyPickupForm;