import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";

export const Container = styled('div')(({ theme }) => ({
    padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    borderRadius: '12px',
    border: `2px solid ${theme.palette.secondary.light}`,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[6],

    '&[data-available="true"]': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,

        'div > *': {
            color: theme.palette.primary.contrastText,
            WebkitTextFillColor: theme.palette.primary.contrastText,
        }
    },

    [theme.breakpoints.down(800)]: {
        padding: '12px',
    },
}));

export const IconContainer = styled('div')(({ theme }) => ({
    fontSize: '28px',
    lineHeight: '28px',
    height: '28px',
    marginRight: '12px',

    [theme.breakpoints.down(800)]: {
        fontSize: '20px',
        lineHeight: '20px',
        height: '20px',
    }
}));

export const ClemName = styled('div')(({ theme }) => ({
    fontWeight: 'bold',
    width: 'calc(100% - 60px)',

    [theme.breakpoints.down(800)]: {
        fontSize: '14px',
        width: 'calc(100% - 44px)',
    }
}));

export const ValidateButton = styled(Button)(({ theme }) => ({
    width: '100px',
}));