import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MarketPlace from "../../data/marketPlace";

import TwoPanelsLayout from "../../Components/library/Layout/TwoPanels";
import User from "./User";
import UserIntegrations from "./Integrations/Integrations";
import UserBills from "./Bills";

const UserPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [view, setView] = useState(params.view ? params.view : null);
    const [integrations, setIntegrations] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            const mpConfigs = await MarketPlace.config.getAll();
            setIntegrations(mpConfigs);
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        setView(params.view)
    }, [params.view]);

    const handleSaveIntegration = async (type, data) => {
        if (type === 'marketplace') {
            if (data.id.startsWith('new-')) {
                const newMPConfigs = await MarketPlace.config.create({
                    type: data.type.toUpperCase(),
                    platform: data.platform.toUpperCase(),
                    authConfig: data.authConfig,
                });

                const _integrations = [
                    ...(integrations || []),
                    newMPConfigs,
                ];

                console.log(_integrations);

                setIntegrations(_integrations);
            } else {
                // update
            }
        }
    };

    const renderRightPanel = () => {
        if (view === 'integrations') {
            return (
                <UserIntegrations
                    integrations={integrations}
                    onSave={handleSaveIntegration}
                    onBack={() => {
                        setView(null);
                        navigate(`/app/user/`)
                    }}
                />
            );
        } else if (view === 'bills') {
            return (
                <UserBills
                    onBack={() => {
                        setView(null);
                        navigate(`/app/user/`)
                    }}
                />
            );
        }

        return null;
    }

    return (
        <TwoPanelsLayout
            leftPanel={
                <User
                    onSelectView={(_view) => {
                        if (_view === 'admin') {
                            navigate(`/app/admin`);
                            return;
                        }
                        setView(_view);
                        navigate(`/app/user/${_view}`)
                    }}
                    integrations={integrations}
                />
            }
            rightPanel={renderRightPanel()}
            showRightPanel={!!view}
        />
    );
};

export default UserPage;