import { getData, distanceFromViewingStart } from "../utils";
import DataElem from "../DataElem";
import FileComp from "../../Reporter/Form/Inputs/File";
import { SplitContentContainer, SplitContent, SplitContentSingle, List, Separator } from '../SessionReport.styled';

export default { // eslint-disable-line
    name: 'access',
    time: (reports) => {
        return getData(reports.sessionReport, 'times', 'access') || 'Not yet';;
    },
    title: () => {
        return 'Accessed the property';
    },
    subtitle: (reports) => {
        return (
            <>
                <DataElem
                    report={reports.sessionReport}
                    attr="times"
                    objkey="access"
                    format={(access) => {
                        if (access) {
                            return distanceFromViewingStart({ time: access, viewingReport: reports.viewingReports[0], suffix: false });
                        }
                        return null;
                    }}
                    sx={{ marginLeft: 0 }}
                />
                in advance
            </>
        );
    },
    emoji: '🛬',
    content: (reports) => {
        const instructionsVideosS3URLS = getData(reports.sessionReport, 'files', 'videos-instruction');
        const accessVideosS3URLS = getData(reports.sessionReport, 'files', 'videos-access');
        const accessImagesS3URLS = getData(reports.sessionReport, 'files', 'images-access');

        return (
            <>
                <SplitContentContainer>
                    <SplitContentSingle sx={{ width: '100%' }}>
                        <div>
                            🛬
                            <DataElem
                                report={reports.sessionReport}
                                attr="info"
                                objkey="clem-name"
                            />
                            arrived
                            <DataElem text={
                                distanceFromViewingStart({ time: getData(reports.sessionReport, 'times', 'access'), viewingReport: reports.viewingReports[0] })
                            } />
                            the first viewing
                        </div>
                    </SplitContentSingle>
                </SplitContentContainer>
                <Separator />
                <SplitContentContainer>
                    <SplitContent>
                        {
                            instructionsVideosS3URLS.map((url, idx) => (
                                <FileComp file={{ s3: url }} type="video" display key={`videos-instructions${idx}`} />
                            ))
                        }
                    </SplitContent>
                    <SplitContentSingle>
                        📲
                        <DataElem
                            report={reports.sessionReport}
                            attr="info"
                            objkey="clem-name"
                        />
                        sent the instructions video to:
                        <List sx={{ margin: '12px 0px' }}>
                            {
                                reports.viewingReports.map((v) => (
                                    <div>
                                        🧑
                                        <DataElem
                                            report={v}
                                            attr="info"
                                            objkey="customer-name"
                                        />
                                    </div>
                                ))
                            }
                        </List>
                        via Whatsapp or SMS
                    </SplitContentSingle>
                </SplitContentContainer>
                <Separator />
                <SplitContentContainer>
                    <SplitContent>
                        {
                            accessImagesS3URLS.map((url, idx) => (
                                <FileComp file={{ s3: url }} type="image" display key={`images-access${idx}`} />
                            ))
                        }
                    </SplitContent>
                    <SplitContentSingle>
                        🤳
                        <DataElem
                            report={reports.sessionReport}
                            attr="info"
                            objkey="clem-name"
                        />
                        took a photo in front of the door
                    </SplitContentSingle>
                </SplitContentContainer>
                <Separator />
                <SplitContentContainer>
                    <SplitContent>
                        {
                            accessVideosS3URLS.map((url, idx) => (
                                <FileComp file={{ s3: url }} type="video" display key={`videos-access${idx}`} />
                            ))
                        }
                    </SplitContent>
                    <SplitContentSingle>
                        🔓
                        <DataElem
                            report={reports.sessionReport}
                            attr="info"
                            objkey="clem-name"
                        />
                        opened the door{accessVideosS3URLS.length > 1 ? 's' : null}
                    </SplitContentSingle>
                </SplitContentContainer>
            </>
        );
    },
};