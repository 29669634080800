import { DataStore } from '@aws-amplify/datastore';
import { Property } from '../models';

export const createProperty = async (data) => {
    const property = await DataStore.save(
        new Property({
            occupied: data.occupied,
            postcode: data.postcode,
            address: data.address,
            city: data.city,
            price: data.price,
            info: data.info,
            listingUrl: data.listingUrl !== '' ? data.listingUrl : null,
            active: true,
        })
    );

    return property;
};

export const getProperty = async (propertyId) => {
    return await DataStore.query(Property, propertyId);
};

export const updateProperty = async (propertyId, data) => {
    const original = await DataStore.query(Property, propertyId);
    const property = await DataStore.save(
        Property.copyOf(original, updated => {
            updated.postcode = data.postcode ? data.postcode : original.postcode;
            updated.address = data.address ? data.address : original.address;
            updated.price = data.price ? data.price : original.price;
            updated.info = data.info ? data.info : original.info;
            updated.occupied = data.occupied !== undefined && data.occupied !== null ? data.occupied : original.occupied;
            updated.active = data.active !== undefined && data.active !== null ? data.active : original.active;
        })
    );

    return property;
};

export const getAllProperties = async () => {
    return await DataStore.query(Property, p => p.createdAt('gt', '2022-07-20T14:00:00.000Z'));
};

export default { // eslint-disable-line
    create: createProperty,
    get: getProperty,
    update: updateProperty,
    getAll: getAllProperties,
};