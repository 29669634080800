import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import ControlledInput from '../Input/ControlledInput';
import { invert } from '../../theme';

const _Container = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    width: '600px',
    height: '600px',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: 'calc(50% - 300px)',
    top: 'calc(50% - 300px)',
    // justifyContent: 'center',
    alignItems: 'center',
    // gap: 20,
    borderRadius: '10px',
    justifyContent: 'space-evenly',
    paddingBottom: '40px',

    [theme.breakpoints.down(1000)]: {
        width: '90%',
        left: '5%',
    }
}));

export const Container = invert(_Container);

export const LargeContainer = styled(Container)(() => ({
    height: '750px',
    top: 'calc(50% - 375px)',
}));

export const Logo = styled('img')(() => ({
    width: '80px',
    height: '80px',
    // position: 'absolute',
    // marginTop: '40px',
}));

export const FieldContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,

    [theme.breakpoints.down(1000)]: {
        width: '80%',
    }
}));

export const HalfInputContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    gap: '20px',
    width: '450px',
}));

export const HalfInput = styled(ControlledInput)(() => ({
    width: '215px',
}));

export const Input = styled(ControlledInput)(({ theme }) => ({
    width: '450px',

    [theme.breakpoints.down(1000)]: {
        width: '100%',
    }
}));

export const Email = styled(ControlledInput)(({ theme }) => ({
    width: '450px',

    [theme.breakpoints.down(1000)]: {
        width: '100%',
    }
}));

export const Password = styled(ControlledInput)(({ theme }) => ({
    width: '450px',

    [theme.breakpoints.down(1000)]: {
        width: '100%',
    }
}));

export const ActionButton = styled(LoadingButton)(() => ({
    marginTop: '20px',
}));