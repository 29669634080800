const APPS_CONFIG = {
    properties: {
        name: 'Properties',
        basePath: '/app/properties/',
        color: '#ffefdc', //'#FFE4C5'
        logo: '/app/services-logos/property.svg',
    },
    leads: {
        name: 'Leads',
        basePath: '/app/leads/',
        color: '#EEFAF7',
        logo: '/app/services-logos/lead.svg',
    },
    viewings: {
        name: 'Viewings',
        basePath: '/app/viewings/',
        color: '#f9f6ff', //'secondary.light',
        logo: '/app/services-logos/viewing.svg',
    },
    user: {
        name: 'User',
        basePath: '/app/user/',
        color: '#FFF9DC',
        logo: '/app/services-logos/user.svg',
    },
    clem: {
        name: 'Clem',
        basePath: '/app/clem/',
        color: '#FCEBEB',
        logo: '/app/services-logos/report.svg',
    },
};

export default APPS_CONFIG;