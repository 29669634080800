import { useState } from "react";
import Button from "@mui/material/Button";
import copy from "copy-to-clipboard";

const FeedbackButton = ({ children, revertAfter = 2000, feedback, onClick = (() => {}), sx = {}, ...props }) => {
    const [displayFeedback, setDisplayFeedback] = useState(false);

    const handleClick = () => {
        copy(children);
        setDisplayFeedback(true);
        onClick();
        setTimeout(() => setDisplayFeedback(false), revertAfter)
    };

    return (
        <Button
            disabled={displayFeedback}
            onClick={handleClick}
            sx={{ width: 'fit-content', overflow: 'hidden', ...sx }}
            {...props}
        >{displayFeedback ? feedback : children}</Button>
    );
};

export default FeedbackButton