import React from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ReportTimeline from './Timeline';
import { Container, Header, Title, SubtitleContainer, SubtitleIcon, SubtitleRow, SubtitleText, Logo, SummaryTitle, Summary, Content, ContentWrapper, Footer } from './Report.styled';

const ReportRenderer = ({ reportData, error }) => {
    if (!reportData || error) {
        return (
            <Container>
                <Header>
                    <Logo src='/app/logo-white.svg' />
                    <Title>Viewing report</Title>
                </Header>
                {
                    error ? (
                        <Footer sx={{ position: 'absolute', bottom: '20%' }}>{'Not available yet'}</Footer>
                    ) : null
                }
            </Container>
        );
    }

    return (
        <Container>
            <Header>
                <Logo src='/app/logo-white.svg' />
                <Title>Viewing report</Title>
                <SubtitleContainer>
                    <SubtitleRow>
                        <SubtitleIcon>
                            <AccountCircleOutlinedIcon sx={{ fontSize: 'inherit' }} />
                        </SubtitleIcon>
                        <SubtitleText>{reportData ? reportData.customer : ''}</SubtitleText>
                    </SubtitleRow>
                    <SubtitleRow>
                        <SubtitleIcon>
                            <CalendarTodayOutlinedIcon sx={{ fontSize: 'inherit' }} />
                        </SubtitleIcon>
                        <SubtitleText>{reportData ? reportData.date : ''}</SubtitleText>
                    </SubtitleRow>
                    <SubtitleRow>
                        <SubtitleIcon>
                            <PlaceOutlinedIcon sx={{ fontSize: 'inherit' }} />
                        </SubtitleIcon>
                        <SubtitleText>
                            {reportData ? reportData.address : ''}
                            <br />
                            {reportData ? reportData.postcode : ''}
                            <br />
                            {`London`}
                        </SubtitleText>
                    </SubtitleRow>
                </SubtitleContainer>
            </Header>
            <ContentWrapper>
                <Summary>
                    <SummaryTitle>Summary</SummaryTitle>
                    <ReportTimeline
                        summary
                        content={reportData ? reportData.content : []}
                    />
                </Summary>
                <Content>
                    <ReportTimeline
                        content={reportData ? reportData.content : []}
                    />
                </Content>
                <Footer>{'Done with ❤️ by Clem'}</Footer>
            </ContentWrapper>
        </Container>
    );
};

export default ReportRenderer;