import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import MenuButton from '../../MenuButton/MenuButton';
import { Link } from 'react-router-dom';

export const _ViewingCard = styled(Card)(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.light,
    flexShrink: 0,
    transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
    borderRadius: '10px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    maxWidth: '450px',

    '&:hover': {
        transform: 'scale(1.02)',
        backgroundColor: theme.palette.background.default,
        boxShadow: theme.shadows[24],
    },

    '&[data-simple="true"] > div': {
        width: '100%',
    },
}));

export const PastViewingCard = styled(_ViewingCard)(({ theme }) => ({
    // backgroundColor: theme.palette.background.default,
}));

// export const ViewingCard = ({ children, ...otherProps }) => (
//     <ThemeProvider theme={invertedTheme}>
//         <_ViewingCard {...otherProps} >
//             {children}
//         </_ViewingCard>
//     </ThemeProvider>
// );

export const ViewingCard = _ViewingCard;

export const CustomerAvatar = styled(Avatar)(({ theme }) => ({
    width: 18,
    height: 32,
    backgroundColor: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 'bold',
}));

export const Time = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.secondary.main}`
}));

export const Place = styled(CustomerAvatar)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
}));

export const Info = styled(CardHeader)(() => ({
    padding: 0,
    width: 'calc(100% - 60px)',
}));

export const Status = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '60px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const SettingsMenu = styled(MenuButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
}));

export const ReportButton = styled(Button)(({ theme }) => ({
    position: 'relative',
    left: 'calc(50% - 75px)',
    height: '40px',
    width: '150px'
}));

export const ReportLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
}));

export const ConfirmCancelContainer = styled('div')(({ theme }) => ({
    // width: '200px',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 12px',
    fontWeight: 'lighter',
}));